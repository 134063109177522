import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  ADD_MI_CONVERSATION,
  GET_MI_CONVERSATION,
  SEND_MI_MESSAGE_REPLAY,
  GET_MI_WHAT_GOING_ON,
  UPDATE_MI_CONVERSATION,
  DELETE_MI_CONVERSATION,
  DELETE_MI_CONVERSATION_REPLY,
  UPDATE_MI_CONVERSATION_REPLY
} from '../../../apiRoutes';
import { toast } from 'react-toastify';
import errorHandlers from '../../../../shared/config/errorHandler';
import { setLoading } from '../../CommonSlice/commonSlice.';

interface AddConversation {
  message: string;
  visibility: number;
  sendeId: number;
}

export const addConversation = createAsyncThunk(
  'conversation/addConversation',
  async (data: AddConversation, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const response = await axios.post(`${ADD_MI_CONVERSATION}`, data);
      AppDispatch(setLoading(false));
      toast.success('Conversation added successfully');
      AppDispatch(getMiConversation(userData?.userId));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const updateConversation = createAsyncThunk(
  'conversation/updateConversation',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const response = await axios.put(`${UPDATE_MI_CONVERSATION}`, data);
      const userDataString = sessionStorage.getItem('userData');
      const userDataSession = JSON.parse(userDataString);
      const friend = localStorage.getItem('friend');
      AppDispatch(setLoading(false));
      toast.success('Conversation updated successfully');

      {
        friend == 'true'
          ? AppDispatch(
              getMiConversation(
                userDataSession?.userId ? userDataSession?.userId : userData?.userId
              )
            )
          : AppDispatch(getMiConversation(userData?.userId));
      }

      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const updateMessageReply = createAsyncThunk(
  'conversation/updateMessageReply',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const response = await axios.put(`${UPDATE_MI_CONVERSATION_REPLY}`, data);

      const userDataString = sessionStorage.getItem('userData');
      const userDataSession = JSON.parse(userDataString);
      const friend = localStorage.getItem('friend');

      AppDispatch(setLoading(false));

      if (data.whatGoing) {
        AppDispatch(getWhatGoingOn());
      } else {
        {
          friend == 'true'
            ? AppDispatch(
                getMiConversation(
                  userDataSession?.userId ? userDataSession?.userId : userData?.userId
                )
              )
            : AppDispatch(getMiConversation(userData?.userId));
        }
      }

      toast.success('Conversation updated successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteConversation = createAsyncThunk(
  'conversation/deleteConversation',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const response = await axios.delete(`${DELETE_MI_CONVERSATION}?id=${id}`);
      AppDispatch(setLoading(false));
      toast.success('Conversation deleted successfully');
      AppDispatch(getMiConversation(userData?.userId));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteMessageReply = createAsyncThunk(
  'conversation/deleteMessageReply',
  // async (id: number, { dispatch: AppDispatch }) => {
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const response = await axios.delete(
        `${DELETE_MI_CONVERSATION_REPLY}?id=${data.getReplayDeleteId}`
      );
      const userDataString = sessionStorage.getItem('userData');
      const userDataSession = JSON.parse(userDataString);
      const friend = localStorage.getItem('friend');

      AppDispatch(setLoading(false));
      toast.success('Conversation deleted successfully');
      if (data.whatGoing) {
        AppDispatch(getWhatGoingOn());
      } else {
        {
          friend == 'true'
            ? AppDispatch(
                getMiConversation(
                  userDataSession?.userId ? userDataSession?.userId : userData?.userId
                )
              )
            : AppDispatch(getMiConversation(userData?.userId));
        }
      }

      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getMiConversation = createAsyncThunk(
  'miProfile/getMiConversation',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_MI_CONVERSATION + '?userId=' + id);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

interface SendConversation {
  message: string;
  conversationReplyId: number;
  conversationId: number;
  receiverId: number;
  whatGoing?: boolean;
  userHome?: boolean;
  getterId?: number;
}

export const sendMiMessageReplay = createAsyncThunk(
  'conversation/sendMiMessageReplay',
  async (data: SendConversation, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(`${SEND_MI_MESSAGE_REPLAY}`, data);
      const userData = JSON.parse(localStorage.getItem('userData'));
      AppDispatch(setLoading(false));
      if (data.whatGoing) {
        AppDispatch(getMiConversation(data?.receiverId));
        AppDispatch(getWhatGoingOn());
      } else {
        if (data.userHome) {
          AppDispatch(getMiConversation(data?.receiverId));
        } else {
          AppDispatch(getMiConversation(data?.getterId));
        }
      }

      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

interface WahtGoingOnConversation {
  message: string;
  conversationReplyId: number;
  conversationId: number;
  receiverId: number;
}

export const getWhatGoingOn = createAsyncThunk(
  'conversation/getWhatGoingOn',
  async (_, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const response = await axios.get(`${GET_MI_WHAT_GOING_ON}`);
      AppDispatch(setLoading(false));
      AppDispatch(getMiConversation(userData?.userId));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

interface DataState {
  isLoading: boolean;
  error: string | null;
}

const initialState: DataState = {
  isLoading: false,
  error: null
};
