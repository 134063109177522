/* eslint-disable prettier/prettier */
import React from 'react';
import abstractlogo from '/public/images/abstractlogo.svg';
import formemail from '/public/images/form-email.svg';
import formpassword from '/public/images/form-password.svg';
import eyefill from '/public/images/eye-fill.svg';
import eyeofffill from '/public/images/eye-off-fill.svg';
import authlogo from '/public/images/mi-logo.svg';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { AuthSidepage, ErrorMessage } from '../../../components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { LoginUser } from '../../../store/Slices/AuthModule/AuthModuleSlice';

interface MyFormValues {
  Email: string;
  Password: string;
}

function Login() {
  { document.title = 'Mi Sports Profile - Login' }
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPass, setShowPass] = React.useState(false);
  const data = useAppSelector((state) => state?.auth);

  const LoginSchema = Yup.object().shape({
    Email: Yup.string()
      .email('Please enter valid email address')
      .required('Please enter email address'),
    Password: Yup.string()
      .required('Please enter  password')
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .strict()
    // .min(5, 'password must be greater than 5 character')
  });

  const initialValues: MyFormValues = {
    Email: '',
    Password: ''
  };

  const navigateFunction = () => {
    navigate('/');
  };
  return (
    <div className="auth-wrapper d-flex flex-wrap align-items-center">
      <div className="auth-left">
        <AuthSidepage />
      </div>
      <div className="auth-right">
        <div className="auth-logo-mobile">
          <img src={authlogo} />
        </div>
        <div className="auth-right-wrapper">
          <div className="auth-right-box">
            <div className="auth-form-top d-flex flex-wrap align-items-center justify-content-between">
              <p>Member Login</p>
              <img src={abstractlogo} />
            </div>
            <div className="auth-form">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={LoginSchema}
                onSubmit={(values, actions) => {
                  dispatch(LoginUser({ data: values, navigate: navigateFunction }));

                  // const userData: any = {
                  //   userId: 6,
                  //   userName: 'John Die',
                  //   email: 'john@email.com',
                  //   token:
                  //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2IiwiZW1haWwiOiJqb2huQGVtYWlsLmNvbSIsInVzZXJOYW1lIjoiam9obmRvZSIsIm5iZiI6MTY5MTAzNzUxNCwiZXhwIjoxNjkxNjQyMzE0LCJpYXQiOjE2OTEwMzc1MTR9._8NmbaYnmxBjaqkWZHjS2UylfR69k3Xv5_fvjbMOZAU'
                  // };
                  // localStorage.setItem('userData', JSON.stringify(userData));
                  // const retrievedObject = JSON.parse(localStorage.getItem('token'));
                  // navigate('/home');

                  actions.setSubmitting(false);
                }}>
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <InputGroup>
                      <InputGroupText>
                        <img src={formemail} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="Email"
                        type="email"
                        placeholder="Email Address"
                        value={values.Email}
                        onChange={handleChange}
                      />
                      {errors.Email && touched.Email && <ErrorMessage text={errors.Email} />}
                    </InputGroup>

                    <InputGroup>
                      <InputGroupText>
                        <img src={formpassword} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="Password"
                        type={showPass ? 'text' : 'password'}
                        placeholder="Password"
                        value={values.Password}
                        onChange={handleChange}
                      />
                      <InputGroupText
                        className="viewpassword"
                        onClick={() => setShowPass(!showPass)}>
                        {showPass ? (
                          <img src={eyefill} className="pointer" />
                        ) : (
                          <img src={eyeofffill} className="pointer" />
                        )}
                      </InputGroupText>

                      {errors.Password && touched.Password && (
                        <ErrorMessage text={errors.Password} />
                      )}
                    </InputGroup>

                    <div className="form-login">
                      <Link to="/forgot-password" className="d-flex flex-row-reverse text-dark ">
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="form-button">
                      <button type="submit" disabled={data?.loading ? true : false}>
                        {data?.loading ? 'Loading...' : 'Login'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="d-flex flex-wrap align-items-center justify-content-center form-login">
                <p className="me-1">Don't have an account?</p>
                <Link to="/register">Register now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
