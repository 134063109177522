import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { INutritionSummary } from '../../../types/miNutrition';
type ToggleFunction = () => void;
interface IProps {
  miNutritionSummary: INutritionSummary;
  modal: boolean;
  toggle: ToggleFunction;
}
interface IChart {
  // eslint-disable-next-line no-undef
  options?: ApexCharts.ApexOptions;
  // eslint-disable-next-line no-undef
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
}
export default function BreakdownModal(props: IProps) {
  const { modal, toggle, miNutritionSummary } = props;

  const dataSet: IChart = {
    series: [
      miNutritionSummary?.fatPercentage,
      miNutritionSummary?.carbohydratePercentage,
      miNutritionSummary?.proteinPercentage
    ],
    options: {
      chart: {
        width: 380,
        type: 'pie'
      },
      labels: ['Fat', 'Carbs', 'Protein'],
      colors: ['#54A613', '#B8CC08', '#2B2B2B'],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  };

  return (
    <>
      <Modal
        className="popup-wrapper nutrition-popup"
        isOpen={modal}
        toggle={toggle}
        {...arguments}
        centered
        scrollable>
        <ModalHeader toggle={toggle} className="p-0">
          Today’s Nutrition
        </ModalHeader>
        <ModalBody>
          <div className="nutrition-entry-bottom">
            <Row>
              <Col lg="6" className="mb-3">
                <div className="nutrition-info">
                  <div className="nutrition-info-header">
                    <h5>Nutritional Information</h5>
                  </div>
                  <div className="nutrition-info-content">
                    <ul>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Calories</p>
                        <span className="text-break">{miNutritionSummary?.totalCalories} kcal</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Protein</p>
                        <span className="text-break">{miNutritionSummary?.totalProtein}g</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Fat</p>
                        <span className="text-break">{miNutritionSummary?.totalFat}g</span>
                        <ul>
                          <li className="d-flex flex-wrap align-items-center">
                            <p className="text-break">Saturated Fat</p>
                            <span className="text-break">
                              {miNutritionSummary?.totalSaturatedFat}g
                            </span>
                          </li>
                          <li className="d-flex flex-wrap align-items-center">
                            <p className="text-break">Polyunsaturated Fat</p>
                            <span className="text-break">
                              {miNutritionSummary?.totalPolyunsaturatedFat}g
                            </span>
                          </li>
                          <li className="d-flex flex-wrap align-items-center">
                            <p className="text-break">Monounsaturated Fat</p>
                            <span className="text-break">
                              {miNutritionSummary?.totalMonosaturatedFat}g
                            </span>
                          </li>
                          <li className="d-flex flex-wrap align-items-center">
                            <p className="text-break">Trans Fat</p>
                            <span className="text-break">{miNutritionSummary?.totalTransFat}g</span>
                          </li>
                        </ul>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Carbohydrate</p>
                        <span className="text-break">{miNutritionSummary?.totalCarbohydrate}g</span>
                        <ul>
                          <li className="d-flex flex-wrap align-items-center">
                            <p className="text-break">Sugar</p>
                            <span className="text-break">{miNutritionSummary?.totalSugar}g</span>
                          </li>
                        </ul>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Fibre</p>
                        <span className="text-break">{miNutritionSummary?.totalFiber}g</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Cholesterol</p>
                        <span className="text-break">{miNutritionSummary?.totalCholesterol}mg</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Potassium</p>
                        <span className="text-break">{miNutritionSummary?.totalPotassium}mg</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Sodium</p>
                        <span className="text-break">{miNutritionSummary?.totalSodium}mg</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg="6">
                <div className="nutrition-info mb-3">
                  <div className="nutrition-info-header">
                    <h5>Recommended Daily Intake</h5>
                    <p>(based on 2000 cal diet)</p>
                  </div>
                  <div className="nutrition-info-content">
                    <ul>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Vitamin A</p>
                        <span className="text-break">{miNutritionSummary?.totalVitaminA}%</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Vitamin C</p>
                        <span className="text-break">{miNutritionSummary?.totalVitaminc}%</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Calcium</p>
                        <span className="text-break">{miNutritionSummary?.totalCalcium}%</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Iron</p>
                        <span className="text-break">{miNutritionSummary?.totalIron}%</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="nutrition-info mb-3">
                  <div className="nutrition-info-header">
                    <h5 className="mb-0">Distribution</h5>
                  </div>
                  <div className="nutrition-info-content">
                    <div className="d-flex flex-wrap justify-content-center mt-4 mb-4">
                      <ReactApexChart
                        options={dataSet.options}
                        series={dataSet.series}
                        type="pie"
                        width={380}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter className="justify-content-center mt-1">
          <button className="cancel-btn me-3" onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
