import React, { useState } from 'react';
import search_ic from '/public/images/search-ic.svg';
import { Col, Input, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  getMiFriendList,
  searchMyFriend,
  updateFriendStatus
} from '../../../store/Slices/userModule/MiFriends/mifriendsAction';
import no_friend from '/public/images/no-friend.svg';
import approve_ic from '/public/images/approve.svg';
import removeFriend from '/public/images/removeFriend.svg';
import ApproveRequest from '../../../components/common/ConfirmModal';
import RemoveConfirmation from '../../../components/common/ConfirmModal';
import BlockConfirmation from '../../../components/common/ConfirmModal';
import UnBlockConfirmation from '../../../components/common/ConfirmModal';
import { redirectUser_friend } from '../../../shared/utils';
import defultprofile from '/public/images/defultprofile.svg';

function NoFriend() {
  document.title = 'Mi Sports Profile - Mi Friends';
  const navigate = useNavigate();
  const [isdelete, setIsdelete] = useState(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const toggleDelete = () => setIsdelete(!isdelete);
  const [query, setQuery] = useState('');

  const [isApprove, setApproved] = useState<boolean>(false);
  const [isConformationAprrove, setIsConformationAprroved] = useState(false);

  const [isRemove, setRemoved] = useState<boolean>(false);
  const [isConformationremove, setIsConformationremove] = useState(false);
  const toggleConformationRemove = () => setIsConformationremove(!isConformationremove);

  const [isBlock, setBlocked] = useState<boolean>(false);
  const [isConformationBlock, setIsConformationBlock] = useState(false);
  const toggleConformationBlock = () => setIsConformationBlock(!isConformationBlock);

  const [isUnBlock, setUnBlocked] = useState<boolean>(false);
  const [isConformationUnBlock, setIsConformationUnBlock] = useState(false);
  const toggleConformationUnBlock = () => setIsConformationUnBlock(!isConformationUnBlock);

  const dispatch = useAppDispatch();

  const toggleConformationApprove = () => setIsConformationAprroved(!isConformationAprrove);

  const goHome = () => {
    localStorage.setItem('friend', 'true');
    {
      isFriend == 'true' ? 'true' : navigate('/frinds/home');
    }
  };
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [isFriend, setIsFriend] = React.useState('false');

  const location = useLocation();
  React.useLayoutEffect(() => {
    dispatch(getMiFriendList());
  }, []);

  const userData = JSON.parse(localStorage.getItem('userData'));

  const friendList: any = useAppSelector((state) => state?.miFriends?.getMiFriendLists);

  const userAccount = (data: any) => {
    redirectUser_friend(data?.userId);
  };
  const myProfile = (data: any) => {
    localStorage.removeItem('friend');
    sessionStorage.removeItem('userData');
    sessionStorage.clear();
    navigate('/home');
  };
  return (
    <>
      <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between">
        <h4>Mi Friends</h4>
      </div>
      {friendList?.length ? (
        <>
          <div className="friend-list d-flex flex-wrap">
            {friendList?.length ? (
              friendList?.map((data: any, index: number) => (
                <div className="friend-list-box" key={index}>
                  {userData?.userId === data?.userId ? (
                    <>
                      <div
                        className="friend-list-img position-relative mb-2"
                        onClick={() => myProfile(data)}>
                        {data.imageUrl ? (
                          <img
                            className="friend-profile-img"
                            src={process.env.CLIENT_PATH + data.imageUrl}
                            alt="Image Not Found"
                          />
                        ) : (
                          <img className="friend-profile-img" src={defultprofile} />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="friend-list-img position-relative mb-2"
                        onClick={() => userAccount(data)}>
                        {data.imageUrl ? (
                          <img
                            className="friend-profile-img"
                            src={process.env.CLIENT_PATH + data.imageUrl}
                            alt="Image Not Found"
                          />
                        ) : (
                          <img className="friend-profile-img" src={defultprofile} />
                        )}
                      </div>
                    </>
                  )}

                  <div className="row">
                    <div className="mb-1 col-md-8">
                      <p className="text-break">{data.userName}</p>
                    </div>
                    <div className="text-end mb-1 col-md-4">
                      <span className="text-break">{data.gender}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-2 col-md-8">
                      <label className="text-break">{data.location}</label>
                    </div>
                    <div className="text-end mb-2 col-md-4">
                      <span className="text-break"></span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <>
                <div className="d-flex flex-wrap justify-content-center">
                  {/* <img className="pe-none" src={no_nutrition} /> */}
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-center no-friend-found">
            <p className="mt-4 w-100 text-center mb-3">
              You currently have no Mi Sports Profile friends
            </p>
            {/* <Link to="/nofriend/findmorefriends" state={{ state_title: 'Mi friend' }}>
              <button>Finds Friends</button>
              </Link> */}
          </div>
          <div className="d-flex flex-wrap justify-content-center mt-5 mb-4">
            <img src={no_friend} />
          </div>
        </>
      )}

      {/* <Delete
        header="Delete"
        message="Are you sure you want to block?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
      /> */}

      <ApproveRequest
        header={isApprove && 'Accept Friend Request'}
        message={isApprove && 'Are you sure you want to accept friend request?'}
        modal={isConformationAprrove}
        toggle={() => {
          toggleConformationApprove();
          setApproved(false);
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: (isApprove as any)?.userId,
            userRelationShipId: (isApprove as any)?.userRelationShipId,
            status: 2,
            isApprove: true,
            isRemove: false
            // isDeleteAll: isApprove
          };
          dispatch(updateFriendStatus(payload));
          toggleConformationApprove();
        }}
        confirmNoClickClick={() => toggleConformationApprove()}
      />

      <RemoveConfirmation
        header={isRemove && 'Remove Friend'}
        message={isRemove && 'Are you sure you want to remove friend?'}
        modal={isConformationremove}
        toggle={() => {
          toggleConformationRemove();
          setRemoved(false);
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: (isRemove as any)?.userId,
            userRelationShipId: (isRemove as any)?.userRelationShipId,
            status: 3,
            isRemove: true
          };

          dispatch(updateFriendStatus(payload));
          toggleConformationRemove();
        }}
        confirmNoClickClick={() => toggleConformationRemove()}
      />

      <BlockConfirmation
        header={isBlock && 'Block Friend'}
        message={isBlock && 'Are you sure you want to block friend?'}
        modal={isConformationBlock}
        toggle={() => {
          toggleConformationBlock();
          setBlocked(false);
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: (isBlock as any)?.userId,
            userRelationShipId: (isBlock as any)?.userRelationShipId,
            status: 4,
            isRemove: false
          };
          dispatch(updateFriendStatus(payload));
          toggleConformationBlock();
        }}
        confirmNoClickClick={() => toggleConformationBlock()}
      />

      <UnBlockConfirmation
        header={isUnBlock && 'UnBlock Friend'}
        message={isUnBlock && 'Are you sure you want to unblock this friend?'}
        modal={isConformationUnBlock}
        toggle={() => {
          toggleConformationUnBlock();
          setUnBlocked(false);
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: (isUnBlock as any)?.userId,
            userRelationShipId: (isUnBlock as any)?.userRelationShipId,
            status: 4,
            isRemove: true
          };
          dispatch(updateFriendStatus(payload));
          toggleConformationUnBlock();
        }}
        confirmNoClickClick={() => toggleConformationUnBlock()}
      />
    </>
  );
}

export default NoFriend;
