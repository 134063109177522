import React from 'react';
import { Col, Row } from 'reactstrap';
import { useAppSelector } from '../../../store/hooks';
import DotSpinner from '../../common/dotSpinner';

interface SportRecordDetailComponentProps {}

const SportRecordDetail: React.FC<SportRecordDetailComponentProps> = () => {
  const sportsData = useAppSelector((state) => state.miDashboard.GetScheduleSportsListById);
  return Object.keys(sportsData).length ? (
    <div className="auth-left-wrapper">
      <Row>
        <Col md="6">
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded">
            <div className="d-flex justify-content-between">
              <span className="p-2">Sport:</span>
              <span className="p-2">{sportsData?.sportName}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Warmup details:</span>
              <span className="p-2">{sportsData?.warmUpDetails}</span>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded">
            <div className="d-flex justify-content-between">
              <span className="p-2">Duration:</span>
              <span className="p-2">{sportsData?.duration}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Outcome notes:</span>
              <span className="p-2">{sportsData?.outComeNotes}</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded d-none">
            <div className="d-flex justify-content-between">
              <h6 className="p-2">Repeat</h6>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat:</span>
              <span className="p-2">Yes</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">When does it start?</span>
              <span className="p-2">14-02-2023, 11:14</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">When does it end?</span>
              <span className="p-2">14-02-2023, 11:14</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat Days:</span>
              <span className="p-2">Sun, Mon, Tue, Wen, Thu, Fri, Sat</span>
            </div>{' '}
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat Every:</span>
              <span className="p-2">1</span>
            </div>{' '}
            <div className="d-flex justify-content-between">
              <span className="p-2">Recurrence:</span>
              <span className="p-2">Week</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <DotSpinner />
  );
};

export default SportRecordDetail;
