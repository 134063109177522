/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Col, Row, Input } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ErrorMessage } from '../../../components';
import { useAppDispatch } from '../../../store/hooks';
import { addFoodDetailEntry } from '../../../store/Slices/userModule/NutritionSlice/miNutritionAction';

interface NutritionalFood {
  food_id: number;
  measurement_description: string;
  metric_serving_amount: string;
  metric_serving_unit: string;
  calories: string;
  protein: string;
  fat: string;
  saturated_fat: string;
  polyunsaturated_fat: string;
  monounsaturated_fat: string;
  trans_fat: string;
  carbohydrate: string;
  sugar: string;
  fiber: string;
  cholesterol: string;
  potassium: string;
  sodium: string;
  vitamin_a: string;
  vitamin_c: string;
  calcium: string;
  iron: string;
}

const AddServingSchema = Yup.object().shape({
  measurement_description: Yup.string()
    .required('Please enter measurement description')
    .trim()
    .strict(true),
  metric_serving_amount: Yup.string()
    .required('Please enter metric serving amount')
    .trim()
    .strict(true),
  metric_serving_unit: Yup.string()
    .required('Please enter metric serving Unit')
    .trim()
    .strict(true),
  calories: Yup.string().required('Please enter calories').trim().strict(true),
  protein: Yup.string().required('Please enter protein').trim().strict(true),
  fat: Yup.string().required('Please enter Fat').trim().strict(true),
  saturated_fat: Yup.string().required('Please enter saturated fat').trim().strict(true),
  polyunsaturated_fat: Yup.string()
    .required('Please enter polyunsaturated fat')
    .trim()
    .strict(true),
  monounsaturated_fat: Yup.string()
    .required('Please enter monounsaturated fat')
    .trim()
    .strict(true),
  trans_fat: Yup.string().required('Please enter transfat').trim().strict(true),
  carbohydrate: Yup.string().required('Please enter carbohydrate').trim().strict(true),
  sugar: Yup.string().required('Please enter sugar').trim().strict(true),
  fiber: Yup.string().required('Please enter fiber').trim().strict(true),
  cholesterol: Yup.string().required('Please enter cholesterol').trim().strict(true),
  potassium: Yup.string().required('Please enter potassium').trim().strict(true),
  sodium: Yup.string().required('Please enter sodium').trim().strict(true),
  vitamin_a: Yup.string().required('Please enter vitamina').trim().strict(true),
  vitamin_c: Yup.string().required('Please enter itaminc').trim().strict(true),
  calcium: Yup.string().required('Please enter calcium').trim().strict(true),
  iron: Yup.string().required('Please enter iron').trim().strict(true)
  // FoodName: Yup.string().required('Please enter food name').trim().strict(true),
  // Brand: Yup.string().required('Please enter brand name').trim().strict(true)
});

const initialValues: NutritionalFood = {
  food_id: 0,
  measurement_description: '',
  metric_serving_amount: '',
  metric_serving_unit: '',
  calories: '',
  protein: '',
  fat: '',
  saturated_fat: '',
  polyunsaturated_fat: '',
  monounsaturated_fat: '',
  trans_fat: '',
  carbohydrate: '',
  sugar: '',
  fiber: '',
  cholesterol: '',
  potassium: '',
  sodium: '',
  vitamin_a: '',
  vitamin_c: '',
  calcium: '',
  iron: ''
};
function AddaServing() {
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const navigateFunction = () => {
    navigate('/minutrition/editfood', {
      state: {
        state_title: 'Mi Nutrition',
        foodData: state?.foodData,
        retrievalData: state?.retrievalData
      }
    });
  };
  return (
    <>
      <h3 className="page-heading">
        {state?.foodData ? 'Add a Serving for ' + state?.foodData?.food_name : ''}
      </h3>
      <div className="serving-table">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={AddServingSchema}
          onSubmit={(values, actions) => {
            (values.food_id = state?.foodData?.food_id),
              dispatch(addFoodDetailEntry({ data: values, navigate: navigateFunction }));
            actions.setSubmitting(false);
          }}>
          {({ errors, touched, values, handleChange }) => (
            <Form>
              <Row>
                <Col lg="6" className="mb-3">
                  <div className="serving-wrapper">
                    <div className="serving-header">
                      <h5 className="mb-0">Nutritional Information</h5>
                    </div>
                    <div className="serving-content">
                      <ul>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Measurement description</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="measurement_description"
                              value={values.measurement_description}
                              onChange={handleChange}
                              type="text"
                              placeholder="Eg. Cup or 100g"
                            />
                            {errors.measurement_description && touched.measurement_description && (
                              <ErrorMessage text={errors.measurement_description} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Metric serving amount</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="metric_serving_amount"
                              value={values.metric_serving_amount}
                              onChange={handleChange}
                              type="text"
                              placeholder="Eg. 100"
                            />
                            {errors.metric_serving_amount && touched.metric_serving_amount && (
                              <ErrorMessage text={errors.metric_serving_amount} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Metric serving unit</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="metric_serving_unit"
                              value={values.metric_serving_unit}
                              onChange={handleChange}
                              type="text"
                              placeholder="Metric serving unit"
                            />
                            {errors.metric_serving_unit && touched.metric_serving_unit && (
                              <ErrorMessage text={errors.metric_serving_unit} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Calories</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="calories"
                              value={values.calories}
                              onChange={handleChange}
                              type="text"
                              placeholder="Calories"
                            />

                            <p>Cal</p>
                            {errors.calories && touched.calories && (
                              <ErrorMessage text={errors.calories} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Protein</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="protein"
                              value={values.protein}
                              onChange={handleChange}
                              type="text"
                              placeholder="Protein"
                            />

                            <p>g</p>
                            {errors.protein && touched.protein && (
                              <ErrorMessage text={errors.protein} />
                            )}
                          </div>

                          <ul>
                            <li className="d-flex flex-wrap align-items-center">
                              <div className="serving-content-left">
                                <p>Fat</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-center">
                                <Input
                                  name="fat"
                                  value={values.fat}
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Fat"
                                />
                                <p>g</p>
                                {errors.fat && touched.fat && <ErrorMessage text={errors.fat} />}
                              </div>
                            </li>
                            <li className="d-flex flex-wrap align-items-center">
                              <div className="serving-content-left">
                                <p>Saturated fat</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-center">
                                <Input
                                  name="saturated_fat"
                                  value={values.saturated_fat}
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Saturated fat"
                                />
                                <p>g</p>
                                {errors.saturated_fat && touched.saturated_fat && (
                                  <ErrorMessage text={errors.saturated_fat} />
                                )}
                              </div>
                            </li>
                            <li className="d-flex flex-wrap align-items-center">
                              <div className="serving-content-left">
                                <p>Polyunsaturated fat</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-center">
                                <Input
                                  name="polyunsaturated_fat"
                                  value={values.polyunsaturated_fat}
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Polyunsaturated fat"
                                />
                                <p>g</p>
                                {errors.polyunsaturated_fat && touched.polyunsaturated_fat && (
                                  <ErrorMessage text={errors.polyunsaturated_fat} />
                                )}
                              </div>
                            </li>
                            <li className="d-flex flex-wrap align-items-center">
                              <div className="serving-content-left">
                                <p>Monounsaturated fat</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-center">
                                <Input
                                  name="monounsaturated_fat"
                                  value={values.monounsaturated_fat}
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Monounsaturated fat"
                                />
                                <p>g</p>
                                {errors.monounsaturated_fat && touched.monounsaturated_fat && (
                                  <ErrorMessage text={errors.monounsaturated_fat} />
                                )}
                              </div>
                            </li>
                          </ul>
                        </li>

                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Trans fat</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="trans_fat"
                              value={values.trans_fat}
                              onChange={handleChange}
                              type="text"
                              placeholder="Trans fat"
                            />
                            <p>g</p>
                            {errors.trans_fat && touched.trans_fat && (
                              <ErrorMessage text={errors.trans_fat} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Carbohydrate</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="carbohydrate"
                              value={values.carbohydrate}
                              onChange={handleChange}
                              type="text"
                              placeholder="Carbohydrate"
                            />
                            <p>g</p>
                            {errors.carbohydrate && touched.carbohydrate && (
                              <ErrorMessage text={errors.carbohydrate} />
                            )}
                          </div>
                          <ul>
                            <li className="d-flex flex-wrap align-items-center">
                              <div className="serving-content-left">
                                <p>Sugar</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-center">
                                <Input
                                  placeholder="Sugar"
                                  name="sugar"
                                  value={values.sugar}
                                  onChange={handleChange}
                                  type="text"
                                />
                                <p>g</p>
                                {errors.sugar && touched.sugar && (
                                  <ErrorMessage text={errors.sugar} />
                                )}
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Fiber</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              placeholder="Fiber"
                              name="fiber"
                              value={values.fiber}
                              onChange={handleChange}
                              type="text"
                            />
                            <p>g</p>
                            {errors.fiber && touched.fiber && <ErrorMessage text={errors.fiber} />}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Cholesterol</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              placeholder="Cholesterol"
                              name="cholesterol"
                              value={values.cholesterol}
                              onChange={handleChange}
                              type="text"
                            />
                            <p>mg</p>
                            {errors.cholesterol && touched.cholesterol && (
                              <ErrorMessage text={errors.cholesterol} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Potassium</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              placeholder="Potassium"
                              name="potassium"
                              value={values.potassium}
                              onChange={handleChange}
                              type="text"
                            />
                            <p>mg</p>
                            {errors.potassium && touched.potassium && (
                              <ErrorMessage text={errors.potassium} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Sodium</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="sodium"
                              value={values.sodium}
                              onChange={handleChange}
                              type="text"
                              placeholder="Sodium"
                            />
                            <p>mg</p>
                            {errors.sodium && touched.sodium && (
                              <ErrorMessage text={errors.sodium} />
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="mb-3">
                  <div className="serving-wrapper">
                    <div className="serving-header">
                      <h5 className="mb-0">
                        Recommended Daily Intake <span>(based on 2000 cal diet)</span>
                      </h5>
                    </div>
                    <div className="serving-content">
                      <ul>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Vitamin a</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="vitamin_a"
                              value={values.vitamin_a}
                              onChange={handleChange}
                              type="text"
                              placeholder="Vitamin a"
                            />
                            <p>%</p>
                            {errors.vitamin_a && touched.vitamin_a && (
                              <ErrorMessage text={errors.vitamin_a} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Vitamin c</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="vitamin_c"
                              value={values.vitamin_c}
                              onChange={handleChange}
                              type="text"
                              placeholder="Vitamin c"
                            />
                            <p>%</p>
                            {errors.vitamin_c && touched.vitamin_c && (
                              <ErrorMessage text={errors.vitamin_c} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Calcium</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="calcium"
                              value={values.calcium}
                              onChange={handleChange}
                              type="text"
                              placeholder="Calcium"
                            />
                            <p>%</p>
                            {errors.calcium && touched.calcium && (
                              <ErrorMessage text={errors.calcium} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <div className="serving-content-left">
                            <p>Iron</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-center">
                            <Input
                              name="iron"
                              value={values.iron}
                              onChange={handleChange}
                              type="text"
                              placeholder="Iron"
                            />
                            <p>%</p>
                            {errors.iron && touched.iron && <ErrorMessage text={errors.iron} />}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="d-flex flex-wrap align-items-center justify-content-end nutrition-btn">
                    <button className="cancel me-3" onClick={() => navigateFunction()}>
                      Cancel
                    </button>
                    <button className="add-entry" type="submit">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddaServing;
