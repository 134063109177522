/* eslint-disable prettier/prettier */
import React, { ReactNode, useEffect, useState } from 'react';
import abstractlogo from '/public/images/abstractlogo.svg';
import formemail from '/public/images/form-email.svg';
import formpassword from '/public/images/form-password.svg';
import formusername from '/public/images/form-username.svg';
import timezone from '/public/images/time-zone-fill.svg';
import eyefill from '/public/images/eye-fill.svg';
import eyeofffill from '/public/images/eye-off-fill.svg';
import authlogo from '/public/images/mi-logo.svg';
import downIcon from '/public/images/down.svg';
import searchIcon from '/public/images/search-ic.svg';
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap';
import { AuthSidepage, ErrorMessage } from '../../../components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RegisterUser } from '../../../store/Slices/AuthModule/AuthModuleSlice';

import { fetchTimeZonCommon } from '../../../store/Slices/CommonSlice/commonSlice.';
import ReactSelect, { StylesConfig } from 'react-select';
import { components } from 'react-select';

interface MyFormValues {
  email: string;
  termsAndConditions: boolean;
  userName: string;
  timeZone: number;
  password: string;
  confirmPassword: string;
}

function register() {
  {
    document.title = 'Mi Sports Profile - Register';
  }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPass, setShowPass] = useState({ password: false, onfirmPassword: false });
  const data = useAppSelector((state) => state?.auth);

  // strat
  interface StateOption {
    value: number;
    label: string;
  }

  const { timeZone } = useAppSelector((state) => state?.common);

  const [isOpen, setIsOpen] = useState(false);

  const selectStyles: StylesConfig<StateOption, false> = {
    control: (provided) => ({
      ...provided,
      minWidth: 240,
      margin: 0,
      boxShadow: 'none',
      borderColor: '#d6d6d6 !important'
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
      top: '29px',
      border: '1px solid #d6d6d6',
      borderTop: 'none',
      borderRadius: '0 0 4px 4px'
    })
  };

  const Menu = (props: JSX.IntrinsicElements['div']) => (
    <div
      className="timezone-btn"
      style={{
        backgroundColor: 'white',
        position: 'absolute',
        zIndex: 9,
        top: '51px',
        width: '100%',
        left: '0'
      }}
      {...props}
    />
  );

  const Blanket = (props: JSX.IntrinsicElements['div']) => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1
      }}
      {...props}
    />
  );

  const Dropdown = ({
    children,
    isOpen,
    target,
    onClose
  }: {
    children?: ReactNode;
    isOpen: boolean;
    target: ReactNode;
    onClose: () => void;
  }) => (
    <div className="input-group" style={{ position: 'relative' }}>
      {target}
      {isOpen && (
        <>
          <Menu>{children}</Menu>
          <Blanket onClick={onClose} />
        </>
      )}
    </div>
  );

  const CustomDropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <img
        src={searchIcon}
        alt="searchIcon icon"
        style={{ width: 20, height: 20, marginRight: 8 }}
      />
    </components.DropdownIndicator>
  );
  //end

  const navigateFunction = () => {
    navigate('/login');
  };

  useEffect(() => {
    dispatch(fetchTimeZonCommon());
  }, [dispatch]);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid email address')
      .required('Please enter email address')
      .trim()
      .strict(true),
    userName: Yup.string()
      .required('Please enter username')
      .max(15, 'Username must be less than 15 character')
      .strict(true)
      .trim('Invalid username'),
    password: Yup.string()
      .required('Please enter  password')
      .min(5, 'Password must be greater than 5 characters')
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_.])[A-Za-z\d!@#$%^&*_.]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      )
      .strict(true),
    timeZone: Yup.number().required('Please select time zone').strict(true),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Please enter confirm password')
      .strict(true),
    termsAndConditions: Yup.bool().oneOf([true], 'You need to accept the terms and conditions')
  });

  const initialValues: MyFormValues = {
    email: '',
    termsAndConditions: false,
    timeZone: null,
    userName: '',
    password: '',
    confirmPassword: ''
  };

  return (
    <div className="auth-wrapper d-flex flex-wrap align-items-center">
      <div className="auth-left">
        <AuthSidepage />
      </div>
      <div className="auth-right">
        <div className="auth-logo-mobile">
          <img src={authlogo} />
        </div>
        <div className="auth-right-wrapper">
          <div className="auth-right-box">
            <div className="auth-form-top d-flex flex-wrap align-items-center justify-content-between">
              <p>Register Now</p>

              <img src={abstractlogo} />
            </div>
            <div className="auth-form">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={RegisterSchema}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  dispatch(RegisterUser({ data: values, navigate: navigateFunction }));
                }}>
                {({ errors, touched, values, handleChange, setFieldValue }) => (
                  <Form>
                    <InputGroup>
                      <InputGroupText>
                        <img src={formusername} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="userName"
                        placeholder="Username"
                        type="text"
                        value={values.userName}
                        onChange={handleChange}
                        className="Email-field"
                      />
                      {errors.userName && touched.userName && (
                        <ErrorMessage text={errors.userName} />
                      )}
                    </InputGroup>
                    <InputGroup>
                      <InputGroupText>
                        <img src={formemail} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && <ErrorMessage text={errors.email} />}
                    </InputGroup>
                    <Dropdown
                      isOpen={isOpen}
                      onClose={() => setIsOpen(false)}
                      target={
                        <>
                          <Button
                            onClick={() => setIsOpen((prev) => !prev)}
                            isSelected={isOpen}
                            className="form-control"
                            style={{
                              background: 'none',
                              borderRadius: '50px',
                              border: '1px solid #d6d6d6'
                            }}>
                            {values.timeZone ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                  src={timezone}
                                  alt="timezone icon"
                                  style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                {timeZone.find((tz) => tz.value === values.timeZone)?.label}
                                <div className="timezone-arrow-arapper">
                                  <img
                                    className="timezone-arrow"
                                    src={downIcon}
                                    alt="downIcon icon"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: 50,
                                  paddingLeft: 0,
                                  boxShadow: 'none',
                                  border: 'none'
                                }}
                                className="form-control1">
                                <img
                                  src={timezone}
                                  alt="timezone icon"
                                  style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                Select a Time Zone
                                <div className="timezone-arrow-arapper">
                                  <img
                                    className="timezone-arrow"
                                    src={downIcon}
                                    alt="downIcon icon"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                  />
                                </div>
                              </div>
                            )}
                          </Button>
                          <>
                            {errors.timeZone && touched.timeZone && (
                              <ErrorMessage text={errors.timeZone} />
                            )}
                          </>
                        </>
                      }>
                      <ReactSelect
                        autoFocus
                        className="form-control"
                        components={{
                          IndicatorSeparator: null,
                          DropdownIndicator: CustomDropdownIndicator
                        }}
                        controlShouldRenderValue={false}
                        menuIsOpen
                        value={timeZone?.find((data) => data.value == values.timeZone)}
                        onChange={(options) => {
                          setFieldValue('timeZone', options.value);
                          setIsOpen(false);
                        }}
                        options={timeZone}
                        placeholder="Please select time zone"
                        styles={selectStyles}
                      />
                    </Dropdown>

                    <InputGroup>
                      <InputGroupText>
                        <img src={formpassword} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="password"
                        type={showPass.password ? 'text' : 'password'}
                        placeholder="Password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <InputGroupText
                        className="viewpassword"
                        onClick={() => setShowPass({ ...showPass, password: !showPass.password })}>
                        {showPass.password ? (
                          <img src={eyefill} className="pointer" />
                        ) : (
                          <img src={eyeofffill} className="pointer" />
                        )}
                      </InputGroupText>

                      {errors.password && touched.password && (
                        <ErrorMessage text={errors.password} />
                      )}
                    </InputGroup>

                    <InputGroup>
                      <InputGroupText>
                        <img src={formpassword} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="confirmPassword"
                        type={showPass.onfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                      />
                      <InputGroupText
                        className="viewpassword"
                        onClick={() =>
                          setShowPass({ ...showPass, onfirmPassword: !showPass.onfirmPassword })
                        }>
                        {showPass.onfirmPassword ? (
                          <img src={eyefill} className="pointer" />
                        ) : (
                          <img src={eyeofffill} className="pointer" />
                        )}
                      </InputGroupText>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <ErrorMessage text={errors.confirmPassword} />
                      )}
                    </InputGroup>

                    <InputGroup>
                      <div className="custom-checkbox">
                        <label className="checkbox-container">
                          I agree to the <Link to="/mitermsconditions">Terms & Conditions</Link>
                          <Input type="checkbox" tag={Field} name="termsAndConditions" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {errors.termsAndConditions && touched.termsAndConditions && (
                        <ErrorMessage text={errors.termsAndConditions} />
                      )}
                    </InputGroup>

                    <div className="form-button">
                      <button type="submit" disabled={data?.loading ? true : false}>
                        {data?.loading ? 'Loading...' : 'Register'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="d-flex flex-wrap align-items-center justify-content-center form-login">
                <p className="me-1">Already have an account?</p>
                <Link to="/login">Login now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default register;
