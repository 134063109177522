/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';

import comment_user from '/public/images/comment-user.png';
import competitors from '/public/images/competitors-img.png';
import friend_clientimg from '/public/images/mask_group.png';
import profile_placeholder from '/public/images/profile_placeholder.png';

import edit_btn from '/public/images/edit-btn.svg';
import * as Yup from 'yup';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  getSingleCompetition,
  getSingleCompetitionComments,
  postMICompetitionComment,
  CompetitionInvite,
  getAllAcceptedCompetitors,
  gameonForCompetitionList,
  updateCompetitionComment,
  deletCompetitionComment
} from '../../../store/Slices/userModule/CompetitionSlice/competitionAction';
import { useFormik } from 'formik';
import { CommentInitialVal } from '../../../types';
import { fetchMiProfileDetail } from '../../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import { getTimeDifference, redirectUser_friend } from '../../../shared/utils';
import CommonReplayConfirmModal from '../../../components/common/ConfirmModal';
import close from '../../../Assets/images/close-btn.svg';

dayjs.extend(relativeTime);

const ValidationSchema = Yup.object().shape({
  comment: Yup.string().trim().strict(false).required('Message required')
});
const initialValues: CommentInitialVal = {
  comment: '',
  competitionCommentId: 0,
  userId: null,
  competitionId: null
};

function MiCompetitionDetails() {
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const [showButton, setShowButton] = useState('false');
  const [isFriend, setIsFriend] = React.useState('false');
  const location = useLocation();
  React.useLayoutEffect(() => {
    setShowButton(localStorage.getItem('friend'));
    setIsFriend(localStorage.getItem('friend'));
  }, [location]);
  const { localUserData } = useAppSelector((state) => state.common);

  const params = useParams();

  let auth = JSON.parse(localStorage.getItem('userData'));

  const miCompetition = useAppSelector((state) => state?.miCompetition?.getSingleCompetition);

  const miCompetitionID = (miCompetition as any).competitionId;

  const userId = (miCompetition as any).userId;

  const [getReplayActiveIndex, setReplayActiveIndex] = useState<number>(null);
  const [getReplayDeleteId, setReplayDeleteId] = useState<number>(null);
  const [getReplayActiveMessageText, setReplayActiveMessageText] = useState<string>('');
  const [isReplayConformationDelete, setReplayIsConformationDelete] = useState<boolean>(false);

  const toggleReplayConformationDelete = () =>
    setReplayIsConformationDelete(!isReplayConformationDelete);

  useEffect(() => {
    if (miCompetitionID) {
      dispatch(gameonForCompetitionList(miCompetitionID));
      dispatch(getAllAcceptedCompetitors(miCompetitionID));
    }
  }, [dispatch, miCompetitionID]);

  const gameonForCompetition = useAppSelector(
    (state) => state?.miCompetition?.gameonForCompetitionList
  );

  const getSingleCompetitionCommentsData: any = useAppSelector(
    (state) => state?.miCompetition?.getSingleCompetitionComments
  );

  const getAllAcceptedCompetitorsData: any = useAppSelector(
    (state) => state?.miCompetition?.getAllAcceptedCompetitors
  );

  const totalCompetitorsCount = getAllAcceptedCompetitorsData.length;

  const [isButtonOn, setIsButtonOn] = useState(true);

  const gameon = (item: any) => {
    setIsButtonOn((prevState) => !prevState);

    const payload = {
      competitionInviteId: item?.competitionInviteId,
      competitionId: miCompetitionID,
      senderId: localUserData?.userId,
      receiverId: 0,
      isInCompetition: !item.isInCompetition
    };
    dispatch(CompetitionInvite(payload));
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values: CommentInitialVal, { resetForm }) => {
      const userData: any = JSON.parse(localStorage.getItem('userData'));
      values.userId = userData.userId;
      values.competitionId = state?.state_value;
      dispatch(postMICompetitionComment(values));
      resetForm();
    }
  });

  useEffect(() => {
    dispatch(getSingleCompetition(params?.id || state?.state_value));
    dispatch(getSingleCompetitionComments(params?.id || state?.state_value));
  }, [dispatch]);

  const { FetchMiProfileDetail, miProfileRelationCombo } = useAppSelector(
    (state) => state.miProfile
  );

  useEffect(() => {
    dispatch(fetchMiProfileDetail(localUserData?.userId || 0));
  }, [dispatch]);

  const miprofiles = (FetchMiProfileDetail as any)?.imageUrl;
  const userData: any = JSON.parse(localStorage.getItem('userData'));

  const userAccount = (data: any) => {
    redirectUser_friend(data?.userId);
  };

  return (
    <>
      <div className="competitions-detail-head page-heading-button">
        <Row className="d-flex flex-wrap align-items-top">
          <Col xl="7">
            <div className="d-flex flex-wrap align-items-center">
              <h4>{(miCompetition as any).sportName ?? 'MI Sports'}</h4>
              <p className="ms-2">
                by
                <Link to="#" className="ms-2">
                  {(miCompetition as any).createdByName}
                </Link>
              </p>
              <p className="error-mess w-100 mt-2">
                {(miCompetition as any).isEnded && 'This competition has ended.'}
              </p>
            </div>
          </Col>
          <Col
            xl="5"
            className="d-flex flex-wrap align-items-top justify-content-end competiton-edit-btn">
            <div className="d-flex flex-wrap">
              <Link to="/micompetition" state={{ state_title: 'Mi Competition' }}>
                <button>Back To Competitions</button>
              </Link>
              {auth?.userId === (miCompetition as any).userId ? (
                <Link
                  to={`/micompetition/laydownmicompetition`}
                  state={{ state_title: 'Mi Competition', state_userId: state?.state_value }}>
                  <button
                    className="heading-edit ms-3"
                    onClick={() => {
                      // e.preventDefault();
                      // dispatch(setEditVal(miCompetition as any));
                    }}>
                    <img src={edit_btn} className="me-2" />
                    Edit
                  </button>
                </Link>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
      <div className="mi-training-wrapper">
        <h5>About Competition</h5>
        <div className="mi-training-box mb-3">
          <div className="mb-2 row">
            <div className="col-md-3">
              <label>Sport:</label>
            </div>
            <div className="col">
              <p>{(miCompetition as any).name ?? 'MI Sports'}</p>
            </div>
          </div>
          <div className="mb-2 row">
            <div className="col-md-3">
              <label>Time frame:</label>
            </div>
            <div className="col">
              <p>
                {dayjs((miCompetition as any).startDate).format('h:mma')} (
                {(miCompetition as any).timeZone}){' '}
                {dayjs((miCompetition as any).startDate).format('D MMMM YYYY')} -{' '}
                {dayjs((miCompetition as any).endDate).format('h:mma')} (
                {(miCompetition as any).timeZone}){' '}
                {dayjs((miCompetition as any).endDate).format('D MMMM YYYY')}
              </p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3">
              <label>The Goal:</label>
            </div>
            <div className="col">
              <p>{(miCompetition as any).goal}</p>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-3">
              <label>Why I chose this:</label>
            </div>
            <div className="col">
              <p>{(miCompetition as any).reason}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <label>Address:</label>
            </div>
            <div className="col">
              <p>{(miCompetition as any).address}</p>
            </div>
          </div>
        </div>
      </div>
      <h6 className="comp-heading mb-3">Comments</h6>
      <form onSubmit={formik.handleSubmit}>
        <div className="write-comment mb-3">
          <div className="write-comment-top d-flex flex-wrap align-items-center">
            <div className="write-comment-img">
              {/* <img src={comment_user} /> */}
              {isFriend == 'true' ? (
                <img src={friend_clientimg} />
              ) : miprofiles ? (
                <img src={process.env.CLIENT_PATH + miprofiles} />
              ) : (
                <img src={profile_placeholder} />
              )}
            </div>
            <div className="write-comment-text-area">
              <textarea
                placeholder="Start writing here..."
                name="comment"
                value={formik.values.comment}
                disabled={dayjs().isBefore((miCompetition as any).endDate) ? false : true}
                onChange={formik.handleChange}></textarea>
            </div>
            <div
              className={
                formik.values.comment && !formik.errors.comment
                  ? 'write-comment-btn'
                  : 'write-comment-btn-disable'
              }>
              <button
                type="submit"
                disabled={formik.values.comment && !formik.errors.comment ? false : true}>
                post
              </button>
            </div>
          </div>
        </div>
      </form>
      {getSingleCompetitionCommentsData?.length ? (
        getSingleCompetitionCommentsData?.map((data: any, index: number) => (
          <div className="repaly-comment mb-2" key={index}>
            <div className="replay-top d-flex flex-wrap align-items-start">
              <div className="replay-top-img">
                {/* {isFriend == 'true' ? (
                  <img src={friend_clientimg} />
                ) : miprofiles ? (
                  <img src={process.env.CLIENT_PATH + miprofiles} />
                ) : (
                  <img src={profile_placeholder} />
                )} */}

                {data?.imageUrl ? (
                  <img src={process.env.CLIENT_PATH + data?.imageUrl} />
                ) : (
                  <img src={profile_placeholder} />
                )}
              </div>
              <div className="replay-top-content">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                  {/* {userData?.userId == data?.userId ? (
                    <h4>{data.displayName}</h4>
                  ) : (
                    <h4 onClick={() => userAccount(data)} className="cursor-pointer">
                      {data.displayName}
                    </h4>
                  )} */}

                  {userData?.userId == data?.userId ? (
                    <div className="w-100">
                      <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <h4>{data.displayName}</h4>
                        <p className="comment-status">
                          <span>{getTimeDifference(new Date(data.createdDate))}</span>
                        </p>
                      </div>
                      {getReplayActiveIndex === index ? (
                        <>
                          <div className="replay-update-bottom d-flex flex-wrap align-items-center justify-content-between">
                            <textarea
                              className="w-100-767"
                              placeholder="Write your comments..."
                              value={getReplayActiveMessageText}
                              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                setReplayActiveMessageText(e.target.value);
                              }}
                            />
                            <div className="d-flex flex-wrap align-items-center">
                              <button
                                type="button"
                                className="me-2 update-btn"
                                onClick={() => {
                                  const payload = {
                                    competitionCommentId: data?.competitionCommentId,
                                    competitionId: data?.competitionId,
                                    userId: data?.userId,
                                    comment: getReplayActiveMessageText
                                  };
                                  dispatch(updateCompetitionComment(payload));
                                  setReplayActiveIndex(null);
                                }}>
                                Update
                              </button>
                              <button
                                type="button"
                                className="rounded-circle-btn"
                                onClick={() => setReplayActiveIndex(null)}>
                                <img src={close} />
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="replay-comment-comment d-flex flex-wrap align-items-center justify-content-between">
                          <p>{data.comment}</p>
                          <div>
                            <button
                              className="me-2 bg-transparent mx-2 border-0"
                              onClick={() => {
                                setReplayActiveIndex(index);
                                setReplayActiveMessageText(data?.comment);
                              }}>
                              <img src={edit_ic} />
                            </button>
                            <button
                              className="view-all border-0 bg-transparent"
                              onClick={() => {
                                toggleReplayConformationDelete();
                                setReplayDeleteId(data?.competitionCommentId);
                              }}>
                              <img src={delete_ic} />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <h4 onClick={() => userAccount(data)} className="cursor-pointer">
                        {data.displayName}
                      </h4>
                      <p className="comment-status">
                        <span>{getTimeDifference(new Date(data.createdDate))}</span>
                      </p>
                    </>
                  )}
                </div>
                <div className="replay-comment-comment">
                  {userData?.userId == data?.userId ? '' : <p>{data.comment}</p>}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="repaly-comment mb-2">
          <div className="replay-top d-flex flex-wrap align-items-center justify-content-center lead text-muted">
            No Comments Found
          </div>
        </div>
      )}
      <p className="error-mess w-100 mt-2">
        {dayjs().isBefore((miCompetition as any).endDate) ? (
          <>
            <h6 className="comp-heading mb-3">
              {totalCompetitorsCount == 1 ? 'Competitor' : 'Competitors'}
            </h6>
            <div className="mb-3 d-flex flex-wrap align-items-center competiton-on">
              {gameonForCompetition.map((item: any, index: any) => (
                <>
                  <p className="me-3">Are you going to participate for this game?</p>
                  <div className="competitors-box" key={index}>
                    <p>
                      {item.receiverId !== userId && (
                        <button
                          onClick={() => gameon(item)}
                          className={'game-on-' + !item.isInCompetition}>
                          {!item.isInCompetition ? 'Game On' : 'Throw in the Towel'}
                        </button>
                      )}
                    </p>
                  </div>
                </>
              ))}
            </div>

            <div className="competitors-wrapper">
              <p className="competitors-heading mb-2">
                {totalCompetitorsCount} {totalCompetitorsCount == 1 ? 'Competitor' : 'Competitors'}
              </p>
              <div className="competitors-box-wrapper d-flex flex-wrap">
                {getAllAcceptedCompetitorsData.map((item: any, index: any) => (
                  <>
                    <div className="competitors-box">
                      {/* <img src={process.env.CLIENT_PATH + item?.imageUrl} /> */}
                      {item.imageUrl == null ? (
                        <img src={profile_placeholder} />
                      ) : (
                        <img src={process.env.CLIENT_PATH + item.imageUrl} />
                      )}
                      <p>{item.userName}</p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </p>

      <CommonReplayConfirmModal
        header="Delete Comment"
        message="Are you sure you want to delete this comment?"
        modal={isReplayConformationDelete}
        toggle={() => {
          toggleReplayConformationDelete();
        }}
        confirmDeleteClick={(data: any) => {
          data.getReplayDeleteId = getReplayDeleteId;
          data.competitionId = miCompetitionID;
          dispatch(deletCompetitionComment(data));
          toggleReplayConformationDelete();
        }}
        confirmNoClickClick={() => toggleReplayConformationDelete()}
      />
    </>
  );
}

export default MiCompetitionDetails;
