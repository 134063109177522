import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useAppSelector } from '../../../store/hooks';
import DotSpinner from '../../common/dotSpinner';

interface GeneralFitnessRecordDetailComponentProps {
  togModal: any;
}

const GeneralFitnessRecordDetail: React.FC<GeneralFitnessRecordDetailComponentProps> = ({
  togModal
}) => {
  const generalFitnessData = useAppSelector(
    (state) => state.miDashboard.GetScheduleGeneralListById
  );
  return Object.keys(generalFitnessData).length ? (
    <div className="auth-left-wrapper">
      <Row>
        <Col md="6">
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded">
            <div className="d-flex justify-content-between">
              <span className="p-2">Activity:</span>
              <span className="p-2">{generalFitnessData?.activity}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Duration:</span>
              <span className="p-2">{generalFitnessData?.duration}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Prior nutrition:</span>
              <span className="p-2">{generalFitnessData?.priorNutrition}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Heart rate before :</span>
              <span className="p-2">{generalFitnessData?.heartRateBefore}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Latitude:</span>
              <span className="p-2">{generalFitnessData?.latitude}</span>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded">
            <div className="d-flex justify-content-between">
              <span className="p-2">Prior activity:</span>
              <span className="p-2">{generalFitnessData?.priorActivity}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Details:</span>
              <span className="p-2">{generalFitnessData?.details}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Heart rate after:</span>
              <span className="p-2">{generalFitnessData?.heartRateAter}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Longitude:</span>
              <span className="p-2">{generalFitnessData?.longitude}</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded d-none">
            <div className="d-flex justify-content-between">
              <h6 className="p-2">Repeat</h6>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat:</span>
              <span className="p-2">Yes</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">When does it start?</span>
              <span className="p-2">14-02-2023, 11:14</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">When does it end?</span>
              <span className="p-2">14-02-2023, 11:14</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat Days:</span>
              <span className="p-2">Sun, Mon, Tue, Wen, Thu, Fri, Sat</span>
            </div>{' '}
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat Every:</span>
              <span className="p-2">1</span>
            </div>{' '}
            <div className="d-flex justify-content-between">
              <span className="p-2">Recurrence:</span>
              <span className="p-2">Week</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <DotSpinner />
  );
};

export default GeneralFitnessRecordDetail;
