import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../../CommonSlice/commonSlice.';
import axios from 'axios';
import { Combo } from '../../../../types/dashboard';
import {
  COMBO_GET_EQUIPMENT,
  COMBO_GET_EXERCISE_TYPE,
  COMBO_GET_MUSCLE_GROUP,
  COMBO_GET_SINGLE_MUSCLE_GROUP,
  CREATE_SCHEDULE_WEIGHT_SET,
  DELETE_SCHEDULE_WEIGHT_SET,
  GET_SCHEDULE_WEIGHT_LIST,
  GET_SCHEDULE_CARDIO_LAT_LONG,
  UPDATE_SCHEDULE_WEIGHT_SET,
  CREATE_SCHEDULE_GENERAL_FITNESS,
  GET_SCHEDULE_GENERAL_FITNESS_LIST,
  DELETE_SCHEDULE_GENERAL_FITNESS_SET,
  GET_SINGLE_SCHEDULE_WEIGHT_LIST,
  UPDATE_SCHEDULE_GENERAL_FITNESS,
  GET_SCHEDULE_CARDIO_LIST,
  CREATE_SCHEDULE_CARDIO,
  DELETE_SCHEDULE_CARDIO,
  UPDATE_SCHEDULE_CARDIO,
  GET_SCHEDULE_SPORTS_LIST,
  COMBO_GET_SCHEDULE_SPORTS_LIST,
  GET_PROGRAM_LIST,
  CREATE_SCHEDULE_SPORTS,
  UPDATE_SCHEDULE_SPORTS,
  DELETE_SCHEDULE_SPORTS,
  GET_DASHBOARD_SCHEDULAR,
  GET_SCHEDULE_CARDIO_LIST_BY_ID,
  CREATE_SCHEDULE_GENERAL_FITNESS_BY_ID,
  GET_SCHEDULE_SPORTS_LIST_BY_ID,
  GET_USERS_SCHEDULAR
} from '../../../apiRoutes';
import { toast } from 'react-toastify';
import errorHandlers from '../../../../shared/config/errorHandler';
import { openModal } from './dashboardSlice';

export const getDashBoardSchedular = createAsyncThunk(
  'dashboard/GetDashBoardSchedular',
  async (
    { startDate, endDate }: { startDate: string | Date; endDate: string | Date },
    { dispatch: AppDispatch }
  ) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(
        `${GET_DASHBOARD_SCHEDULAR}FromDate=${startDate}&ToDate=${endDate}`
      );
      AppDispatch(setLoading(false));
      // AppDispatch(getScheduleWeightSetList());
      // AppDispatch(getScheduleCardioList());
      // AppDispatch(getScheduleGeneralList());
      // AppDispatch(getScheduleSportsList());
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getMuscleGroupCombo = createAsyncThunk(
  'dashboard/GetMuscleGroupCombo',
  async (_, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<Combo[]>(COMBO_GET_MUSCLE_GROUP);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getSingleMuscleGroupCombo = createAsyncThunk(
  'dashboard/GetSingleMuscleGroupCombo',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<Combo[]>(COMBO_GET_SINGLE_MUSCLE_GROUP + id);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);
export const getEquipmentCombo = createAsyncThunk(
  'dashboard/GetEquipmentCombo',
  async (_, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<Combo[]>(COMBO_GET_EQUIPMENT);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getExerciseTypeCombo = createAsyncThunk(
  'dashboard/GetExerciseTypeCombo',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<Combo[]>(COMBO_GET_EXERCISE_TYPE + id);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getScheduleWeightSetList = createAsyncThunk(
  'dashboard/GetScheduleWeightSetList',
  async (_, { dispatch: AppDispatch, getState }) => {
    const state = getState();
    const date = (state as any)?.miDashboard.dates;
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(
        GET_SCHEDULE_WEIGHT_LIST + '?StartDate=' + date.startDate + '&EndDate=' + date.endDate
      );
      AppDispatch(setLoading(false));
      AppDispatch(getDashBoardSchedular({ startDate: date.startDate, endDate: date.endDate }));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);
export const getSingleScheduleWeightSetList = createAsyncThunk(
  'dashboard/GetSingleScheduleWeightSetList',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(GET_SINGLE_SCHEDULE_WEIGHT_LIST + id);
      AppDispatch(setLoading(false));
      AppDispatch(getExerciseTypeCombo((response.data as any).equipmentId));
      AppDispatch(getSingleMuscleGroupCombo((response.data as any).muscleGroupId));
      if ((response.data as any).scheduleWeightId == 0) {
        return [];
      } else {
        return response.data;
      }
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const createScheduleWeightSet = createAsyncThunk(
  'dashboard/CreateScheduleWeightSet',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(CREATE_SCHEDULE_WEIGHT_SET, data);
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleWeightSetList());
      toast.success('Weight created successfully');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      if (error?.response?.status === 412) {
        AppDispatch(openModal({ modalData: data }));
      } else {
        errorHandlers(error);
      }
    }
  }
);

export const updateScheduleWeightSet = createAsyncThunk(
  'dashboard/UpdateScheduleWeightSet',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(UPDATE_SCHEDULE_WEIGHT_SET, data);
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleWeightSetList());
      toast.success('Weight updated successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);
export const deleteScheduleWeightSet = createAsyncThunk(
  'dashboard/DeleteScheduleWeightSet',
  async (data: { id: number; isDeleteAll: boolean }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(
        DELETE_SCHEDULE_WEIGHT_SET + data.id + '?DeleteAll=' + data.isDeleteAll
      );
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleWeightSetList());
      toast.success('Weight deleted successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

// Cardio

export const getScheduleCardioList = createAsyncThunk(
  'dashboard/GetScheduleCardioList',
  async (_, { dispatch: AppDispatch, getState }) => {
    const state = getState();
    const date = (state as any)?.miDashboard.dates;
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(
        GET_SCHEDULE_CARDIO_LIST + '?StartDate=' + date.startDate + '&EndDate=' + date.endDate
      );
      AppDispatch(setLoading(false));
      AppDispatch(getDashBoardSchedular({ startDate: date.startDate, endDate: date.endDate }));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getScheduleCardioListById = createAsyncThunk(
  'dashboard/GetScheduleCardioListById',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(GET_SCHEDULE_CARDIO_LIST_BY_ID + id);
      AppDispatch(setLoading(false));
      AppDispatch(getExerciseTypeCombo((response.data as any).equipmentId));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getScheduleCardioLatLong = createAsyncThunk(
  'dashboard/GetScheduleCardioLatLong',
  async (date: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(GET_SCHEDULE_CARDIO_LAT_LONG + '/' + date);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const createScheduleCardioSet = createAsyncThunk(
  'dashboard/CreateScheduleCardioSet',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(CREATE_SCHEDULE_CARDIO, data);
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleCardioList());
      toast.success('Cardio created successfully');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      if (error?.response?.status === 412) {
        AppDispatch(openModal({ modalData: data }));
      } else {
        errorHandlers(error);
      }
    }
  }
);

export const updateScheduleCardioSet = createAsyncThunk(
  'dashboard/UpdateScheduleCardioSet',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(UPDATE_SCHEDULE_CARDIO, data);
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleCardioList());
      toast.success('Cardio updated successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteScheduleCardioSet = createAsyncThunk(
  'dashboard/DeleteScheduleCardioSet',
  async (data: { id: number; isDeleteAll: boolean }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(
        DELETE_SCHEDULE_CARDIO + data.id + '?DeleteAll=' + data.isDeleteAll
      );
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleCardioList());
      toast.success('Cardio deleted successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

// General Fitness
export const getScheduleGeneralList = createAsyncThunk(
  'dashboard/GetScheduleGeneralList',
  async (_, { dispatch: AppDispatch, getState }) => {
    const state = getState();
    const date = (state as any)?.miDashboard.dates;
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(
        GET_SCHEDULE_GENERAL_FITNESS_LIST +
          '?StartDate=' +
          date.startDate +
          '&EndDate=' +
          date.endDate
      );
      AppDispatch(getDashBoardSchedular({ startDate: date.startDate, endDate: date.endDate }));
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getScheduleGeneralListById = createAsyncThunk(
  'dashboard/GetScheduleGeneralListById',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(CREATE_SCHEDULE_GENERAL_FITNESS_BY_ID + id);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const createScheduleGeneral = createAsyncThunk(
  'dashboard/CreateScheduleGeneral',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(CREATE_SCHEDULE_GENERAL_FITNESS, data);
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleGeneralList());
      toast.success('General Fitness created successfully');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      if (error?.response?.status === 412) {
        AppDispatch(openModal({ modalData: data }));
      } else {
        errorHandlers(error);
      }
    }
  }
);

export const updateScheduleGeneral = createAsyncThunk(
  'dashboard/UpdateScheduleGeneral',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(UPDATE_SCHEDULE_GENERAL_FITNESS, data);
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleGeneralList());
      toast.success('General Fitness updated successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteScheduleGeneralFITNESSSet = createAsyncThunk(
  'dashboard/DeleteScheduleGeneralFITNESSSet',
  async (data: { id: number; isDeleteAll: boolean }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(
        DELETE_SCHEDULE_GENERAL_FITNESS_SET + data.id + '?DeleteAll=' + data.isDeleteAll
      );
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleGeneralList());
      toast.success('General Fitness deleted successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

// Sports

export const getUsersSchedular = createAsyncThunk(
  'dashboard/GetUsersSchedular',
  async (_, { dispatch: AppDispatch, getState }) => {
    const state = getState();
    const userId = JSON.parse(sessionStorage.getItem('userData'))?.userId;
    const date = (state as any)?.miDashboard.dates;

    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(
        GET_USERS_SCHEDULAR +
          '?FromDate=' +
          date.startDate +
          '&ToDate=' +
          date.endDate +
          '&userId=' +
          userId
      );
      // AppDispatch(getDashBoardSchedular({ startDate: date.startDate, endDate: date.endDate }));
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getScheduleSportsList = createAsyncThunk(
  'dashboard/GetScheduleSportsList',
  async (_, { dispatch: AppDispatch, getState }) => {
    const state = getState();
    const date = (state as any)?.miDashboard.dates;
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(
        GET_SCHEDULE_SPORTS_LIST + '?StartDate=' + date.startDate + '&EndDate=' + date.endDate
      );
      AppDispatch(getDashBoardSchedular({ startDate: date.startDate, endDate: date.endDate }));
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getScheduleSportsListById = createAsyncThunk(
  'dashboard/GetScheduleSportsListById',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(GET_SCHEDULE_SPORTS_LIST_BY_ID + id);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getSportsCombo = createAsyncThunk(
  'dashboard/GetSportsCombo',
  async (_, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(COMBO_GET_SCHEDULE_SPORTS_LIST);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getProgramLists = createAsyncThunk(
  'dashboard/GetListedProgram',
  async (_, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<any[]>(GET_PROGRAM_LIST);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const createScheduleSportsSet = createAsyncThunk(
  'dashboard/CreateScheduleSportsSet',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(CREATE_SCHEDULE_SPORTS, data);
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleSportsList());
      toast.success('Sport created successfully');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      if (error?.response?.status === 412) {
        AppDispatch(openModal({ modalData: data }));
      } else {
        errorHandlers(error);
      }
    }
  }
);

export const updateScheduleSportsSet = createAsyncThunk(
  'dashboard/UpdateScheduleSportsSet',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(UPDATE_SCHEDULE_SPORTS, data);
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleSportsList());
      toast.success('Sport updated successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteScheduleSportsSet = createAsyncThunk(
  'dashboard/DeleteScheduleSportsSet',
  async (data: { id: number; isDeleteAll: boolean }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(
        DELETE_SCHEDULE_SPORTS + data.id + '?DeleteAll=' + data.isDeleteAll
      );
      AppDispatch(setLoading(false));
      AppDispatch(getScheduleSportsList());
      toast.success('Sport deleted successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);
