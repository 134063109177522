import React from 'react';
import { Col, Row } from 'reactstrap';
import { useAppSelector } from '../../../store/hooks';
import DotSpinner from '../../common/dotSpinner';

interface CardioRecordDetailComponentProps {}

const CardioRecordDetail: React.FC<CardioRecordDetailComponentProps> = () => {
  const cardioData = useAppSelector((state) => state.miDashboard.GetSingleScheduleCardioList);
  return Object.keys(cardioData).length ? (
    <div className="auth-left-wrapper">
      <Row>
        <Col md="6">
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded">
            <div className="d-flex justify-content-between">
              <span className="p-2">Exercise equipment:</span>
              <span className="p-2">{cardioData?.equipment}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Warmup duration:</span>
              <span className="p-2">{cardioData?.warmUpDuration}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Sustained duration:</span>
              <span className="p-2">{cardioData?.sustainedDuration}</span>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded">
            <div className="d-flex justify-content-between">
              <span className="p-2">Exercise type:</span>
              <span className="p-2">{cardioData?.exerciseType}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Sustained heart rate:</span>
              <span className="p-2">{cardioData?.sustainedHeartRate}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Cooldown duration:</span>
              <span className="p-2">{cardioData?.coolDownDuration}</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <DotSpinner />
  );
};

export default CardioRecordDetail;
