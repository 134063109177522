/* eslint-disable prettier/prettier */
import React, { ReactNode, useEffect, useState } from 'react';
import { Col, FormGroup, Form, Input, Label, Row, InputGroupText, Button } from 'reactstrap';
import profile_placeholder from '/public/images/profile_placeholder.png';
import eyefill from '/public/images/eye-fill.svg';
import eyeofffill from '/public/images/eye-off-fill.svg';
import green_style_loader from '/public/images/green_style_loader.gif';
import timezone from '/public/images/time-zone-fill.svg';
import delete_ic from '/public/images/delete.svg';
import close from '/public/images/close.svg';
import { FormikValues, useFormik } from 'formik';
import downIcon from '/public/images/down.svg';
import searchIcon from '/public/images/search-ic.svg';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  fetchMiProfileDetail,
  fetchMiProfileRelationCombo,
  fetchMiSportsCombo,
  fetchMiGetUserSportData,
  saveProfilePicture,
  updateMiProfile,
  updateUserSport
} from '../../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import { fetchCommon } from '../../../store/Slices/CommonSlice/commonSlice.';
import DatePicker from 'react-datepicker';
import ReactSelect, { StylesConfig } from 'react-select';
import DotSpinner from '../../../components/common/dotSpinner';
import { changePassword } from '../../../store/Slices/AuthModule/AuthModuleSlice';
import { IProfileDetail } from '../../../types/miProfile';
import { dA } from '@fullcalendar/core/internal-common';
import { useLocation } from 'react-router-dom';
import Menu from 'react-select/dist/declarations/src/components/Menu';
import { components } from 'react-select';

interface MyFormValues {
  userName: string;
  email: string;
  gender: number;
  relationShip: number;
  dob: string;
  location: string;
  occupation: string;
  galleryImageId: string;
  timezone: number;
  basicInfoVisibility: number;
  friendVisibility: number;
  photosVisibility: number;
  miPageVisibility: number;
  isSearchable: boolean;
  isAllowFriends: boolean;
  notiftyOnFriends: boolean;
  notifyOnComments: boolean;
}

interface MyFormChangePasswordValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  email: string;
}

const genders = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Others', value: 3 }
];

function EditProfile() {
  document.title = 'Mi Sports Profile - Mi Profile';
  const location = useLocation();
  const { state } = useLocation();

  const [selectedBadge, setSelectedBadge] = useState([]);
  const [selectedBadge1, setSelectedBadge1] = useState([]);
  const [isProfileIMGRemoved, setIsProfileIMGRemoved] = useState(false);
  const [profile, setProfile] = useState(false);
  const dispatch = useAppDispatch();
  const [showPass, setShowPass] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false
  });

  const { miProfileRelationCombo, MiProfilePictureDetail } = useAppSelector(
    (state) => state.miProfile
  );
  const { miSportsCombo } = useAppSelector((state) => state.miProfile);

  const { miSportData } = useAppSelector((state) => state.miProfile);

  const [isOpen, setIsOpen] = useState(false);

  const { timeZone } = useAppSelector((state) => state?.common);

  const filterSports = (allSports: any, selectedSports: any) => {
    return allSports.filter((sport: any) => {
      return !selectedSports.some((selectedSport: any) => selectedSport.sportId === sport.value);
    });
  };

  // Usage:
  const array = filterSports(miSportsCombo, miSportData);
  const FetchMiProfileDetail: IProfileDetail = useAppSelector(
    (state) => state.miProfile.FetchMiProfileDetail
  );
  const { visibility, localUserData, partialLoading } = useAppSelector((state) => state.common);

  useEffect(() => {
    dispatch(fetchMiProfileRelationCombo());
    dispatch(fetchMiSportsCombo());
    dispatch(fetchMiGetUserSportData());
    dispatch(fetchCommon());
    dispatch(fetchMiProfileDetail(localUserData.userId || 0));
  }, [dispatch]);

  useEffect(() => {
    dispatch(saveProfilePicture([]));
    setIsProfileIMGRemoved(false);
  }, [location.pathname]);

  const removeSports = (data: any, value: any) => {
    const arrayData = miSportData.filter((item: any) => {
      if (item.sportId !== data.sportId) {
        return item.sportId;
      }
    });

    const payload = {
      userSportId: 0,
      sportId: arrayData.map((data: any) => data.sportId)
    };
    dispatch(updateUserSport(payload));
  };

  const ValidationSchema = Yup.object().shape({
    userName: Yup.string().required('Please enter user name'),
    email: Yup.string().email('Please enter valid email').required('Please enter email'),
    gender: Yup.number().required('Please select gender').min(1, 'Please select gender'),
    relationShip: Yup.number()
      .required('Please select relationship')
      .min(1, 'Please select relationship'),
    dob: Yup.string().required('Please select dob'),
    location: Yup.string().required('Please enter location'),
    occupation: Yup.string().required('Please enter occupation'),
    timezone: Yup.number().required('Please select Time Zone').min(1, 'Please select Time Zone')
  });

  const initialValues: MyFormValues = {
    userName: FetchMiProfileDetail ? FetchMiProfileDetail.displayName : '',
    email: FetchMiProfileDetail ? FetchMiProfileDetail.email : '',
    gender: FetchMiProfileDetail ? FetchMiProfileDetail.gender : null,
    relationShip: FetchMiProfileDetail ? FetchMiProfileDetail.relationShip : null,
    dob: FetchMiProfileDetail ? FetchMiProfileDetail.dob : '',
    location: FetchMiProfileDetail ? FetchMiProfileDetail.location : '',
    occupation: FetchMiProfileDetail ? FetchMiProfileDetail.occupation : '',
    galleryImageId: FetchMiProfileDetail
      ? FetchMiProfileDetail.galleryImageId
      : 'c3f6a774-cbdf-47ba-0f59-08dc008ded0a',
    timezone: FetchMiProfileDetail ? FetchMiProfileDetail.timezone : null,
    basicInfoVisibility: FetchMiProfileDetail ? FetchMiProfileDetail.basicInfoVisibility : 4,
    friendVisibility: FetchMiProfileDetail ? FetchMiProfileDetail.friendVisibility : 4,
    photosVisibility: FetchMiProfileDetail ? FetchMiProfileDetail.photosVisibility : 4,
    miPageVisibility: FetchMiProfileDetail ? FetchMiProfileDetail.miPageVisibility : 4,
    isSearchable: FetchMiProfileDetail ? FetchMiProfileDetail.isSearchable : true,
    isAllowFriends: FetchMiProfileDetail ? FetchMiProfileDetail.isAllowFriends : true,
    notiftyOnFriends: FetchMiProfileDetail ? FetchMiProfileDetail.notiftyOnFriends : true,
    notifyOnComments: FetchMiProfileDetail ? FetchMiProfileDetail.notifyOnComments : true
  };

  const initialhangePasswordValues: MyFormChangePasswordValues = {
    email: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    enableReinitialize: true,

    onSubmit: (values: any) => {
      if (Object.keys(MiProfilePictureDetail || {}).length) {
        values.galleryImageId = isProfileIMGRemoved
          ? null
          : (MiProfilePictureDetail as any).galleryImageId;
      }

      values.galleryImageId = isProfileIMGRemoved
        ? null
        : Object.keys(MiProfilePictureDetail || {}).length
        ? (MiProfilePictureDetail as any).galleryImageId
        : (FetchMiProfileDetail as any).galleryImageId;

      setProfile(false);
      values.userId = localUserData.userId || 0;
      dispatch(updateMiProfile(values));
    }
  });

  const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Please enter old password').trim().strict(true),
    newPassword: Yup.string()
      .required('Please enter  new password')
      .min(5, 'Password must be greater than 5 characters')
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_.])[A-Za-z\d!@#$%^&*_.]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
      )
      .strict(true),
    confirmPassword: Yup.string()
      .required('Please enter confirm password')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .strict(true)
  });

  const formikChangePassword = useFormik({
    initialValues: initialhangePasswordValues,
    validationSchema: ChangePasswordSchema,
    enableReinitialize: true,
    onSubmit: (values: FormikValues, resetForm) => {
      values.email = localUserData.email;
      dispatch(changePassword(values));
      formikChangePassword.resetForm();
    }
  });

  const Menu = (props: JSX.IntrinsicElements['div']) => (
    <div
      className="timezone-btn"
      style={{
        backgroundColor: 'white',
        position: 'absolute',
        zIndex: 9,
        top: '33px',
        width: '100%',
        left: 0
      }}
      {...props}
    />
  );

  interface StateOption {
    value: number;
    label: string;
  }
  const selectStyles: StylesConfig<StateOption, false> = {
    control: (provided) => ({
      ...provided,
      minWidth: 240,
      margin: 0,
      boxShadow: 'none',
      borderColor: '#d6d6d6 !important'
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
      top: '44px',
      border: '1px solid #d6d6d6',
      borderTop: 'none',
      borderRadius: '0 0 4px 4px'
    })
  };

  const CustomDropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <img
        src={searchIcon}
        alt="searchIcon icon"
        style={{ width: 20, height: 20, marginRight: 8 }}
      />
    </components.DropdownIndicator>
  );

  const Blanket = (props: JSX.IntrinsicElements['div']) => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1
      }}
      {...props}
    />
  );

  const Dropdown = ({
    children,
    isOpen,
    target,
    onClose
  }: {
    children?: ReactNode;
    isOpen: boolean;
    target: ReactNode;
    onClose: () => void;
  }) => (
    <div className="input-group" style={{ position: 'relative' }}>
      {target}
      {isOpen && (
        <>
          <Menu>{children}</Menu>
          <Blanket onClick={onClose} />
        </>
      )}
    </div>
  );

  const removeImage = () => {
    setIsProfileIMGRemoved(true);
    setProfile(false);
    localStorage.setItem('isProfileIMGRemoved', 'true');
  };

  return Object.keys(FetchMiProfileDetail).length ? (
    <>
      <Form>
        <h3 className="page-heading">Edit Mi Profile</h3>
        <Row>
          <Col lg="6">
            <div className="profile-box mb-3">
              <div className="profile-personal-detail">
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  {isProfileIMGRemoved ? (
                    <Label className="profile-personal-img position-relative col-sm-3 col-form-label">
                      <img src={profile_placeholder} alt="profile placeholder" />
                    </Label>
                  ) : (
                    <Label
                      className="profile-personal-img position-relative"
                      sm={3}
                      onMouseEnter={() => {
                        setProfile(true);
                      }}
                      onMouseLeave={() => setProfile(false)}>
                      {Object.keys(MiProfilePictureDetail || {})?.length ? (
                        <img
                          src={
                            (MiProfilePictureDetail as any)?.imageUrl
                              ? process.env.CLIENT_PATH + (MiProfilePictureDetail as any).imageUrl
                              : (FetchMiProfileDetail as any)?.imageUrl
                              ? (FetchMiProfileDetail as any).imageUrl
                              : '/UserImage/profile-pic.jpg'
                          }
                        />
                      ) : (FetchMiProfileDetail as any)?.imageUrl ? (
                        <img
                          src={
                            (FetchMiProfileDetail as any)?.imageUrl
                              ? process.env.CLIENT_PATH + (FetchMiProfileDetail as any).imageUrl
                              : '/UserImage/profile-pic.jpg'
                          }
                        />
                      ) : (
                        <img
                          src={profile_placeholder}
                          alt="profile placeholder"
                          className="removehover"
                        />
                      )}

                      {partialLoading && (
                        <span className="position-absolute top-50 start-50 loader-image-translate-middle">
                          <img src={green_style_loader} className="w-50 h-50" alt="loader" />
                        </span>
                      )}

                      {profile && (
                        <span className="position-absolute  loader-image-translate-middle-img">
                          <img
                            src={delete_ic}
                            className="pointer"
                            alt="loader"
                            onClick={() => removeImage()}
                          />
                        </span>
                      )}
                    </Label>
                  )}
                  <Col sm={4} className="file-uploader position-relative">
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={(e: any) => {
                        setIsProfileIMGRemoved(false);
                        setProfile(false);
                        localStorage.setItem('isProfileIMGRemoved', 'false');
                        // Get the selected file
                        const files = e.target.files || [];

                        // If a file is selected, dispatch the action to save it
                        if (files.length > 0) {
                          dispatch(saveProfilePicture(files[0]));
                        }
                      }}
                    />
                    <button type="button">Choose File</button>
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleEmail" sm={3}>
                    Display name
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="userName"
                      type="text"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                      placeholder="Please enter display name"
                    />
                    {formik.touched.userName && formik.errors.userName && (
                      <div className="text-danger mx-3 mt-1">
                        {formik.errors.userName as string}
                      </div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleSelect" sm={3}>
                    Gender
                  </Label>
                  <Col sm={9}>
                    <ReactSelect
                      className="react-select-dropdown"
                      options={genders}
                      placeholder="Select gender"
                      name="gender"
                      value={genders.find((data) => data.value === formik.values.gender)}
                      onChange={(option) => {
                        formik.setFieldValue('gender', +option.value);
                      }}
                      noOptionsMessage={() => <>No option available</>}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 50,
                          paddingLeft: 8,
                          boxShadow: null
                        })
                      }}
                    />
                    {formik.errors.gender && (
                      <div className="text-danger mx-3 mt-1">{formik.errors.gender as string}</div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleEmail" sm={3}>
                    Date of birth
                  </Label>
                  <Col sm={9}>
                    <DatePicker
                      autoComplete="off"
                      name="dob"
                      selected={formik?.values?.dob ? new Date(formik?.values?.dob) : null}
                      onChange={(date: Date | null) => formik.setFieldValue('dob', date)}
                      placeholderText="Select date of birth"
                      dateFormat="MM/dd/yyyy"
                      popperClassName="high-zindex-datepicker"
                    />
                    {formik.touched.dob && formik.errors.dob && (
                      <div className="text-danger mx-3 mt-1">{formik.errors.dob as string}</div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleSelect" sm={3}>
                    Relationship status
                  </Label>
                  <Col sm={9}>
                    <ReactSelect
                      className="react-select-dropdown"
                      options={miProfileRelationCombo}
                      placeholder="Select relationship status"
                      name="relationShip"
                      value={miProfileRelationCombo.find(
                        (data) => data.value === formik.values.relationShip
                      )}
                      onChange={(option) => {
                        formik.setFieldValue('relationShip', +option.value);
                      }}
                      noOptionsMessage={() => <>No option available</>}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 50,
                          paddingLeft: 8,
                          boxShadow: null
                        })
                      }}
                    />

                    {formik.touched.relationShip && formik.errors.relationShip && (
                      <div className="text-danger mx-3 mt-1">
                        {formik.errors.relationShip as string}
                      </div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleEmail" sm={3}>
                    Location
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="location"
                      type="text"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      placeholder="Please enter location"
                    />
                    {formik.touched.location && formik.errors.location && (
                      <div className="text-danger mx-3 mt-1">
                        {formik.errors.location as string}
                      </div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleEmail" sm={3}>
                    Occupation
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="occupation"
                      type="text"
                      value={formik.values.occupation}
                      onChange={formik.handleChange}
                      placeholder="Please enter occupation"
                    />
                    {formik.touched.occupation && formik.errors.occupation && (
                      <div className="text-danger mx-3 mt-1">
                        {formik.errors.occupation as string}
                      </div>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleSelect" sm={3}>
                    Timezone
                  </Label>
                  <Col sm={9}>
                    <Dropdown
                      isOpen={isOpen}
                      onClose={() => setIsOpen(false)}
                      target={
                        <>
                          <Button
                            onClick={() => setIsOpen((prev) => !prev)}
                            isSelected={isOpen}
                            className="form-control"
                            style={{
                              background: 'none',
                              borderRadius: '50px',
                              border: '1px solid #d6d6d6'
                            }}>
                            {formik.values.timezone ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                  src={timezone}
                                  alt="timezone icon"
                                  style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                {timeZone.find((tz) => tz.value === formik.values.timezone)?.label}

                                <div className="timezone-arrow-arapper">
                                  <img
                                    className="timezone-arrow"
                                    src={downIcon}
                                    alt="downIcon icon"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: 50,
                                  paddingLeft: 0,
                                  boxShadow: 'none',
                                  border: 'none'
                                }}
                                className="form-control1">
                                <img
                                  src={timezone}
                                  alt="timezone icon"
                                  style={{ width: 20, height: 20, marginRight: 8 }}
                                />
                                Select a Time Zone
                                <div className="timezone-arrow-arapper">
                                  <img
                                    className="timezone-arrow"
                                    src={downIcon}
                                    alt="downIcon icon"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                  />
                                </div>
                              </div>
                            )}
                          </Button>
                        </>
                      }>
                      <ReactSelect
                        autoFocus
                        className="form-control"
                        components={{
                          IndicatorSeparator: null,
                          DropdownIndicator: CustomDropdownIndicator
                        }}
                        controlShouldRenderValue={false}
                        menuIsOpen
                        // value={timeZone?.find((data) => data.value == values.timeZone)}
                        // onChange={(options) => {
                        //   setFieldValue('timeZone', options.value);
                        //   setIsOpen(false);
                        // }}
                        value={timeZone.find((data) => data.value === formik.values.timezone)}
                        onChange={(option) => {
                          formik.setFieldValue('timezone', +option.value);
                          setIsOpen(false);
                        }}
                        options={timeZone}
                        placeholder="Please select time zone"
                        styles={selectStyles}
                      />
                    </Dropdown>
                  </Col>
                </FormGroup>

                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleEmail" sm={3}>
                    Email address
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="email"
                      type="text"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Please enter email"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="text-danger mx-3 mt-1">{formik.errors.email as string}</div>
                    )}
                  </Col>
                </FormGroup>
              </div>
            </div>
            <div className="profile-box">
              <div className="profile-personal-detail">
                <h3 className="mb-3">Change Password</h3>

                <Form>
                  <FormGroup row className="d-flex flex-wrap align-items-center">
                    <Label for="exampleEmail" sm={3}>
                      Old Password
                    </Label>
                    <Col sm={9} className="password-wrapper">
                      <Input
                        type={showPass.oldPassword ? 'text' : 'password'}
                        autoComplete="on"
                        placeholder="Enter old password"
                        name="oldPassword"
                        value={formikChangePassword.values.oldPassword}
                        onChange={formikChangePassword.handleChange}
                      />
                      <InputGroupText
                        className="viewpassword"
                        onClick={() =>
                          setShowPass({ ...showPass, oldPassword: !showPass.oldPassword })
                        }>
                        {showPass.oldPassword ? (
                          <img src={eyefill} className="pointer" />
                        ) : (
                          <img src={eyeofffill} className="pointer" />
                        )}
                      </InputGroupText>
                      {formikChangePassword.touched.oldPassword &&
                        formikChangePassword.errors.oldPassword && (
                          <div className="text-danger mx-3 mt-1">
                            {formikChangePassword.errors.oldPassword as string}
                          </div>
                        )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="d-flex flex-wrap align-items-center">
                    <Label for="exampleEmail" sm={3}>
                      New Password
                    </Label>
                    <Col sm={9} className="password-wrapper">
                      <Input
                        type={showPass.newPassword ? 'text' : 'password'}
                        autoComplete="on"
                        placeholder="Enter new password"
                        name="newPassword"
                        value={formikChangePassword.values.newPassword}
                        onChange={formikChangePassword.handleChange}
                      />
                      <InputGroupText
                        className="viewpassword"
                        onClick={() =>
                          setShowPass({ ...showPass, newPassword: !showPass.newPassword })
                        }>
                        {showPass.newPassword ? (
                          <img src={eyefill} className="pointer" />
                        ) : (
                          <img src={eyeofffill} className="pointer" />
                        )}
                      </InputGroupText>
                      {formikChangePassword.touched.newPassword &&
                        formikChangePassword.errors.newPassword && (
                          <div className="text-danger mx-3 mt-1">
                            {formikChangePassword.errors.newPassword as string}
                          </div>
                        )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="d-flex flex-wrap align-items-center">
                    <Label for="exampleEmail" sm={3}>
                      Confirm Password
                    </Label>
                    <Col sm={9} className="password-wrapper">
                      <Input
                        type={showPass.confirmPassword ? 'text' : 'password'}
                        autoComplete="on"
                        placeholder="Confirm new password"
                        name="confirmPassword"
                        value={formikChangePassword.values.confirmPassword}
                        onChange={formikChangePassword.handleChange}
                      />
                      <InputGroupText
                        className="viewpassword"
                        onClick={() =>
                          setShowPass({ ...showPass, confirmPassword: !showPass.confirmPassword })
                        }>
                        {showPass.confirmPassword ? (
                          <img src={eyefill} className="pointer" />
                        ) : (
                          <img src={eyeofffill} className="pointer" />
                        )}
                      </InputGroupText>
                      {formikChangePassword.touched.confirmPassword &&
                        formikChangePassword.errors.confirmPassword && (
                          <div className="text-danger mx-3 mt-1">
                            {formikChangePassword.errors.confirmPassword as string}
                          </div>
                        )}
                    </Col>
                  </FormGroup>
                  <div className="mt-3 d-flex flex-wrap align-item-center justify-content-end">
                    <button
                      type="submit"
                      className="save-changes"
                      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.preventDefault();
                        formikChangePassword.handleSubmit();
                      }}>
                      Change Password
                    </button>
                  </div>
                </Form>
                {/* )} */}
                {/* </Formik> */}
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div className="profile-box mb-3">
              <div className="profile-personal-detail">
                <h3 className="mb-3">Mi Sports</h3>
                <Form>
                  <FormGroup row className="d-flex flex-wrap align-items-center">
                    <Label for="exampleEmail" sm={12} className="mb-2">
                      Enter sports that you actively participate in on a regular basis:
                    </Label>

                    <Col sm={12}>
                      <ReactSelect
                        className="react-select-dropdown"
                        value={selectedBadge1}
                        options={array}
                        placeholder={selectedBadge1 ? 'Select Mi sports' : ''}
                        onChange={(option: any) => {
                          let arrayData;
                          const data = miSportData.map((data) => {
                            return data.sportId;
                          });
                          arrayData = [option?.value, ...data];

                          const payload = {
                            userSportId: 0,
                            sportId: arrayData
                          };
                          dispatch(updateUserSport(payload));
                          setSelectedBadge1(null);
                        }}
                        noOptionsMessage={() => <>No option available</>}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: 50,
                            paddingLeft: 8,
                            boxShadow: null,
                            indicatorSeparator: null
                          })
                        }}
                      />
                    </Col>
                  </FormGroup>

                  <div className="d-flex flex-wrap align-items-center select-option-wrapper ep-select ">
                    {miSportData?.length
                      ? miSportData?.map((data: any, index: number) => (
                          <div
                            className="select-option d-flex flex-wrap align-items-center mb-3"
                            key={index}>
                            <>
                              <p>{data.sportName}</p>
                            </>
                            <button type="button">
                              <img src={close} onClick={() => removeSports(data, index)} />
                            </button>
                          </div>
                        ))
                      : formik.values.userName &&
                        state?.isError && (
                          <div className="d-flex flex-wrap align-items-center mb-3 error-msg">
                            <div className="misportError">
                              You do not have any sports selected. Please update your profile with
                              one or more sports that you participate in.
                            </div>
                          </div>
                        )}
                  </div>
                </Form>
              </div>
            </div>
            <div className="profile-box mb-3">
              <div className="profile-personal-detail">
                <h3 className="mb-3">Visibility Settings</h3>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleSelect" sm={3}>
                    Basic Info
                  </Label>
                  <Col sm={9}>
                    <ReactSelect
                      className="react-select-dropdown"
                      options={visibility}
                      placeholder="Select visibility"
                      name="basicInfoVisibility"
                      value={visibility.find(
                        (data) => data.value === formik.values.basicInfoVisibility
                      )}
                      onChange={(option) => {
                        formik.setFieldValue('basicInfoVisibility', +option.value);
                      }}
                      noOptionsMessage={() => <>No option available</>}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 50,
                          paddingLeft: 8,
                          boxShadow: null
                        })
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleSelect" sm={3}>
                    Friends
                  </Label>
                  <Col sm={9}>
                    <ReactSelect
                      className="react-select-dropdown"
                      options={visibility}
                      placeholder="Select visibility"
                      name="friendVisibility"
                      value={visibility.find(
                        (data) => data.value === formik.values.friendVisibility
                      )}
                      onChange={(option) => {
                        formik.setFieldValue('friendVisibility', +option.value);
                      }}
                      noOptionsMessage={() => <>No option available</>}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 50,
                          paddingLeft: 8,
                          boxShadow: null
                        })
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleSelect" sm={3}>
                    Photos
                  </Label>
                  <Col sm={9}>
                    <ReactSelect
                      className="react-select-dropdown"
                      options={visibility}
                      placeholder="Select visibility"
                      name="photosVisibility"
                      value={visibility.find(
                        (data) => data.value === formik.values.photosVisibility
                      )}
                      onChange={(option) => {
                        formik.setFieldValue('photosVisibility', +option.value);
                      }}
                      noOptionsMessage={() => <>No option available</>}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 50,
                          paddingLeft: 8,
                          boxShadow: null
                        })
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleSelect" sm={3}>
                    Mi Page
                  </Label>
                  <Col sm={9}>
                    <ReactSelect
                      className="react-select-dropdown"
                      options={visibility}
                      placeholder="Select visibility"
                      name="miPageVisibility"
                      value={visibility.find(
                        (data) => data.value === formik.values.miPageVisibility
                      )}
                      onChange={(option) => {
                        formik.setFieldValue('miPageVisibility', +option.value);
                      }}
                      noOptionsMessage={() => <>No option available</>}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 50,
                          paddingLeft: 8,
                          boxShadow: null
                        })
                      }}
                    />
                  </Col>
                </FormGroup>
              </div>
            </div>
            <div className="profile-box mb-3">
              <div className="profile-personal-detail">
                <h3 className="mb-3">Miscellaneous Privacy</h3>
                <div className="custom-checkbox mb-3">
                  <label className="checkbox-container">
                    Be Searchable
                    <Input
                      type="checkbox"
                      name="isSearchable"
                      checked={formik.values.isSearchable}
                      onChange={formik.handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="custom-checkbox">
                  <label className="checkbox-container">
                    Allow Friend Requests
                    <Input
                      type="checkbox"
                      name="isAllowFriends"
                      checked={formik.values.isAllowFriends}
                      onChange={formik.handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="profile-box">
              <div className="profile-personal-detail">
                <h3 className="mb-3">Email Notifications</h3>
                <div className="custom-checkbox mb-3">
                  <label className="checkbox-container">
                    Notify on friends
                    <Input
                      type="checkbox"
                      name="notiftyOnFriends"
                      checked={formik.values.notiftyOnFriends}
                      onChange={formik.handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="custom-checkbox">
                  <label className="checkbox-container">
                    Notify on comment
                    <Input
                      type="checkbox"
                      name="notifyOnComments"
                      checked={formik.values.notifyOnComments}
                      onChange={formik.handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="mt-3 d-flex flex-wrap align-item-center justify-content-end">
          {/* <button className="cancel-btn me-2" type="button">
            Cancel
          </button> */}
          <button
            type="submit"
            className="save-changes"
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault();
              formik.handleSubmit();
            }}>
            Save Changes
          </button>
        </div>
      </Form>
    </>
  ) : (
    <>
      <DotSpinner />
    </>
  );
}

export default EditProfile;
