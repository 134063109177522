import React, { useEffect, useState } from 'react';
import mi_page from '/public/images/mi-page.svg';
import whats_going_on from '/public/images/whats-going-on.svg';
import todays_program from '/public/images/todays-program-active.svg';
import mi_nutrition from '/public/images/mi-nutrition.svg';
import mi_friends from '/public/images/mi-friends.svg';
import mi_competition from '/public/images/mi-competition.svg';
import mi_training from '/public/images/mi-training.svg';
import mi_photos from '/public/images/mi-photos.svg';
import act_mi_page from '/public/images/active-mi-page.svg';
import act_whats_going_on from '/public/images/active-whats-going-on.svg';
import act_todays_program from '/public/images/active-todays-program-active.svg';
import act_mi_nutrition from '/public/images/active-mi-nutrition.svg';
import act_mi_friends from '/public/images/active-mi-friends.svg';
import act_mi_competition from '/public/images/active-mi-competition.svg';
import act_mi_training from '/public/images/active-mi-training.svg';
import act_mi_photos from '/public/images/active-mi-photos.svg';
import website from '/public/images/website.svg';
import plus_ic from '/public/images/add.svg';
import { Col, Collapse, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { Link, NavLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchMiProfileDetail } from '../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import {
  friendRequest,
  getMiFriendList,
  getsStatusOfFriend,
  searchMyFriend,
  updateFriendStatus
} from '../../store/Slices/userModule/MiFriends/mifriendsAction';
// import RemoveConfirmation from '../../../components/common/ConfirmModal';
import RemoveConfirmation from '../../components/common/ConfirmModal';
import RequestConfirmation from '../../components/common/ConfirmModal';
import ApproveRequest from '../../components/common/ConfirmModal';
import BlockConfirmation from '../../components/common/ConfirmModal';
import { TMIProfileDetail } from '../../types/miProfile';

function FriendContentSidebar() {
  // const [isAllDeleted, setAllDeleted] = useState<boolean>(false);

  const [AddFriendData, setAddFriendData] = useState<boolean>(false);
  const [isConformationdelete, setIsConformationdelete] = useState(false);
  const toggleConformationDelete = () => setIsConformationdelete(!isConformationdelete);

  const [isRemove, setRemoved] = useState<boolean>(false);
  const [isConformationremove, setIsConformationremove] = useState(false);
  const toggleConformationRemove = () => setIsConformationremove(!isConformationremove);
  const [isApprove, setApproved] = useState<boolean>(false);
  const [isConformationAprrove, setIsConformationAprroved] = useState(false);
  const [isConformationBlock, setIsConformationBlock] = useState(false);

  const toggleConformationBlock = () => setIsConformationBlock(!isConformationBlock);

  const toggleConformationApprove = () => setIsConformationAprroved(!isConformationAprrove);

  const { FetchMiProfileDetail, miProfileRelationCombo } = useAppSelector(
    (state) => state.miProfile
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getStatusOfFriend: any = useAppSelector((state) => state?.miFriends?.getStatusOfFriends);
  const friendData: TMIProfileDetail = useAppSelector(
    (state) => state.miProfile.FetchMiProfileFriendDetail
  );

  const userDataString = sessionStorage.getItem('userData');
  const userData = JSON.parse(userDataString);
  const userName = friendData?.displayName;

  const [isOpen, setIsOpen] = React.useState(false);
  const [islogout, setLogout] = React.useState(false);
  const links = [
    {
      visibility: friendData?.miPageVisibility === 3 ? true : false,
      href: '/friends/home',
      text: `${userName}'s Page`,
      img1: mi_page,
      img2: act_mi_page,
      state: { friend_state_title: 'Mi Home' }
    },
    {
      visibility: friendData?.basicInfoVisibility === 3 ? true : false,
      href: '/friends/dashboard',
      text: 'Today’s Program',
      img1: todays_program,
      img2: act_todays_program,
      state: { friend_state_title: 'Today’s Program' }
    },

    {
      visibility: friendData?.friendVisibility === 3 ? true : false,
      href: '/friends/miFriends',
      text: 'Mi Friends',
      img1: mi_friends,
      img2: act_mi_friends,
      state: { friend_state_title: 'Mi Friends' }
    },

    {
      visibility: friendData?.basicInfoVisibility === 3 ? true : false,
      href: '/friends/friendsMiTraining',
      text: 'Mi Training',
      img1: mi_training,
      img2: act_mi_training,
      state: { friend_state_title: 'Mi Training' }
    },
    {
      visibility: friendData?.photosVisibility === 3 ? true : false,
      href: '/friends/friendsphotos',
      text: 'Mi Photos',
      img1: mi_photos,
      img2: act_mi_photos,
      state: { friend_state_title: 'Mi Photos' }
    }
  ];
  const toggle = () => setIsOpen(!isOpen);
  const toggleLogout = () => setLogout(!islogout);
  const closeMenu = () => {
    // ... and this one only
    setIsOpen(false); // closes it ...
  };

  let buttonText = '';

  // Determine the button text based on the condition
  if (getStatusOfFriend === 'Status not avaliable') {
    buttonText = 'Add Friend';
  } else if (getStatusOfFriend === 'Friend') {
    buttonText = 'Remove Friend';
  } else if (getStatusOfFriend === 'Pending') {
    buttonText = 'Accept Request';
  } else if (getStatusOfFriend === 'Request Sent') {
    buttonText = 'Pending';
  }

  const sideBarAction = () => {
    if (getStatusOfFriend === 'Friend') {
      toggleConformationRemove();
    } else if (getStatusOfFriend === 'Status not avaliable') {
      toggleConformationDelete();
    } else if (getStatusOfFriend === 'Pending') {
      toggleConformationApprove();
    } else {
      //
    }
  };
  const location = useLocation();
  const { state } = location;

  const { UserID } = location.state || {};

  useEffect(() => {
    dispatch(fetchMiProfileDetail(UserID || 0));
    dispatch(getMiFriendList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getsStatusOfFriend());
  }, [dispatch]);

  useEffect(() => {
    if (friendData?.miPageVisibility === 3) {
      navigate('/friends/home');
    } else if (friendData?.basicInfoVisibility === 3) {
      navigate('/friends/dashboard');
    } else if (friendData?.friendVisibility === 3) {
      navigate('/friends/miFriends');
    } else if (friendData?.photosVisibility === 3) {
      navigate('/friends/friendsphotos');
    } else {
      navigate('/no-friend-data');
    }
  }, [friendData]);

  const filteredArray = links?.filter((item) => item.visibility);

  return (
    <>
      <Col className="content-left" md="4" lg="3" xxl="2">
        <div className="content-sidebar-sticky">
          {filteredArray?.length > 0 && (
            <div className="top-sidebar mb-3">
              <Navbar light expand="md">
                <NavbarToggler>
                  <NavbarBrand>
                    {state?.friend_state_title ? state?.friend_state_title : 'Mi Page'}{' '}
                  </NavbarBrand>
                </NavbarToggler>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    {links?.map((link, index) => {
                      return (
                        link.visibility && (
                          <NavItem className="ml-auto" key={index}>
                            <NavLink
                              key={index}
                              to={link.href}
                              onClick={closeMenu}
                              state={link.state}
                              className="d-flex flex-wrap align-items-center">
                              <img src={link.img1} className="nor-img" />{' '}
                              <img src={link.img2} className="active-img" />
                              <span>{link.text}</span>
                            </NavLink>
                          </NavItem>
                        )
                      );
                    })}
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
          )}

          {getStatusOfFriend !== 'Friend Not allowed' && (
            <div className="bottom-sidebar">
              <div className="d-flex flex-wrap justify-content-center">
                {Object.keys(buttonText).length > 0 && (
                  <button className="add-new-btn mb-3 only-first" onClick={sideBarAction}>
                    {buttonText}
                  </button>
                )}
              </div>
              {getStatusOfFriend === 'Friend' && (
                <div className="d-flex flex-wrap justify-content-center">
                  <button className="add-new-btn mb-3" onClick={toggleConformationBlock}>
                    Block
                  </button>
                </div>
              )}
              {getStatusOfFriend === 'Pending' && (
                <div className="d-flex flex-wrap justify-content-center">
                  <button className="add-new-btn mb-3" onClick={toggleConformationBlock}>
                    Block
                  </button>
                </div>
              )}
              {getStatusOfFriend === 'Request Sent' && (
                <div className="d-flex flex-wrap justify-content-center">
                  <button className="add-new-btn mb-3" onClick={toggleConformationRemove}>
                    Remove Friend
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </Col>

      <RemoveConfirmation
        header={'Remove Friend'}
        message={'Are you sure you want to remove friend?'}
        modal={isConformationremove}
        toggle={() => {
          toggleConformationRemove();
          setRemoved(false);
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: userData?.userId,
            userRelationShipId: (isRemove as any)?.userRelationShipId,
            status: 3,
            isRemove: true
          };
          dispatch(updateFriendStatus(payload));
          toggleConformationRemove();
        }}
        confirmNoClickClick={() => toggleConformationRemove()}
      />

      <RequestConfirmation
        header={AddFriendData ? '' : 'Add Friend'}
        message={AddFriendData ? '' : 'Are you sure you want to add friend?'}
        modal={isConformationdelete}
        toggle={() => {
          toggleConformationDelete();
          setAddFriendData(false);
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: userData?.userId,
            userRelationShipId: 0,
            status: 1,
            isRemove: true
          };
          dispatch(friendRequest(payload));

          toggleConformationDelete();
        }}
        confirmNoClickClick={() => toggleConformationDelete()}
      />
      <ApproveRequest
        header={'Accept Friend Request'}
        message={'Are you sure you want to accept friend request?'}
        modal={isConformationAprrove}
        toggle={() => {
          toggleConformationApprove();
          setApproved(false);
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: userData?.userId,
            userRelationShipId: userData?.relationShip,
            status: 2,
            isApprove: true,
            isRemove: false,
            isDeleteAll: isApprove
          };
          dispatch(updateFriendStatus(payload));
          toggleConformationApprove();
        }}
        confirmNoClickClick={() => toggleConformationApprove()}
      />

      <BlockConfirmation
        header={'Block Friend'}
        message={'Are you sure you want to block friend?'}
        modal={isConformationBlock}
        toggle={() => {
          toggleConformationBlock();
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: userData?.userId,
            userRelationShipId: userData?.relationShip,
            status: 4,
            isRemove: false
          };

          const handleFriendDataAndNavigate = () => {
            const friendData = localStorage.getItem('friend');
            localStorage.removeItem('friend');
            sessionStorage.removeItem('userData');
            sessionStorage.clear();
            navigate('/nofriend');
            return friendData;
          };

          const dataWithNavigate = {
            ...payload,
            navigate,
            redirectUrl: handleFriendDataAndNavigate()
          };
          dispatch(updateFriendStatus(dataWithNavigate));
          toggleConformationBlock();
        }}
        confirmNoClickClick={() => toggleConformationBlock()}
      />
    </>
  );
}

export default FriendContentSidebar;
