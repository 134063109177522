import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  CREATE_COMPETITION_INVITE,
  DELETE_SINGLE_COMPETITION,
  GET_MI_COMPETITION,
  GET_SINGLE_MI_COMPETITION,
  GET_SINGLE_MI_COMPETITION_COMMENTS,
  POST_MI_COMPETITION,
  POST_MI_COMPETITION_COMMENT,
  UPDATE_MI_COMPETITION,
  FRIENDS_NOT_INVITED_FOR_COMPETITION,
  GAME_ON_FOR_COMPETITION,
  ACCEPT_COMPETITION_INVITE,
  GET_ALL_ACCEPTED_COMPETITORS,
  GET_ALL_COMPETITORS_LIST,
  UPDATE_MI_COMPETITIOON_COMMENT,
  DELETE_MI_COMPETITIOON_COMMENT
} from '../../../apiRoutes';
import { toast } from 'react-toastify';
import errorHandlers from '../../../../shared/config/errorHandler';
import { setLoading } from '../../CommonSlice/commonSlice.';
import { CommentInitialVal } from '../../../../types';

export const fetchMiCompetition = createAsyncThunk(
  'competition/fetchMiCompetition',
  async (isCompEnded: boolean, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_MI_COMPETITION + '?CompetitionEnded=' + isCompEnded);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const getSingleCompetition = createAsyncThunk(
  'competition/getSingleCompetition',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_SINGLE_MI_COMPETITION}${id}`);
      dispatch(setLoading(false));
      dispatch(getAllCompetitorsLists(id));
      dispatch(FriendInvite(id));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const getSingleCompetitionComments = createAsyncThunk(
  'competition/getSingleCompetitionComments',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_SINGLE_MI_COMPETITION_COMMENTS}${id}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const postMICompetition = createAsyncThunk(
  'competition/postMICompetition',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(`${POST_MI_COMPETITION}`, data);
      dispatch(fetchMiCompetition(false));
      dispatch(setLoading(false));
      toast.success('Competition added successfully');
      navigate();
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);
export const postMICompetitionComment = createAsyncThunk(
  'competition/postMICompetitionComment',
  async (values: CommentInitialVal, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.put(`${POST_MI_COMPETITION_COMMENT}`, values);
      dispatch(getSingleCompetitionComments(values.competitionId));
      dispatch(setLoading(false));
      toast.success('Comment added successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const updateMICompetition = createAsyncThunk(
  'competition/uppdateMICompetition',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.put(`${UPDATE_MI_COMPETITION}`, data);
      dispatch(setLoading(false));
      dispatch(fetchMiCompetition(false));
      navigate();
      toast.success('Competition updated successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const deleteSingleCompetition = createAsyncThunk(
  'competition/deleteSingleCompetition',
  async ({ id, isCompEnded }: { id: number; isCompEnded: boolean }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(`${DELETE_SINGLE_COMPETITION}${id}`);
      dispatch(setLoading(false));
      toast.success('Competition deleted successfully');
      dispatch(fetchMiCompetition(isCompEnded));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

// export const createCompetitionInvite = createAsyncThunk(
//   'competition/createCompetitionInvite',
//   async ({ data: any }, { dispatch }) => {
//     dispatch(setLoading(true));
//     try {
//       const response = await axios.post(`${CREATE_COMPETITION_INVITE}`, data);
//       dispatch(fetchMiCompetition(false));
//       dispatch(setLoading(false));
//       toast.success('Competition added successfully');
//       // navigate();
//       return response.data;
//     } catch (error) {
//       errorHandlers(error);
//       dispatch(setLoading(false));
//     }
//   }
// );

export const createCompetitionInvite = createAsyncThunk(
  'competition/createCompetitionInvite',
  // async (data: any, { dispatch }) => {
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(CREATE_COMPETITION_INVITE, data);
      AppDispatch(fetchMiCompetition(false));
      AppDispatch(FriendInvite(data?.competitionId));
      toast.success('Invite Team Members successfully');
      AppDispatch(setLoading(false));
      navigate();
      return response?.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const FriendInvite = createAsyncThunk(
  'competition/FriendInviteds',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${FRIENDS_NOT_INVITED_FOR_COMPETITION}${id}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const gameonForCompetitionList = createAsyncThunk(
  'competition/gameonForCompetitionList',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GAME_ON_FOR_COMPETITION}${id}`);
      dispatch(fetchMiCompetition(false));
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const CompetitionInvite = createAsyncThunk(
  'competition/CompetitionInvite',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(ACCEPT_COMPETITION_INVITE, data);
      AppDispatch(getAllAcceptedCompetitors(data?.competitionId));
      AppDispatch(gameonForCompetitionList(data?.competitionId));
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getAllAcceptedCompetitors = createAsyncThunk(
  'competition/getAllAcceptedCompetitors',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_ALL_ACCEPTED_COMPETITORS}${id}`);
      dispatch(fetchMiCompetition(false));
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const getAllCompetitorsLists = createAsyncThunk(
  'competition/getAllCompetitorsLists',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_ALL_COMPETITORS_LIST}${id}`);
      dispatch(fetchMiCompetition(false));
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const updateCompetitionComment = createAsyncThunk(
  'conversation/pdateCompetitionComment',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const response = await axios.put(`${UPDATE_MI_COMPETITIOON_COMMENT}`, data);
      AppDispatch(setLoading(false));
      toast.success('Comment updated successfully');
      AppDispatch(getSingleCompetitionComments(data.competitionId));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deletCompetitionComment = createAsyncThunk(
  'conversation/deletCompetitionComment',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const response = await axios.delete(
        `${DELETE_MI_COMPETITIOON_COMMENT}?id=${data?.getReplayDeleteId}`
      );
      AppDispatch(getSingleCompetitionComments(data.competitionId));
      toast.success('Comment deleted successfully');
      AppDispatch(fetchMiCompetition(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);
