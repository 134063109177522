import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading, setPartialLoading } from '../../CommonSlice/commonSlice.';
import axios from 'axios';
import errorHandlers from '../../../../shared/config/errorHandler';
import {
  COMBO_GET_SCHEDULE_SPORTS_LIST,
  GET_MI_PROFILE_DETAIL,
  GET_MI_PROFILE_RELATION_COMBO,
  GET_SPORTS_DATA,
  GET_USER_SPORT_DATA,
  UPDATE_MI_PROFILE_DETAIL,
  UPDATE_USER_SPORT,
  ADD_FEEDBACK,
  UPLOAD_MI_PROFILE_PICTURE,
  GET_MI_PROFILE_FRIEND_DETAIL
} from '../../../apiRoutes';
import { toast } from 'react-toastify';

export const fetchMiProfileRelationCombo = createAsyncThunk(
  'miProfile/FetchMiProfileRelationCombo',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_MI_PROFILE_RELATION_COMBO);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const fetchMiSportsCombo = createAsyncThunk(
  'miProfile/fetchMiSportsCombo',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(COMBO_GET_SCHEDULE_SPORTS_LIST);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const fetchMiProfileDetail = createAsyncThunk(
  'miProfile/FetchMiProfileDetail',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_MI_PROFILE_DETAIL + id);
      dispatch(setLoading(false));
      localStorage.setItem('baseUtcOffsetSec', response.data.baseUtcOffsetSec);
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const fetchMiProfileFriendDetail = createAsyncThunk(
  'miProfile/fetchMiProfileFriendDetail',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_MI_PROFILE_FRIEND_DETAIL + id);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const updateMiProfile = createAsyncThunk(
  'dashboard/UpdateMiProfile',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(UPDATE_MI_PROFILE_DETAIL, data);
      AppDispatch(setLoading(false));
      AppDispatch(fetchMiProfileDetail(data.userId));
      toast.success('Profile updated successfully');
      if (response?.data?.token) {
        localStorage.setItem('token', JSON.stringify(response?.data?.token));
      }
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

// mi sport data

export const fetchMiGetUserSportsData = createAsyncThunk(
  'miProfiles/FetchMiGetUserSportsData',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_SPORTS_DATA);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const fetchMiGetUserSportData = createAsyncThunk(
  'miProfile/FetchMiGetUserSportData',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_USER_SPORT_DATA);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const updateUserSport = createAsyncThunk(
  'dashboard/UpdateUserSport',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(UPDATE_USER_SPORT, data);
      AppDispatch(setLoading(false));
      AppDispatch(fetchMiGetUserSportData());
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

// End mi sport Data

export const saveProfilePicture = createAsyncThunk(
  'dashboard/SaveProfilePicture',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setPartialLoading(true));
    const formData = new FormData();
    formData.append('file', data);
    try {
      if (data?.name) {
        const response = await axios.post(UPLOAD_MI_PROFILE_PICTURE, formData);
        AppDispatch(setPartialLoading(false));
        return response.data;
      }
      AppDispatch(setPartialLoading(false));
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setPartialLoading(false));
    }
  }
);

export const AddFeedback = createAsyncThunk(
  'dashboard/AddFeedback',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    AppDispatch(setPartialLoading(true));
    try {
      const response = await axios.post(ADD_FEEDBACK, data);
      AppDispatch(setLoading(false));
      AppDispatch(setPartialLoading(false));
      toast.success('Inquiry sent Successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setPartialLoading(false));
    }
  }
);
