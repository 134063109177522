import React, { useState } from 'react';
import search_ic from '/public/images/search-ic.svg';
import block_fnd from '/public/images/block-fnd.svg';
import add_friend from '/public/images/add-friend.svg';
import friend from '/public/images/friend.png';
import { Col, Input, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Delete from '../../../components/DeleteComponent/delete';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { searchMyFriend } from '../../../store/Slices/userModule/MiFriends/mifriendsAction';
import defultprofile from '/public/images/defultprofile.svg';

function MiFriend() {
  document.title = 'Mi Sports Profile - Mi Friends';
  const navigate = useNavigate();
  const [isdelete, setIsdelete] = useState(false);
  const [pageNum, setPageNum] = useState<number>(1);
  const toggleDelete = () => setIsdelete(!isdelete);
  const [query, setQuery] = useState('');
  const dispatch = useAppDispatch();

  const goHome = () => {
    localStorage.setItem('friend', 'true');
    {
      isFriend == 'true' ? 'true' : navigate('/frinds/home');
    }
  };
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [isFriend, setIsFriend] = React.useState('false');
  const location = useLocation();
  React.useLayoutEffect(() => {
    setIsFriend(localStorage.getItem('friend'));
    dispatch(searchMyFriend({ search: query }));
  }, [location]);
  const searchMyFriends: any = useAppSelector((state) => state.miFriends.searchMyFriends);

  return (
    <>
      <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between">
        <h4>Mi Friends</h4>
        {isFriend == 'true' ? (
          ''
        ) : (
          <Link to="/nofriend/findmorefriends">
            <button>Find More Friends</button>
          </Link>
        )}
      </div>
      <div className="friend-search d-flex flex-wrap align-items-center mb-3">
        <div className="friend-search-left position-relative">
          <Input
            type="text"
            placeholder="Search..."
            value={query}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
            }}
          />
          <span>
            <img src={search_ic} />
          </span>
        </div>
        <div className="friend-search-right">
          <button
            onClick={() => {
              dispatch(searchMyFriend({ search: query }));
              setQuery('');
            }}>
            Search
          </button>
        </div>
      </div>
      <div className="friend-list d-flex flex-wrap">
        {searchMyFriends?.length ? (
          searchMyFriends?.map((data: any, index: number) => (
            <div className="friend-list-box" key={index}>
              <div className="friend-list-img position-relative mb-2">
                {data.imageUrl ? (
                  <img
                    className="friend-profile-img"
                    src={process.env.CLIENT_PATH + data.imageUrl}
                    alt="Image Not Found"
                  />
                ) : (
                  <img className="friend-profile-img" alt="Image Not Found" src={defultprofile} />
                )}

                <button className="add-friend">
                  <img src="/4a0c0e2e3ca932e09ac0.svg" />
                </button>
                <button className="block-friend">
                  <img src="/5f699304fa771b829f14.svg" />
                </button>
              </div>
              <div className="row">
                <div className="mb-1 col-md-8">
                  <p className="text-break">{data.name}</p>
                </div>
                <div className="text-end mb-1 col-md-4">
                  <span className="text-break">{data.genderName}</span>
                </div>
              </div>
              <div className="row">
                <div className="mb-2 col-md-8">
                  <label className="text-break">{data.location}</label>
                </div>
                <div className="text-end mb-2 col-md-4">
                  <span className="text-break"></span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="d-flex flex-wrap justify-content-center">
              {/* <img className="pe-none" src={no_nutrition} /> */}
            </div>
          </>
        )}
      </div>
      <div className="pagination-wrapper d-flex flex-wrap align-items-center justify-content-center">
        {/* <Pagination onChange={(e: React.FormEvent<HTMLElement>) => console.log(e.target)}>
          {/* <PaginationItem>
                    <PaginationLink first href="#" />
                  </PaginationItem> */}
        {/* <PaginationItem>
          <PaginationLink href="#" previous className="prev" />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#" className="active">
            1
          </PaginationLink>
        </PaginationItem>
        {pages.map((data, index) => (
          <PaginationItem
            key={index}
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.preventDefault();
              setPageNum(index + 1);
            }}>
            <PaginationLink href="#" className={pageNum === index + 1 ? 'active' : ''}>
              {data}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationLink href="#">3</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#">4</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#">5</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          <PaginationLink href="#" next className="next" />
        </PaginationItem> */}
        {/* <PaginationItem>
                    <PaginationLink href="#" last />
                  </PaginationItem> */}
        {/* </Pagination> */}
      </div>
      <Delete
        header="Delete"
        message="Are you sure you want to block?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
      />
    </>
  );
}

export default MiFriend;
