import React, { useCallback, useEffect, useRef, useState } from 'react';

import friend from '/public/images/friend.png';
import view_photo from '/public/images/view-photo.svg';
import dont_view_photo from '/public/images/dont-view-photo.svg';
import { Link, useLocation } from 'react-router-dom';
import {
  FormGroup,
  // Form,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { ErrorMessage } from '../../../components';

import { Form, Formik, Field, FormikValues } from 'formik';
import delete_bin from '/public/images/delete-bin.svg';
import * as Yup from 'yup';
import {
  deleteGalleryFolder,
  getAllGallery,
  postGalleryImage,
  uploadImage
} from '../../../store/Slices/userModule/AlbumSlice/albumAction';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ReactSelect from 'react-select';
import NoDataFound from '../../../components/common/NoDataFound';
import { fetchMiProfileDetail } from '../../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import { IProfileDetail } from '../../../types/miProfile';
import CommonDelete from '../../../components/DeleteComponent/commonDelete';
import { getFriendGallerys } from '../../../store/Slices/userModule/MiFriends/mifriendsAction';
import defultprofile from '/public/images/defultprofile.svg';

interface albumFormValues {
  galleryId: number;
  userId: number;
  title: string;
  galleryImage: any[];
}

const PhotosSchema = Yup.object().shape({
  title: Yup.string().required('Please select album or create new'),
  galleryImage: Yup.mixed().required('Please upload an image')
});

interface IGallery {
  title?: string;
  label?: string;
  value?: number;
  galleryId?: number;
  photosCount?: number;
  imageUrlANDGuid?: number;
}

function Photos() {
  document.title = 'Mi Sports Profile - Mi Photos'

  const [isdelete, setIsdelete] = useState(false);
  const toggleDelete = () => setIsdelete(!isdelete);
  const [currentImage, setCurrentImage] = React.useState();

  const dispatch = useAppDispatch();
  const uploadImageData = useAppSelector((state) => state.miAlbum.UploadedImageResponse);
  const galleryData: IGallery[] = useAppSelector((state) => state.miAlbum.GetAllGallery);
  const getFriendGallery: any = useAppSelector((state) => state.miAlbum.GetFriendGallery);

  const localUser = useAppSelector((state) => state.common.localUserData);

  const [previewImages, setPreviewImages] = useState([]);

  const GetAlbumCombo: IGallery[] = galleryData?.map((data: any) => ({
    label: data.title,
    value: data.galleryId
  }));

  const handleFileSelect = (event: any) => {
    const files = event.target.files;
    const imagePreviews = new Array();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        imagePreviews.push(reader.result);
        if (imagePreviews.length === files.length) {
          setPreviewImages((previewImages) => [...previewImages, ...imagePreviews]);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  // END
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [isFriend, setIsFriend] = React.useState('false');
  React.useLayoutEffect(() => {
    setIsFriend(localStorage.getItem('friend'));
  }, [location]);

  const [show, setShow] = React.useState(true);
  useEffect(() => {
    return () => {
      setPreviewImages([]);
    };
  }, [modal]);

  useEffect(() => {
    dispatch(getAllGallery());
    dispatch(getFriendGallerys());
  }, [dispatch]);

  const initialValues: albumFormValues = {
    galleryId: 0,
    userId: 0,
    title: '',
    galleryImage: null
  };

  const FetchMiProfileDetail: IProfileDetail = useAppSelector(
    (state) => state.miProfile.FetchMiProfileDetail
  );

  const { localUserData } = useAppSelector((state) => state.common);

  useEffect(() => {
    dispatch(fetchMiProfileDetail(localUserData?.userId || 0));
  }, [dispatch]);

  const onGalleryImageChange = useCallback((e: any) => {
    handleFileSelect(e);
    dispatch(uploadImage(e.target.files));
  }, []);

  const isVideo = (url: any) => {
    return /\.(mp4|webm|ogg|mov)$/i.test(url);
  };

  return (
    <>
      {isFriend == 'true' ? (
        ''
      ) : (
        <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between lay-down-wrapper">
          <h4>{(FetchMiProfileDetail as any)?.displayName}’s Albums</h4>
          <button onClick={toggle}>Upload Photo</button>
        </div>
      )}
      <div className="friend-list d-flex flex-wrap">
        {/* <div className="friend-list-box">
          <Link to="/photos/album">
            <div className="friend-list-img position-relative mb-2">
              <img src={friend} />
            </div>
            <div className="photo-content d-flex flex-wrap">
              <p className="text-break">New Album</p>
              <img src={view_photo} />
              <label className="text-break">2 Photos</label>
            </div>
          </Link>
        </div> */}
        {Object.keys(getFriendGallery).length ? (
          getFriendGallery.map((data: any, index: number) => (
            <div className="friend-list-box position-relative" key={index}>
              <div className="delete-photo">
                {/* <button
                  className="delete-btn-albums"
                  onClick={() => {
                    toggleDelete();
                    setCurrentImage(data.galleryId);
                  }}>
                  <img src={delete_bin} />
                </button> */}
              </div>
              <Link to="/friend/friend-album-detail" state={{ gallaryData: data }}>
                <div className="friend-list-img position-relative mb-2">
                  {/* <img className='friend-photo' src={process.env.CLIENT_PATH + data?.imageUrlANDGuid[0]?.imageUrl} /> */}
                  {isVideo(data?.imageUrlANDGuid[0]?.imageUrl) || data.photosCount == 0 ? (
                    <img className="friend-profile-img cursor-pointer" src={defultprofile} />
                  ) : (
                    <img
                      className="friend-photo"
                      src={process.env.CLIENT_PATH + data?.imageUrlANDGuid[0]?.imageUrl}
                    />
                  )}
                </div>

                <div className="photo-content d-flex flex-wrap">
                  <p className="text-break">{data.title}</p>
                  {/* <img src={dont_view_photo} /> */}
                  <label className="text-break">{data.photosCount}</label>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <NoDataFound message="No Album Found" />
        )}
      </div>
      {/* popup start */}
      <Modal
        className="popup-wrapper nutrition-popup"
        isOpen={modal}
        toggle={toggle}
        {...arguments}
        centered
        scrollable>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={PhotosSchema}
          onSubmit={(values: FormikValues, actions) => {
            actions.setSubmitting(false);
            values.userId = localUser.userId;
            values.galleryImage = uploadImageData;
            values.isPublic = true;
            dispatch(postGalleryImage(values));
            toggle();
          }}>
          {({ errors, touched, values, handleChange, setFieldValue }) => (
            <Form>
              <ModalHeader toggle={toggle} className="p-0">
                Add Photo
              </ModalHeader>
              <ModalBody>
                <div className="upload-photo-popup">
                  <div className="upload-photo-popup-top d-flex flex-wrap align-items-center mb-4">
                    <p>Album name: </p>
                    <div className="ms-2 d-flex flex-wrap align-items-center position-relative">
                      <div className="ms-2 d-flex flex-wrap align-items-center">
                        {show || (
                          // <InputGroup>
                          //   <select
                          //     className="me-2"
                          //     name="title"
                          //     value={values.title}
                          //     onChange={handleChange}>
                          //     <option value="">Choose...</option>
                          //     <option>Album 1</option>
                          //     <option>Album 2</option>
                          //     <option>Album 3</option>
                          //     <option>Album 4</option>
                          //   </select>
                          //   {errors.title && touched.title && <ErrorMessage text={errors.title} />}
                          // </InputGroup>
                          <>
                            <ReactSelect
                              className="react-select-dropdown"
                              options={GetAlbumCombo}
                              placeholder="Select album"
                              name="title"
                              onChange={(option) => {
                                setFieldValue('galleryId', option.value);
                                setFieldValue('title', option.label);
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: 50,
                                  paddingLeft: 8,
                                  boxShadow: null
                                })
                              }}
                            />
                            {errors.title && touched.title && <ErrorMessage text={errors.title} />}
                          </>
                        )}
                      </div>
                      {show && (
                        <>
                          <Input
                            tag={Field}
                            type="text"
                            name="title"
                            id="albumName"
                            className="me-2"
                            value={values.title}
                            onChange={handleChange}
                          />
                          {errors.title && touched.title && <ErrorMessage text={errors.title} />}
                        </>
                      )}
                      <label onClick={() => setShow(!show)} className="ms-3 pointer">
                        {show ? 'or select an existing album' : 'create a new album'}
                      </label>
                    </div>
                  </div>

                  <div className="upload-photo-popup-content d-flex flex-wrap align-items-center justify-content-center position-relative mb-3">
                    <FormGroup>
                      <Input
                        id="exampleFile"
                        name="galleryImage"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={(e) => {
                          handleFileSelect(e);
                          dispatch(uploadImage(e.target.files));
                          setFieldValue('galleryImage', e.target.files);
                        }}
                      />

                      <label>Click here or drag your photos here to upload them.</label>

                      {errors.galleryImage && touched.galleryImage && (
                        <ErrorMessage text={errors.galleryImage} />
                      )}
                    </FormGroup>
                  </div>
                </div>
                <div className="d-flex flex-wrap friend-list friend-list-popup">
                  {' '}
                  {previewImages.map((previewImage, index) => (
                    <>
                      <div className="friend-list-box text-center">
                        <img
                          key={index}
                          src={previewImage}
                          alt={`Preview ${index}`}
                          width={150}
                          height={150}
                        />
                      </div>
                    </>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center mt-1">
                <button className="save-record me-3" type="submit">
                  Save Album
                </button>
                <button className="cancel-btn" onClick={toggle}>
                  Cancel
                </button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <CommonDelete
        header="Delete Albums"
        message="Are you sure you want to delete this Albums ?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
        confirmDeleteClick={async () => {
          await dispatch(deleteGalleryFolder(currentImage));
          toggleDelete();
        }}
      />
    </>
  );
}

export default Photos;
