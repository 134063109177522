import React, { useCallback, useState } from 'react';
import { Table } from 'reactstrap';
import { items } from './profileTableUtils';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import up_ic from '/public/images/up.svg';
import down_ic from '/public/images/down.svg';
import AddUpdateModal from './AddUpdateModal/index';
import CommonDelete from '../DeleteComponent/commonDelete';
import {
  deleteScheduleCardioSet,
  getScheduleCardioListById
} from '../../store/Slices/userModule/DashboardSlice/dashboardAction';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import dayjs from 'dayjs';
import { Icardio } from '../../types/dashboard';

function CardioTable(props: any) {
  const [isAllDeleted, setAllDeleted] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [isdelete, setIsdelete] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('Cardio');
  const [currentCardioData, setCurrentCardioData] = useState<Icardio>();
  const [active, setActive] = useState<number>();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(null);

  const toggleImage = (index: number) => {
    setActiveTabIndex((prevIndex: number) => (prevIndex === index ? null : index));
    setActive((prevIndex: number) => (prevIndex === index ? null : index));
  };

  const dispatch = useAppDispatch();
  const currentCardio = useAppSelector((state) => state.miDashboard.GetSingleScheduleCardioList);

  const searchBarProps = {
    modal: modal,
    isEditMode: true,
    currentCardioData: currentCardioData,
    modalTitle: modalTitle,
    toggle: (name: string) => toggle(name)
  };

  const toggle = async (name: string) => {
    await setModal(!modal);
    await setModalTitle(name);
  };
  const toggleDelete = () => setIsdelete(!isdelete);

  return (
    <>
      {Object.keys(props.tableData).length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Exercise</th>
              <th>Warm Up Duration</th>
              {/* <th>Sus. Heart Rate</th> */}
              <th>Sus. H/R Duration</th>
              <th>Cooldown Duration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props?.tableData?.map((item: Icardio, rowIndex: number) => (
              <React.Fragment key={rowIndex}>
                <tr key={item.id}>
                  <td>{dayjs((item as Icardio)?.date)?.format('MM/DD/YYYY')}</td>
                  <td>{item.exerciseType}</td>
                  <td>{item.warmUpDuration}</td>
                  {/* <td>{item.sustainedHeartRate}</td> */}
                  <td>{item.sustainedDuration}</td>
                  <td>{item.coolDownDuration}</td>
                  <td>
                    <button
                      className="me-2"
                      onClick={() => {
                        toggle('Cardio');
                        setCurrentCardioData(item);
                      }}>
                      <img src={edit_ic} />
                    </button>
                    <button
                      className="me-2"
                      onClick={() => {
                        toggleDelete();
                        setCurrentCardioData(item);
                        item.scheduleCardioListModels.length === 0 ? setAllDeleted(false) : setAllDeleted(true)
                      }}>
                      <img src={delete_ic} />
                    </button>
                    {item.scheduleCardioListModels.length === 0 ? <></> : <><button onClick={() => toggleImage(rowIndex + 1)}>
                      <img src={active === rowIndex + 1 ? up_ic : down_ic} alt="Image" />
                    </button></>}
                  </td>
                </tr>
                {activeTabIndex === rowIndex + 1 && (
                  <>
                    {item.scheduleCardioListModels.length === 0 ? <></> :
                      <>
                        {item.scheduleCardioListModels.map((item: Icardio, index: number) => (
                          <tr key={index} className='expanded'>
                            <td>{dayjs((item as Icardio)?.date)?.format('MM/DD/YYYY')}</td>
                            <td>{item.exerciseType}</td>
                            <td>{item.warmUpDuration}</td>
                            {/* <td>{item.sustainedHeartRate}</td> */}
                            <td>{item.sustainedDuration}</td>
                            <td>{item.coolDownDuration}</td>
                            <td>
                              <button
                                className="me-2"
                                onClick={() => {
                                  toggle('Cardio');
                                  setCurrentCardioData(item);
                                }}>
                                <img src={edit_ic} />
                              </button>
                              <button
                                className="me-2"
                                onClick={() => {
                                  toggleDelete();
                                  setCurrentCardioData(item);
                                }}>
                                <img src={delete_ic} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>}
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="border p-5 rounded">
          <center>
            <h3 className="text-muted">No data Found</h3>
          </center>
        </div>
      )}
      <CommonDelete
        header={isAllDeleted ? "Delete All Cardio" : "Delete Cardio"}
        message={isAllDeleted ? "Are you sure you want to delete all Cardio ?" : "Are you sure you want to delete this Cardio ?"}
        modal={isdelete}
        toggle={() => {
          toggleDelete();
          setAllDeleted(false)
        }}
        confirmDeleteClick={() => {
          const payload = { id: (currentCardioData as any)?.scheduleCardioId, isDeleteAll: isAllDeleted }
          dispatch(deleteScheduleCardioSet(payload));
          toggleDelete();
        }}
      />
      <AddUpdateModal {...searchBarProps} />
    </>
  );
}
export default CardioTable;
