import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { Col, Row, FormGroup, Label, Input } from 'reactstrap';
import { Formik, Form } from 'formik';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import { ErrorMessage } from '../../../components';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  fetchMiTraining,
  postMITraining
} from '../../../store/Slices/userModule/MiTraining/MITrainingSlice';
import dayjs from 'dayjs';

interface EditProfiletraining {
  userId: any;
  userTrainingId: any;
  whyStarted: string;
  whenStarted: string | Date;
  howStarted: string;
  myInspiration: string;
  longTermGoals: string;
  shortTermGoals: string;
  myStrengths: string;
  myWeakness: string;
}

const EditProfileTrainingSchema = Yup.object().shape({
  whyStarted: Yup.string()
    .required('Please enter why I started')
    .trim('Leading and trailing space is not allowed')
    .strict(true),
  whenStarted: Yup.date().required('Please select date').strict(true),

  howStarted: Yup.string()
    .required('Please enter how I started')
    .trim('Leading and trailing space is not allowed')
    .strict(true),

  myInspiration: Yup.string()
    .required('Please enter inspiration')
    .trim('Please enter inspiration')
    .strict(true),

  longTermGoals: Yup.string()
    .required('Please enter long term goals')
    .trim('Leading and trailing space is not allowed')
    .strict(true),

  shortTermGoals: Yup.string()
    .required('Please enter short term goals')
    .trim('Leading and trailing space is not allowed')
    .strict(true),

  myStrengths: Yup.string()
    .required('Please enter strengths')
    .trim('Leading and trailing space is not allowed')
    .strict(true),

  myWeakness: Yup.string()
    .required('Please enter weakness')
    .trim('Leading and trailing space is not allowed')
    .strict(true)
});

function EditProfileTraining() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [userId, setUserId] = useState(null);
  const [isStartOpen, setIsStartOpen] = useState(false);

  const miTrainingDetail = useAppSelector((state) => state?.miTraining?.miTraining);

  useEffect(() => {
    const userData: any = localStorage.getItem('userData');
    setUserId(JSON.parse(userData));
    const userData1 = JSON.parse(userData);
    dispatch(fetchMiTraining(userData1?.userId));
  }, []);

  const navigateFunction: NavigateFunction = () => {
    navigate('/mitraining');
  };

  const initialValues: EditProfiletraining = {
    whyStarted: miTrainingDetail?.whyStarted ? miTrainingDetail?.whyStarted : '',
    whenStarted: miTrainingDetail?.whyStarted
      ? new Date(miTrainingDetail?.whenStarted || dayjs())
      : '',
    howStarted: miTrainingDetail?.whyStarted ? miTrainingDetail?.howStarted : '',
    myInspiration: miTrainingDetail?.whyStarted ? miTrainingDetail?.myInspiration : '',
    longTermGoals: miTrainingDetail?.whyStarted ? miTrainingDetail?.longTermGoals : '',
    shortTermGoals: miTrainingDetail?.whyStarted ? miTrainingDetail?.shortTermGoals : '',
    myStrengths: miTrainingDetail?.whyStarted ? miTrainingDetail?.myStrengths : '',
    myWeakness: miTrainingDetail?.whyStarted ? miTrainingDetail?.myWeakness : '',
    userId: null,
    userTrainingId: miTrainingDetail?.whyStarted ? miTrainingDetail?.userTrainingId : 0
  };
  return (
    <>
      <h3 className="page-heading">Edit Profile training</h3>
      <div className="write-comment edit-profile-training">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={EditProfileTrainingSchema}
          onSubmit={(values, actions) => {
            values.userId = userId?.userId;
            values.whenStarted = dayjs(values.whenStarted).format('YYYY-MM-DDTHH:mm:ss');
            dispatch(postMITraining({ data: values, navigate: navigateFunction }));
            actions.setSubmitting(false);
          }}>
          {({ errors, touched, values, handleChange, setFieldValue }) => (
            <Form>
              <Row>
                <Col md="6">
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" lg={3}>
                      Why I started
                    </Label>
                    <Col lg={9}>
                      <Input
                        id="exampleSelect"
                        name="whyStarted"
                        value={values.whyStarted}
                        onChange={handleChange}
                        type="textarea"></Input>
                      {errors.whyStarted && touched.whyStarted && (
                        <ErrorMessage text={errors.whyStarted} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" lg={3}>
                      When I started
                    </Label>
                    <Col lg={9}>
                      <DatePicker
                        onInputClick={() => setIsStartOpen(true)}
                        onClickOutside={() => setIsStartOpen(false)}
                        open={isStartOpen}
                        autoComplete="off"
                        name="whenStarted"
                        selected={values?.whenStarted ? new Date(values?.whenStarted) : null}
                        onChange={(date: Date | null) => setFieldValue('whenStarted', date)}
                        placeholderText="Please select start time"
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm:ss aa"
                        showTimeInput
                      />
                      {errors.whenStarted && touched.whenStarted && (
                        <ErrorMessage text={errors.whenStarted} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup row>
                    <Label for="exampleText" lg={3}>
                      How I started
                    </Label>
                    <Col lg={9}>
                      <Input
                        id="exampleText"
                        name="howStarted"
                        type="textarea"
                        value={values.howStarted}
                        onChange={handleChange}
                      />
                      {errors.howStarted && touched.howStarted && (
                        <ErrorMessage text={errors.howStarted} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup row>
                    <Label for="exampleText" lg={3}>
                      My Inspiration
                    </Label>
                    <Col lg={9}>
                      <Input
                        id="exampleText"
                        name="myInspiration"
                        value={values.myInspiration}
                        onChange={handleChange}
                        type="textarea"
                      />
                      {errors.myInspiration && touched.myInspiration && (
                        <ErrorMessage text={errors.myInspiration} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup row>
                    <Label for="exampleText" lg={3}>
                      Long Term Goals
                    </Label>
                    <Col lg={9}>
                      <Input
                        id="exampleText"
                        name="longTermGoals"
                        value={values.longTermGoals}
                        onChange={handleChange}
                        type="textarea"
                      />
                      {errors.longTermGoals && touched.longTermGoals && (
                        <ErrorMessage text={errors.longTermGoals} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup row>
                    <Label for="exampleText" lg={3}>
                      Short Term Goals
                    </Label>
                    <Col lg={9}>
                      <Input
                        id="exampleText"
                        name="shortTermGoals"
                        value={values.shortTermGoals}
                        onChange={handleChange}
                        type="textarea"
                      />
                      {errors.shortTermGoals && touched.shortTermGoals && (
                        <ErrorMessage text={errors.shortTermGoals} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup row>
                    <Label for="exampleText" lg={3}>
                      My Strengths
                    </Label>
                    <Col lg={9}>
                      <Input
                        id="exampleText"
                        name="myStrengths"
                        value={values.myStrengths}
                        onChange={handleChange}
                        type="textarea"
                      />
                      {errors.myStrengths && touched.myStrengths && (
                        <ErrorMessage text={errors.myStrengths} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup row>
                    <Label for="exampleText" lg={3}>
                      My Weaknesses
                    </Label>
                    <Col lg={9}>
                      <Input
                        id="exampleText"
                        name="myWeakness"
                        value={values.myWeakness}
                        onChange={handleChange}
                        type="textarea"
                      />
                      {errors.myWeakness && touched.myWeakness && (
                        <ErrorMessage text={errors.myWeakness} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col md="12">
                  <div className="d-flex flex-wrap align-items-center justify-content-end">
                    <Link to="/mitraining" state={{ state_title: 'Mi Training' }}>
                      <button className="cancle-btn me-3" type="button">
                        Cancel
                      </button>
                    </Link>
                    <button type="submit" className="save-changes">
                      Save Changes
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default EditProfileTraining;
