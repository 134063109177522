import React, { useEffect, useState } from 'react';

import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { ErrorMessage } from '../..';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  createScheduleWeightSet,
  getEquipmentCombo,
  getExerciseTypeCombo,
  getMuscleGroupCombo,
  getSingleMuscleGroupCombo,
  getSingleScheduleWeightSetList,
  updateScheduleWeightSet
} from '../../../store/Slices/userModule/DashboardSlice/dashboardAction';
import ReactSelect from 'react-select';
import dayjs from 'dayjs';
import delete_ic from '/public/images/delete.svg';
import DatePicker from 'react-datepicker';
import CommonConfirmModal from '../../common/ConfirmModal';

interface WeightSchema {
  scheduleWeightId: number;
  userId?: number;
  muscleGroupId: string | number;
  muscleCategoryId: string | number;
  equipmentId: string | number;
  exerciseTypeId: string | number;
  warmUpCount: number;
  warmWeight: number;
  scheduleWeightSet?: any;
  date?: Date | string;
  // Repeat
  startDate: string;
  endDate: string;
  daysOfWeek?: any;
  repeats: number;
  recurrenceType: number;
  isRecurrence: boolean;
  isOveride: boolean;
  slot: number;
}

function WeightRecord(props: any) {
  const Weight = props.activeTab;
  const [isDetailRequire, setIsDetailRequire] = useState(props?.model?.isDetailRequired);
  const [confirmModal, setConfirmModal] = useState(false);
  const [editPayloadData, setEditPayloadData] = useState<any>();
  const dispatch = useAppDispatch();

  const {
    GetEquipmentCombo,
    GetExerciseTypeCombo,
    GetMuscleGroupCombo,
    GetSingleMuscleGroupCombo
  } = useAppSelector((state) => state.miDashboard);
  const { localUserData } = useAppSelector((state) => state?.common);
  const currentWeight: any = useAppSelector(
    (state) => state.miDashboard.GetSingleScheduleGeneralList
  );

  const model = props.model;
  const handleClose = () => {
    props?.model?.toggle();
  };

  useEffect(() => {
    model?.currentWeightData?.scheduleWeightId &&
      model.isEditMode &&
      dispatch(getSingleScheduleWeightSetList(model?.currentWeightData?.scheduleWeightId));
  }, []);

  useEffect(() => {
    dispatch(getMuscleGroupCombo());
    dispatch(getEquipmentCombo());
  }, [dispatch]);

  const WeightRecordSchema = Yup.object().shape({
    muscleGroupId: Yup.number().required('Please select muscle group'),
    muscleCategoryId: Yup.number().required('Please select muscle').strict(true),
    equipmentId: Yup.number().required('Please select exercise equipment').strict(true),
    exerciseTypeId: Yup.number().required('Please select exercise type').strict(true),
    warmUpCount: Yup.number()
      .typeError('Please enter a  number')
      .required('Please enter warmup count'),
    warmWeight: Yup.number().required('Please enter warmup weight').strict(true),
    scheduleWeightSet: Yup.array().of(
      Yup.object().shape({
        count: Yup.number().required('Please enter count'),
        weight: Yup.number().required('Please enter weight')
      })
    ),

    // Repeat
    startDate: Yup.string()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.string().nullable().required('Please select start date')
      }),
    endDate: Yup.string()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.string().nullable().required('Please select end date')
      }),
    repeats: Yup.number()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.number().nullable().required('Please enter interval duration')
      }),
    recurrenceType: Yup.number()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.number().nullable().required('Please select recurrence')
      })
  });
  const initialValues: WeightSchema = {
    scheduleWeightId: model.isEditMode ? currentWeight?.scheduleWeightId : 0,
    userId: model.isEditMode ? currentWeight?.userId : 0,
    date: model.isEditMode ? currentWeight?.date : new Date(),
    muscleGroupId: model.isEditMode ? currentWeight?.muscleGroupId : null,
    muscleCategoryId: model.isEditMode ? currentWeight?.muscleCategoryId : null,
    equipmentId: model.isEditMode ? currentWeight?.equipmentId : null,
    exerciseTypeId: model.isEditMode ? currentWeight?.exerciseTypeId : null,
    warmUpCount: model.isEditMode ? currentWeight?.warmUpCount : null,
    warmWeight: model.isEditMode ? currentWeight?.warmWeight : null,
    scheduleWeightSet: model.isEditMode
      ? currentWeight?.scheduleWeightSet
      : [
          {
            scheduleWeightSetId: 0,
            scheduleWeightId: 0,
            count: null,
            weight: null
          }
        ],

    // Repeat
    startDate: props?.model?.isEditMode ? currentWeight?.startDate : null,
    endDate: props?.model?.isEditMode ? currentWeight?.endDate : null,
    daysOfWeek: props?.model?.isEditMode
      ? currentWeight?.daysOfWeek || [0, 0, 0, 0, 0, 0, 0]
      : [0, 0, 0, 0, 0, 0, 0],
    repeats: props?.model?.isEditMode ? currentWeight?.repeats : null,
    recurrenceType: props?.model?.isEditMode ? currentWeight?.recurrenceType : null,
    isRecurrence: props?.model?.isEditMode ? currentWeight?.isRecurrence : false,
    isOveride: props?.model?.isEditMode ? currentWeight?.isOveride : false,
    slot: props?.model?.isEditMode ? currentWeight?.slot : 0
  };

  const recurrence = [
    { label: 'Daily', value: 1 },
    { label: 'Weekly', value: 2 },
    { label: 'Monthly', value: 3 }
  ];

  return (
    <>
      <div className="auth-left-wrapper">
        {!model.isEditMode && <h4 className="mb-3 popup-subtitle">Add Weight Record</h4>}
        {Weight == 'Weight' && (
          <Formik
            initialValues={initialValues}
            enableReinitialize={model.isEditMode ? true : false}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={WeightRecordSchema}
            onSubmit={(values) => {
              values.userId = localUserData.userId || 0;
              if (!props?.model?.isEditMode) {
                values.date = props?.model?.currentSelectedDate || dayjs().format('YYYY-MM-DD');
              }
              if (currentWeight.length > 0) {
                values.date = dayjs((currentWeight as any)?.date).format('YYYY-MM-DD');
              }
              if (!values?.isRecurrence) {
                values.repeats = 0;
                values.startDate = null;
                values.endDate = null;
                values.recurrenceType = 0;
                values.slot = 0;
              }
              if (values?.startDate && values.endDate) {
                values.startDate = dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss');
                values.endDate = dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss');
              }
              if (values.scheduleWeightId) {
                if (values.isRecurrence) {
                  setConfirmModal(true);
                  setEditPayloadData(values);
                } else {
                  dispatch(updateScheduleWeightSet(values));
                  handleClose();
                }
              } else {
                dispatch(createScheduleWeightSet(values));
                handleClose();
              }

              // handleClose();
            }}>
            {({ errors, touched, values, handleChange, setFieldValue, resetForm }) => (
              <Form>
                {isDetailRequire && props?.model?.isEditMode && (
                  <Row>
                    <Col md="12">
                      <FormGroup row className="d-flex flex-wrap align-items-top">
                        <Col sm={12} className="m-auto">
                          <span>{dayjs((currentWeight as any)?.date).format('DD/MM/YYYY')}</span>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Muscle Group:
                      </Label>
                      {isDetailRequire && model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentWeight as any)?.muscleGroup}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <ReactSelect
                            className="react-select-dropdown"
                            options={GetMuscleGroupCombo}
                            placeholder="Select muscle group"
                            name="muscleGroupId"
                            value={GetMuscleGroupCombo?.find(
                              (data) => data.value === values.muscleGroupId
                            )}
                            onChange={(option) => {
                              setFieldValue('muscleGroupId', +option.value);
                              setFieldValue('muscleCategoryId', null);
                              dispatch(getSingleMuscleGroupCombo(+option.value));
                            }}
                            noOptionsMessage={() => <>No option available</>}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (provided) => ({
                                ...provided,
                                borderRadius: 50,
                                paddingLeft: 8,
                                boxShadow: null
                              })
                            }}
                          />
                          {errors.muscleGroupId && touched.muscleGroupId && (
                            <ErrorMessage text={errors.muscleGroupId} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Muscle:
                      </Label>
                      {isDetailRequire && model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentWeight as any)?.muscleCategory}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <ReactSelect
                            isDisabled={values.muscleGroupId ? false : true}
                            className="react-select-dropdown"
                            options={GetSingleMuscleGroupCombo}
                            placeholder="Select muscle"
                            name="muscleCategoryId"
                            value={
                              values.muscleCategoryId &&
                              GetSingleMuscleGroupCombo?.find(
                                (data) => data.value === values.muscleCategoryId
                              )
                            }
                            onChange={(option) => {
                              setFieldValue('muscleCategoryId', +option.value);
                            }}
                            noOptionsMessage={() => <>Select muscle group first</>}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (provided) => ({
                                ...provided,
                                borderRadius: 50,
                                paddingLeft: 8,
                                boxShadow: null
                              })
                            }}
                          />
                          {errors.muscleCategoryId && touched.muscleCategoryId && (
                            <ErrorMessage text={errors.muscleCategoryId} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Exercise Equipment:
                      </Label>
                      {isDetailRequire && model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentWeight as any)?.equipment}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <ReactSelect
                            className="react-select-dropdown"
                            options={GetEquipmentCombo}
                            placeholder="Select exercise equipment"
                            name="equipmentId"
                            value={GetEquipmentCombo?.find(
                              (data) => data.value === values.equipmentId
                            )}
                            onChange={(option) => {
                              setFieldValue('equipmentId', +option.value);
                              setFieldValue('exerciseTypeId', null);
                              dispatch(getExerciseTypeCombo(+option.value));
                            }}
                            noOptionsMessage={() => <>No option available</>}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (provided) => ({
                                ...provided,
                                borderRadius: 50,
                                paddingLeft: 8,
                                boxShadow: null
                              })
                            }}
                          />
                          {errors.equipmentId && touched.equipmentId && (
                            <ErrorMessage text={errors.equipmentId} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Exercise Type:
                      </Label>
                      {isDetailRequire && model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentWeight as any)?.exerciseType}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <ReactSelect
                            isDisabled={values.equipmentId ? false : true}
                            className="react-select-dropdown"
                            options={GetExerciseTypeCombo}
                            placeholder="Select exercise type"
                            name="exerciseTypeId"
                            value={
                              values.exerciseTypeId &&
                              GetExerciseTypeCombo?.find(
                                (data) => data.value === values.exerciseTypeId
                              )
                            }
                            onChange={(option) => {
                              setFieldValue('exerciseTypeId', +option.value);
                            }}
                            noOptionsMessage={() => <>Select exercise equipment first.</>}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (provided) => ({
                                ...provided,
                                borderRadius: 50,
                                paddingLeft: 8,
                                boxShadow: null
                              })
                            }}
                          />
                          {errors.exerciseTypeId && touched.exerciseTypeId && (
                            <ErrorMessage text={errors.exerciseTypeId} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Warmup Count:
                      </Label>
                      {isDetailRequire && model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentWeight as any)?.warmUpCount}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="warmUpCount"
                            placeholder="Warmup count"
                            value={values.warmUpCount}
                            onChange={handleChange}
                            className="custom-input"
                            min={0}
                            type="number"></Input>
                          {errors.warmUpCount && touched.warmUpCount && (
                            <ErrorMessage text={errors.warmUpCount} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Warmup Weight:
                      </Label>
                      {isDetailRequire && model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentWeight as any)?.warmWeight} Kg</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="position-relative extra-etti">
                          <Input
                            name="warmWeight"
                            placeholder="Warmup weight"
                            min={0}
                            value={values.warmWeight}
                            onChange={handleChange}
                            className="custom-input"
                            type="number"></Input>
                          {errors.warmWeight && touched.warmWeight && (
                            <ErrorMessage text={errors.warmWeight} />
                          )}
                          <label>kg</label>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <FieldArray name="scheduleWeightSet">
                    {({ remove, push }) => (
                      <>
                        {values?.scheduleWeightSet?.map((data: any, index: number) => (
                          <>
                            <Col md="6">
                              <FormGroup row className="d-flex flex-wrap align-items-top">
                                <Label className="mb-0" sm={4}>
                                  Set # {index + 1}:
                                </Label>
                                {isDetailRequire && model?.isEditMode ? (
                                  <Col sm={8} className="m-auto">
                                    <>
                                      {data.count} X {data.weight} kg
                                    </>
                                  </Col>
                                ) : (
                                  <Col sm={8}>
                                    <Row className="flex-wrap align-items-top">
                                      <Col>
                                        {values?.scheduleWeightSet?.length && (
                                          <Input
                                            name={`scheduleWeightSet[${index}].count`}
                                            value={values?.scheduleWeightSet[index].count}
                                            placeholder="Count"
                                            onChange={(e) => {
                                              setFieldValue(
                                                `scheduleWeightSet[${index}].count`,
                                                +e.target.value
                                              );
                                            }}
                                            min={0}
                                            type="number"></Input>
                                        )}
                                        {errors?.scheduleWeightSet?.length &&
                                        values?.scheduleWeightSet?.length &&
                                        (errors?.scheduleWeightSet as any[])[index]?.count ? (
                                          <div className="text-danger font-size-12 mt-1">
                                            {(errors?.scheduleWeightSet as any[])[index]?.count}
                                          </div>
                                        ) : null}
                                      </Col>
                                      X
                                      <Col className="position-relative extra-etti">
                                        {values?.scheduleWeightSet?.length && (
                                          <Input
                                            name={`scheduleWeightSet[${index}].weight`}
                                            value={values?.scheduleWeightSet[index].weight}
                                            placeholder="Weight"
                                            onChange={(e) => {
                                              setFieldValue(
                                                `scheduleWeightSet[${index}].weight`,
                                                +e.target.value
                                              );
                                            }}
                                            min={0}
                                            type="number"></Input>
                                        )}
                                        {errors?.scheduleWeightSet?.length &&
                                        values?.scheduleWeightSet?.length &&
                                        (errors?.scheduleWeightSet as any[])[index]?.weight ? (
                                          <div className="text-danger font-size-12 mt-1">
                                            {(errors?.scheduleWeightSet as any[])[index]?.weight}
                                          </div>
                                        ) : null}

                                        <label>kg</label>
                                      </Col>
                                      <Col className="position-relative extra-etti modal-footer top-0">
                                        <Button
                                          className="save-record p-2 px-3"
                                          onClick={() => {
                                            push({
                                              scheduleWeightSetId: 0,
                                              scheduleWeightId: 0,
                                              count: null,
                                              weight: null
                                            });
                                          }}>
                                          Add
                                        </Button>
                                        <button
                                          className={`mx-3 border-0 bg-transparent ${
                                            values?.scheduleWeightSet?.length === 1
                                              ? 'invisible'
                                              : ''
                                          }`}
                                          onClick={(
                                            e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                                          ) => {
                                            e.preventDefault();
                                            remove(index);
                                          }}>
                                          <img src={delete_ic} />
                                        </button>
                                        {errors?.scheduleWeightSet?.length &&
                                        values?.scheduleWeightSet?.length &&
                                        (errors?.scheduleWeightSet as any[])[index]?.weight ? (
                                          <div className="text-danger font-size-12 mt-1 invisible">
                                            {(errors?.scheduleWeightSet as any[])[index]?.weight}
                                          </div>
                                        ) : null}
                                      </Col>
                                    </Row>
                                  </Col>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md="6"></Col>
                          </>
                        ))}
                      </>
                    )}
                  </FieldArray>
                  {isDetailRequire && props?.model?.isEditMode ? (
                    <Col md="12">{(currentWeight as any)?.isRecurrence && <b>Repeat</b>}</Col>
                  ) : (
                    <Col md="12">
                      <div className="custom-checkbox d-inline-block">
                        <label className="checkbox-container">
                          Repeat
                          <Input
                            name="isRecurrence"
                            type="checkbox"
                            className="form-check-input"
                            checked={values.isRecurrence}
                            onChange={(e) => {
                              setFieldValue('isRecurrence', e.target.checked);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </Col>
                  )}

                  {values?.isRecurrence && (
                    <>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" lg={4}>
                            When does it start?
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{dayjs(currentWeight?.startDate).format('DD/MM/YYYY')}</span>
                            </Col>
                          ) : (
                            <Col lg={8}>
                              <Row className="flex-wrap align-items-top">
                                <Col>
                                  <DatePicker
                                    autoComplete="off"
                                    name="startDate"
                                    selected={
                                      values?.startDate ? new Date(values?.startDate) : null
                                    }
                                    onChange={(date: Date | null) => {
                                      setFieldValue('startDate', date);
                                      setFieldValue('endDate', null);
                                    }}
                                    placeholderText="Please select start date"
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm:ss aa"
                                    showTimeInput
                                    popperProps={{ strategy: 'fixed' }}
                                    popperPlacement="bottom-start"
                                  />
                                  {errors.startDate && touched.startDate && (
                                    <ErrorMessage text={errors.startDate} />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" lg={4}>
                            When does it end?
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{dayjs(currentWeight?.endDate).format('DD/MM/YYYY')}</span>
                            </Col>
                          ) : (
                            <Col lg={8}>
                              <Row className="flex-wrap align-items-top">
                                <Col>
                                  <DatePicker
                                    autoComplete="off"
                                    name="endDate"
                                    disabled={values.startDate ? false : true}
                                    minDate={values.startDate && new Date(values.startDate)}
                                    selected={values?.endDate ? new Date(values?.endDate) : null}
                                    onChange={(date: Date | null) => setFieldValue('endDate', date)}
                                    placeholderText="Please select end date"
                                    timeInputLabel="Time:"
                                    dateFormat="dd/MM/yyyy h:mm:ss aa"
                                    showTimeInput
                                    popperProps={{ strategy: 'fixed' }}
                                    popperPlacement="bottom-start"
                                  />
                                  {errors.endDate && touched.endDate && (
                                    <ErrorMessage text={errors.endDate} />
                                  )}
                                </Col>{' '}
                              </Row>
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" sm={4}>
                            Recurrence
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>
                                {
                                  recurrence.find(
                                    (data) => data.value === currentWeight?.recurrenceType
                                  )?.label
                                }
                              </span>
                            </Col>
                          ) : (
                            <Col sm={8}>
                              <ReactSelect
                                className="react-select-dropdown"
                                options={recurrence}
                                placeholder="Select recurrence"
                                name="recurrenceType"
                                onChange={(options) => {
                                  setFieldValue('recurrenceType', +options.value);
                                }}
                                value={recurrence.find(
                                  (data) => data.value === values.recurrenceType
                                )}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  control: (provided) => ({
                                    ...provided,
                                    borderRadius: 50,
                                    paddingLeft: 8,
                                    boxShadow: null
                                  })
                                }}
                              />
                              {errors.recurrenceType && touched.recurrenceType && (
                                <ErrorMessage text={errors.recurrenceType} />
                              )}
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" sm={4}>
                            Interval duration
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{(currentWeight as any)?.repeats}</span>
                            </Col>
                          ) : (
                            <Col sm={8}>
                              <Input
                                name="repeats"
                                placeholder="Interval duration"
                                value={values.repeats}
                                onChange={handleChange}
                                type="number"
                                min={1}
                                max={99}></Input>
                              {errors.repeats && touched.repeats && (
                                <ErrorMessage text={errors.repeats} />
                              )}
                            </Col>
                          )}
                        </FormGroup>
                      </Col>

                      {isDetailRequire && props?.model?.isEditMode ? (
                        <></>
                      ) : (
                        values.recurrenceType === 2 && (
                          <Col md="12" className="d-flex flex-wrap mb-3">
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Sun
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[0]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[0]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Mon
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[1]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[1]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Tue
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[2]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[2]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Wed
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[3]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[3]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Thu
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[4]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[4]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Fri
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[5]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[5]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Sat
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[6]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[6]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </Col>
                        )
                      )}
                    </>
                  )}
                  <Col md="12">
                    <div className="justify-content-end mt-3 col-md-12 modal-footer">
                      <Button
                        className="cancel-btn me-3"
                        onClick={() => {
                          handleClose();
                          resetForm();
                          // setCurrentWeight({});
                        }}>
                        Cancel
                      </Button>{' '}
                      {/* {isDetailRequire && model?.isEditMode && (
                        <Button
                          className="save-record d-none"
                          type="button"
                          >
                          Edit
                        </Button>
                      )} */}
                      {!isDetailRequire && (
                        <Button className="save-record" type="submit">
                          {model.isEditMode ? 'Update' : 'Add'}
                        </Button>
                      )}
                      {props?.model?.isDateSelected && !model?.isEditMode && (
                        <Button className="save-record" type="submit">
                          {model.isEditMode ? 'Update' : 'Add'}
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <CommonConfirmModal
        header="Please confirm"
        message="Click to update record"
        modal={confirmModal}
        toggle={() => {
          setConfirmModal(false);
        }}
        confirmNoClickClick={() => {
          dispatch(updateScheduleWeightSet({ ...editPayloadData, onlyThisDate: true }));
          setConfirmModal(false);
          setEditPayloadData({});
          handleClose();
        }}
        confirmDeleteClick={() => {
          dispatch(updateScheduleWeightSet({ ...editPayloadData, onlyThisDate: false }));
          setConfirmModal(false);
          setEditPayloadData({});
          handleClose();
        }}
      />
    </>
  );
}

export default WeightRecord;
