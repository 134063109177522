/* eslint-disable prettier/prettier */
import React from 'react';
import { Col, Row } from 'reactstrap';
import WithoutLoginHeader from '../../../layouts/WithoutLoginHeader/withoutloginheader';
import Header from '../../../layouts/Header/header';


function MiSportsProfileValues() {
  { document.title = 'Mi Sports Profile - Mi Sports Profile Values' }

  let auth = localStorage.getItem('token');
  return (
    <>
      {auth != 'true' ? <WithoutLoginHeader /> : <Header />}
      <div className="content-wrapper mb-5 mt-3 cms-page-wrapper">
        <div className="container">
          <Row className="mb-2">
            <Col md="12" className="content-right">
              <h3 className="page-heading">Mi Sports Profile Values</h3>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>No matter who you are,</p>

                  <p>No matter what you do and</p>

                  <p>No matter what goals you want to achieve in life,</p>

                  <p>Mi Sports Profile is there all the way....</p>
                </div>
                <h5>Mi Sports Profile is:</h5>
                <div className="mi-training-box mb-3">
                  <ul>
                    <li>Inclusive</li>
                    <li>Positive</li>
                    <li>International</li>
                    <li>Collective</li>
                    <li>Passionate</li>
                    <li>For self improvement</li>
                    <li>An ideas exchange</li>
                    <li>Here for you & your sport</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default MiSportsProfileValues;
