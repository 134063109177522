/* eslint-disable prettier/prettier */
import * as React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { INITIAL_EVENTS, createEventId } from './fullCalendarUtils';
import {
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventContentArg,
  EventInput
} from '@fullcalendar/core';
import AddUpdateModal from '../../Friendscomponents/profileTable/AddUpdateModal/index';
import sports from '/public/images/sports.svg';
import weight from '/public/images/weight.svg';
import cardio from '/public/images/cardio.svg';
import general_fitness from '/public/images/general-fitness.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getDashBoardSchedular,
  getScheduleCardioList,
  getScheduleCardioListById,
  getScheduleGeneralList,
  getScheduleGeneralListById,
  getScheduleSportsList,
  getScheduleSportsListById,
  getScheduleWeightSetList,
  getSingleScheduleWeightSetList,
  getUsersSchedular
} from '../../store/Slices/userModule/DashboardSlice/dashboardAction';
import dayjs from 'dayjs';
import { setDates } from '../../store/Slices/userModule/DashboardSlice/dashboardSlice';
import CardioRecordDetail from '../TodaysProgramComponent/CardioRecord/CardioRecordDetails';
import DetailModal from '../profileTable/DetailModal';

const FullCalendarComponent = React.memo(function FullCalendarComponent() {
  const [modal, setModal] = React.useState(false);
  const [isNewEntry, setIsNewEntry] = React.useState<boolean>();
  const [detailModal, setDetailModal] = React.useState(false);
  const [currentSelectedDate, setCurrentSelectedDate] = React.useState<string | Date>();
  const [modalTitle, setModalTitle] = React.useState('Weight');
  const [schedularData, setSchedularData] = React.useState<any>();
  const [currentWeightData, setCurrentWeightData] = React.useState<any>();
  const [currentCardioData, setCurrentCardioData] = React.useState<any>();
  const [currentSportsData, setCurrentSportsData] = React.useState<any>();
  const [currentGeneralFitnessData, setCurrentGeneralFitnessData] = React.useState<any>();
  const dispatch = useAppDispatch();

  const { GetDashBoardSchedular } = useAppSelector((state) => state.miDashboard);
  const currentWeight: any = useAppSelector(
    (state) => state.miDashboard.GetSingleScheduleGeneralList
  );

  const initialEvent = GetDashBoardSchedular?.map((data) => ({
    ...data,
    start: dayjs(data.date).format('YYYY-MM-DD'),
    id: String(data.id)
  }));




  const toggle = async (name: any) => {
    await setModal(!modal);
    await setModalTitle(name);
  };

  const searchBarProps = {
    modal: modal,
    currentSelectedDate: currentSelectedDate,
    currentWeightData: currentWeightData,
    currentCardioData: currentCardioData,
    currentGeneralFitnessData: currentGeneralFitnessData,
    currentSportsData: currentSportsData,
    isDateSelected: true,
    isDetailRequired: true,
    isEditMode: isNewEntry,
    schedularData: schedularData,
    modalTitle: modalTitle,
    toggle: (name: any) => toggle(name)
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    toggle('Weight');
    setIsNewEntry(false);
  };

  const handleDateClick = (info: any) => {
    const eventDetail = info.dayEl.innerText.split('\n');
    if (eventDetail.length === 5) {
      return;
    } else {
      toggle('Weight');
      setIsNewEntry(false);
      setCurrentSelectedDate(info.dateStr);
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    toggle(clickInfo.event._def.title);
    setSchedularData(clickInfo.event._def);
    setIsNewEntry(true);
    if (clickInfo.event._def.title === 'Weight') {
      toggle('Weight');
      setCurrentWeightData({ scheduleWeightId: clickInfo.event._def.publicId });
    }
    if (clickInfo.event._def.title === 'Cardio') {
      toggle('Cardio');
      setCurrentCardioData({ scheduleCardioId: clickInfo.event._def.publicId });
    }
    if (clickInfo.event._def.title === 'General Fitness') {
      toggle('General Fitness');
      setCurrentGeneralFitnessData({ scheduleGeneralId: clickInfo.event._def.publicId });
    }
    if (clickInfo.event._def.title === 'Sports') {
      toggle('Sports');
      setCurrentSportsData({ scheduleSportId: clickInfo.event._def.publicId });
    }
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    return (
      <div className="pointer">
        {eventInfo.event.title === 'Weight' && <img src={weight} className="me-2 normal" />}
        {eventInfo.event.title === 'Cardio' && <img src={cardio} className="me-2 normal" />}
        {eventInfo.event.title === 'General Fitness' && (
          <img src={general_fitness} className="me-2 normal" />
        )}
        {eventInfo.event.title === 'Sports' && <img src={sports} className="me-2 normal" />}
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  return (
    <>
      <div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev',
            center: 'title',
            right: 'next'
          }}
          initialView="dayGridMonth"
          editable={false}
          selectable={false}
          selectMirror={true}
          dayMaxEvents={true}
          // initialEvents={INITIAL_EVENTS}
          events={async () => await initialEvent}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          // dateClick={handleDateClick}
          rerenderDelay={1000}
          datesSet={(e: DatesSetArg) => {
            const startDate = dayjs(e.startStr).format('YYYY-MM-DD');
            const endDate = dayjs(e.endStr).subtract(1, 'day').format('YYYY-MM-DD');
            dispatch(setDates({ startDate: startDate, endDate: endDate }));
            dispatch(getUsersSchedular());
            // dispatch(getScheduleWeightSetList());
            // dispatch(getScheduleCardioList());
            // dispatch(getScheduleGeneralList());
            // dispatch(getScheduleSportsList());
          }}
        />
      </div>
      <AddUpdateModal {...searchBarProps} />
      <>
        <DetailModal
          modal={detailModal}
          toggle={() => setDetailModal(!detailModal)}
          modalTitle={modalTitle}
        />
      </>
    </>
  );
});

export default FullCalendarComponent;
