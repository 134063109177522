import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { GET_MI_TRAINING, POST_MI_TRAINING } from '../../../apiRoutes';
import { setLoading } from '../../CommonSlice/commonSlice.';
import { toast } from 'react-toastify';
import errorHandlers from '../../../../shared/config/errorHandler';
import { ITrainingPayload } from '../../../../types';

export interface dataState {
  loading: boolean;
  error: string | null;
  miTraining: any | null;
}

export const postMITraining = createAsyncThunk(
  'miTraining/addMiTraining',
  async (
    { data, navigate }: { data: ITrainingPayload; navigate: any },
    { dispatch: AppDispatch }
  ) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(`${POST_MI_TRAINING}`, data);
      AppDispatch(setLoading(false));
      navigate();
      if (data?.userTrainingId) {
        toast.success('Training updated successfully');
      } else {
        toast.success('Training added successfully');
      }
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const fetchMiTraining = createAsyncThunk(
  'miTraining/fetchMiTraining',
  async (data: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<ITrainingPayload[]>(`${GET_MI_TRAINING}${data}`);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

const initialState: dataState = {
  loading: false,
  error: null,
  miTraining: []
};

const miTrainigSlice = createSlice({
  name: 'miTraining',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMiTraining.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMiTraining.fulfilled, (state, action) => {
        state.miTraining = action.payload;
        state.loading = false;
      })
      .addCase(fetchMiTraining.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Error fetching posts';
      });
  }
});

// export const { getDataStart, getDataSuccess, getDataFailure, addData } = miTrainigSlice.actions; /// for reducers only
export default miTrainigSlice.reducer;
