import * as React from 'react';
import headerlogo from '/public/images/mi-header-logo.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap';
import Logout from './Logout';
function Header() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [islogout, setLogout] = React.useState(false);
  const links = [
    { href: '/home', text: 'Mi Profile' },
    { href: '/nofriend', text: 'Friends' },
    { href: '/editprofile', text: 'Account' }
  ];
  const toggle = () => setIsOpen(!isOpen);
  const toggleLogout = () => setLogout(!islogout);
  const closeMenu = () => {
    localStorage.removeItem('friend');
    sessionStorage.removeItem('userData');
    sessionStorage.clear();
    navigate('/home');
    // ... and this one only
    setIsOpen(false); // closes it ...
  };
  const navigate = useNavigate();

  const userAccount = () => {
    const isFriend1 = localStorage.getItem('friend');
    if (isFriend1 === 'true') {
      localStorage.removeItem('friend');
      sessionStorage.removeItem('userData');
      sessionStorage.clear();
      navigate('/home');
    } else {
    }
  };
  const menuRef = React.useRef(null);

  const handleClickOutside = (event: any) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    } else {
      setIsOpen(!isOpen);
    }
  };

  React.useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      <div className="header-wrapper py-4 sticky-top">
        <div className="container">
          <div className="header-inner d-flex flex-wrap align-items-center justify-content-between">
            <div>
              <a href="/home">
                <img src={headerlogo} onClick={() => userAccount()} />
              </a>
            </div>
            <div className="header-nav">
              <Navbar light expand="md">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    {links?.map((link, index) => {
                      return (
                        <NavLink key={index} to={link.href} onClick={closeMenu}>
                          {link.text}
                        </NavLink>
                      );
                    })}
                    <p onClick={() => toggleLogout()}>Logout</p>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
          </div>
        </div>
        <Logout
          modal={islogout}
          toggle={() => {
            toggleLogout();
          }}
        />
      </div>
    </>
  );
}
export default Header;
