import React, { useCallback, useState } from 'react';
import { Table, UncontrolledTooltip } from 'reactstrap';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import AddUpdateModal from './AddUpdateModal/index';
import up_ic from '/public/images/up.svg';
import down_ic from '/public/images/down.svg';
import CommonDelete from '../DeleteComponent/commonDelete';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  deleteScheduleWeightSet,
  getSingleScheduleWeightSetList
} from '../../store/Slices/userModule/DashboardSlice/dashboardAction';
import dayjs from 'dayjs';
import { items } from './profileTableUtils';
import { Iweight } from '../../types/dashboard';
import info_line_ic from '/public/images/info_line_ic.svg';


function WeightTable(props: any) {
  const [isAllDeleted, setAllDeleted] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [isdelete, setIsdelete] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('Weight');
  const [currentWeightData, setCurrentWeightData] = useState<Iweight>();
  const [active, setActive] = useState<number>();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(null);

  const toggleImage = (index: number) => {
    setActiveTabIndex((prevIndex: number) => (prevIndex === index ? null : index));
    setActive((prevIndex: number) => (prevIndex === index ? null : index));
  };
  const dispatch = useAppDispatch();
  const currentWeight: any = useAppSelector(
    (state) => state.miDashboard.GetSingleScheduleGeneralList
  );

  const searchBarProps = {
    modal: modal,
    currentWeightData: currentWeightData,
    isEditMode: true,
    modalTitle: modalTitle,
    toggle: (name: string) => toggle(name)
  };

  const toggle = async (name: string) => {
    await setModal(!modal);
    await setModalTitle(name);
  };

  const toggleDelete = () => setIsdelete(!isdelete);
  return (
    <>
      {Object.keys(props?.tableData).length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Muscle</th>
              <th>Equipment</th>
              <th>Exercise</th>
              <th colSpan={2}>Warm UP</th>
              <th colSpan={2}>Sets</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props?.tableData?.map((item: Iweight, rowIndex: number) => (
              <React.Fragment key={rowIndex}>
                <tr key={item.id}>
                  <td>{dayjs((item as Iweight)?.date)?.format('MM/DD/YYYY')}</td>
                  <td>{item.muscleGroup}</td>
                  <td>{item.equipment}</td>
                  <td>{item.exerciseType}</td>
                  <td>
                    <span>
                      <span className="span">{item.warmUpCount}</span>
                    </span>
                  </td>
                  <td>
                    <span>
                      <span className="span">{item.warmWeight}kg</span>
                    </span>
                  </td>
                  <td>
                    <span>
                      <span className="span">{item.scheduleWeightSet[0]?.count}</span>
                    </span>
                  </td>
                  <td>
                    <div className='d-flex flex-wrap position-relative table-tooltip-wrapper'>
                      <span className='me-2'>
                        <span className="span">{item.scheduleWeightSet[0]?.weight}kg</span>
                      </span>
                      <div >
                        {item.scheduleWeightSet.length === 1 ? <></> : <span><img src={info_line_ic} /></span>}
                       
                              {item.scheduleWeightSet.length === 1 ? '' : <>
                                <div className='table-tooltip'>
                          <div className="table-responsive">
                            <table className="table">
                                {item.scheduleWeightSet.map((item: any, index: number) => (

                                  <tr key={index} className='expanded'>
                                    <td>Set # {index + 1}:</td>
                                    <td>
                                      <span>
                                        <span className="span">{item.count}</span> X <span className="span">{item.weight} KG</span>
                                      </span>
                                    </td>

                                  </tr>

                                ))}
</table>
                          </div>
                        </div>
                              </>}
                            
                      </div>
                    </div>
                  </td>

                  <td>
                    <button
                      className="me-2"
                      onClick={() => {
                        toggle('Weight');
                        setCurrentWeightData(item);
                      }}>
                      <img src={edit_ic} />
                    </button>
                    <button
                      className="me-2"
                      onClick={() => {
                        toggleDelete();
                        setCurrentWeightData(item);
                        item.scheduleWeightListModels.length === 0 ? setAllDeleted(false) : setAllDeleted(true);
                      }}>
                      <img src={delete_ic} />
                    </button>
                    {item.scheduleWeightListModels.length === 0 ? <></> : <><button onClick={() => toggleImage(rowIndex + 1)}>
                      <img src={active === rowIndex + 1 ? up_ic : down_ic} alt="Image" />
                    </button></>}
                  </td>
                </tr>
                {activeTabIndex === rowIndex + 1 && (
                  <>
                    {item.scheduleWeightListModels.length === 0 ? <></> :
                      <>
                        {item.scheduleWeightListModels.map((item: Iweight, index: number) => (
                          <tr key={index} className='expanded'>
                            <td>{dayjs((item as Iweight)?.date)?.format('MM/DD/YYYY')}</td>
                            <td>{item.muscleGroup}</td>
                            <td>{item.equipment}</td>
                            <td>{item.exerciseType}</td>
                            <td>
                              <span>
                                <span className="span">{item.warmUpCount}</span>
                              </span>
                            </td>
                            <td>
                              <span>
                                <span className="span">{item.warmWeight}kg</span>
                              </span>
                            </td>
                            <td>
                              <span>
                                <span className="span">{item.scheduleWeightSet[0]?.count}</span>
                              </span>
                            </td>
                            {/* <td>
                              <span>
                                <span className="span">{item.scheduleWeightSet[0]?.weight}kg</span>
                              </span>

                            </td> */}
                            <td>
                              <div className='d-flex flex-wrap position-relative table-tooltip-wrapper'>
                                <span className='me-2'>
                                  <span className="span">{item.scheduleWeightSet[0]?.weight}kg</span>
                                </span>
                                <div>
                                  {item.scheduleWeightSet.length === 1 ? <></> : <span><img src={info_line_ic} /></span>}
                                  <div className='table-tooltip'>
                                    <div className="table-responsive">
                                      <table className="table">
                                        {item.scheduleWeightSet.length === 1 ? '' : <>
                                          {item.scheduleWeightSet.map((item: any, index: number) => (

                                            <tr key={index} className='expanded'>
                                              <td>Set # {index + 1}:</td>
                                              <td>
                                                <span>
                                                  <span className="span">{item.count}</span> X <span className="span">{item.weight} KG</span>
                                                </span>
                                              </td>

                                            </tr>

                                          ))}

                                        </>}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <button
                                className="me-2"
                                onClick={() => {
                                  toggle('Weight');
                                  setCurrentWeightData(item);
                                }}>
                                <img src={edit_ic} />
                              </button>
                              <button
                                className="me-2"
                                onClick={() => {
                                  toggleDelete();
                                  setCurrentWeightData(item);
                                }}>
                                <img src={delete_ic} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>}
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table >
      ) : (
        <div className="border p-5 rounded">
          <center>
            <h3 className="text-muted">No data Found</h3>
          </center>
        </div>
      )
      }
      <CommonDelete
        header={isAllDeleted ? "Delete All Weight" : "Delete Weight"}
        message={isAllDeleted ? "Are you sure you want to delete all Weight ?" : "Are you sure you want to delete this Weight ?"}
        modal={isdelete}
        toggle={() => {
          toggleDelete();
          setAllDeleted(false)
        }}
        confirmDeleteClick={() => {
          const payload = { id: (currentWeightData as any)?.scheduleWeightId, isDeleteAll: isAllDeleted }
          dispatch(deleteScheduleWeightSet(payload));
          toggleDelete();
        }}
      />
      <AddUpdateModal {...searchBarProps} />
    </>
  );
}

export default WeightTable;
