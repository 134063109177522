import dayjs from 'dayjs';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Container } from 'reactstrap';

function Footer() {
  const links = [
    { href: '/aboutus', text: 'About Us' },
    { href: '/misportsprofilevalues', text: 'Mi Sports Profile Values' },
    // { href: '/mitermsconditions', text: 'Terms & Conditions' },
    { href: '/miprivacypolicy', text: 'Terms & Conditions and Privacy Policy' },
    { href: '/contactus', text: 'Contact Us' }
  ];
  return (
    <>
      <div className="footer py-3 position-relative">
        <Container>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="footer-left">
              <h3>Be Motivated!</h3>
              <span>Copyright © {dayjs().format('YYYY')} Mi Sports Profile.</span>
            </div>
            <div className="footer-right">
              <ul className="d-flex flex-wrap align-items-center">
                {links?.map((link, index) => {
                  return (
                    <li key={index}>
                      <NavLink key={index} to={link.href}>
                        {link.text}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Footer;
