import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { dataState } from '../../../types/index';
import {
  FORGET_PASSWORD,
  LOGIN_USER,
  REGISTER_USER,
  RESET_PASSWORD,
  CHANGE_PASSWORD
} from '../../../store/apiRoutes';
import { fetchCommon, setLoading } from '../CommonSlice/commonSlice.';
import { toast } from 'react-toastify';
import errorHandlers from '../../../shared/config/errorHandler';
import { fetchMiRecords } from '../userModule/MiRecordSlice/miRecordSlice';
import {
  fetchMiProfileDetail,
  fetchMiProfileRelationCombo
} from '../userModule/MiProfileSlice/miProfileAction';

export const RegisterUser = createAsyncThunk(
  'auth/RegisterUser',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(`${REGISTER_USER}`, data);
      AppDispatch(setLoading(false));
      navigate();
      toast.success('Successfully registered');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      errorHandlers(error);
      throw error;
    }
  }
);

export const LoginUser = createAsyncThunk(
  'auth/LoginUser',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(LOGIN_USER, data);
      AppDispatch(setLoading(false));
      localStorage.setItem('userData', JSON.stringify(response.data));
      localStorage.setItem('token', JSON.stringify(response.data.token));
      axios.defaults.headers.common['Authorization'] = response.data.token;
      AppDispatch(fetchMiRecords());
      AppDispatch(fetchMiProfileRelationCombo());
      AppDispatch(fetchCommon());
      AppDispatch(fetchMiProfileDetail(response.data?.userId || 0));
      navigate();
      toast.success(
        `${response.data.userName ? response.data.userName : 'User'} logged in successfully`
      );
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      errorHandlers(error?.response?.data?.message);
      throw error;
    }
  }
);

export const forgetPassword = createAsyncThunk(
  'auth/RegisterUser',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(`${FORGET_PASSWORD}`, data);
      AppDispatch(setLoading(false));
      navigate();
      toast.success('Password reset link has been sent to your registered email account.');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      errorHandlers(error);
      throw error;
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/RegisterUser',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(`${RESET_PASSWORD}`, data);
      AppDispatch(setLoading(false));
      navigate();
      toast.success('Password changed successfully, Please login afresh');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      errorHandlers(error);
      throw error;
    }
  }
);

// CHANGE PASSWORD
export const changePassword = createAsyncThunk(
  'auth/RegisterUser',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(`${CHANGE_PASSWORD}`, data);
      AppDispatch(setLoading(false));
      toast.success('Password changed successfully');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      errorHandlers(error);
      throw error;
    }
  }
);

const initialState: dataState = {
  data: [],
  loading: false,
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RegisterUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(RegisterUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(RegisterUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      ///login
      .addCase(LoginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        state.loading = false;
        // state.data = action.payload;
      })
      .addCase(LoginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

// export const { getDataStart, getDataSuccess, getDataFailure, addData } = dataSlice.actions; /// for reducers only
export default authSlice.reducer;
