import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMiCompetition,
  getAllAcceptedCompetitors,
  getSingleCompetition,
  getSingleCompetitionComments,
  FriendInvite,
  gameonForCompetitionList,
  getAllCompetitorsLists
} from './competitionAction';
import { CommentInitialVal, SingleCompetitionInterface } from '../../../../types';
import { number } from 'yup';

export interface FriendInvite {
  userName: string;
  userId: number;
  imageUrl: string;
}

export interface GameonForCompetition {
  userId: number;
}

// export interface CompetitionInvite {
//   competitionInviteId: number;
//   competitionId: number;
//   senderId: number;
//   receiverId: number;
//   isInCompetition: boolean;
// }

interface DataState {
  getCompetition: any[];
  getAllAcceptedCompetitors: any[];
  getSingleCompetition: SingleCompetitionInterface[];
  FriendInvite: FriendInvite[];
  getSingleCompetitionComments: CommentInitialVal[];
  gameonForCompetitionList: GameonForCompetition[];
  getAllCompetitorsListData: GameonForCompetition[];
  // CompetitionInvite: CompetitionInvite[];1
  taskRes?: any[];
  isLoading: boolean;
  error: string | null;
}

type TCompetition = {
  competitionInviteId: number;
  competitorsCount: number;
  competitionId: number;
  senderId: number;
  receiverId: number;
  isInCompetition: boolean;
  userName: string;
  imageUrl?: string | null;
};

const initialState: DataState = {
  getCompetition: [],
  getAllAcceptedCompetitors: [],
  getSingleCompetition: [],
  getSingleCompetitionComments: [],
  FriendInvite: [],
  gameonForCompetitionList: [],
  getAllCompetitorsListData: [],
  isLoading: false,
  error: null
};

const competitionSlice = createSlice({
  name: 'competition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMiCompetition.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchMiCompetition.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getCompetition = action.payload;
      })
      .addCase(fetchMiCompetition.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      })

      .addCase(getAllAcceptedCompetitors.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllAcceptedCompetitors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getAllAcceptedCompetitors = action.payload;
      })
      .addCase(getAllAcceptedCompetitors.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      })

      .addCase(getSingleCompetition.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSingleCompetition.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getSingleCompetition = action.payload;
      })
      .addCase(getSingleCompetition.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      })
      .addCase(getSingleCompetitionComments.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSingleCompetitionComments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getSingleCompetitionComments = action.payload;
      })
      .addCase(getSingleCompetitionComments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      })
      .addCase(FriendInvite.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(FriendInvite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.FriendInvite = action.payload;
      })
      .addCase(FriendInvite.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      })
      .addCase(gameonForCompetitionList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(gameonForCompetitionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.gameonForCompetitionList = action.payload;
      })
      .addCase(gameonForCompetitionList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      })
      .addCase(getAllCompetitorsLists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getAllCompetitorsListData = action.payload;
      })
      .addCase(getAllCompetitorsLists.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      });
    // .addCase(CompetitionInvite.pending, (state) => {
    //   state.isLoading = true;
    //   state.error = null;
    // })
    // .addCase(CompetitionInvite.fulfilled, (state, action) => {
    //   state.isLoading = false;
    //   state.CompetitionInvite = action.payload;
    // })
    // .addCase(CompetitionInvite.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.error = action.error.message || 'error';
    // });
  }
});

export default competitionSlice.reducer;
