/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { Input, Label } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  deleteSingleCompetition,
  fetchMiCompetition
} from '../../../store/Slices/userModule/CompetitionSlice/competitionAction';
import dayjs from 'dayjs';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import view_ic from '/public/images/view.svg';
import CommonDelete from '../../../components/DeleteComponent/commonDelete';
import { redirectUser_friend } from '../../../shared/utils';

interface Competition {
  competitionId?: number;
  name: string;
  goal: string;
  reason: string;
  startDate: string;
  endDate: string;
  timeZone: string;
  sportId: number;
  visibility: number;
  userId: number;
  createdByName: string;
  sportName: string;
}
function MiCompetition() {
  document.title = 'Mi Sports Profile - Mi Competitions';
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [deleteCompetition, setDeleteCompetition] = React.useState<number>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const miCompetition = useAppSelector((state) => state?.miCompetition);
  const localUser = useAppSelector((state) => state?.common?.localUserData);
  const currentDate = dayjs();
  const [isFriend, setIsFriend] = React.useState(false);
  const [isFriend1, setIsFriend1] = React.useState(false);

  useEffect(() => {
    dispatch(fetchMiCompetition(isChecked));
  }, [dispatch]);
  const upcomingCompetitions = miCompetition?.getCompetition.filter((competition: Competition) => {
    if (localUser?.userId === competition.userId) {
      return competition;
    }
  });

  const lostCompetitions = miCompetition?.getCompetition.filter((competition: Competition) => {
    if (localUser?.userId !== competition.userId) {
      return competition;
    }
  });

  const handleDeleteMICompetition = (data: Competition) => {
    setDeleteModal(true);
    setDeleteCompetition(data.competitionId);
  };

  const handleConfirmDelete = () => {
    setDeleteModal(false);
    dispatch(deleteSingleCompetition({ id: deleteCompetition, isCompEnded: isChecked }));
    setDeleteCompetition(null);
  };
  const checkHandler = (event: any) => {
    dispatch(fetchMiCompetition(event.target.checked));
    setIsChecked(!isChecked);
  };
  const navigate = useNavigate();

  const userAccount = (data: any) => {
    redirectUser_friend(data?.userId);
  };

  return (
    <>
      <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between lay-down-wrapper ">
        <h4>Mi Competitions</h4>
        <div className="d-flex align-items-center justify-content-between">
          <div className="custom-checkbox mx-3">
            <label className="checkbox-container">
              Ended Competitions
              <Input type="checkbox" name="endCompetition" onChange={checkHandler} />
              <span className="checkmark"></span>
            </label>
          </div>
          <Link
            to={`/micompetition/laydownmicompetition`}
            state={{ state_title: 'Mi Competition', state_userId: 0 }}>
            <button>Lay Down Mi Competition</button>
          </Link>
        </div>
      </div>
      {upcomingCompetitions.length > 0 ? (
        <>
          {upcomingCompetitions?.map((data, index) => (
            <div
              className="competition-box mb-3 d-flex flex-wrap align-items-center justify-content-between"
              key={index}>
              <p>
                <Link
                  to={`/Competition/GetByCompetitionId/${data.competitionId}`}
                  state={{ state_title: 'Mi Competition', state_value: data.competitionId }}>
                  {data.name}
                </Link>{' '}
                ({data.sportName}) by{' '}
                <a className="me-2 cursor-pointer">{data.createdByName ?? 'User '}</a>
                {dayjs(data.endDate).isSame('2000-01-01T00:00:00')
                  ? 'has ended.'
                  : `ends at ${dayjs(data.endDate).format('h:mma')} (${data.timeZone}) ${dayjs(
                      data.endDate
                    ).format('D MMMM YYYY')}.`}
              </p>
              <p className="d-flex flex-wrap align-items-center">
                <Link
                  to={`/Competition/GetByCompetitionId/${data.competitionId}`}
                  state={{ state_title: 'Mi Competition', state_value: data.competitionId }}>
                  <img src={edit_ic} className="me-2" />
                </Link>
                <img
                  className="pointer"
                  onClick={() => handleDeleteMICompetition(data)}
                  src={delete_ic}
                />
              </p>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="border p-4 mb-4 rounded">
            <center>
              <h3 className="text-muted">No data Found</h3>
            </center>
          </div>
        </>
      )}

      <h4 className="competition-subtitle mb-2 ">Other competitions</h4>
      {lostCompetitions.length > 0 ? (
        <>
          {' '}
          {lostCompetitions?.map((data, index) => (
            <div
              key={index}
              className="competition-box mb-3 d-flex flex-wrap align-items-center justify-content-between">
              <p>
                <Link
                  to={`/Competition/GetByCompetitionId/${data.competitionId}`}
                  state={{ state_title: 'Mi Competition', state_value: data.competitionId }}>
                  {data?.name}
                </Link>
                ({data?.sportName}){' '}
                <a className="cursor-pointer" onClick={() => userAccount(data)}>
                  {data.createdByName ?? 'User '}{' '}
                </a>
                {dayjs(data.endDate).isSame('2000-01-01T00:00:00')
                  ? 'has ended.'
                  : `ends at ${dayjs(data.endDate).format('h:mma')} (${data.timeZone}) ${dayjs(
                      data.endDate
                    ).format('D MMMM YYYY')}.`}
              </p>
              <p className="d-flex flex-wrap align-items-center">
                <Link
                  to={`/Competition/GetByCompetitionId/${data.competitionId}`}
                  state={{ state_title: 'Mi Competition', state_value: data.competitionId }}>
                  <img src={view_ic} className="me-2" />
                </Link>
              </p>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className="border p-4 mb-4 rounded">
            <center>
              <h3 className="text-muted">No data Found</h3>
            </center>
          </div>
        </>
      )}
      <>
        <CommonDelete
          modal={deleteModal}
          message="Are you sure you want to delete this competition?"
          header="Delete MI Competition"
          toggle={() => setDeleteModal(!deleteModal)}
          confirmDeleteClick={() => handleConfirmDelete()}
        />
      </>
    </>
  );
}
export default MiCompetition;
