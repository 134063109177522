/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import food_redirect from '/public/images/food-redirect.svg';
import search_ic from '/public/images/search-ic.svg';
import { Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDebouncedValue } from '../../../components/common/DebounceHook';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import no_nutrition from '/public/images/no-nutrition.png';
import fatsecret from '/public/images/fatsecret.svg';
import {
  FetchMiNutritionFoodData,
  FetchMiNutritionFoodFinder
} from '../../../store/Slices/userModule/NutritionSlice/miNutritionAction';

const SearchFoodTab = () => {
  const [query, setQuery] = useState('');
  const [activeSearchResult, setActiveSearchResult] = useState<number>(2);
  // eslint-disable-next-line no-unused-vars

  const debouncedSearchTerm = useDebouncedValue(query, 500);
  const dispatch = useAppDispatch();
  const { miNutritionFoodFinder, isLoading } = useAppSelector((state) => state.miNutrition);

  const miNutritionFoodData: any = useAppSelector((state) => state.miNutrition.miNutritionFoodData);

  useEffect(() => {
    dispatch(FetchMiNutritionFoodData('asdljaskhdashdjkashd'));
    dispatch(FetchMiNutritionFoodFinder({ search: 'azsfcjlhkasdf' }));
  }, [dispatch]);

  return (
    <>
      <div className="friend-search d-flex flex-wrap align-items-center mb-3">
        <div className="friend-search-left position-relative">
          <Input
            type="text"
            placeholder="Search..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
            }}
          />
          <span>
            <img src={search_ic} />
          </span>
        </div>
        <div className="friend-search-right">
          <button
            style={{ background: !query && 'grey' }}
            disabled={!query}
            onClick={() => {
              dispatch(FetchMiNutritionFoodData(query));
              dispatch(FetchMiNutritionFoodFinder({ search: query }));
            }}>
            Search
          </button>
        </div>
      </div>
      <div className="friend-search d-flex flex-wrap align-items-center mb-3">
        <div className="friend-search-right">
          <button
            className={activeSearchResult === 1 && 'active'}
            style={{ background: activeSearchResult === 2 && 'grey' }}
            onClick={() => setActiveSearchResult(1)}>
            FatSecret
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm mt-2 mx-2 text-light"
                role="status"
                aria-hidden="true"></span>
            )}
          </button>
        </div>
        <div className="friend-search-right mx-3">
          <button
            className={activeSearchResult === 2 && 'active'}
            style={{ background: activeSearchResult === 1 && 'grey' }}
            onClick={() => setActiveSearchResult(2)}>
            MISportsProfile
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm mt-2 mx-2 text-light"
                role="status"
                aria-hidden="true"></span>
            )}
          </button>
        </div>
      </div>
      {activeSearchResult === 1 && (
        <>
          {miNutritionFoodData?.length ? (
            miNutritionFoodData?.map((data: any, index: number) => (
              <div
                className="search-food-list d-flex flex-wrap align-items-center mb-3"
                key={index}>
                <div className="search-food-left">
                  <h5>{data.food_name}</h5>
                  <p>{data.food_description}</p>
                </div>
                <div className="search-food-right">
                  <Link
                    to="/minutrition/minutritionaddanentry"
                    state={{
                      state_title: 'Mi Nutrition Fat Secret',
                      foodData: data,
                      foodID: data.food_id,
                      isEditFatSecret: true,
                      isThirdFood: true
                    }}>
                    <button>
                      <img src={food_redirect} />
                    </button>
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="d-flex flex-wrap justify-content-center">
                <img className="pe-none" src={no_nutrition} />
              </div>
            </>
          )}
        </>
      )}
      {activeSearchResult === 2 && (
        <>
          {miNutritionFoodFinder?.length ? (
            miNutritionFoodFinder?.map((data: any, index: number) => (
              <div
                className="search-food-list d-flex flex-wrap align-items-center mb-3"
                key={index}>
                <div className="search-food-left">
                  <h5>{data.food_name}</h5>
                  <p>{data.description}</p>
                </div>
                <div className="search-food-right">
                  <Link
                    to="/minutrition/minutritionaddanentry"
                    state={{
                      state_title: 'Mi Nutrition Mi Sports Profile',
                      foodData: data,
                      foodID: data?.food_id,
                      isEditProfile: true
                    }}>
                    <button>
                      <img src={food_redirect} />
                    </button>
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="d-flex flex-wrap justify-content-center">
                <img className="pe-none" src={no_nutrition} />
              </div>
            </>
          )}
        </>
      )}
      <div className="add-new-food">
        {activeSearchResult === 1 && (
          <>
            <div className="d-flex flex-rap justify-content-center mb-3 mt-3">
              <img src={fatsecret} />
            </div>
          </>
        )}
        <p>
          Can't find what you're looking for?{' '}
          <Link to="/minutrition/food-action" state={{ state_title: 'Mi Nutrition' }}>
            Add a new food!
          </Link>{' '}
        </p>
      </div>
    </>
  );
};

export default SearchFoodTab;
