import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAppDispatch } from '../../store/hooks';
import { DeleteMIRecords } from '../../store/Slices/userModule/MiRecordSlice/miRecordSlice';

function Delete(props: any) {
  const dispatch = useAppDispatch();
  const onLogoutClick = () => {
    props?.toggle;
  };
  const { message, header, modal, toggle, currentReecord } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} className="popup-wrapper delete-popup" centered size="sm">
      <ModalHeader toggle={toggle} className="p-0">
        {header}
      </ModalHeader>
      <ModalBody className="text-center">{message}</ModalBody>
      <ModalFooter className="justify-content-center mt-3">
        <Button className="cancel-btn me-3" onClick={props?.toggle}>
          Cancel
        </Button>{' '}
        <Button
          className="save-record"
          onClick={() => {
            onLogoutClick();
            dispatch(DeleteMIRecords(currentReecord.userRecordId));
            toggle();
          }}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Delete;
