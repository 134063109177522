import React, { useEffect, useRef, useState } from 'react';
// import clientimg from '/public/images/client-img.png';
import friend_clientimg from '/public/images/mask_group.png';
import profile_placeholder from '/public/images/profile_placeholder.png';
import genderic from '/public/images/gender-icon.svg';
import marrage_ic from '/public/images/merrage-status-icon.svg';
import location_ic from '/public/images/location-icon.svg';
import birthday_ic from '/public/images/birthdate-icon.svg';
import time_ic from '/public/images/time-icon.svg';
import profession_ic from '/public/images/profession-icon.svg';
import back_arrow from '/public/images/back-arrow.svg';
import {
  fetchMiProfileDetail,
  fetchMiProfileFriendDetail
} from '../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import { Col, Row } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import dayjs from 'dayjs';
import { TMIProfileDetail } from '../../types/miProfile';

const genders = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
  { label: 'Others', value: 3 }
];

const relationShip = [
  { label: 'Not Specified', value: 1 },
  { label: 'Single', value: 2 },
  { label: 'Defacto', value: 3 },
  { label: 'Married', value: 4 },
  { label: 'Divorced', value: 5 },
  { label: 'Widowed', value: 6 },
  { label: 'Complicated', value: 7 }
];

function HeaderBottom() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState('false');
  // eslint-disable-next-line no-unused-vars
  const [isFriend, setIsFriend] = React.useState('false');

  const userDataString = sessionStorage.getItem('userData');
  const userData = JSON.parse(userDataString);

  const friendData: TMIProfileDetail = useAppSelector(
    (state) => state.miProfile.FetchMiProfileFriendDetail
  );

  const userName = friendData?.displayName;

  const locationFriend = friendData?.location;
  const timezoneInfo = friendData?.timezoneInfo;
  const dob = friendData?.dob;
  const occupation = friendData?.occupation;
  const miprofiles = friendData?.imageUrl;

  const friendgender = genders.find((data) => data.value === friendData?.gender);
  const relationship = relationShip.find((data) => data.value === friendData?.relationShip);

  const BacktoProfile = () => {
    localStorage.removeItem('friend');
    sessionStorage.removeItem('userData');
    sessionStorage.clear();
    navigate('/home');
  };

  React.useLayoutEffect(() => {
    setShowButton(localStorage.getItem('friend'));
    setIsFriend(localStorage.getItem('friend'));
  }, [location]);

  const { UserID } = location.state || {};

  useEffect(() => {
    dispatch(fetchMiProfileDetail(UserID || 0));
    dispatch(fetchMiProfileFriendDetail(userData?.userId || 0));
  }, [dispatch]);

  return (
    <>
      <div className="header-bottom py-3 mb-3">
        <div className="container">
          <div className="header-bottom-inner d-flex flex-wrap align-items-center">
            <div className="header-client-img d-flex flex-wrap align-items-center justify-content-center position-relative">
              {miprofiles ? (
                <img src={process.env.CLIENT_PATH + miprofiles} />
              ) : (
                <img src={profile_placeholder} />
              )}
            </div>
            <div className="header-side-content">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <h1 className="mb-3">{userName}</h1>
                {showButton == 'true' && (
                  <>
                    <button onClick={BacktoProfile} className="back-profile mb-3">
                      <img src={back_arrow} className="me-2" />
                      Back to Profile
                    </button>
                  </>
                )}
              </div>

              {friendData?.basicInfoVisibility === 3 && (
                <Row className="mb-2">
                  <Col>
                    <div className="d-flex flex-wrap align-items-center">
                      {friendgender && <img src={genderic} />}
                      <p>{friendgender?.label}</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-wrap align-items-center">
                      {relationship && <img src={marrage_ic} />}
                      <p>{relationship?.label}</p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="d-flex flex-wrap align-items-center">
                      {locationFriend && <img src={location_ic} />}
                      <p> {locationFriend} </p>
                    </div>
                  </Col>
                </Row>
              )}
              {friendData?.basicInfoVisibility === 3 && (
                <Row className="header-bottom-content">
                  <Col>
                    <div className="d-flex flex-wrap align-items-center">
                      {timezoneInfo && <img src={time_ic} />}
                      <p> {timezoneInfo}</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex flex-wrap align-items-center">
                      {dob && <img src={birthday_ic} />}
                      <p> {dob && dayjs(dob).format('MM/DD/YYYY')}</p>
                    </div>
                  </Col>

                  <Col md="6">
                    <div className="d-flex flex-wrap align-items-center">
                      {occupation && <img src={profession_ic} />}
                      <p> {occupation}</p>
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderBottom;
