import { Formik, Form, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, FormGroup, Label, ModalHeader, Input } from 'reactstrap';
import * as Yup from 'yup';
import { ErrorMessage } from '..';
import { useAppDispatch } from '../../store/hooks';
import {
  postMIRecords,
  updateMIRecords
} from '../../store/Slices/userModule/MiRecordSlice/miRecordSlice';

interface MyFormValues {
  userRecordId?: number;
  userId: string;
  label: string;
  url: string;
  notes: string;
}
const maxLength = 40;

const SignupSchema = Yup.object().shape({
  label: Yup.string()
    .required('Please enter record label')
    // .min(5, 'Record label must be greater than 5 character')
    .max(maxLength, 'Record label must be less than 40 character')
    // .trim('Leading and trailing space is not allowed')
    .strict(true),
  url: Yup.string()
    .required('Please enter URL')
    .matches(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
      'URL Pattern not Matching'
    )
    // .trim('Leading and trailing space is not allowed')
    // .trim('Leading and trailing space is not allowed')
    .strict(true),

  notes: Yup.string()
    .required('Please enter notes')
    // .min(5, 'Note must be greater than 5 character')
    // .trim('Leading and trailing space is not allowed')
    .strict(true)
});

function RecordModel(props: any) {
  const { modal, toggle, currentReecord } = props;
  const [userId, setUserId] = useState(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const userData: any = localStorage.getItem('userData');
    setUserId(JSON.parse(userData));
  }, []);

  const initialValues: MyFormValues = {
    userId: currentReecord ? currentReecord?.userId : '',
    label: currentReecord ? currentReecord?.label : '',
    url: currentReecord ? currentReecord?.url : '',
    notes: currentReecord ? currentReecord?.notes : '',
    userRecordId: currentReecord ? currentReecord?.userRecordId : 0
  };

  return (
    <Modal
      className="popup-wrapper"
      isOpen={modal}
      toggle={toggle}
      {...arguments}
      centered
      scrollable>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          values.userId = userId?.userId;
          if (currentReecord?.userRecordId) {
            dispatch(updateMIRecords(values));
          } else {
            dispatch(postMIRecords(values));
          }
          actions?.setSubmitting(false);
          toggle();
        }}>
        {({ errors, touched, values, handleChange, handleSubmit }) => (
          <>
            <ModalHeader toggle={toggle} className="p-0 justify-content-between">
              {currentReecord?.userRecordId ? 'Edit' : 'New'} Bookmark
            </ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label className="ms-3">Label</Label>
                  <Input
                    tag={Field}
                    name="label"
                    placeholder="Enter record label"
                    value={values.label}
                    onChange={handleChange}
                    isInvalid={!!errors.label}
                    maxLength={40}
                    max={4}
                    type="text"
                  />
                  {errors.label && touched.label && <ErrorMessage text={errors.label} />}
                </FormGroup>
                <FormGroup>
                  <Label className="ms-3">Url</Label>
                  <Input
                    tag={Field}
                    name="url"
                    placeholder="Enter url"
                    value={values.url}
                    onChange={handleChange}
                    type="text"
                  />
                  {errors.url && touched.url && <ErrorMessage text={errors.url} />}
                </FormGroup>
                <FormGroup>
                  <Label className="ms-3" for="exampleText">
                    Notes
                  </Label>
                  <Input
                    tag={Field}
                    id="exampleText"
                    name="notes"
                    type="textarea"
                    value={values.notes}
                    onChange={handleChange}
                    placeholder="Write notes"
                  />
                  {errors.notes && touched.notes && <ErrorMessage text={errors.notes} />}
                </FormGroup>
                <div className="modal-footer justify-content-center mt-1">
                  <button className="cancel-btn me-3" onClick={toggle} type="button">
                    Cancel
                  </button>
                  <button className="save-record" type="submit">
                    {currentReecord?.userRecordId ? 'Save' : 'Add'} Mi Record
                  </button>
                </div>
              </Form>
            </ModalBody>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default RecordModel;
