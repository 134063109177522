import { createSlice } from '@reduxjs/toolkit';
import { IMiNutritionInitials } from '../../../../types/miNutrition';
import {
  FetchMiNutritionById,
  FetchMiDetailsById,
  FetchMiNutritionFoodData,
  FetchMiNutritionFoodFinder,
  FetchMiNutritionListByDate,
  FetchMiNutritionListById,
  FetchMiNutritionMostEatenFoods,
  FetchMiNutritionMostRecentFoods,
  FetchMiNutritionSummaryByDate,
  FetchServingFoodCombo,
  FetchServingFoodDetailsCombo
} from './miNutritionAction';

const initialState: IMiNutritionInitials = {
  miNutritionSummary: null,
  miNutritionFoodDetailsCombo: null,
  miServingFoodDetailsCombos: null,
  miNutritionFoodDataById: null,
  miNutritionMostRecentFoods: null,
  miNutritionMostEatenFoods: null,
  miNutritionList: [],
  miNutritionFoodFinder: null,
  miNutritionFoodData: null,
  miNutritionDetailByIds: null,
  FetchMiDetailsByIds: null,
  isLoading: false,
  error: null
};

const miNutritionSlice = createSlice({
  name: 'miNutrition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(FetchMiNutritionSummaryByDate.fulfilled, (state, action) => {
      state.miNutritionSummary = action.payload;
    });
    builder.addCase(FetchMiNutritionListByDate.fulfilled, (state, action) => {
      state.miNutritionList = action.payload;
    });
    builder.addCase(FetchMiNutritionFoodData.pending, (state, action) => {
      state.isLoading = true;
      state.miNutritionFoodData = action.payload;
    });
    builder.addCase(FetchMiNutritionFoodData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(FetchMiNutritionFoodData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.miNutritionFoodData = action.payload;
    });
    builder.addCase(FetchMiNutritionFoodFinder.fulfilled, (state, action) => {
      state.isLoading = false;
      state.miNutritionFoodFinder = action.payload;
    });
    builder.addCase(FetchMiNutritionListById.fulfilled, (state, action) => {
      state.miNutritionFoodDataById = action.payload;
    });
    builder.addCase(FetchMiNutritionMostRecentFoods.fulfilled, (state, action) => {
      state.miNutritionMostRecentFoods = action.payload;
    });
    builder.addCase(FetchMiNutritionMostEatenFoods.fulfilled, (state, action) => {
      state.miNutritionMostEatenFoods = action.payload;
    });
    builder.addCase(FetchServingFoodCombo.fulfilled, (state, action) => {
      state.miNutritionFoodDetailsCombo = action.payload;
    });
    builder.addCase(FetchServingFoodDetailsCombo.fulfilled, (state, action) => {
      state.miServingFoodDetailsCombos = action.payload;
    });
    builder.addCase(FetchMiNutritionById.fulfilled, (state, action) => {
      state.miNutritionDetailByIds = action.payload;
    });
    builder.addCase(FetchMiDetailsById.fulfilled, (state, action) => {
      state.FetchMiDetailsByIds = action.payload;
    });
  }
});

export default miNutritionSlice.reducer;
