import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../../CommonSlice/commonSlice.';
import axios from 'axios';
import {
  FRIEND_REQUEST,
  SEARCH_FRIEND,
  SEARCH_MY_FRIEND,
  UPDATE_FRIEND_STATUS,
  FRIEND_LIST,
  GET_STATUS_OF_FRIEND,
  GET_ALL_GALLERY_FRIEND
} from '../../../apiRoutes';
import errorHandlers from '../../../../shared/config/errorHandler';

export const searchFriend = createAsyncThunk(
  'Friends/searchFriend',
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(SEARCH_FRIEND, data);
      dispatch(setLoading(false));
      return response?.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const searchMyFriend = createAsyncThunk(
  'MyFriend/searchMyFriend',
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(SEARCH_MY_FRIEND, data);
      dispatch(setLoading(false));
      return response?.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const friendRequest = createAsyncThunk(
  'FriendRequests/FriendRequest',
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(FRIEND_REQUEST, data);
      dispatch(setLoading(false));
      dispatch(getsStatusOfFriend());

      dispatch(searchFriend({ search: '' }));
      return response?.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const updateFriendStatus = createAsyncThunk(
  'FriendStatus/FriendStatus',
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(UPDATE_FRIEND_STATUS, data);
      dispatch(setLoading(data));
      dispatch(getsStatusOfFriend());
      if (data.flag == 1) {
        dispatch(searchFriend({ search: '' }));
      } else {
        dispatch(searchMyFriend({ search: '' }));
      }
      return response?.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const getMiFriendList = createAsyncThunk(
  'friendList/getMiFriendList',
  async (_, { dispatch }) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const fuserData = JSON.parse(sessionStorage.getItem('userData'));
    const fuserId = fuserData?.userId;

    const isFriend = localStorage.getItem('friend') === 'true';
    const userId = isFriend ? fuserId : userData?.userId;

    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${FRIEND_LIST}/${userId}`);
      return response.data || [];
    } catch (error) {
      errorHandlers(error);
      throw error; // Re-throw the error to make it available to rejected action
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getsStatusOfFriend = createAsyncThunk(
  'getfriendStatus/getsStatusOfFriend',
  async (_, { dispatch }) => {
    const fuserDataString = sessionStorage.getItem('userData');
    const fuserData = JSON.parse(fuserDataString);
    const fuserId = fuserData.userId;
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_STATUS_OF_FRIEND}/${fuserId}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const getFriendGallerys = createAsyncThunk(
  'getFriendGallerys/getFriendGallerys',
  async (_, { dispatch }) => {
    const fuserDataString = sessionStorage.getItem('userData');
    const fuserData = JSON.parse(fuserDataString);
    const fuserId = fuserData.userId;
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_ALL_GALLERY_FRIEND}?userId=${fuserId}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);
