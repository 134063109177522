import React from 'react';
import { Col, Row } from 'reactstrap';
import { useAppSelector } from '../../../store/hooks';
import DotSpinner from '../../common/dotSpinner';

interface WeightRecordDetailComponentProps { }

const WeightRecordDetail: React.FC<WeightRecordDetailComponentProps> = () => {
  const weightData = useAppSelector((state) => state.miDashboard.GetSingleScheduleGeneralList);
  return Object.keys(weightData).length ? (
    <div className="auth-left-wrapper">
      <Row>
        <Col md="6">
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded">
            <div className="d-flex justify-content-between">
              <h6 className="p-2">Weight</h6>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Muscle Group:</span>
              <span className="p-2">{weightData?.muscleGroup}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Exercise Equipment:</span>
              <span className="p-2">{weightData?.equipment}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Warmup Count:</span>
              <span className="p-2">{weightData?.warmUpCount}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Muscle:</span>
              <span className="p-2">{weightData?.muscleCategory}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Warmup Weight:</span>
              <span className="p-2">{weightData?.warmWeight}Kg</span>
            </div>
          </div>
        </Col>
        <Col md="6">
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded">
            <div className="d-flex justify-content-between">
              <h6 className="p-2">Sets</h6>
            </div>
            {weightData.scheduleWeightSet.map((data: any, index: number) => (
              <div className="d-flex justify-content-between" key={index}>
                <span className="p-2">Set #{index + 1}:</span>
                <span className="p-2">
                  {data.count} X {data.weight}kg
                </span>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="bg-secondary p-2 m-2 bg-opacity-10 rounded d-none">
            <div className="d-flex justify-content-between">
              <h6 className="p-2">Repeat</h6>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat:</span>
              <span className="p-2">Yes</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">When does it start?</span>
              <span className="p-2">14-02-2023, 11:14</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">When does it end?</span>
              <span className="p-2">14-02-2023, 11:14</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat Days:</span>
              <span className="p-2">Sun, Mon, Tue, Wen, Thu, Fri, Sat</span>
            </div>{' '}
            <div className="d-flex justify-content-between">
              <span className="p-2">Repeat Every:</span>
              <span className="p-2">1</span>
            </div>{' '}
            <div className="d-flex justify-content-between">
              <span className="p-2">Recurrence:</span>
              <span className="p-2">Week</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <DotSpinner />
  );
};

export default WeightRecordDetail;
