/* eslint-disable prettier/prettier */
import React from 'react';
import abstractlogo from '/public/images/abstractlogo.svg';
import formemail from '/public/images/form-email.svg';
import authlogo from '/public/images/mi-logo.svg';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { AuthSidepage, ErrorMessage } from '../../../components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { forgetPassword } from '../../../store/Slices/AuthModule/AuthModuleSlice';

interface MyFormValues {
  email: string;
}

function register() {
  { document.title = 'Mi Sports Profile - Forgot Password' }
  const dispatch = useAppDispatch();
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Please enter valid email address').required('Please enter email address')
  });

  const initialValues: MyFormValues = {
    email: ''
  };
  const navigator = useNavigate();

  const navigateFunction = () => {
    navigator('#');
  };
  return (
    <div className="auth-wrapper d-flex flex-wrap align-items-center">
      <div className="auth-left">
        <AuthSidepage />
      </div>
      <div className="auth-right">
        <div className="auth-logo-mobile">
          <img src={authlogo} />
        </div>
        <div className="auth-right-wrapper">
          <div className="auth-right-box">
            <div className="auth-form-top d-flex flex-wrap align-items-center justify-content-between">
              <p>Forgot Password</p>
              <img src={abstractlogo} />
            </div>
            <div className="forget-content">
              <p>
                Don't worry, we can help you get back into your account safely! Fill out the form
                below to request an e-mail with information on how to reset your password.
              </p>
            </div>
            <div className="auth-form">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={SignupSchema}
                onSubmit={(values, actions) => {
                  dispatch(forgetPassword({ data: values, navigate: navigateFunction }));
                  actions.setSubmitting(false);
                  actions.resetForm();
                }}>
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <InputGroup>
                      <InputGroupText>
                        <img src={formemail} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="email"
                        type="email"
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && <ErrorMessage text={errors.email} />}
                    </InputGroup>

                    <div className="form-button">
                      <button type="submit">Send</button>
                    </div>
                  </Form>
                )}
              </Formik>

              <div className="d-flex justify-content-md-center form-login">
                <Link to="/login">Back To Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default register;
