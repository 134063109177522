import { toast } from 'react-toastify';

const errorHandlers = (error: any) => {
  if (error) {
    if (error?.response?.status === 401) {
      toast.error('Token expired');
      localStorage.removeItem('userData');
      localStorage.removeItem('token');
      localStorage.removeItem('friend');
      sessionStorage.removeItem('userData');
      sessionStorage.clear();
      return (window.location.href = '/login');
    } else if (error?.response?.data?.message) {
      return toast.error(error?.response?.data?.message);
    } else if (error?.response?.message) {
      return toast.error(error?.response.message);
    } else if (error?.response?.errorMessage) {
      return toast.error(error?.response?.errorMessage);
    } else if (error?.message) {
      return toast.error(error?.message);
    } else {
      return toast.error(error || 'Something Went Wrong');
    }
  } else {
    return toast.error('Something Went Wrong');
  }
};

export default errorHandlers;
