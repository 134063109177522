import React from 'react';
import authlogo from '/public/images/mi-logo.svg';

function sideComponent() {
  return (
    <div className="auth-left-wrapper">
      <img src={authlogo} />
      <div className="auth-welcome-text">
        <span>Welcome to </span>
        <p>Mi Sports Profile</p>
      </div>
      <h1 className="mb-2">The sports motivator website</h1>
      <label>
        Sign up, log in, aim, record, connect, network, achieve and Succeed in your Sport or
        Passion.
      </label>
      <iframe width="474" height="267" src="//www.youtube.com/embed/008HBHnZMBQ"></iframe>
    </div>
  );
}

export default sideComponent;
