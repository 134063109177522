import React, { useEffect, useState } from 'react';

import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ErrorMessage } from '../..';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ReactSelect from 'react-select';
import {
  createScheduleSportsSet,
  getScheduleSportsListById,
  getSportsCombo,
  updateScheduleSportsSet
} from '../../../store/Slices/userModule/DashboardSlice/dashboardAction';
import { fetchMiGetUserSportsData } from '../../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import CommonConfirmModal from '../../common/ConfirmModal';

interface SportRecordValues {
  scheduleSportId: number;
  sportId: number;
  duration: number;
  warmUpDetails: string;
  outComeNotes: string;
  userId: number;
  date: string | Date;
  // Repeat
  startDate: string;
  endDate: string;
  daysOfWeek?: any;
  repeats: number;
  recurrenceType: number;
  isRecurrence: boolean;
  isOveride: boolean;
  slot: number;
}

function SportRecord(props: any) {
  const [confirmModal, setConfirmModal] = useState(false);
  const [editPayloadData, setEditPayloadData] = useState<any>();
  const [isDetailRequire, setIsDetailRequire] = useState(props?.model?.isDetailRequired);
  const dispatch = useAppDispatch();

  const { GetSportsCombo } = useAppSelector((state) => state.miDashboard);

  const { localUserData } = useAppSelector((state) => state?.common);
  const currentSports = useAppSelector((state) => state.miDashboard.GetScheduleSportsListById);

  const { miSportsData } = useAppSelector((state) => state?.miProfile);

  useEffect(() => {
    dispatch(getSportsCombo());
    dispatch(fetchMiGetUserSportsData());
    // dispatch(miSportData());
  }, [dispatch]);

  useEffect(() => {
    props?.model?.currentSportsData?.scheduleSportId &&
      props?.model?.isEditMode &&
      dispatch(getScheduleSportsListById(props?.model?.currentSportsData?.scheduleSportId));
  }, []);

  const initialValues: SportRecordValues = {
    scheduleSportId: props?.model?.isEditMode ? currentSports?.scheduleSportId : 0,
    userId: props?.model?.isEditMode ? currentSports?.userId : 0,
    date: props?.model?.isEditMode ? currentSports?.date : new Date(),
    sportId: props?.model?.isEditMode ? currentSports?.sportId : null,
    duration: props?.model?.isEditMode ? currentSports?.duration : null,
    warmUpDetails: props?.model?.isEditMode ? currentSports?.warmUpDetails : '',
    outComeNotes: props?.model?.isEditMode ? currentSports?.outComeNotes : '',

    // Repeat
    startDate: props?.model?.isEditMode ? currentSports?.startDate : null,
    endDate: props?.model?.isEditMode ? currentSports?.endDate : null,
    daysOfWeek: props?.model?.isEditMode
      ? currentSports?.daysOfWeek || [0, 0, 0, 0, 0, 0, 0]
      : [0, 0, 0, 0, 0, 0, 0],
    repeats: props?.model?.isEditMode ? currentSports?.repeats || '' : null,
    recurrenceType: props?.model?.isEditMode ? currentSports?.recurrenceType || '' : null,
    isRecurrence: props?.model?.isEditMode ? currentSports?.isRecurrence : false,
    isOveride: props?.model?.isEditMode ? currentSports?.isOveride : false,
    slot: props?.model?.isEditMode ? currentSports?.slot : 0
  };

  const SportRecordSchema = Yup.object().shape({
    sportId: Yup.number().required('Please select sport'),
    duration: Yup.number().typeError('Please enter number').required('Please enter duration'),
    warmUpDetails: Yup.string()
      .matches(/^[a-zA-Z0-9\s]*$/, 'Special characters are not allowed')
      .required('Please enter warmup details'),
    // warmUpDetails: Yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Special characters are not allowed').required('Please enter warmup details'),
    outComeNotes: Yup.string().required('Please enter outcome notes'),

    // Repeat
    startDate: Yup.string()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.string().nullable().required('Please select start date')
      }),
    endDate: Yup.string()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.string().nullable().required('Please select end date')
      }),
    repeats: Yup.number()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.number().nullable().required('Please enter interval duration')
      }),
    recurrenceType: Yup.number()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.number().nullable().required('Please select recurrence')
      })
  });

  const sports = props.activeTab;
  const recurrence = [
    { label: 'Daily', value: 1 },
    { label: 'Weekly', value: 2 },
    { label: 'Monthly', value: 3 }
  ];
  return (
    <>
      <div className="auth-left-wrapper">
        {!props.model.isEditMode && <h4 className="mb-3 popup-subtitle">Add Sport Record</h4>}
        {sports == 'Sports' && (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={SportRecordSchema}
            onSubmit={(values, actions) => {
              values.userId = localUserData.userId || 0;
              if (!props?.model?.isEditMode) {
                values.date = props?.model?.currentSelectedDate || dayjs().format('YYYY-MM-DD');
              }
              if (currentSports.length > 0) {
                values.date = dayjs((currentSports as any)?.date).format('YYYY-MM-DD');
              }
              if (!values?.isRecurrence) {
                values.repeats = 0;
                values.startDate = null;
                values.endDate = null;
                values.recurrenceType = 0;
                values.slot = 0;
              }
              if (values?.startDate && values.endDate) {
                values.startDate = dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss');
                values.endDate = dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss');
              }
              if (values.scheduleSportId) {
                if (values.isRecurrence) {
                  setConfirmModal(true);
                  setEditPayloadData(values);
                } else {
                  dispatch(updateScheduleSportsSet(values));
                  props?.toggle();
                }
              } else {
                dispatch(createScheduleSportsSet(values));
                props?.toggle();
              }
              actions.setSubmitting(false);
            }}>
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                {isDetailRequire && props?.model?.isEditMode && (
                  <Row>
                    <Col md="12">
                      <FormGroup row className="d-flex flex-wrap align-items-top">
                        <Col sm={12} className="m-auto">
                          <span>{dayjs((currentSports as any)?.date).format('MM/DD/YYYY')}</span>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Sport:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentSports as any)?.sportName}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <ReactSelect
                            className="react-select-dropdown"
                            options={miSportsData}
                            placeholder="Select sport"
                            name="sportId"
                            value={miSportsData.find((data) => data.value === values.sportId)}
                            onChange={(option) => {
                              setFieldValue('sportId', +option.value);
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (provided) => ({
                                ...provided,
                                borderRadius: 50,
                                paddingLeft: 8,
                                boxShadow: null
                              })
                            }}
                          />
                          {errors.sportId && touched.sportId && (
                            <ErrorMessage text={errors.sportId} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Duration:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentSports as any)?.duration} Minutes</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="side-content position-relative">
                          <Input
                            type="number"
                            name="duration"
                            value={values.duration}
                            onChange={(e: React.ChangeEvent<any>) => {
                              if (e.target.value.length < 6) {
                                setFieldValue('duration', e.target.value);
                              }
                            }}
                            placeholder="Duration"></Input>
                          {errors.duration && touched.duration && (
                            <ErrorMessage text={errors.duration} />
                          )}
                          <span>Minutes</span>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Warmup details:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentSports as any)?.warmUpDetails}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="warmUpDetails"
                            placeholder="Warmup details"
                            value={values.warmUpDetails}
                            onChange={handleChange}
                            maxLength={100}
                            type="textarea"></Input>
                          {errors.warmUpDetails && touched.warmUpDetails && (
                            <ErrorMessage text={errors.warmUpDetails} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Outcome notes:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentSports as any)?.outComeNotes}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="outComeNotes"
                            placeholder="Outcome notes"
                            value={values.outComeNotes}
                            onChange={handleChange}
                            maxLength={100}
                            type="textarea"></Input>
                          {errors.outComeNotes && touched.outComeNotes && (
                            <ErrorMessage text={errors.outComeNotes} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6"></Col>
                  {isDetailRequire && props?.model?.isEditMode ? (
                    <Col md="12">{(currentSports as any)?.isRecurrence && <b>Repeat</b>}</Col>
                  ) : (
                    <Col md="12">
                      <div className="custom-checkbox d-inline-block">
                        <label className="checkbox-container">
                          Repeat
                          <Input
                            name="isRecurrence"
                            type="checkbox"
                            className="form-check-input"
                            checked={values.isRecurrence}
                            onChange={(e) => {
                              setFieldValue('isRecurrence', e.target.checked);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </Col>
                  )}
                  {values?.isRecurrence && (
                    <>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" lg={4}>
                            When does it start?
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{dayjs(currentSports?.startDate).format('MM/DD/YYYY')}</span>
                            </Col>
                          ) : (
                            <Col lg={8}>
                              <Row className="flex-wrap align-items-top">
                                <Col>
                                  <DatePicker
                                    autoComplete="off"
                                    name="startDate"
                                    selected={
                                      values?.startDate ? new Date(values?.startDate) : null
                                    }
                                    onChange={(date: Date | null) => {
                                      setFieldValue('startDate', date);
                                      setFieldValue('endDate', null);
                                    }}
                                    placeholderText="Please select start date"
                                    dateFormat="MM/dd/yyyy"
                                    popperProps={{ strategy: 'fixed' }}
                                  />
                                  {errors.startDate && touched.startDate && (
                                    <ErrorMessage text={errors.startDate} />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" lg={4}>
                            When does it end?
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{dayjs(currentSports?.endDate).format('MM/DD/YYYY')}</span>
                            </Col>
                          ) : (
                            <Col lg={8}>
                              <Row className="flex-wrap align-items-top">
                                <Col>
                                  <DatePicker
                                    autoComplete="off"
                                    name="endDate"
                                    disabled={values?.startDate ? false : true}
                                    minDate={values?.startDate && new Date(values?.startDate)}
                                    selected={values?.endDate ? new Date(values?.endDate) : null}
                                    onChange={(date: Date | null) => setFieldValue('endDate', date)}
                                    placeholderText="Please select end date"
                                    dateFormat="MM/dd/yyyy"
                                    popperProps={{ strategy: 'fixed' }}
                                    popperPlacement="bottom-start"
                                  />
                                  {errors.endDate && touched.endDate && (
                                    <ErrorMessage text={errors.endDate} />
                                  )}
                                </Col>{' '}
                              </Row>
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" sm={4}>
                            Recurrence
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>
                                {
                                  recurrence.find(
                                    (data) => data.value === currentSports.recurrenceType
                                  )?.label
                                }
                              </span>
                            </Col>
                          ) : (
                            <Col sm={8}>
                              <ReactSelect
                                className="react-select-dropdown"
                                options={recurrence}
                                placeholder="Select recurrence"
                                name="recurrenceType"
                                onChange={(options) => {
                                  setFieldValue('recurrenceType', +options.value);
                                }}
                                value={recurrence.find(
                                  (data) => data.value === values.recurrenceType
                                )}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  control: (provided) => ({
                                    ...provided,
                                    borderRadius: 50,
                                    paddingLeft: 8,
                                    boxShadow: null
                                  })
                                }}
                              />
                              {errors.recurrenceType && touched.recurrenceType && (
                                <ErrorMessage text={errors.recurrenceType} />
                              )}
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" sm={4}>
                            Interval duration
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{(currentSports as any)?.repeats}</span>
                            </Col>
                          ) : (
                            <Col sm={8}>
                              <Input
                                name="repeats"
                                placeholder="Interval duration"
                                value={values.repeats}
                                onChange={handleChange}
                                type="number"
                                min={1}
                                max={99}></Input>
                              {errors.repeats && touched.repeats && (
                                <ErrorMessage text={errors.repeats} />
                              )}
                            </Col>
                          )}
                        </FormGroup>
                      </Col>

                      {isDetailRequire && props?.model?.isEditMode ? (
                        <></>
                      ) : (
                        values.recurrenceType === 2 && (
                          <Col md="12" className="d-flex flex-wrap mb-3">
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Sun
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[0]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[0]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Mon
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[1]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[1]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Tue
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[2]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[2]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Wed
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[3]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[3]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Thu
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[4]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[4]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Fri
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[5]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[5]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Sat
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[6]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[6]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </Col>
                        )
                      )}
                    </>
                  )}
                  <Col md="12">
                    <div className="justify-content-end mt-3 col-md-12 modal-footer">
                      <Button className="cancel-btn me-3" onClick={props?.toggle}>
                        Cancel
                      </Button>{' '}
                      {isDetailRequire && props?.model?.isEditMode && (
                        <Button
                          className="save-record"
                          type="button"
                          onClick={() => {
                            setIsDetailRequire(!isDetailRequire);
                          }}>
                          Edit
                        </Button>
                      )}
                      {!isDetailRequire && (
                        <Button className="save-record" type="submit">
                          {props?.model.isEditMode ? 'Update' : 'Add'}
                        </Button>
                      )}
                      {props?.model?.isDateSelected && !props?.model?.isEditMode && (
                        <Button className="save-record" type="submit">
                          {props?.model.isEditMode ? 'Update' : 'Add'}
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <CommonConfirmModal
        header="Please confirm"
        message="Are you sure you want to over ride all record ?"
        modal={confirmModal}
        toggle={() => {
          setConfirmModal(false);
        }}
        confirmNoClickClick={() => {
          dispatch(updateScheduleSportsSet({ ...editPayloadData, onlyThisDate: true }));
          setConfirmModal(false);
          setEditPayloadData({});
          props?.toggle();
        }}
        confirmDeleteClick={() => {
          dispatch(updateScheduleSportsSet({ ...editPayloadData, onlyThisDate: false }));
          setConfirmModal(false);
          setEditPayloadData({});
          props?.toggle();
        }}
      />
    </>
  );
}

export default SportRecord;
