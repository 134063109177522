import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface Item {
  id: number;
  message: string;
  header: string;
  modal: boolean;
  toggle: string;
  confirmDeleteClick: boolean;
}

function CommonDelete(props: any) {
  const { message, header, modal, toggle, confirmDeleteClick } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} className="popup-wrapper delete-popup" centered size="sm">
      <ModalHeader toggle={toggle} className="p-0">
        {header}
      </ModalHeader>
      <ModalBody className="text-center">{message}</ModalBody>
      <ModalFooter className="justify-content-center mt-3">
        <Button className="cancel-btn me-3" onClick={props?.toggle}>
          Cancel
        </Button>{' '}
        <Button className="save-record" onClick={confirmDeleteClick}>
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CommonDelete;
