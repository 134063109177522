import React from 'react';

import not_found from '/public/images/not-found.png';
import { Link } from 'react-router-dom';
function NotFound() {
  return (
    <>
      <div className="not-found-wrapper py-5">
        <div className="container d-flex flex-wrap align-items-center">
          <div className="not-found-left">
            <img src={not_found} />
          </div>
          <div className="d-flex flex-wrap justify-content-center not-found-right">
            <h2 className="mb-2">Sorry!</h2>
            <p className="mb-5">Page You Are Looking Can’t Be Found.</p>
            <Link to="/">
              <button>Go to Home Page</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
