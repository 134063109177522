import axios from 'axios';
import { GET_MI_PROFILE_DETAIL } from '../../store/apiRoutes';
import dayjs from 'dayjs';
import { store } from '../../store/store';
export const redirectUser_friend = async (userId: number) => {
  try {
    localStorage.setItem('friend', 'true');

    const isFriend1 = localStorage.getItem('friend');

    const response = await axios.get<any>(GET_MI_PROFILE_DETAIL + userId);

    const userData = response.data;

    const userDataString = JSON.stringify(userData);

    sessionStorage.setItem('userData', userDataString);

    if (isFriend1 === 'true') {
      location.assign('/friends/home');
    } else {
      //
    }
  } catch (error) {
    console.error('An error occurred:', error);
    throw error;
  }
};

function localDate(offSet: number): Date | string {
  const localOffSet = new Date().getTimezoneOffset();
  const a = dayjs();
  const foreignLocalTime = a.add(localOffSet, 'minute');

  const currentLocalTime = foreignLocalTime.add(offSet, 'minute');

  return new Date(currentLocalTime as any);
}

export function getTimeDifference(commentTime: Date | string): string {
  const storeData = store.getState();
  const userOffSet =
    storeData?.miProfile?.FetchMiProfileDetail?.baseUtcOffsetSec ||
    +localStorage.getItem('baseUtcOffsetSec');
  const newTime = localDate(userOffSet / 60 || 1);
  const currentTime: Date = new Date(newTime);
  const commentDate: Date = new Date(commentTime);
  const timeDifference: number = currentTime.getTime() - commentDate.getTime();

  const minutes: number = Math.floor(timeDifference / (1000 * 60));

  if (minutes < 1) {
    return 'just now';
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (minutes < 1440) {
    const hours: number = Math.floor(minutes / 60);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else {
    const days: number = Math.floor(minutes / 1440);
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  }
}
