import { createSlice } from '@reduxjs/toolkit';
import {
  fetchMiProfileDetail,
  fetchMiProfileRelationCombo,
  fetchMiSportsCombo,
  fetchMiGetUserSportData,
  saveProfilePicture,
  fetchMiGetUserSportsData,
  fetchMiProfileFriendDetail
} from './miProfileAction';
import { IMiProfileInitials } from '../../../../types/miProfile';

const initialState: IMiProfileInitials = {
  miProfileRelationCombo: [],
  miSportsCombo: [],
  miSportData: [],
  miSportsData: [],
  FetchMiProfileDetail: [],
  MiProfilePictureDetail: [],
  FetchMiProfileFriendDetail: null,
  isLoading: false,
  error: null
};

const miProfileSlice = createSlice({
  name: 'miProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMiProfileRelationCombo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.miProfileRelationCombo = action.payload;
      })
      .addCase(fetchMiSportsCombo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.miSportsCombo = action.payload;
      })
      .addCase(fetchMiGetUserSportData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.miSportData = action.payload;
      })
      .addCase(fetchMiGetUserSportsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.miSportsData = action.payload;
      })
      .addCase(fetchMiProfileDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.FetchMiProfileDetail = action.payload;
      })
      .addCase(fetchMiProfileFriendDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.FetchMiProfileFriendDetail = action.payload;
      })
      .addCase(saveProfilePicture.fulfilled, (state, action) => {
        state.isLoading = false;
        state.MiProfilePictureDetail = action.payload;
      });
  }
});

export default miProfileSlice.reducer;
