import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../../CommonSlice/commonSlice.';
import axios from 'axios';
import errorHandlers from '../../../../shared/config/errorHandler';
import {
  CREATE_NUTRITION_ENTRY,
  CREATE_NUTRITION_FOOD,
  GET_FOOD_DETAILS_BY_FOOD_ID,
  GET_FOOD_DETAILS_COMBO,
  GET_FOOD_DETAILS_BY_FOOD_ID_COMBO,
  GET_MOST_EATEN_FOODS,
  GET_MOST_RECENT_FOODS,
  GET_NUTRITION_FOOD_BY_ID,
  GET_NUTRITION_FOOD_FINDER,
  GET_NUTRITION_FOOD_SEARCH,
  GET_NUTRITION_LIST_BY_DATE,
  GET_NUTRITION_LIST_BY_ID,
  GET_NUTRITION_SUMMARY_BY_DATE,
  UPDATE_NUTRITION_FOOD,
  ADD_FOOD_DETAIL,
  DELETE_FOOD_DETAIL,
  DELETE_FOOD_NUTRITION,
  UPDATE_NUTRITION_ENTRY,
  CREATE_NUTRITION_ENTRY_FAT_SECRET,
  DELETE_FOOD_DETAILS
} from '../../../apiRoutes';
import { toast } from 'react-toastify';

export const FetchMiNutritionSummaryByDate = createAsyncThunk(
  'miNutrition/FetchMiNutritionSummaryByDate',
  async (date: Date | string, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_NUTRITION_SUMMARY_BY_DATE}?CreatedDate=${date}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const FetchMiNutritionListByDate = createAsyncThunk(
  'miNutrition/FetchMiNutritionListByDate',
  async (date: Date | string, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_NUTRITION_LIST_BY_DATE}?CreatedDate=${date}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const FetchMiNutritionListById = createAsyncThunk(
  'miNutrition/FetchMiNutritionListById',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_NUTRITION_LIST_BY_ID + id);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const FetchMiNutritionFoodData = createAsyncThunk(
  'miNutrition/FetchMiNutritionFoodData',
  async (query: string, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${GET_NUTRITION_FOOD_SEARCH}?SearchExpression=${query}&PageNumber=1&MaxResults=25&GenericDescription=1`
      );
      dispatch(setLoading(false));
      return response?.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const FetchMiNutritionFoodFinder = createAsyncThunk(
  'miNutrition/FetchMiNutritionFoodFinder',
  async (data: any, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post(GET_NUTRITION_FOOD_FINDER, data);
      dispatch(setLoading(false));
      return response?.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const createNutritionFood = createAsyncThunk(
  'miNutrition/createNutritionFood',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(CREATE_NUTRITION_FOOD, data);
      AppDispatch(setLoading(false));
      navigate();
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const FetchMiNutritionById = createAsyncThunk(
  'miNutrition/FetchMiNutritionById',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_NUTRITION_FOOD_BY_ID}/${id}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

// Fat
export const FetchMiDetailsById = createAsyncThunk(
  'miNutrition/FetchMiDetailsById',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_FOOD_DETAILS_BY_FOOD_ID}?FoodId=${id}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const updateNutritionFood = createAsyncThunk(
  'miNutrition/updateNutritionFood',
  // async (data: any, { dispatch: AppDispatch }) => {
  async (
    { data, navigate1, navigate2 }: { data: any; navigate1: any; navigate2: any },
    { dispatch: AppDispatch }
  ) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(UPDATE_NUTRITION_FOOD, data);
      navigate1();
      navigate2();
      toast.success('Food updated successfully.');
      AppDispatch(FetchMiNutritionById(data.food_id));

      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const FetchMiNutritionMostRecentFoods = createAsyncThunk(
  'miNutrition/FetchMiNutritionMostRecentFoods',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_MOST_RECENT_FOODS);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const FetchMiNutritionMostEatenFoods = createAsyncThunk(
  'miNutrition/FetchMiNutritionMostEatenFoods',
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_MOST_EATEN_FOODS);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const FetchServingFoodCombo = createAsyncThunk(
  'miNutrition/FetchServingFoodCombo',
  async (food_id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_FOOD_DETAILS_COMBO + food_id);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);
export const FetchServingFoodDetailsCombo = createAsyncThunk(
  'miNutrition/FetchServingFoodDetailsCombo',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(`${GET_FOOD_DETAILS_BY_FOOD_ID_COMBO}?FoodId=${id}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const createNutritionEntry = createAsyncThunk(
  'miNutrition/createNutritionEntry',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(CREATE_NUTRITION_ENTRY, data);
      toast.success('Nutrition added successfully.');
      AppDispatch(setLoading(false));
      navigate();
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const createNutritionEntryFatSecret = createAsyncThunk(
  'miNutrition/createNutritionEntryFatSecret',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(CREATE_NUTRITION_ENTRY_FAT_SECRET, data);
      toast.success('Nutrition added successfully.');
      AppDispatch(setLoading(false));
      navigate();
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const updateNutritionEntry = createAsyncThunk(
  'miNutrition/updateNutritionEntry',
  // async (data: any, { dispatch: AppDispatch }) => {
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(UPDATE_NUTRITION_ENTRY, data);
      toast.success('Nutrition updated successfully.');
      navigate();
      AppDispatch(FetchMiNutritionById(data.food_id || data?.foodId));
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const addFoodDetailEntry = createAsyncThunk(
  'miNutrition/addFoodDetailEntry',
  async ({ data, navigate }: { data: any; navigate: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(ADD_FOOD_DETAIL, data);
      navigate();
      AppDispatch(setLoading(false));
      AppDispatch(FetchMiNutritionById(data.food_id));
      toast.success('Serving added successfully.');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteFoodDetailEntry = createAsyncThunk(
  'miNutrition/deleteFoodDetailEntry',
  async ({ id, food_id }: { id: number; food_id: number }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(DELETE_FOOD_DETAIL + id);
      AppDispatch(setLoading(false));
      AppDispatch(FetchMiNutritionById(food_id));
      toast.success('Serving deleted successfully.');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteNutritionFoodEntry = createAsyncThunk(
  'miNutrition/deleteNutritionFoodEntry',
  async ({ id, date }: { id: number; date: Date | string }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(DELETE_FOOD_NUTRITION + id);
      AppDispatch(setLoading(false));
      AppDispatch(FetchMiNutritionListByDate(date));
      AppDispatch(FetchMiNutritionSummaryByDate(date));
      toast.success('Nutrition deleted successfully.');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteFoodDetails = createAsyncThunk(
  'miNutrition/deleteFoodDetails',
  async ({ id, redirect }: { id: number; redirect: any }, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(DELETE_FOOD_DETAILS + id);
      AppDispatch(setLoading(false));
      redirect();
      toast.success('Food deleted successfully.');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);
