/* eslint-disable prettier/prettier */
import React from 'react';
import { FullCalendar } from '../../../Friendscomponents/index';

export default function dashboard() {
  document.title = 'Mi Sports Profile - Mi Program';
  return (
    <>
      <h3 className="page-heading">Mi Program</h3>

      <div className="calender-wrapper mb-3">
        <FullCalendar />
      </div>
    </>
  );
}
