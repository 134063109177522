import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoutes from './protectedRoutes';

import {
  Login,
  Register,
  ResetPassword,
  ForgotPassword,
  Home,
  EditProfile,
  Dashboard,
  MiRecord,
  WhatGoingOn,
  NoFriend,
  MiFriend,
  MiTraining,
  EditProfileTraining,
  Photos,
  MiNutrition,
  MiCompetition,
  AboutUs,
  MiSportsProfileValues,
  MiTermsConditions,
  MiPrivacyPolicy,
  ContactUs,
  MiCompetitionDetails,
  LayDownMiCompetition,
  MiNutritionSearchFood,
  EditFood,
  AddaServing,
  AddNewFood,
  FindMoreFriends,
  Loader,
  NotFound,
  Album,
  FriendsHome,
  FriendsDashboard,
  MiFriends,
  FriendsMiTraining,
  Friendsphotos
} from '../pages/index';
import AlbumFriend from '../pages/Friendmodule/Album';
import NoDataFound from '../components/common/NoDataFound';

const MiNutritionAddanEntry = React.lazy(() =>
  import('../pages/UserModule/MiNutritionAddanEntry/index')
);

function Useroutes() {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const userDataString = sessionStorage.getItem('userData');
  const friendData = JSON.parse(userDataString);
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        {/* temp chnages from home redirection to dashboard */}
        <Route path="/" element={<Navigate to="/home" replace />} />
        {userData && <Route path="/login" element={<Navigate to="/home" replace />} />}
        {userData && <Route path="/register" element={<Navigate to="/home" replace />} />}
        {userData && <Route path="/forgot-password" element={<Navigate to="/home" replace />} />}

        <Route
          path="/friends/home"
          element={friendData ? <FriendsHome /> : <Navigate to="/home" replace />}
        />
        <Route
          path="/friends/dashboard"
          element={friendData ? <FriendsDashboard /> : <Navigate to="/home" replace />}
        />
        <Route
          path="/friends/miFriends"
          element={friendData ? <MiFriends /> : <Navigate to="/home" replace />}
        />
        <Route
          path="/friends/friendsMiTraining"
          element={friendData ? <FriendsMiTraining /> : <Navigate to="/home" replace />}
        />
        <Route
          path="/friends/friendsphotos"
          element={friendData ? <Friendsphotos /> : <Navigate to="/home" replace />}
        />
        <Route
          path="/friend/friend-album-detail"
          element={friendData ? <AlbumFriend /> : <Navigate to="/home" replace />}
        />
        <Route
          path="/no-friend-data"
          element={
            friendData ? <NoDataFound message="No data found" /> : <Navigate to="/home" replace />
          }
        />

        {/* <Route path="/friends/friendsalbum" element={<FriendsAlbum />} /> */}

        <Route path="/home" element={<Home />} />
        <Route path="/editprofile" element={<EditProfile />} />
        <Route path="/mirecord" element={<MiRecord />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/whatgoingon" element={<WhatGoingOn />} />
        <Route path="/nofriend" element={<NoFriend />} />
        <Route path="/nofriend/mifriend" element={<MiFriend />} />
        <Route path="/mitraining" element={<MiTraining />} />
        <Route path="/mitraining/editprofiletraining" element={<EditProfileTraining />} />
        <Route path="/photos" element={<Photos />} />
        <Route path="/minutrition" element={<MiNutrition />} />
        <Route path="/micompetition" element={<MiCompetition />} />
        <Route path="/Competition/GetByCompetitionId/:id" element={<MiCompetitionDetails />} />
        <Route path="/micompetition/laydownmicompetition" element={<LayDownMiCompetition />} />
        <Route path="/minutrition/minutritionsearchfood" element={<MiNutritionSearchFood />} />
        <Route path="/minutrition/minutritionaddanentry" element={<MiNutritionAddanEntry />} />
        <Route path="/minutrition/editfood" element={<EditFood />} />
        <Route path="/minutrition/addaserving" element={<AddaServing />} />
        <Route path="/minutrition/food-action" element={<AddNewFood />} />
        <Route path="/nofriend/findmorefriends" element={<FindMoreFriends />} />
        <Route path="/photos/album" element={<Album />} />
        <Route path="/loader" element={<Loader />} />
        {/* <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/misportsprofilevalues" element={<MiSportsProfileValues />} />
        <Route path="/mitermsconditions" element={<MiTermsConditions />} />
        <Route path="/miprivacypolicy" element={<MiPrivacyPolicy />} />
        <Route path="/contactus" element={<ContactUs />} /> */}
      </Route>

      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/misportsprofilevalues" element={<MiSportsProfileValues />} />
      {/* <Route path="/mitermsconditions" element={<MiTermsConditions />} /> */}
      <Route path="/miprivacypolicy" element={<MiPrivacyPolicy />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
      <Route path="/notfound" element={<NotFound />} />
    </Routes>
  );
}

export default Useroutes;
