import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  GET_ALL_GALLERY,
  GET_GALLERY_DETAIL,
  POST_IMAGE,
  UPLOAD_IMAGE,
  DELETE_GALLERY_IMAGE,
  DELETE_GALLERY_FOLDER,
  GET_GALLERY_COMMENT_IMAGE,
  POST_IMAGE_COMMENT,
  GALLARY_UPDATE_PRIVACY,
  UPDATE_GALLERY_COMMENT,
  DELETE_GALLERY_COMMENT
} from '../../../apiRoutes';
import { toast } from 'react-toastify';
import errorHandlers from '../../../../shared/config/errorHandler';
import { setLoading } from '../../CommonSlice/commonSlice.';

export const getAllGallery = createAsyncThunk('album/GetAllGallery', async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await axios.get(GET_ALL_GALLERY);
    dispatch(setLoading(false));
    return response.data;
  } catch (error) {
    errorHandlers(error);
    dispatch(setLoading(false));
  }
});

export const getGalleryDetail = createAsyncThunk(
  'album/GetGalleryDetail',
  async (id: number, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_GALLERY_DETAIL + id);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const uploadImage = createAsyncThunk(
  'album/UploadImage',
  async (data: any, { dispatch }) => {
    const form = new FormData();
    for (let i = 0; i < data?.length; i++) {
      form.append('files', data[i]);
    }
    dispatch(setLoading(true));
    try {
      const response = await axios.post(UPLOAD_IMAGE, form);
      const imagesWithGalleryImageId = data.filter((image: any) =>
        image.hasOwnProperty('galleryImageId')
      );
      const res = [...imagesWithGalleryImageId, ...response.data];
      dispatch(setLoading(false));
      return res;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const deleteImage = createAsyncThunk('album/DeleteImage', (data: any) => {
  return data;
});

export const postGalleryImage = createAsyncThunk(
  'album/PostGalleryImage',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(POST_IMAGE, data);
      AppDispatch(setLoading(false));
      AppDispatch(getAllGallery());
      if (data?.galleryId === 0) {
        toast.success('Album created successfully');
      } else {
        toast.success('Album updated successfully');
      }
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteGalleryImage = createAsyncThunk(
  'album/DeleteGalleryImage',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(DELETE_GALLERY_IMAGE + id);
      AppDispatch(setLoading(false));
      AppDispatch(getAllGallery());
      toast.success('Image deleted successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteGalleryFolder = createAsyncThunk(
  'album/DeleteGalleryFolder',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(DELETE_GALLERY_FOLDER + id);
      AppDispatch(setLoading(false));
      AppDispatch(getAllGallery());
      toast.success('Albums deleted successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const getAllImageComment = createAsyncThunk(
  'album/GetAllImageComment',
  async (guid: string, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(GET_GALLERY_COMMENT_IMAGE + guid);
      dispatch(setLoading(false));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      dispatch(setLoading(false));
    }
  }
);

export const postImageComment = createAsyncThunk(
  'album/PostImageComment',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(POST_IMAGE_COMMENT, data);
      AppDispatch(setLoading(false));
      AppDispatch(getAllImageComment(data.galleryImageId));
      toast.success('Comment added successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const updateGalleryComment = createAsyncThunk(
  'album/updateGalleryComment',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(`${UPDATE_GALLERY_COMMENT}`, data);
      AppDispatch(setLoading(false));
      AppDispatch(getAllImageComment(data.galleryImageId));
      toast.success('Comment updated successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const deleteGalleryComment = createAsyncThunk(
  'album/deleteGalleryComment',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(
        `${DELETE_GALLERY_COMMENT}?id=${data?.getReplayDeleteId}`
      );
      AppDispatch(setLoading(false));
      toast.success('Comment deleted successfully');
      AppDispatch(getAllImageComment(data.getReplayGalleryDeleteId));
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const changePrivacyPermission = createAsyncThunk(
  'album/ChangePrivacyPermission',
  async (data: any, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(GALLARY_UPDATE_PRIVACY, data);
      AppDispatch(setLoading(false));
      AppDispatch(getAllGallery());
      if (data?.isPublic) {
        toast.success('Album turned public');
      } else {
        toast.success('Album turned private');
      }

      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);
