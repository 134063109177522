import React from 'react';

import loader from '/public/images/loader.svg';

function Loader() {
  return (
    <>
      <div className="loader-wrapper">
        <div className="loader-inner">
          <img src={loader} />
        </div>
      </div>
    </>
  );
}

export default Loader;
