/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';

import plus_ic from '/public/images/add.svg';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import Delete from '../../../components/DeleteComponent/delete';
import RecordModel from '../../../components/MiRecordsComponent/recordmodel';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  Record,
  fetchMiRecords
} from '../../../store/Slices/userModule/MiRecordSlice/miRecordSlice';
import NoDataFound from '../../../components/common/NoDataFound';
import { Link } from 'react-router-dom';

function EditProfile() {
  document.title = 'Mi Sports Profile - Mi Records'
  const [isModelOpen, setModelOpen] = React.useState(false);
  const [currentReecord, setCurrentRecord] = useState({});

  const toggleModels = () => setModelOpen(!isModelOpen);

  const [isdelete, setIsdelete] = useState(false);
  const toggleDelete = () => setIsdelete(!isdelete);

  const dispatch = useAppDispatch();
  const miRecordsData: Record[] = useAppSelector((state) => state?.miRecords?.miRecord);
  let userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    dispatch(fetchMiRecords());
  }, [dispatch]);

  return (
    <>
      <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between lay-down-wrapper ">
        <h4>Mi Records</h4>

        <button onClick={() => {
          toggleModels();
          setCurrentRecord({});
        }}><img className="me-2" src={plus_ic} />Add New Mi Record</button>

      </div>
      <div className="table-wrapper mb-3">
        {miRecordsData?.length ? (
          <Table responsive>
            <thead>
              <tr>
                <th>Label</th>
                <th>Url</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {miRecordsData?.map((data) => (
                <>
                  {userData?.userId === data.userId ? (
                    <tr key={data.userRecordId}>
                      <td className="tab-lable">{data.label}</td>
                      <td>
                        <Link
                          to={data.url}
                          target="_blank">
                          {data.url}
                        </Link></td>
                      <td>{data.notes}</td>
                      <td>
                        <button
                          className="me-2"
                          onClick={() => {
                            toggleModels();
                            setCurrentRecord(data);
                          }}>
                          <img src={edit_ic} />
                        </button>
                        <button
                          onClick={() => {
                            toggleDelete();
                            setCurrentRecord(data);
                          }}>
                          <img src={delete_ic} />
                        </button>
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </>
              ))}
            </tbody>
          </Table>
        ) : (
          <NoDataFound message="No Record Found" />
        )}
      </div>


      <Delete
        currentReecord={currentReecord}
        header="Delete"
        message="Are you sure you want to delete?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
      />

      <RecordModel
        currentReecord={currentReecord}
        modal={isModelOpen}
        toggle={() => {
          toggleModels();
        }}
      />
    </>
  );
}

export default EditProfile;
