/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

import view_photo from '/public/images/view-photo.svg';
import dont_view_photo from '/public/images/dont-view-photo.svg';
import { Link } from 'react-router-dom';
import defultprofile from '/public/images/defultprofile.svg';

import { FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ErrorMessage } from '../../../components';

import { Form, Formik, Field, FormikValues } from 'formik';
import delete_bin from '/public/images/delete-bin.svg';
import * as Yup from 'yup';
import {
  changePrivacyPermission,
  deleteGalleryFolder,
  deleteImage,
  getAllGallery,
  postGalleryImage,
  uploadImage
} from '../../../store/Slices/userModule/AlbumSlice/albumAction';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ReactSelect from 'react-select';
import NoDataFound from '../../../components/common/NoDataFound';
import { fetchMiProfileDetail } from '../../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import { IProfileDetail } from '../../../types/miProfile';
import CommonDelete from '../../../components/DeleteComponent/commonDelete';
import { toast } from 'react-toastify';

interface albumFormValues {
  galleryId: number;
  userId: number;
  title: string;
  galleryImage: any[];
}

const PhotosSchema = Yup.object().shape({
  title: Yup.string().required('Please select album or create new'),
  galleryImage: Yup.mixed().required('Please upload an image')
});

interface IGallery {
  title?: string;
  label?: string;
  value?: number;
  galleryId?: number;
  photosCount?: number;
  imageUrlANDGuid?: number;
}

function Photos() {
  document.title = 'Mi Sports Profile - Mi Photos';

  const [isdelete, setIsdelete] = useState(false);
  const toggleDelete = () => setIsdelete(!isdelete);
  const [currentImage, setCurrentImage] = React.useState();

  const dispatch = useAppDispatch();
  const uploadImageData = useAppSelector((state) => state.miAlbum.UploadedImageResponse);

  const galleryData: IGallery[] = useAppSelector((state) => state.miAlbum.GetAllGallery);
  const localUser = useAppSelector((state) => state.common.localUserData);

  const [previewImages, setPreviewImages] = useState([]);
  const [PreviewVideos, setPreviewVideos] = useState([]);

  const [modal, setModal] = useState(false);

  const [show, setShow] = React.useState(true);

  const [isFriend, setIsFriend] = React.useState('false');

  const isImage = (url: any) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
  };

  const isVideo = (url: any) => {
    return /\.(mp4|webm|ogg|mov)$/i.test(url);
  };

  const allowedFormats = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'image/webp',
    'image/gif',
    'video/mp4'
  ];

  const toggle = () => {
    dispatch(deleteImage([]));
    setModal(!modal);
  };

  const initialValues: albumFormValues = {
    galleryId: 0,
    userId: 0,
    title: '',
    galleryImage: null
  };

  const GetAlbumCombo: IGallery[] = galleryData?.map((data: any) => ({
    label: data.title,
    value: data.galleryId
  }));

  const handleFileSelect = (files: any) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        if (file.type.startsWith('image')) {
          setPreviewImages((previewImages) => [...previewImages, reader.result]);
        } else if (file.type.startsWith('video')) {
          setPreviewVideos((previewVideos) => [...previewVideos, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // END

  React.useLayoutEffect(() => {
    setIsFriend(localStorage.getItem('friend'));
  }, [location]);

  useEffect(() => {
    return () => {
      setPreviewImages([]);
      setPreviewVideos([]);
    };
  }, [modal]);

  useEffect(() => {
    dispatch(getAllGallery());
  }, [dispatch]);

  const FetchMiProfileDetail: IProfileDetail = useAppSelector(
    (state) => state.miProfile.FetchMiProfileDetail
  );

  const { localUserData } = useAppSelector((state) => state.common);

  useEffect(() => {
    dispatch(fetchMiProfileDetail(localUserData?.userId || 0));
  }, [dispatch]);

  const onGalleryImageChange = useCallback((e: any) => {
    handleFileSelect(e);
    dispatch(uploadImage(e.target.files));
  }, []);

  const deletePreviewImage = (index: number) => {
    setPreviewImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const deletePreviewVideo = (index: number) => {
    setPreviewVideos((previewImages) => previewImages.filter((_, i) => i !== index));
  };

  const view_albums = (galleryId: number, visibility: boolean) => {
    const payload = { galleryId: galleryId, isPublic: visibility };
    dispatch(changePrivacyPermission(payload));
  };

  const validImageTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'image/webp',
    'image/gif'
  ];
  const validVideoTypes = ['video/mp4'];

  return (
    <>
      {isFriend == 'true' ? (
        ''
      ) : (
        <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between lay-down-wrapper">
          <h4>{(FetchMiProfileDetail as any)?.displayName}’s Albums</h4>
          <button onClick={toggle}>Upload Attachment</button>
        </div>
      )}
      <div className="friend-list d-flex flex-wrap">
        {Object.keys(galleryData).length ? (
          galleryData.map((data: any, index: number) => (
            <div className="friend-list-box position-relative" key={index}>
              <div className="delete-photo">
                <button
                  className="delete-btn-albums"
                  onClick={() => {
                    toggleDelete();
                    setCurrentImage(data.galleryId);
                  }}>
                  <img src={delete_bin} />
                </button>
              </div>

              <Link to="/photos/album" state={data.galleryId}>
                <div className="friend-list-img position-relative mb-2">
                  {isVideo(data?.imageUrlANDGuid[0]?.imageUrl) || data.photosCount == 0 ? (
                    <img className="friend-profile-img cursor-pointer" src={defultprofile} />
                  ) : (
                    <img
                      className="friend-photo"
                      // eslint-disable-next-line no-undef
                      src={process.env.CLIENT_PATH + data?.imageUrlANDGuid[0]?.imageUrl}
                    />
                  )}
                </div>
              </Link>
              <div className="photo-content d-flex flex-wrap">
                <p className="text-break">{data.title}</p>
                {!data.isPublic ? (
                  <img
                    className="pointer"
                    title="Make this album public"
                    src={dont_view_photo}
                    onClick={() => view_albums(data?.galleryId, true)}
                  />
                ) : (
                  <img
                    className="pointer"
                    title="Make this album private"
                    src={view_photo}
                    onClick={() => view_albums(data?.galleryId, false)}
                  />
                )}
                <label className="text-break">{data.photosCount}</label>
              </div>
            </div>
          ))
        ) : (
          <NoDataFound message="No Album Found" />
        )}
      </div>
      {/* popup start */}
      <Modal
        className="popup-wrapper nutrition-popup"
        isOpen={modal}
        toggle={toggle}
        {...arguments}
        centered
        scrollable>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={PhotosSchema}
          onSubmit={(values: FormikValues, actions) => {
            actions.setSubmitting(false);
            values.userId = localUser.userId;
            values.galleryImage = uploadImageData;
            values.isPublic = true;
            if (values.galleryImage.length > 0) {
              dispatch(postGalleryImage(values));
            }
            toggle();
          }}>
          {({ errors, touched, values, handleChange, setFieldValue }) => (
            <Form>
              <ModalHeader toggle={toggle} className="p-0">
                Add Photo
              </ModalHeader>
              <ModalBody>
                <div className="upload-photo-popup">
                  <div className="upload-photo-popup-top d-flex flex-wrap align-items-center mb-4">
                    <p>Album name: </p>
                    <div className="ms-2 d-flex flex-wrap align-items-center position-relative">
                      <div className="ms-2 d-flex flex-wrap align-items-center">
                        {show || (
                          <>
                            <ReactSelect
                              className="react-select-dropdown"
                              options={GetAlbumCombo}
                              placeholder="Select existing album"
                              name="title"
                              onChange={(option) => {
                                setFieldValue('galleryId', option.value);
                                setFieldValue('title', option.label);
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                control: (provided) => ({
                                  ...provided,
                                  borderRadius: 50,
                                  paddingLeft: 8,
                                  boxShadow: null
                                })
                              }}
                            />
                            {errors.title && touched.title && <ErrorMessage text={errors.title} />}
                          </>
                        )}
                      </div>
                      {show && (
                        <>
                          <Input
                            tag={Field}
                            type="text"
                            name="title"
                            id="albumName"
                            placeholder="Enter new album name"
                            className="me-2"
                            value={values.title}
                            onChange={handleChange}
                          />
                          {errors.title && touched.title && <ErrorMessage text={errors.title} />}
                        </>
                      )}
                      <label onClick={() => setShow(!show)} className="ms-3 pointer">
                        {show ? 'or select an existing album' : 'create a new album'}
                      </label>
                    </div>
                  </div>

                  <div className="upload-photo-popup-content d-flex flex-wrap align-items-center justify-content-center position-relative mb-3">
                    <FormGroup>
                      <Input
                        id="exampleFile"
                        name="galleryImage"
                        type="file"
                        multiple
                        max={50 * 1024 * 1024}
                        // accept="image/* video/*"
                        accept="image/jpeg,image/jpg,image/png,image/svg+xml,image/webp,image/gif,video/mp4"
                        onChange={(e) => {
                          let refinedFiles: any[] = [];
                          let allFilesValid = true;
                          const rawFiles = e.target.files;
                          for (let i = 0; i < rawFiles.length; i++) {
                            if (rawFiles[i].size / (1024 * 1024) > 50) {
                              toast.error('File size is too big.' + rawFiles[i].name);
                            } else {
                              refinedFiles = [...refinedFiles, rawFiles[i]];
                            }
                            if (!allowedFormats.includes(rawFiles[i].type)) {
                              toast.error(`Unsupported file format: ${rawFiles[i].name}`);
                              allFilesValid = false;
                              continue;
                            }
                          }
                          if (!allFilesValid) return;
                          handleFileSelect(refinedFiles);

                          if (refinedFiles.length > 0) {
                            dispatch(
                              uploadImage([...(values?.galleryImage || []), ...refinedFiles])
                            );
                            setFieldValue('galleryImage', [
                              ...(values?.galleryImage || []),
                              ...refinedFiles
                            ]);
                          }
                        }}
                      />

                      <label>Click here or drag your photos & Videos here to upload them.</label>

                      {errors.galleryImage && touched.galleryImage && (
                        <ErrorMessage text={errors.galleryImage} />
                      )}
                    </FormGroup>
                  </div>
                </div>
                <div className="d-flex flex-wrap friend-list friend-list-popup">
                  {uploadImageData?.length > 0 &&
                    uploadImageData.map((item, index) => (
                      <div className="friend-list-box text-center position-relative " key={index}>
                        <button className="delete-btn-albums">
                          <img
                            src={delete_bin}
                            onClick={(e) => {
                              e.preventDefault();
                              let filteredGallery = uploadImageData.filter(
                                (i) => i.galleryImageId !== item.galleryImageId
                              );
                              dispatch(deleteImage(filteredGallery));
                              setFieldValue('galleryImage', filteredGallery);
                            }}
                          />
                        </button>
                        {isVideo(item.imageUrl) ? (
                          <video controls className="width-100">
                            <source
                              // eslint-disable-next-line no-undef
                              src={process.env.CLIENT_PATH + item.imageUrl}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            // eslint-disable-next-line no-undef
                            src={process.env.CLIENT_PATH + item.imageUrl}
                            alt={`Preview ${index}`}
                            width={150}
                            height={150}
                          />
                        )}
                      </div>
                    ))}
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center mt-1">
                <button className="save-record me-3" type="submit">
                  Save Album
                </button>
                <button
                  className="cancel-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    toggle();
                  }}>
                  Cancel
                </button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
      <CommonDelete
        header="Delete Album"
        message="Are you sure you want to delete this album?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
        confirmDeleteClick={async () => {
          await dispatch(deleteGalleryFolder(currentImage));
          toggleDelete();
        }}
      />
    </>
  );
}

export default Photos;
