import { createSlice } from '@reduxjs/toolkit';
import {
  deleteImage,
  getAllGallery,
  getAllImageComment,
  getGalleryDetail,
  uploadImage
} from './albumAction';
import { IAlbumSlice } from '../../../../types';
import { getFriendGallerys } from '../MiFriends/mifriendsAction';

const initialState: IAlbumSlice = {
  GetAllGallery: [],
  GetFriendGallery: [],
  UploadedImageResponse: [],
  GetGalleryDetail: [],
  GetAllImageComment: []
};

const albumSlice = createSlice({
  name: 'album',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadImage.fulfilled, (state, action) => {
        state.UploadedImageResponse = action.payload;
      })
      .addCase(deleteImage.fulfilled, (state, action) => {
        (state as any).UploadedImageResponse = action.payload;
      })
      .addCase(getAllGallery.fulfilled, (state, action) => {
        state.GetAllGallery = action.payload;
      })
      .addCase(getFriendGallerys.fulfilled, (state, action) => {
        state.GetFriendGallery = action.payload;
      })
      .addCase(getAllImageComment.fulfilled, (state, action) => {
        state.GetAllImageComment = action.payload;
      })
      .addCase(getGalleryDetail.fulfilled, (state, action) => {
        state.GetGalleryDetail = action.payload;
      });
  }
});

export default albumSlice.reducer;
