/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
// import comment_user from '/public/images/comment-user.png';
import profile_placeholder from '/public/images/profile_placeholder.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addConversation,
  deleteMessageReply,
  getMiConversation,
  sendMiMessageReplay,
  updateMessageReply
} from '../../../store/Slices/userModule/ConversationSlice/conversationActions';
import friend_clientimg from '/public/images/mask_group.png';
// import profile_placeholder from '/public/images/profile_placeholder.png';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { fetchMiProfileDetail } from '../../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import { getMiFriendList } from '../../../store/Slices/userModule/MiFriends/mifriendsAction';
import { getTimeDifference, redirectUser_friend } from '../../../shared/utils';
import CommonReplayConfirmModal from '../../../components/common/ConfirmModal';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import close from '../../../Assets/images/close-btn.svg';

const ValidationSchema = Yup.object().shape({
  message: Yup.string().trim().strict(false).required('Message required'),
  visibility: Yup.string().required('Visibility required')
});

const ValidationSchemaReply = Yup.object().shape({
  message: Yup.string().trim().strict(false).required('Message required')
});

dayjs.extend(relativeTime);

function Home() {
  document.title = 'Mi Sports Profile - Mi Page';
  const userAccount = (data: any) => {
    redirectUser_friend(data?.userId);
  };
  const userAccount1 = (data: any) => {
    redirectUser_friend(data?.receiverId);
  };

  const navigate = useNavigate();

  const myProfile = (data: any) => {
    localStorage.removeItem('friend');
    sessionStorage.removeItem('userData');
    sessionStorage.clear();
    navigate('/home');
  };

  const dispatch = useAppDispatch();
  const { localUserData } = useAppSelector((state) => state.common);

  const userDataString = sessionStorage.getItem('userData');
  const userData_Id = JSON.parse(userDataString);
  const userData1 = JSON.parse(localStorage.getItem('userData'));

  const userId = userData1.userId;

  const [getReplayActiveIndex, setReplayActiveIndex] = useState<number>(null);
  const [getReplayDeleteId, setReplayDeleteId] = useState<number>(null);
  const [getReplayActiveMessageText, setReplayActiveMessageText] = useState<string>('');
  const [isReplayConformationDelete, setReplayIsConformationDelete] = useState<boolean>(false);

  const toggleReplayConformationDelete = () =>
    setReplayIsConformationDelete(!isReplayConformationDelete);

  const { FetchMiProfileDetail, miProfileRelationCombo } = useAppSelector(
    (state) => state.miProfile
  );
  useEffect(() => {
    dispatch(fetchMiProfileDetail(userId || 0));
  }, [dispatch]);
  const miprofiles = (FetchMiProfileDetail as any)?.imageUrl;

  const [isFriend, setIsFriend] = React.useState('false');

  const userData = JSON.parse(localStorage.getItem('userData'));

  const location = useLocation();
  React.useLayoutEffect(() => {
    setIsFriend(localStorage.getItem('friend'));
  }, [location]);

  React.useLayoutEffect(() => {
    dispatch(getMiConversation(userData_Id.userId));
  }, []);

  React.useLayoutEffect(() => {
    dispatch(getMiFriendList());
  }, []);

  const getMiConversationComment: any = useAppSelector(
    (state) => state?.miConversation?.getMiConversation
  );

  const friendList: any = useAppSelector((state) => state?.miFriends?.getMiFriendLists);

  function getVisibilityStatus(cmtNumb: number) {
    let label: string;

    switch (cmtNumb) {
      case 1:
        label = 'Private';
        break;
      case 2:
        label = 'Friends Only';
        break;
      case 3:
        label = 'Public';
        break;
      default:
        label = 'Unknown';
        break;
    }

    return label;
  }

  const initialValues: any = {
    message: '',
    visibility: 1,
    senderId: null
  };

  const initialValuesReply: any = {
    conversationReplyId: 0,
    conversationId: 1,
    receiverId: 1,
    message: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    onSubmit: (values: any, { resetForm }) => {
      // const userData = JSON.parse(localStorage.getItem('userData'));
      values.senderId = userId;
      dispatch(addConversation(values));
      resetForm();
    }
  });

  const formikReply = useFormik({
    initialValues: initialValuesReply,
    validationSchema: ValidationSchemaReply,
    onSubmit: (data: any, { resetForm }) => {
      // data.receiverId = userData_Id.userId;
      data.receiverId = userId;
      data.getterId = userData_Id.userId;
      dispatch(sendMiMessageReplay(data));
      resetForm();
    }
  });

  return (
    <>
      <h3 className="page-heading">Mi Page</h3>
      <Row>
        <Col>
          {getMiConversationComment?.length ? (
            getMiConversationComment?.map((data: any, index: number) => (
              <div className="repaly-comment mb-3" key={index}>
                <div className="replay-top d-flex flex-wrap align-items-center">
                  <div className="replay-top-img">
                    {data.imageUrl == null ? (
                      <img src={profile_placeholder} />
                    ) : (
                      <img src={process.env.CLIENT_PATH + data.imageUrl} />
                    )}
                  </div>
                  <div className="replay-top-content">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <h4 className="cursor-pointer">{data.userName}</h4>
                      <p className="comment-status">
                        <label>{getVisibilityStatus(data.visibility)}</label>{' '}
                        <span>{getTimeDifference(new Date(data.createdDate))}</span>
                      </p>
                    </div>
                    <div className="replay-comment-comment">
                      <p>{data.message}</p>
                    </div>
                  </div>
                </div>

                {/* {data.myConversationReply.length === 0 ? <>No Data</> : 'DATA GET'} */}
                <form onSubmit={formikReply.handleSubmit}>
                  {data.myConversationReply.map((data: any, index: number) => (
                    <div className="replay-middle" key={index}>
                      <div className="replay-top d-flex flex-wrap align-items-center">
                        <div className="replay-top-img">
                          {data.replyImageUrl == null ? (
                            <img src={profile_placeholder} />
                          ) : (
                            <img src={process.env.CLIENT_PATH + data.replyImageUrl} />
                          )}
                        </div>
                        {/* <div className="replay-top-content">
                          <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <h4 className="cursor-pointer">{data?.replyUserName}</h4>
                            <p className="comment-status">
                              <span>{getTimeDifference(new Date(data.replyCreatedDate))}</span>
                            </p>
                          </div>
                          <div className="replay-comment-comment">
                            <p>{data.replyMessage}</p>
                          </div>
                        </div> */}
                        <div className="replay-top-content">
                          <div className="d-flex flex-wrap align-items-center justify-content-between">
                            {userData?.userId == data?.receiverId ? (
                              <div className="w-100">
                                <div className="d-flex flex-wrap align-items-center justify-content-between">
                                  <h4>{data.replyUserName}</h4>
                                  <p className="comment-status">
                                    <span>
                                      {getTimeDifference(new Date(data.replyCreatedDate))}
                                    </span>
                                  </p>
                                </div>
                                {getReplayActiveIndex === index ? (
                                  <>
                                    <div className="replay-update-bottom d-flex flex-wrap align-items-center justify-content-between">
                                      <textarea
                                        className="w-100-767"
                                        placeholder="Write your comments..."
                                        value={getReplayActiveMessageText}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                          setReplayActiveMessageText(e.target.value);
                                        }}
                                      />
                                      <div className="d-flex flex-wrap align-items-center">
                                        <button
                                          type="button"
                                          className="me-2 update-btn"
                                          onClick={() => {
                                            const payload = {
                                              conversationReplyId: data?.conversationReplyId,
                                              conversationId: data?.conversationId,
                                              receiverId: data?.receiverId,
                                              message: getReplayActiveMessageText
                                            };
                                            dispatch(updateMessageReply(payload));
                                            setReplayActiveIndex(null);
                                          }}>
                                          Update
                                        </button>
                                        <button
                                          type="button"
                                          className="rounded-circle-btn"
                                          onClick={() => setReplayActiveIndex(null)}>
                                          <img src={close} />
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="replay-comment-comment d-flex flex-wrap align-items-center justify-content-between">
                                    <p>{data.replyMessage}</p>
                                    <div>
                                      <button
                                        className="me-2 bg-transparent mx-2 border-0"
                                        onClick={() => {
                                          setReplayActiveIndex(index);
                                          setReplayActiveMessageText(data.replyMessage);
                                        }}>
                                        <img src={edit_ic} />
                                      </button>
                                      <button
                                        className="view-all border-0 bg-transparent"
                                        onClick={() => {
                                          toggleReplayConformationDelete();
                                          setReplayDeleteId(data?.conversationReplyId);
                                        }}>
                                        <img src={delete_ic} />
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <>
                              <h4 onClick={() => userAccount1(data)} className="cursor-pointer">
                                {data.replyUserName}
                              </h4>
                                <p className="comment-status">
                                    <span>
                                      {getTimeDifference(new Date(data.replyCreatedDate))}
                                    </span>
                                  </p>
                                  </>
                            )}
                          </div>
                          <div className="replay-comment-comment">
                            {userData?.userId == data?.receiverId ? '' : <p>{data.replyMessage}</p>}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="replay-bottom d-flex flex-wrap align-items-center">
                    <textarea
                      className="w-100-767"
                      placeholder="Write your comments..."
                      name="message"
                      value={
                        formikReply.values.conversationId === data.conversationId
                          ? formikReply.values.message
                          : ''
                      }
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        formikReply.setFieldValue('message', e.target.value);
                        formikReply.setFieldValue('conversationId', data.conversationId);
                      }}></textarea>
                    <div
                      className={
                        formikReply.values.message && !formikReply.errors.message
                          ? 'write-comment-btn'
                          : 'write-comment-btn-disable'
                      }>
                      <button
                        type="submit"
                        disabled={
                          formikReply.values.message && !formikReply.errors.message ? false : true
                        }>
                        Comment
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            ))
          ) : (
            <div className="repaly-comment mb-2">
              <div className="replay-top d-flex flex-wrap align-items-center justify-content-center lead text-muted">
                OOps, There is nothing to show for Mi Page!!
              </div>
            </div>
          )}
        </Col>
        <Col lg="3">
          {friendList?.length > 0 && (
            <div className="content-friend friend-lists">
              <h3 className="mb-3">Friends</h3>
              {friendList?.map((data: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="content-friend-box d-flex flex-wrap align-items-center mb-3">
                    {data.imageUrl == null ? (
                      <img src={profile_placeholder} />
                    ) : (
                      <img src={process.env.CLIENT_PATH + data.imageUrl} />
                    )}
                    {userData?.userId === data?.userId ? (
                      <p className="cursor-pointer" onClick={() => myProfile(data)}>
                        {data.userName}
                      </p>
                    ) : (
                      <p className="cursor-pointer" onClick={() => userAccount(data)}>
                        {data.userName}
                      </p>
                    )}
                  </div>
                );
              })}
              <div className="d-flex flex-wrap align-items-center justify-content-center">
                <Link to="/friends/miFriends" state={{ state_title: 'Mi Friend' }}>
                  {friendList?.length == 1 ? '' : <button>View All</button>}
                </Link>
              </div>
            </div>
          )}
          {/* <div className="content-friend friend-lists">
            <h3 className="mb-3">Friends</h3>
            {friendList?.map((data: any, index: number) => (
              <div className="friend-list-box" key={index}>
                <div className="mb-2">
                  <div
                    key={index}
                    className="content-friend-box d-flex flex-wrap align-items-center mb-3">
                    {data.imageUrl == null ? (
                      <img src={profile_placeholder} />
                    ) : (
                      <img src={process.env.CLIENT_PATH + data.imageUrl} />
                    )}
                    {userData?.userId === data?.userId ? (
                      <p className="cursor-pointer" onClick={() => myProfile(data)}>
                        {data.userName}
                      </p>
                    ) : (
                      <p className="cursor-pointer" onClick={() => userAccount(data)}>
                        {data.userName}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}

            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <Link to="/friends/miFriends" state={{ state_title: 'Mi Friend' }}>
                <button>View All</button>
              </Link>
            </div>
          </div> */}
        </Col>
      </Row>
      <CommonReplayConfirmModal
        header="Delete Reply Conversation"
        message="Are you sure you want to delete this reply conversation?"
        modal={isReplayConformationDelete}
        toggle={() => {
          toggleReplayConformationDelete();
        }}
        confirmDeleteClick={(data: any) => {
          data.getReplayDeleteId = getReplayDeleteId;
          dispatch(deleteMessageReply(data));
          toggleReplayConformationDelete();
        }}
        confirmNoClickClick={() => toggleReplayConformationDelete()}
      />
    </>
  );
}

export default Home;
