/* eslint-disable prettier/prettier */
import React, { ReactNode, useEffect, useState } from 'react';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Col, Row, FormGroup, Label, Input, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { Formik, Form, FormikValues, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import ErrorComponent from '../../../components/ErrorMessage/error';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchCommon } from '../../../store/Slices/CommonSlice/commonSlice.';
import {
  createCompetitionInvite,
  getSingleCompetition,
  postMICompetition,
  updateMICompetition,
  FriendInvite,
  getAllCompetitorsLists
} from '../../../store/Slices/userModule/CompetitionSlice/competitionAction';
import ReactSelect, { StylesConfig } from 'react-select';
import dayjs from 'dayjs';
import { fetchMiGetUserSportsData } from '../../../store/Slices/userModule/MiProfileSlice/miProfileAction';
import profile_placeholder from '/public/images/profile_placeholder.png';
import timezone from '/public/images/time-zone-fill.svg';
import downIcon from '/public/images/down.svg';
import searchIcon from '/public/images/search-ic.svg';
import { components } from 'react-select';
import { ErrorMessage } from '../../../components';

interface Mymicompetition {
  competitionId: any;
  userId: number;
  name: string;
  goal: string;
  reason: string;
  startDate: string | Date;
  endDate: string | Date;
  timeZone: string;
  address: string;
  visibility: string;
  sportId: string;
}

function LayDownMiCompetition() {
  const [userId, setUserId] = useState(null);
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [selectedOption, setSelectedOption] = useState([]);
  const [getSelectedTeamMember, setSelectTeamMember] = useState([]);

  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const commonOptions = useAppSelector((state) => state?.common);
  const editValues: any = useAppSelector((state) => state?.miCompetition?.getSingleCompetition);

  const MicompetitionSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter competition name')
      .trim('Please enter competition name')
      .strict(true),
    goal: Yup.string()
      .required('Please enter goal')
      .trim('Leading and trailing spaces not allowed')
      .strict(true),
    reason: Yup.string()
      .required('Please enter reason ')
      .trim('Leading and trailing spaces not allowed')
      .strict(true),
    startDate: Yup.date().required('Please select start date ').strict(true),
    endDate: Yup.date()
      .required('Please select end date ')
      .strict(true)
      .test('startDate', 'End time must be after start time.', function (code) {
        const validDate = dayjs(dates.startDate || editValues.startDate).isBefore(
          dayjs(dates.endDate || editValues.endDate)
        );
        if (validDate) {
          return true;
        } else {
          return false;
        }
      }),

    timeZone: Yup.string().required('Please select time zone'),
    address: Yup.string()
      .required('Please enter address')
      .trim('Leading and trailing spaces not allowed')
      .strict(true),
    visibility: Yup.number().required('Please select visibility ').strict(true),
    sportId: Yup.number().required('Please select sport').strict(true)
  });

  // strat
  interface StateOption {
    value: number;
    label: string;
  }

  const { timeZone } = useAppSelector((state) => state?.common);

  const [isOpen, setIsOpen] = useState(false);

  const selectStyles: StylesConfig<StateOption, false> = {
    control: (provided) => ({
      ...provided,
      minWidth: 240,
      margin: 0,
      boxShadow: 'none',
      borderColor: '#d6d6d6 !important'
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)',
      top: '29px',
      border: '1px solid #d6d6d6',
      borderTop: 'none',
      borderRadius: '0 0 4px 4px'
    })
  };

  const Menu = (props: JSX.IntrinsicElements['div']) => (
    <div
      className="timezone-btn"
      style={{
        backgroundColor: 'white',
        position: 'absolute',
        zIndex: 9,
        top: '33px',
        width: '100%',
        left: 0
      }}
      {...props}
    />
  );

  const Blanket = (props: JSX.IntrinsicElements['div']) => (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1
      }}
      {...props}
    />
  );

  const Dropdown = ({
    children,
    isOpen,
    target,
    onClose
  }: {
    children?: ReactNode;
    isOpen: boolean;
    target: ReactNode;
    onClose: () => void;
  }) => (
    <div className="input-group" style={{ position: 'relative' }}>
      {target}
      {isOpen && (
        <>
          <Menu>{children}</Menu>
          <Blanket onClick={onClose} />
        </>
      )}
    </div>
  );

  const CustomDropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <img
        src={searchIcon}
        alt="searchIcon icon"
        style={{ width: 20, height: 20, marginRight: 8 }}
      />
    </components.DropdownIndicator>
  );
  //end

  useEffect(() => {
    dispatch(fetchCommon());
    dispatch(fetchMiGetUserSportsData());
    dispatch(getSingleCompetition(state?.state_userId || 0));
  }, [dispatch]);

  const { miSportsData } = useAppSelector((state) => state?.miProfile);

  useEffect(() => {
    const userData: any = localStorage.getItem('userData');
    setUserId(JSON.parse(userData));
    // dispatch(getAllCompetitorsLists(editValues?.competitionId));
  }, []);

  React.useEffect(() => {
    if (editValues?.competitionId) {
      dispatch(FriendInvite(editValues?.competitionId));
      // dispatch(getAllCompetitorsLists(editValues?.competitionId));
    }
  }, []);

  const FriendInviteds: any = useAppSelector((state) => state?.miCompetition?.FriendInvite);

  const getAllCompetitorsListData: any = useAppSelector(
    (state) => state?.miCompetition?.getAllCompetitorsListData
  );

  const navigateFunction = () => {
    if (editValues?.competitionId !== 0) {
      navigate(`/Competition/GetByCompetitionId/${editValues?.competitionId || 0}`, {
        state: { state_value: editValues?.competitionId }
      });
    } else {
      navigate(`/micompetition`);
    }
  };
  const initialValues: Mymicompetition = {
    name: editValues?.name ? editValues?.name : '',
    goal: editValues?.name ? editValues.goal : '',
    reason: editValues?.name ? editValues.reason : '',
    startDate: editValues?.name ? new Date(editValues.startDate || dayjs()) : '',
    endDate: editValues?.name ? new Date(editValues.endDate || dayjs()) : '',
    timeZone: editValues?.name ? editValues.timeZone : '',
    address: editValues?.name ? editValues.address : '',
    visibility: editValues?.name ? editValues.visibility : '',
    sportId: editValues?.name ? editValues.sportId : '',
    userId: null,
    competitionId: editValues?.name ? editValues.competitionId : 0
  };

  // const minTime = new Date(); // Disable past times
  const minTime = new Date();
  const navigator = useNavigate();

  // const navigateFunction = () => {
  //   navigator('/micompetition/laydownmicompetition');
  // };

  const sendInvitation = () => {
    const payload = {
      competitionInviteId: 0,
      competitionId: editValues?.competitionId,
      senderId: 0,
      receiverId: selectedOption,
      isInCompetition: true
    };
    dispatch(createCompetitionInvite({ data: payload, navigate: navigateFunction }));
    setSelectedOption([]);
    setSelectTeamMember([]);
  };

  return (
    <>
      <h3 className="page-heading">
        {editValues?.competitionId ? 'Edit' : 'Lay Down'} Mi Competition
      </h3>
      <Row>
        <Col xl="6">
          <div className="competition-left-form">
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={MicompetitionSchema}
              onSubmit={(values: FormikValues, actions: FormikHelpers<FormikValues>) => {
                values.userId = userId?.userId;
                values.startDate = dayjs(values?.startDate).format('YYYY-MM-DDTHH:mm:ss');
                values.endDate = dayjs(values?.endDate).format('YYYY-MM-DDTHH:mm:ss');
                if (editValues?.competitionId) {
                  values.competitionId = editValues?.competitionId;
                  dispatch(updateMICompetition({ data: values, navigate: navigateFunction }));
                } else {
                  dispatch(postMICompetition({ data: values, navigate: navigateFunction }));
                }
                // navigateFunction();
                actions.setSubmitting(false);
                actions.resetForm();
              }}>
              {({ errors, touched, values, handleChange, setFieldValue, resetForm }) => (
                <Form>
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      Competition Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Competetion Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        type="text"
                      />
                      {errors.name && touched.name && <ErrorComponent text={errors.name} />}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      {`What's the goal?`}
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Goal..."
                        name="goal"
                        value={values.goal}
                        onChange={handleChange}
                        type="textarea"
                      />
                      {errors.goal && touched.goal && <ErrorComponent text={errors.goal} />}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      Why I chose this
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Reason..."
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        type="textarea"
                      />
                      {errors.reason && touched.reason && <ErrorComponent text={errors.reason} />}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      When does it start?
                    </Label>
                    <Col sm={8}>
                      <Row>
                        <Col>
                          <DatePicker
                            onInputClick={() => setIsStartOpen(true)}
                            onClickOutside={() => setIsStartOpen(false)}
                            open={isStartOpen}
                            selected={values.startDate ? new Date(values.startDate) : null}
                            onChange={(date: Date | null) => {
                              setFieldValue('startDate', date);
                              setFieldValue('endDate', null);
                              setDates({ ...dates, startDate: date });
                            }}
                            placeholderText="Please select start date"
                            dateFormat="MM/dd/yyyy h:mm:ss aa"
                            className="custom-input"
                            minDate={new Date()}
                            minTime={minTime}
                            showTimeInput
                            popperClassName="high-zindex-datepicker"
                          />
                          {errors.startDate && touched.startDate && (
                            <ErrorComponent text={errors.startDate} />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      When does it end?
                    </Label>
                    <Col sm={8}>
                      <Row>
                        <Col>
                          <DatePicker
                            onInputClick={() => setIsEndOpen(true)}
                            onClickOutside={() => setIsEndOpen(false)}
                            open={isEndOpen}
                            selected={values.endDate ? new Date(values.endDate) : null}
                            onChange={(date: Date | null) => {
                              setFieldValue('endDate', date);
                              setDates({ ...dates, endDate: date });
                            }}
                            placeholderText="Please select end date"
                            timeInputLabel="Time:"
                            className="custom-input"
                            dateFormat="MM/dd/yyyy h:mm:ss aa"
                            showTimeInput
                            popperClassName="high-zindex-datepicker"
                            minDate={values.startDate ? new Date(values.startDate) : null}
                          />
                          {errors.endDate && touched.endDate && (
                            <ErrorComponent text={errors.endDate} />
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>

                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      Time Zone
                    </Label>
                    <Col sm={8}>
                      <Dropdown
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                        target={
                          <>
                            <Button
                              onClick={() => setIsOpen((prev) => !prev)}
                              isSelected={isOpen}
                              className="form-control"
                              style={{
                                background: 'none',
                                borderRadius: '50px',
                                border: '1px solid #d6d6d6'
                              }}>
                              {values.timeZone ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <img
                                    src={timezone}
                                    alt="timezone icon"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                  />
                                  {values.timeZone}
                                  <div className="timezone-arrow-arapper">
                                    <img
                                      className="timezone-arrow"
                                      src={downIcon}
                                      alt="downIcon icon"
                                      style={{ width: 20, height: 20, marginRight: 8 }}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    borderRadius: 50,
                                    paddingLeft: 0,
                                    boxShadow: 'none',
                                    border: 'none'
                                  }}
                                  className="form-control1">
                                  <img
                                    src={timezone}
                                    alt="timezone icon"
                                    style={{ width: 20, height: 20, marginRight: 8 }}
                                  />
                                  Select a Time Zone
                                  <div className="timezone-arrow-arapper">
                                    <img
                                      className="timezone-arrow"
                                      src={downIcon}
                                      alt="downIcon icon"
                                      style={{ width: 20, height: 20, marginRight: 8 }}
                                    />
                                  </div>
                                </div>
                              )}
                            </Button>
                            <>
                              {errors.timeZone && touched.timeZone && (
                                <ErrorMessage text={errors.timeZone} />
                              )}
                            </>
                          </>
                        }>
                        <ReactSelect
                          autoFocus
                          className="form-control"
                          components={{
                            IndicatorSeparator: null,
                            DropdownIndicator: CustomDropdownIndicator
                          }}
                          controlShouldRenderValue={false}
                          menuIsOpen
                          value={timeZone?.find((data) => data.value == values.timeZone)}
                          onChange={(options) => {
                            setFieldValue('timeZone', options.label);
                            setIsOpen(false);
                          }}
                          options={timeZone}
                          placeholder="Please select time zone"
                          styles={selectStyles}
                        />
                      </Dropdown>
                    </Col>
                  </FormGroup>

                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      {`Address`}
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Address..."
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        type="textarea"
                      />
                      {errors.address && touched.address && (
                        <ErrorComponent text={errors.address} />
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      Visibility
                    </Label>
                    <Col sm={8}>
                      <ReactSelect
                        options={commonOptions.visibility}
                        value={
                          values.visibility
                            ? commonOptions?.visibility?.find(
                                (data) => data.value == +values.visibility
                              )
                            : null
                        }
                        placeholder="Please select visibility"
                        onChange={(option) => {
                          setFieldValue('visibility', +option.value);
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: 50,
                            paddingLeft: 8,
                            boxShadow: null
                          })
                        }}
                      />
                      {errors.visibility && touched.visibility && (
                        <ErrorComponent text={errors.visibility} />
                      )}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      Sport
                    </Label>
                    <Col sm={8}>
                      <ReactSelect
                        options={miSportsData}
                        placeholder="Please select sport"
                        onChange={(option) => {
                          setFieldValue('sportId', +option.value);
                        }}
                        value={
                          values.sportId
                            ? miSportsData?.find((data) => data.value == +values.sportId)
                            : null
                        }
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          control: (provided) => ({
                            ...provided,
                            borderRadius: 50,
                            paddingLeft: 8,
                            boxShadow: null
                          })
                        }}
                      />
                      {errors.sportId && touched.sportId && (
                        <ErrorComponent text={errors.sportId} />
                      )}
                    </Col>
                  </FormGroup>
                  <div className="team-members-box-footer d-flex flex-wrp align-items-center justify-content-end">
                    <Link
                      to={
                        editValues?.competitionId
                          ? `/Competition/GetByCompetitionId/${editValues?.competitionId}`
                          : '/micompetition'
                      }
                      state={{
                        state_title: 'Mi Competition',
                        state_value: editValues?.competitionId
                      }}>
                      <button className="maybe-later me-3" onClick={() => resetForm()}>
                        Cancel
                      </button>
                    </Link>
                    <button className="send-invitation" type="submit">
                      {editValues?.competitionId ? 'Update' : 'Create'} Competition
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
        {!editValues?.competitionId ? (
          ''
        ) : (
          <Col xl="6">
            <div className="competition-left-form mb-4">
              <h4 className="lay-com-heading mb-3">Invite Team Members</h4>
              <p className="mb-2">Select member from the list to send Invitations:</p>
              <div className="team-members-box1 mb-4">
                <ReactSelect
                  options={FriendInviteds?.map((item: any) => ({
                    ...item,
                    label: item.userName,
                    value: item.userName
                  }))}
                  placeholder="Please select Invite Team Members"
                  value={getSelectedTeamMember}
                  onChange={(selectedOption: any) => {
                    const selectedLabels = selectedOption
                      ? selectedOption.map((item: any) => item.userId)
                      : selectedOption.userId;
                    setSelectedOption(selectedLabels);
                    setSelectTeamMember(selectedOption);
                  }}
                  isMulti
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: 50,
                      paddingLeft: 8,
                      boxShadow: null
                    })
                  }}
                />
              </div>
              <div className="team-members-box-footer d-flex flex-wrp align-items-center justify-content-end">
                <Link
                  to={
                    editValues?.competitionId
                      ? `/Competition/GetByCompetitionId/${editValues?.competitionId}`
                      : '/micompetition'
                  }
                  state={{
                    state_title: 'Mi Competition',
                    state_value: editValues?.competitionId
                  }}>
                  <button className="maybe-later me-3">Maybe Later</button>
                </Link>
                <button
                  className="send-invitation"
                  onClick={() => sendInvitation()}
                  disabled={getSelectedTeamMember?.length === 0}>
                  Send Invitation
                </button>
              </div>
            </div>
            <div>
              <h4 className="lay-com-heading mb-3">Invited Members</h4>
              <div className="competition-left-form p-3">
                <div className="game-on-wrapper d-flex flex-wrap">
                  {getAllCompetitorsListData?.length
                    ? getAllCompetitorsListData?.map((data: any, index: number) => (
                        <div
                          key={index}
                          className="game-on-inner d-flex flex-wrap align-items-center">
                          <div className="game-on-left">
                            {data.imageUrl == null ? (
                              <img src={profile_placeholder} />
                            ) : (
                              <img src={process.env.CLIENT_PATH + data.imageUrl} />
                            )}
                          </div>
                          <div className="game-on-right d-flex flex-wrap">
                            <p className="mb-1 w-100">{data?.userName}</p>
                            {data.isInCompetition ? (
                              <p className="mb-2 invite-sent">Invite Sent</p>
                            ) : (
                              <a onClick={() => sendInvitation()} className="resent cursor-pointer">
                                resend
                              </a>
                            )}
                          </div>
                        </div>
                      ))
                    : ''}
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}

export default LayDownMiCompetition;
