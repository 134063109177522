/* eslint-disable prettier/prettier */
import * as React from 'react';
import Useroutes from './routes/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './layouts/Footer/footer';
// import environment from '../environments/environment';
import './shared/config/axiosIntercepter';
import Loader from './components/common/loader';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import { useAppDispatch, useAppSelector } from './store/hooks';
import { ToastContainer } from 'react-toastify';
import { fetchCommon, setLocalUserData } from './store/Slices/CommonSlice/commonSlice.';
import {
  fetchMiProfileDetail,
  fetchMiProfileRelationCombo
} from './store/Slices/userModule/MiProfileSlice/miProfileAction';
import { fetchMiRecords } from './store/Slices/userModule/MiRecordSlice/miRecordSlice';
function App() {
  const [isErr, setIsErr] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const loader = useAppSelector((state) => state.common.loading);
  const userData = JSON.parse(localStorage.getItem('userData'));
  dispatch(setLocalUserData(userData));
  React.useEffect(() => {
    if (userData) {
      dispatch(fetchMiRecords());
      dispatch(fetchMiProfileRelationCombo());
      dispatch(fetchCommon());
      dispatch(fetchMiProfileDetail(userData?.userId || 0));
    }
  }, []);

  window.onerror = function (message, source, lineno, colno, error) {
    setIsErr(true);
    // window.location.reload();
    return true;
  };
  return (
    <div className="App">
      {isErr && (
        <>
          <div>
            Something went Wrong! <button onClick={() => window.location.reload()}>Refresh</button>
          </div>
        </>
      )}
      {loader && <Loader />}

      <React.Suspense fallback={<Loader />}>
        <Router>
          <Useroutes />
          <Footer />
        </Router>
      </React.Suspense>

      <ToastContainer autoClose={2000} />
    </div>
  );
}
export default App;
