import React, { useState } from 'react';
import mi_page from '/public/images/mi-page.svg';
import whats_going_on from '/public/images/whats-going-on.svg';
import todays_program from '/public/images/todays-program-active.svg';
import mi_nutrition from '/public/images/mi-nutrition.svg';
import mi_friends from '/public/images/mi-friends.svg';
import mi_competition from '/public/images/mi-competition.svg';
import mi_training from '/public/images/mi-training.svg';
import mi_photos from '/public/images/mi-photos.svg';
import act_mi_page from '/public/images/active-mi-page.svg';
import act_whats_going_on from '/public/images/active-whats-going-on.svg';
import act_todays_program from '/public/images/active-todays-program-active.svg';
import act_mi_nutrition from '/public/images/active-mi-nutrition.svg';
import act_mi_friends from '/public/images/active-mi-friends.svg';
import act_mi_competition from '/public/images/active-mi-competition.svg';
import act_mi_training from '/public/images/active-mi-training.svg';
import mirecord from '/public/images/mirecord.svg';
import mirecord_active from '/public/images/mirecord-active.svg';
import act_mi_photos from '/public/images/active-mi-photos.svg';
import website from '/public/images/website.svg';
import plus_ic from '/public/images/add.svg';
import { Col, Collapse, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import RecordModel from '../../components/MiRecordsComponent/recordmodel';
import { useAppSelector } from '../../store/hooks';

function ContentSidebar() {
  const location = useLocation();
  const { state } = location;
  const [isOpen, setIsOpen] = React.useState(false);

  const [isModelOpen, setModelOpen] = React.useState(false);

  const toggleModels = () => setModelOpen(!isModelOpen);

  const [islogout, setLogout] = React.useState(false);

  const miRecordsData = useAppSelector((state) => state?.miRecords?.miRecord || []);

  const links = [
    {
      href: '/home',
      text: 'Mi Page',
      img1: mi_page,
      img2: act_mi_page,
      state: { state_title: 'Mi Page' }
    },
    {
      href: '/whatgoingon',
      text: 'What’s Going On',
      img1: whats_going_on,
      img2: act_whats_going_on,
      state: { state_title: 'What’s Going On' }
    },
    {
      href: '/dashboard',
      text: 'Today’s Program',
      img1: todays_program,
      img2: act_todays_program,
      state: { state_title: 'Today’s Program' }
    },
    {
      href: '/minutrition',
      text: 'Mi Nutrition',
      img1: mi_nutrition,
      img2: act_mi_nutrition,
      state: { state_title: 'Mi Nutrition' }
    },
    {
      href: '/nofriend',
      text: 'Mi Friends',
      img1: mi_friends,
      img2: act_mi_friends,
      state: { state_title: 'Mi Friends' }
    },
    {
      href:
        location.pathname.substring(0, location?.pathname?.lastIndexOf('/')) ===
        '/Competition/GetByCompetitionId'
          ? location.pathname
          : '/micompetition',
      text: 'Mi Competition',
      img1: mi_competition,
      img2: act_mi_competition,
      state: { state_title: 'Mi Competition' }
    },
    {
      href: '/mitraining',
      text: 'Mi Training',
      img1: mi_training,
      img2: act_mi_training,
      state: { state_title: 'Mi Training' }
    },
    {
      href: '/photos',
      text: 'Mi Photos',
      img1: mi_photos,
      img2: act_mi_photos,
      state: { state_title: 'Mi Photos' }
    },
    {
      href: '/mirecord',
      text: 'Mi Record',
      img1: mirecord,
      img2: mirecord_active,
      state: { state_title: 'Mi Records' }
    }
  ];
  const toggle = () => setIsOpen(!isOpen);

  const toggleLogout = () => setLogout(!islogout);

  const [modal, setModal] = useState(false);
  const toggles = () => setModal(!modal);

  const [activeNav, setActiveNav] = useState();
  const closeMenu = () => {
    // localStorage.removeItem('friend');
    // ... and this one only
    setIsOpen(false); // closes it ...
  };

  let userData = JSON.parse(localStorage.getItem('userData'));

  return (
    <>
      <Col className="content-left" md="4" lg="3" xxl="2">
        <div className="content-sidebar-sticky">
          <div className="top-sidebar mb-3">
            <Navbar light expand="md">
              <NavbarToggler className="frame-name">
                <NavbarBrand>{state?.state_title ? state?.state_title : 'Mi Page'} </NavbarBrand>
              </NavbarToggler>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  {links?.map((link, index) => {
                    return (
                      <NavItem className="ml-auto" key={index}>
                        <NavLink
                          key={index}
                          to={link.href}
                          state={link.state}
                          onClick={closeMenu}
                          className="d-flex flex-wrap align-items-center">
                          <img src={link.img1} className="nor-img" />{' '}
                          <img src={link.img2} className="active-img" />
                          {link.text}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Collapse>
            </Navbar>
          </div>
          <div className="bottom-sidebar">
            <h2 className="mb-3">Mi Records</h2>
            <ul>
              {miRecordsData.length
                ? [...miRecordsData.slice(0, 4)]?.map((data, index) => (
                    <li key={index}>
                      <a href="/mirecord" className="d-flex align-items-center">
                        <img src={website} style={{width: "20px"}} />
                        <span style={{width: "calc(100% - 20px)", color: "#54a613"}}>{data.label}</span>
                      </a>
                    </li>
                  ))
                : 'No Records'}
            </ul>
            <div className="d-flex flex-wrap justify-content-center mt-4">
              <button className="add-new-btn mb-3" onClick={toggleModels}>
                <img className="me-2" src={plus_ic} />
                Add New
              </button>
              <Link to="/mirecord" state={{ state_title: 'Mi Record' }}>
                <button className="view-all">View All</button>
              </Link>
            </div>
          </div>
        </div>
        {/* <RecordModel /> */}
      </Col>

      <RecordModel
        modal={isModelOpen}
        toggle={() => {
          toggleModels();
        }}
      />
    </>
  );
}

export default ContentSidebar;
