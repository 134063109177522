export const REGISTER_USER = `/User/Registration`;
export const FORGET_PASSWORD = `/User/ForgotPassword`;
export const RESET_PASSWORD = `/User/ResetPassword`;
export const LOGIN_USER = `/User/Login`;
export const MI_RECORD_FETCH = `/UserRecord/GetUserRecordList`;
export const MI_RECORD_POST = `/UserRecord/CreateUserRecord`;
export const MI_RECORD_UPDATE = `/UserRecord/UpdateUserRecord`;
export const MI_RECORD_DELETE = `/UserRecord/DeleteUserRecord/`;

export const GET_VISIBILITY = `/Combo/GetVisibilityCombo`;
export const GET_TIMEZONE_COMBO = `/Combo/GetTimezoneCombo`;
export const GET_SPORTS_COMBO = `/Combo/GetMasterSportCombo`;

export const POST_MI_COMPETITION = `/Competition/CreateCompetition`;
export const POST_MI_COMPETITION_COMMENT = `/Competition/AddCompetitionComment`;
export const UPDATE_MI_COMPETITION = `/Competition/UpdateCompetition`;
export const GET_MI_COMPETITION = `/Competition/GetAllCompetition`;
export const GET_SINGLE_MI_COMPETITION = `/Competition/GetByCompetitionId/`;
export const GET_SINGLE_MI_COMPETITION_COMMENTS = `/Competition/GetAllCompetitionComment/`;
export const DELETE_SINGLE_COMPETITION = `/Competition/DeleteCompetition/`;

export const CREATE_COMPETITION_INVITE = `/Competition/CreateCompetitionInvite/`;
export const FRIENDS_NOT_INVITED_FOR_COMPETITION = `/Competition/FriendsNotInvitedForCompetition/`;
export const GAME_ON_FOR_COMPETITION = `/Competition/GameOnForCompetition/`;

export const ACCEPT_COMPETITION_INVITE = `/Competition/AcceptCompetitionInvite/`;
export const GET_ALL_ACCEPTED_COMPETITORS = `/Competition/GetAllAcceptedCompetitors/`;
export const GET_ALL_COMPETITORS_LIST = `/Competition/GetCompetitorsList/`;

export const UPDATE_MI_COMPETITIOON_COMMENT = `/Competition/UpdateCompetitionComment`;
export const DELETE_MI_COMPETITIOON_COMMENT = `/Competition/DeleteCompetitionComment`;

export const POST_MI_TRAINING = `/UserTraining/UpdateUserTraining`;
export const GET_MI_TRAINING = `/UserTraining/GetByUserTrainingId/`;

export const ADD_MI_CONVERSATION = `/Conversation/SendMessage`;
export const UPDATE_MI_CONVERSATION = `/Conversation/UpdateMessage`;
export const UPDATE_MI_CONVERSATION_REPLY = `/Conversation/UpdateMessageReply`;
export const DELETE_MI_CONVERSATION = `/Conversation/DeleteMessage`;
export const DELETE_MI_CONVERSATION_REPLY = `/Conversation/DeleteMessageReply`;
export const GET_MI_CONVERSATION = `/Conversation/GetConversation`;
export const SEND_MI_MESSAGE_REPLAY = `/Conversation/SendMessageReply`;
export const GET_MI_WHAT_GOING_ON = `/Conversation/GetWhatGoingOn`;

//dashboard
export const GET_DASHBOARD_SCHEDULAR = `/Schedule/GetSchedular?`;

export const COMBO_GET_MUSCLE_GROUP = `/Combo/GetMuscleGroupCombo`;
export const COMBO_GET_SINGLE_MUSCLE_GROUP = `/Combo/GetMuscleCombo/`;
export const COMBO_GET_EQUIPMENT = `/Combo/GetEquipmentCombo`;
export const COMBO_GET_EXERCISE_TYPE = `/Combo/GetExerciseTypeCombo/`;

export const CREATE_SCHEDULE_WEIGHT_SET = `/Schedule/CreateScheduleWeightSet`;
export const UPDATE_SCHEDULE_WEIGHT_SET = `/Schedule/UpdateScheduleWeightSet`;
export const DELETE_SCHEDULE_WEIGHT_SET = `/Schedule/DeleteScheduleWeightSet/`;
export const GET_SCHEDULE_WEIGHT_LIST = `/Schedule/GetScheduleWeightSetList`;
export const GET_SINGLE_SCHEDULE_WEIGHT_LIST = `/Schedule/GetScheduleWeightSetById/`;
export const GET_SCHEDULE_CARDIO_LAT_LONG = `/Schedule/GetScheduleCardioLatLong`;

export const CREATE_SCHEDULE_GENERAL_FITNESS = `/Schedule/CreateScheduleGeneral`;
export const CREATE_SCHEDULE_GENERAL_FITNESS_BY_ID = `/Schedule/GetScheduleGeneralById/`;
export const UPDATE_SCHEDULE_GENERAL_FITNESS = `/Schedule/UpdateScheduleGeneral`;
export const GET_SCHEDULE_GENERAL_FITNESS_LIST = `/Schedule/GetScheduleGeneralList`;
export const DELETE_SCHEDULE_GENERAL_FITNESS_SET = `/Schedule/DeleteScheduleGeneral/`;

export const GET_SCHEDULE_CARDIO_LIST = `/Schedule/GetScheduleCardioList`;
export const GET_SCHEDULE_CARDIO_LIST_BY_ID = `/Schedule/GetScheduleCardioById/`;
export const CREATE_SCHEDULE_CARDIO = `/Schedule/CreateScheduleCardio`;
export const UPDATE_SCHEDULE_CARDIO = `/Schedule/UpdateScheduleCardio`;
export const DELETE_SCHEDULE_CARDIO = `/Schedule/DeleteScheduleCardio/`;

export const COMBO_GET_SCHEDULE_SPORTS_LIST = `/Combo/GetMasterSportCombo`;
export const GET_SCHEDULE_SPORTS_LIST = `/Schedule/GetScheduleSportList`;
export const GET_SCHEDULE_SPORTS_LIST_BY_ID = `/Schedule/GetScheduleSportById/`;
export const CREATE_SCHEDULE_SPORTS = `/Schedule/CreateScheduleSport`;
export const UPDATE_SCHEDULE_SPORTS = `/Schedule/UpdateScheduleSport`;
export const DELETE_SCHEDULE_SPORTS = `/Schedule/DeleteScheduleSport/`;
export const GET_PROGRAM_LIST = `/Schedule/GetListedProgram`;

export const GET_MI_PROFILE_RELATION_COMBO = `/Combo/GetRelationshipCombo`;
export const GET_MI_PROFILE_DETAIL = `/User/GetUserById/`;
export const GET_MI_PROFILE_FRIEND_DETAIL = `User/GetUserFriendById/`;
export const UPDATE_MI_PROFILE_DETAIL = `/User/UpdateUser`;
export const UPLOAD_MI_PROFILE_PICTURE = `/User/UploadImage`;

export const CHANGE_PASSWORD = `/User/ChangePassword`;
export const ADD_FEEDBACK = `/User/AddFeedback`;

export const GET_ALL_GALLERY = `/Gallery/GetAllGallery`;
export const GET_GALLERY_DETAIL = `/Gallery/GetGalleryDetail/`;
export const UPLOAD_IMAGE = `/Gallery/UploadImage`;
export const POST_IMAGE = `/Gallery/GallerySave`;
export const DELETE_GALLERY_IMAGE = `/Gallery/DeleteGalleryImage/`;
export const DELETE_GALLERY_FOLDER = `/Gallery/DeleteGalleryFolder/`;
export const GET_GALLERY_COMMENT_IMAGE = `/Gallery/GetGalleryCommentDetail?GalleryImageId=`;
export const POST_IMAGE_COMMENT = `/Gallery/AddGalleryComment`;
export const UPDATE_GALLERY_COMMENT = `/Gallery/UpdateGalleryComment`;
export const DELETE_GALLERY_COMMENT = `/Gallery/DeleteGalleryComment`;
export const GALLARY_UPDATE_PRIVACY = `/Gallery/GalleryUpdate`;

export const UPDATE_USER_SPORT = `/Sport/UserSportUpdate`;
export const GET_USER_SPORT_DATA = `/Sport/GetUserSportData`;

export const SEARCH_FRIEND = `/UserRelationShip/SearchFriend`;
export const SEARCH_MY_FRIEND = `/UserRelationShip/SearchMyFriend`;
export const FRIEND_REQUEST = `/UserRelationShip/FriendRequest`;
export const UPDATE_FRIEND_STATUS = `/UserRelationShip/UpdateFriendStatus`;

export const FRIEND_LIST = `/UserRelationShip/FriendsList`;
export const GET_USERS_SCHEDULAR = `/UserRelationShip/GetUsersSchedular`;
export const GET_STATUS_OF_FRIEND = `/UserRelationShip/GetStatusOfFriend`;
export const GET_ALL_GALLERY_FRIEND = `/UserRelationShip/GetAllGalleryByUserId`;

//MI Nutrition
export const GET_SPORTS_DATA = `/Combo/GetSportCombo`;
export const GET_NUTRITION_LIST_BY_DATE = `Food/GetNutritionListByDate`;
export const GET_NUTRITION_LIST_BY_ID = `Food/GetNutritionById/`;
export const GET_FOOD_DETAILS_BY_FOOD_ID = `Food/GetFoodDetailsByFoodId/`;
export const GET_NUTRITION_SUMMARY_BY_DATE = `Food/GetNutritionSummaryByDate`;
export const GET_NUTRITION_FOOD_SEARCH = `Food/SearchFoods`;
export const GET_NUTRITION_FOOD_FINDER = `/Food/FoodFinder`;
export const CREATE_NUTRITION_FOOD = `/Food/CreateFood`;
export const UPDATE_NUTRITION_FOOD = `/Food/UpdateFood`;
export const GET_NUTRITION_FOOD_BY_ID = `/Food/GetFoodById`;
export const GET_MOST_RECENT_FOODS = `/Food/GetMostRecentFoods`;
export const GET_MOST_EATEN_FOODS = `/Food/GetMostEatenFoods`;
export const GET_FOOD_DETAILS_BY_FOOD_ID_COMBO = `/Food/GetFoodDetailsByFoodIdCombo`;
export const GET_FOOD_DETAILS_COMBO = `/Combo/GetFoodDetailsCombo/`;
export const CREATE_NUTRITION_ENTRY = `/Food/CreateNutritionEntry`;
export const CREATE_NUTRITION_ENTRY_FAT_SECRET = `/Food/CreateNutritionEntryFatSecret`;
export const UPDATE_NUTRITION_ENTRY = `/Food/UpdateNutritionEntry`;
export const ADD_FOOD_DETAIL = `/Food/AddFoodDetails`;
export const DELETE_FOOD_DETAIL = `/Food/DeleteServing/`;
export const DELETE_FOOD_NUTRITION = `/Food/DeleteNutrition/`;
export const DELETE_FOOD_DETAILS = `/Food/DeleteFood/`;
