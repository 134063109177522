/* eslint-disable prettier/prettier */
import * as React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Header from '../layouts/Header/header';
import HeaderBottom from '../layouts/HeaderBottom/HeaderBottom';
import FriendHeaderBottom from '../layouts/FriendHeaderBottom/HeaderBottom';
import ContentSidebar from '../layouts/ContentSidebar/ContentSidebar';
import { Col, Row } from 'reactstrap';
import FriendContentSidebar from '../layouts/FriendContentSidebar/FriendContentSidebar';
import { useLocation } from 'react-router-dom';
const PrivateRoutes = () => {
  const [auth, setAuth] = React.useState('false');
  const [isFriend, setIsFriend] = React.useState('false');
  const location = useLocation();
  React.useLayoutEffect(() => {
    setIsFriend(localStorage.getItem('friend'));
    setAuth(localStorage.getItem('token'));
  }, [location]);
  return auth ? (
    <>
      <Header />

      {isFriend == 'true' ? <FriendHeaderBottom /> : <HeaderBottom />}

      <div className="content-wrapper mb-5">
        <div className="container">
          <Row className="mb-2">
            {isFriend == 'true' ? <FriendContentSidebar /> : <ContentSidebar />}
            <Col md="8" lg="9" xxl="10" className="content-right">
              <Outlet />{' '}
            </Col>
          </Row>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};
export default PrivateRoutes;
