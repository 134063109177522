import { createSlice } from '@reduxjs/toolkit';
import {
  getEquipmentCombo,
  getExerciseTypeCombo,
  getMuscleGroupCombo,
  getScheduleWeightSetList,
  getSingleMuscleGroupCombo,
  getScheduleGeneralList,
  getSingleScheduleWeightSetList,
  getScheduleCardioList,
  getScheduleSportsList,
  getSportsCombo,
  getProgramLists,
  getDashBoardSchedular,
  getScheduleCardioListById,
  getScheduleCardioLatLong,
  getScheduleGeneralListById,
  getScheduleSportsListById,
  getUsersSchedular
} from './dashboardAction';
import { DashboardInitials } from '../../../../types/dashboard';

const initialState: DashboardInitials = {
  GetEquipmentCombo: [],
  GetMuscleGroupCombo: [],
  GetSingleMuscleGroupCombo: [],
  GetExerciseTypeCombo: [],
  GetScheduleWeightSetList: [],
  GetScheduleGeneralList: [],
  GetSingleScheduleGeneralList: [],
  GetScheduleCardioList: [],
  GetScheduleSportsList: [],
  GetSportsCombo: [],
  GetProgramLists: [],
  GetDashBoardSchedular: [],
  GetSingleScheduleCardioList: [],
  GetScheduleCardioLatLongList: [],
  GetScheduleGeneralListById: [],
  GetScheduleSportsListById: [],
  loading: false,
  error: null,
  showModal: false,
  modalData: null
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDates: (state, action) => {
      state.dates = action.payload;
    },
    openModal: (state, action) => {
      state.showModal = true;
      state.modalData = action.payload;
    },
    closeModal: (state) => {
      state.showModal = false;
      state.modalData = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMuscleGroupCombo.fulfilled, (state, action) => {
        state.GetMuscleGroupCombo = action.payload;
      })
      .addCase(getSingleMuscleGroupCombo.fulfilled, (state, action) => {
        state.GetSingleMuscleGroupCombo = action.payload;
      })
      .addCase(getEquipmentCombo.fulfilled, (state, action) => {
        state.GetEquipmentCombo = action.payload;
      })
      .addCase(getExerciseTypeCombo.fulfilled, (state, action) => {
        state.GetExerciseTypeCombo = action.payload;
      })
      .addCase(getScheduleWeightSetList.fulfilled, (state, action) => {
        state.GetScheduleWeightSetList = action.payload;
      })
      .addCase(getScheduleGeneralList.fulfilled, (state, action) => {
        state.GetScheduleGeneralList = action.payload;
      })
      .addCase(getScheduleGeneralListById.fulfilled, (state, action) => {
        state.GetScheduleGeneralListById = action.payload;
      })
      .addCase(getSingleScheduleWeightSetList.fulfilled, (state, action) => {
        state.GetSingleScheduleGeneralList = action.payload;
      })
      .addCase(getScheduleCardioList.fulfilled, (state, action) => {
        state.GetScheduleCardioList = action.payload;
      })
      .addCase(getScheduleCardioListById.fulfilled, (state, action) => {
        state.GetSingleScheduleCardioList = action.payload;
      })
      .addCase(getScheduleCardioLatLong.fulfilled, (state, action) => {
        state.GetScheduleCardioLatLongList = action.payload;
      })
      .addCase(getScheduleSportsList.fulfilled, (state, action) => {
        state.GetScheduleSportsList = action.payload;
      })
      .addCase(getScheduleSportsListById.fulfilled, (state, action) => {
        state.GetScheduleSportsListById = action.payload;
      })
      .addCase(getSportsCombo.fulfilled, (state, action) => {
        state.GetSportsCombo = action.payload;
      })
      .addCase(getProgramLists.fulfilled, (state, action) => {
        state.GetProgramLists = action.payload;
      })

      .addCase(getDashBoardSchedular.fulfilled, (state, action) => {
        state.GetDashBoardSchedular = action.payload;
      })
      .addCase(getUsersSchedular.fulfilled, (state, action) => {
        state.GetDashBoardSchedular = action.payload;
      });
  }
});

export const { setDates, closeModal, openModal } = dashboardSlice.actions;

export default dashboardSlice.reducer;
