import { configureStore } from '@reduxjs/toolkit';
// import thunk from 'redux-thunk';
import { rootReducers } from './rootReducers';

export const store = configureStore({
  reducer: rootReducers
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
