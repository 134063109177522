import React, { useEffect } from 'react';
// import edit_ic from '/public/images/edit.svg';
// import delete_ic from '/public/images/delete.svg';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { ErrorMessage } from '../../../components';
import { useAppDispatch } from '../../../store/hooks';
import {
  FetchMiNutritionListById,
  createNutritionFood,
  updateNutritionFood
} from '../../../store/Slices/userModule/NutritionSlice/miNutritionAction';

interface NutritionalFood {
  food_id: number;
  userId: number;
  measurement_description: string;
  metric_serving_amount: number;
  metric_serving_unit: string;
  calories: number;
  protein: number;
  fat: number;
  saturated_fat: number;
  polyunsaturated_fat: number;
  monounsaturated_fat: number;
  trans_fat: number;
  carbohydrate: number;
  sugar: number;
  fiber: number;
  cholesterol: number;
  potassium: number;
  sodium: number;
  vitamin_a: number;
  vitamin_c: number;
  calcium: number;
  iron: number;
  food_name: string;
  brand_name: string;
  serving: any[];
}

const AddNewFoodSchema = Yup.object().shape({
  measurement_description: Yup.string()
    .required('Please enter measurement description')
    .trim()
    .strict(true),
  metric_serving_amount: Yup.number().required('Please enter metric serving amount').strict(true),
  metric_serving_unit: Yup.string().required('Please enter metric serving unit').strict(true),
  calories: Yup.number().required('Please enter calories').strict(true),
  protein: Yup.number().required('Please enter protein').strict(true),
  fat: Yup.number().required('Please enter Fat').strict(true),
  saturated_fat: Yup.number().required('Please enter saturated fat').strict(true),
  polyunsaturated_fat: Yup.number().required('Please enter polyunsaturated fat').strict(true),
  monounsaturated_fat: Yup.number().required('Please enter monounsaturated fat').strict(true),
  trans_fat: Yup.number().required('Please enter trans fat').strict(true),
  carbohydrate: Yup.number().required('Please enter carbohydrate').strict(true),
  sugar: Yup.number().required('Please enter sugar').strict(true),
  fiber: Yup.number().required('Please enter fiber').strict(true),
  cholesterol: Yup.number().required('Please enter cholesterol').strict(true),
  potassium: Yup.number().required('Please enter potassium').strict(true),
  sodium: Yup.number().required('Please enter sodium').strict(true),
  vitamin_a: Yup.number().required('Please enter vitamin_a').strict(true),
  vitamin_c: Yup.number().required('Please enter itaminc').strict(true),
  calcium: Yup.number().required('Please enter calcium').strict(true),
  iron: Yup.number().required('Please enter iron').strict(true),
  food_name: Yup.string().required('Please enter food name').trim().strict(true)
  // brand_name: Yup.string().required('Please enter brand name').trim().strict(true)
});

function AddNewFood() {
  const location = useLocation();
  const { state } = location;

  const foodDataValues = state?.foodData?.serving[state?.clickIndex || 0];

  const initialValues: NutritionalFood = {
    food_id: state?.isEdit ? state?.foodData?.food_id : 0,
    userId: state?.isEdit ? state?.foodData?.food?.userId : 0,
    measurement_description: state?.isEdit ? foodDataValues?.measurement_description : '',
    metric_serving_amount: state?.isEdit ? foodDataValues?.metric_serving_amount : null,
    metric_serving_unit: state?.isEdit ? foodDataValues?.metric_serving_unit : '',
    calories: state?.isEdit ? foodDataValues?.calories : null,
    protein: state?.isEdit ? foodDataValues?.protein : null,
    fat: state?.isEdit ? foodDataValues?.fat : null,
    saturated_fat: state?.isEdit ? foodDataValues?.saturated_fat : null,
    polyunsaturated_fat: state?.isEdit ? foodDataValues?.polyunsaturated_fat : null,
    monounsaturated_fat: state?.isEdit ? foodDataValues?.monounsaturated_fat : null,
    trans_fat: state?.isEdit ? foodDataValues?.trans_fat : null,
    carbohydrate: state?.isEdit ? foodDataValues?.carbohydrate : null,
    sugar: state?.isEdit ? foodDataValues?.sugar : null,
    fiber: state?.isEdit ? foodDataValues?.fiber : null,
    cholesterol: state?.isEdit ? foodDataValues?.cholesterol : null,
    potassium: state?.isEdit ? foodDataValues?.potassium : null,
    sodium: state?.isEdit ? foodDataValues?.sodium : null,
    vitamin_a: state?.isEdit ? foodDataValues?.vitamin_a : null,
    vitamin_c: state?.isEdit ? foodDataValues?.vitamin_c : null,
    calcium: state?.isEdit ? foodDataValues?.calcium : null,
    iron: state?.isEdit ? foodDataValues?.iron : null,
    brand_name: state?.isEdit ? state?.foodData?.brand_name : '',
    food_name: state?.isEdit ? state?.foodData?.food_name : '',
    serving: []
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    state?.isEdit && dispatch(FetchMiNutritionListById(state?.food_id || 0));
  }, [state?.isEdit]);
  const navigate = useNavigate();

  const navigateFunction = () => {
    navigate('/minutrition/minutritionsearchfood');
  };

  const navigateFunction1 = () => {
    navigate('/minutrition/editfood', {
      state: {
        state_title: 'Mi Nutrition',
        retrievalData: state?.retrievalData
      }
    });
  };

  return (
    <>
      <h3 className="page-heading">{state?.isEdit ? 'Edit' : 'Add a New'} Food</h3>
      <div className="serving-table">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={AddNewFoodSchema}
          onSubmit={(values, actions: FormikHelpers<NutritionalFood>) => {
            const userData: any = JSON.parse(localStorage.getItem('userData'));
            const payload = {
              food_id: values.food_id,
              food_name: values.food_name,
              brand_name: values.brand_name,
              userId: userData.userId,
              // eslint-disable-next-line no-unsafe-optional-chaining
              serving: state?.isEdit ? [...state?.foodData?.serving] : []
            };
            payload.serving[state?.isEdit ? state?.clickIndex : 0] = {
              serving_id: foodDataValues?.serving_id,
              food_id: values.food_id,
              measurement_description: values.measurement_description,
              metric_serving_amount: values.metric_serving_amount,
              metric_serving_unit: values.metric_serving_unit,
              calories: values.calories,
              protein: values.protein,
              fat: values.fat,
              saturated_fat: values.saturated_fat,
              polyunsaturated_fat: values.polyunsaturated_fat,
              monounsaturated_fat: values.monounsaturated_fat,
              trans_fat: values.trans_fat,
              carbohydrate: values.carbohydrate,
              sugar: values.sugar,
              fiber: values.fiber,
              cholesterol: values.cholesterol,
              potassium: values.potassium,
              sodium: values.sodium,
              vitamin_a: values.vitamin_a,
              vitamin_c: values.vitamin_c,
              calcium: values.calcium,
              iron: values.iron,
              proteinPercentage: values.protein,
              fatPercentage: values.fat,
              carbohydratePercentage: values.carbohydrate
            };
            if (state?.isEdit) {
              dispatch(
                updateNutritionFood({
                  data: payload,
                  navigate1: navigateFunction1,
                  navigate2: (): null => {
                    return null;
                  }
                })
              );
              // navigate('/minutrition');
            } else {
              dispatch(createNutritionFood({ data: payload, navigate: navigateFunction }));
            }
            actions.setSubmitting(false);
          }}>
          {({ errors, touched, values, handleChange }) => (
            <Form>
              <Row>
                <Col lg="6">
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      Food Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        name="food_name"
                        value={values.food_name}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter a food name"
                      />
                      {errors.food_name && touched.food_name && (
                        <ErrorMessage text={errors.food_name} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row className="d-flex flex-wrap align-items-top">
                    <Label for="exampleEmail" sm={4}>
                      Brand (If Applicable)
                    </Label>
                    <Col sm={8}>
                      <Input
                        name="brand_name"
                        value={values.brand_name}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter brand"
                      />
                      {errors.brand_name && touched.brand_name && (
                        <ErrorMessage text={errors.brand_name} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6" className="mb-3">
                  <div className="serving-wrapper">
                    <div className="serving-header">
                      <h5 className="mb-0">Nutritional Information</h5>
                    </div>
                    <div className="serving-content">
                      <ul>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Measurement description</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="measurement_description"
                              value={values.measurement_description}
                              onChange={handleChange}
                              type="text"
                              placeholder="Eg. Cup or 100g"
                            />
                            {errors.measurement_description && touched.measurement_description && (
                              <ErrorMessage text={errors.measurement_description} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Metric serving amount</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="metric_serving_amount"
                              value={values.metric_serving_amount}
                              onChange={handleChange}
                              type="number"
                              placeholder="Eg. 100"
                            />
                            {errors.metric_serving_amount && touched.metric_serving_amount && (
                              <ErrorMessage text={errors.metric_serving_amount} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Metric serving unit</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="metric_serving_unit"
                              value={values.metric_serving_unit}
                              onChange={handleChange}
                              type="text"
                              placeholder="Metric serving unit"
                            />
                            {errors.metric_serving_unit && touched.metric_serving_unit && (
                              <ErrorMessage text={errors.metric_serving_unit} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Calories</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="calories"
                              value={values.calories}
                              onChange={handleChange}
                              type="number"
                              placeholder="Calories"
                            />

                            <p>Cal</p>
                            {errors.calories && touched.calories && (
                              <ErrorMessage text={errors.calories} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Protein</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="protein"
                              value={values.protein}
                              onChange={handleChange}
                              type="number"
                              placeholder="Protein"
                            />

                            <p>g</p>
                            {errors.protein && touched.protein && (
                              <ErrorMessage text={errors.protein} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Fat</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="fat"
                              value={values.fat}
                              onChange={handleChange}
                              type="number"
                              placeholder="Fat"
                            />
                            <p>g</p>
                            {errors.fat && touched.fat && <ErrorMessage text={errors.fat} />}
                          </div>
                          <ul>
                            <li className="d-flex flex-wrap align-items-top">
                              <div className="serving-content-left">
                                <p>Trans Fat</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-top ">
                                <Input
                                  name="trans_fat"
                                  value={values.trans_fat}
                                  onChange={handleChange}
                                  type="number"
                                  placeholder="Trans Fat"
                                />
                                <p>g</p>
                                {errors.trans_fat && touched.trans_fat && (
                                  <ErrorMessage text={errors.trans_fat} />
                                )}
                              </div>
                            </li>
                            <li className="d-flex flex-wrap align-items-top">
                              <div className="serving-content-left">
                                <p>Saturated fat</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-top ">
                                <Input
                                  name="saturated_fat"
                                  value={values.saturated_fat}
                                  onChange={handleChange}
                                  type="number"
                                  placeholder="Saturated fat"
                                />
                                <p>g</p>
                                {errors.saturated_fat && touched.saturated_fat && (
                                  <ErrorMessage text={errors.saturated_fat} />
                                )}
                              </div>
                            </li>
                            <li className="d-flex flex-wrap align-items-top">
                              <div className="serving-content-left">
                                <p>Polyunsaturated fat</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-top ">
                                <Input
                                  name="polyunsaturated_fat"
                                  value={values.polyunsaturated_fat}
                                  onChange={handleChange}
                                  type="number"
                                  placeholder="Polyunsaturated fat"
                                />
                                <p>g</p>
                                {errors.polyunsaturated_fat && touched.polyunsaturated_fat && (
                                  <ErrorMessage text={errors.polyunsaturated_fat} />
                                )}
                              </div>
                            </li>
                            <li className="d-flex flex-wrap align-items-top">
                              <div className="serving-content-left">
                                <p>Monounsaturated fat</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-top ">
                                <Input
                                  name="monounsaturated_fat"
                                  value={values.monounsaturated_fat}
                                  onChange={handleChange}
                                  type="number"
                                  placeholder="Monounsaturated fat"
                                />
                                <p>g</p>
                                {errors.monounsaturated_fat && touched.monounsaturated_fat && (
                                  <ErrorMessage text={errors.monounsaturated_fat} />
                                )}
                              </div>
                            </li>
                          </ul>
                        </li>

                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Carbohydrate</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="carbohydrate"
                              value={values.carbohydrate}
                              onChange={handleChange}
                              type="number"
                              placeholder="Carbohydrate"
                            />
                            <p>g</p>
                            {errors.carbohydrate && touched.carbohydrate && (
                              <ErrorMessage text={errors.carbohydrate} />
                            )}
                          </div>
                          <ul>
                            <li className="d-flex flex-wrap align-items-top">
                              <div className="serving-content-left">
                                <p>Sugar</p>
                              </div>
                              <div className="serving-content-right d-flex flex-wrap align-items-top ">
                                <Input
                                  name="sugar"
                                  value={values.sugar}
                                  onChange={handleChange}
                                  type="number"
                                  placeholder="Sugar"
                                />
                                <p>g</p>
                                {errors.sugar && touched.sugar && (
                                  <ErrorMessage text={errors.sugar} />
                                )}
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Fiber</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="fiber"
                              value={values.fiber}
                              onChange={handleChange}
                              type="number"
                              placeholder="Fiber"
                            />
                            <p>g</p>
                            {errors.fiber && touched.fiber && <ErrorMessage text={errors.fiber} />}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Cholesterol</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="cholesterol"
                              value={values.cholesterol}
                              onChange={handleChange}
                              type="number"
                              placeholder="Cholesterol"
                            />
                            <p>mg</p>
                            {errors.cholesterol && touched.cholesterol && (
                              <ErrorMessage text={errors.cholesterol} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Potassium</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="potassium"
                              value={values.potassium}
                              onChange={handleChange}
                              type="number"
                              placeholder="Potassium"
                            />
                            <p>mg</p>
                            {errors.potassium && touched.potassium && (
                              <ErrorMessage text={errors.potassium} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Sodium</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="sodium"
                              value={values.sodium}
                              onChange={handleChange}
                              type="number"
                              placeholder="Sodium"
                            />
                            <p>mg</p>
                            {errors.sodium && touched.sodium && (
                              <ErrorMessage text={errors.sodium} />
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col lg="6" className="mb-3">
                  <div className="serving-wrapper">
                    <div className="serving-header">
                      <h5 className="mb-0">
                        Recommended Daily Intake <span>(based on 2000 cal diet)</span>
                      </h5>
                    </div>
                    <div className="serving-content">
                      <ul>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Vitamin a</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="vitamin_a"
                              value={values.vitamin_a}
                              onChange={handleChange}
                              type="number"
                              placeholder="Vitamin a"
                            />
                            <p>%</p>
                            {errors.vitamin_a && touched.vitamin_a && (
                              <ErrorMessage text={errors.vitamin_a} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Vitamin c</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="vitamin_c"
                              value={values.vitamin_c}
                              onChange={handleChange}
                              type="number"
                              placeholder="Vitamin c"
                            />
                            <p>%</p>
                            {errors.vitamin_c && touched.vitamin_c && (
                              <ErrorMessage text={errors.vitamin_c} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Calcium</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="calcium"
                              value={values.calcium}
                              onChange={handleChange}
                              type="number"
                              placeholder="Calcium"
                            />
                            <p>%</p>
                            {errors.calcium && touched.calcium && (
                              <ErrorMessage text={errors.calcium} />
                            )}
                          </div>
                        </li>
                        <li className="d-flex flex-wrap align-items-top">
                          <div className="serving-content-left">
                            <p>Iron</p>
                          </div>
                          <div className="serving-content-right d-flex flex-wrap align-items-top ">
                            <Input
                              name="iron"
                              value={values.iron}
                              onChange={handleChange}
                              type="number"
                              placeholder="Iron"
                            />
                            <p>%</p>
                            {errors.iron && touched.iron && <ErrorMessage text={errors.iron} />}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="d-flex flex-wrap align-items-top justify-content-end nutrition-btn">
                    <button className="cancel me-3" onClick={navigateFunction}>
                      Cancel
                    </button>
                    <button className="add-entry" type="submit">
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddNewFood;
