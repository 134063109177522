/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { List, Table } from 'reactstrap';
import { items } from './profileTableUtils';
import plus_ic from '/public/images/add.svg';
import weight from '/public/images/weight.svg';
import cardio from '/public/images/cardio.svg';
import general_fitness from '/public/images/general-fitness.svg';
import sports from '/public/images/sports.svg';
import w_weight from '/public/images/w-weight.svg';
import w_cardio from '/public/images/w-cardio.svg';
import w_general_fitness from '/public/images/w-general-fitness.svg';
import w_sports from '/public/images/w-sports.svg';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import AddUpdateModal from './AddUpdateModal/index';
import Delete from '../DeleteComponent/delete';
import { useLocation, useNavigate } from 'react-router-dom';
import WeightTable from './weightTable';
import CardioTable from './cardioTable';
import SportsTable from './sportsTable';
import GeneralFitnessTable from './generalFitnessTable';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getScheduleWeightSetList,
  getScheduleGeneralList,
  getScheduleCardioList,
  getScheduleSportsList,
  getProgramLists
} from '../../store/Slices/userModule/DashboardSlice/dashboardAction';

const profileCard: React.FC = () => {
  const [modal, setModal] = useState(false);
  const [isdelete, setIsdelete] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();

  const {
    GetScheduleWeightSetList,
    GetScheduleGeneralList,
    GetScheduleCardioList,
    GetScheduleSportsList
  } = useAppSelector((state) => state.miDashboard);
  // const { GetScheduleGeneralList } = useAppSelector((state) => state.miDashboard);

  const toggleDelete = () => setIsdelete(!isdelete);
  interface Set {
    setNumber: number;
    weight: string;
  }

  interface Item {
    id: number;
    mustle: string;
    equipment: string;
    exercise: string;
    warmup: Set[];
    set1: Set[];
    set2: Set[];
    set3: Set[];
  }
  const searchBarProps = {
    // make sure all required component's inputs/Props keys&types match
    modal: modal,
    modalTitle: modalTitle,
    toggle: (name: any) => toggle(name)
  };

  const [isFriend, setIsFriend] = React.useState('false');
  const location = useLocation();
  React.useLayoutEffect(() => {
    setIsFriend(localStorage.getItem('friend'));
  }, [location]);
  React.useLayoutEffect(() => {
    modalTitle == 'Weight' && dispatch(getScheduleWeightSetList());
    modalTitle == 'General Fitness' && dispatch(getScheduleGeneralList());
    modalTitle == 'Cardio' && dispatch(getScheduleCardioList());
    modalTitle == 'Sports' && dispatch(getScheduleSportsList());
  }, [dispatch, modalTitle]);

  React.useLayoutEffect(() => {
    dispatch(getProgramLists());
  }, [dispatch]);

  const { GetProgramLists } = useAppSelector((state) => state.miDashboard);

  const toggle = async (name: any) => {
    if (GetProgramLists[0]) {
      // setModalTitle('Weight');
      await setModal(!modal);
    } else if (GetProgramLists[1]) {
      setModalTitle('Cardio');
      await setModal(!modal);
    } else if (GetProgramLists[2]) {
      setModalTitle('General Fitness');
      await setModal(!modal);
    } else if (GetProgramLists[3]) {
      setModalTitle('Sports');
      await setModal(!modal);
    } else {
      Navigate('/editprofile', { state: { isError: true } });
    }
  };

  useEffect(() => {
    if (GetProgramLists[0]) {
      setModalTitle('Weight');
    } else if (GetProgramLists[1]) {
      setModalTitle('Cardio');
    } else if (GetProgramLists[2]) {
      setModalTitle('General Fitness');
    } else if (GetProgramLists[3]) {
      setModalTitle('Sports');
    }
  }, [GetProgramLists]);

  return (
    <>
      {isFriend == 'false' ||
        (isFriend == null && (
          <>
            <div className="d-flex flex-wrap align-items-center justify-content-between calender-bottom">
              <div className="d-flex flex-wrap align-items-center">
                {GetProgramLists?.length === 0 ? (
                  <button
                    className={
                      modalTitle == 'Weight' ? 'active weight me-3 mb-3' : 'weight me-3 mb-3'
                    }
                    onClick={() => setModalTitle('Weight')}>
                    <img src={weight} className="me-2 normal" />
                    <img src={w_weight} className="me-2 active" />
                    Weight
                  </button>
                ) : (
                  ''
                )}

                {GetProgramLists[0] && (
                  <button
                    className={
                      modalTitle == 'Weight' ? 'active weight me-3 mb-3' : 'weight me-3 mb-3'
                    }
                    onClick={() => setModalTitle('Weight')}>
                    <img src={weight} className="me-2 normal" />
                    <img src={w_weight} className="me-2 active" />
                    Weight
                  </button>
                )}

                {GetProgramLists[1] && (
                  <button
                    onClick={() => setModalTitle('Cardio')}
                    className={
                      modalTitle == 'Cardio' ? 'active cardio me-3 mb-3' : 'cardio me-3 mb-3'
                    }>
                    <img src={cardio} className="me-2 normal" />
                    <img src={w_cardio} className="me-2 active" />
                    Cardio
                  </button>
                )}

                {GetProgramLists[2] && (
                  <button
                    onClick={() => setModalTitle('General Fitness')}
                    className={
                      modalTitle == 'General Fitness'
                        ? 'active general-fitness me-3 mb-3'
                        : 'general-fitness me-3 mb-3'
                    }>
                    <img src={general_fitness} className="me-2 normal" />{' '}
                    <img src={w_general_fitness} className="me-2 active" />
                    General Fitness
                  </button>
                )}

                {GetProgramLists[3] && (
                  <button
                    onClick={() => setModalTitle('Sports')}
                    className={modalTitle == 'Sports' ? 'active sports mb-3' : 'sports mb-3'}>
                    <img src={sports} className="me-2 normal" />
                    <img src={w_sports} className="me-2 active" />
                    Sports
                  </button>
                )}
              </div>
              <div className="dashboard-add-entry-btn">
                <button className="add-entry mb-3" onClick={() => toggle('Weight')}>
                  <img className="me-2" src={plus_ic} />
                  Add Entry
                </button>
              </div>
            </div>
            {modalTitle == 'Weight' && <WeightTable tableData={GetScheduleWeightSetList || []} />}
            {modalTitle == 'Cardio' && <CardioTable tableData={GetScheduleCardioList} />}
            {modalTitle == 'Sports' && <SportsTable tableData={GetScheduleSportsList} />}
            {modalTitle == 'General Fitness' && (
              <GeneralFitnessTable tableData={GetScheduleGeneralList || []} />
            )}
          </>
        ))}
      <Delete
        header="Delete"
        message="Are you sure you want to delete?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
      />
      <AddUpdateModal {...searchBarProps} />
    </>
  );
};

export default profileCard;
