/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import abstractlogo from '/public/images/abstractlogo.svg';
import formpassword from '/public/images/form-password.svg';
import eyefill from '/public/images/eye-fill.svg';
import eyeofffill from '/public/images/eye-off-fill.svg';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { AuthSidepage, ErrorMessage } from '../../../components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { resetPassword } from '../../../store/Slices/AuthModule/AuthModuleSlice';

interface MyFormValues {
  email: string;
  password: string;
  confirmedPassword: string;
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Please enter  password')
    .min(5, 'Password must be greater than 5 characters')
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*_.])[A-Za-z\d!@#$%^&*_.]+$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    )
    .strict(true),
  confirmedPassword: Yup.string()
    .required('Please enter  Confirmed Password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .strict(true)
});

function ResetPassword() {
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const [showPass, setShowPass] = useState({ password: false, confirmedPassword: false });

  const initialValues: MyFormValues = {
    password: '',
    confirmedPassword: '',
    email: ''
  };

  const navigateFunction = () => {
    navigator('/login');
  };

  return (
    <div className="auth-wrapper d-flex flex-wrap align-items-center">
      <div className="auth-left">
        <AuthSidepage />
      </div>
      <div className="auth-right">
        <div className="auth-right-wrapper">
          <div className="auth-right-box">
            <div className="auth-form-top d-flex flex-wrap align-items-center justify-content-between">
              <p>Reset Password</p>
              <img src={abstractlogo} />
            </div>
            <div className="auth-form">
              <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={resetPasswordSchema}
                onSubmit={(values, actions) => {
                  const url = window.location.href;

                  const regex = /email=([^&]+)/;
                  const match = url.match(regex);

                  if (match) {
                    const encodedEmail = match[1];
                    const decodedEmail = decodeURIComponent(encodedEmail);

                    values.email = decodedEmail;
                  } else {
                    console.log('No email found in the URL.');
                  }

                  dispatch(resetPassword({ data: values, navigate: navigateFunction }));
                  actions.setSubmitting(false);
                  actions.resetForm();
                }}>
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <InputGroup>
                      <InputGroupText>
                        <img src={formpassword} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="password"
                        type={showPass.password ? 'text' : 'password'}
                        placeholder="New Password"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <InputGroupText
                        className="viewpassword"
                        onClick={() => setShowPass({ ...showPass, password: !showPass.password })}>
                        {showPass.password ? (
                          <img src={eyefill} className="pointer" />
                        ) : (
                          <img src={eyeofffill} className="pointer" />
                        )}
                      </InputGroupText>

                      {errors.password && touched.password && (
                        <ErrorMessage text={errors.password} />
                      )}
                    </InputGroup>

                    <InputGroup>
                      <InputGroupText>
                        <img src={formpassword} />
                      </InputGroupText>
                      <Input
                        tag={Field}
                        name="confirmedPassword"
                        type={showPass.confirmedPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        value={values.confirmedPassword}
                        onChange={handleChange}
                      />
                      <InputGroupText
                        className="viewpassword"
                        onClick={() =>
                          setShowPass({
                            ...showPass,
                            confirmedPassword: !showPass.confirmedPassword
                          })
                        }>
                        {showPass.confirmedPassword ? (
                          <img src={eyefill} className="pointer" />
                        ) : (
                          <img src={eyeofffill} className="pointer" />
                        )}
                      </InputGroupText>
                      {errors.confirmedPassword && touched.confirmedPassword && (
                        <ErrorMessage text={errors.confirmedPassword} />
                      )}
                    </InputGroup>

                    <div className="form-button">
                      <button type="submit">Save</button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="d-flex justify-content-md-center form-login">
                <Link to="/login">Back To Login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
