import React from 'react';

interface NoDataFoundComponentProps {
  message: string;
}

const NoDataFound: React.FC<NoDataFoundComponentProps> = ({ message }) => {
  return (
    <div className="border p-5 rounded w-100">
      <center>
        <h3 className="text-muted">{message || 'No Album Found'}</h3>
      </center>
    </div>
  );
};

export default NoDataFound;
