import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
import food_redirect from '/public/images/food-redirect.svg';
import { Nav, NavItem, TabContent, TabPane, NavLink } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchFoodTab from './SearchFoodTab';
import RecentFoodTab from './RecentFoodTab';
import MostEatenFoodTab from './MostEatenFoodTab';
import dayjs from 'dayjs';

function MiNutritionSearchFood() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('1');
  const selectedDate = sessionStorage.getItem('selectedDate');

  const AddNewFood = () => {
    navigate('/minutrition/food-action', { state: { state_title: 'Mi Nutrition' } });
  };

  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-between page-heading">
        <div className="d-flex flex-wrap align-items-end justify-content-center">
          <h3 className="">Mi Nutrition - Food Search</h3>
          <h5 className="ms-2 mb-0 lead">{dayjs(selectedDate).format('D MMMM YYYY')}</h5>
        </div>

        <div className="add-an-entry-btn">
          <button className="add-an-entry-btn button" onClick={AddNewFood}>
            Add a new food!
          </button>
        </div>
      </div>
      {/* Add food button */}
      <div className="food-search-tab">
        <Nav tabs>
          <NavItem>
            <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
              Search for Foods
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
              Mi Recent Foods
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>
              Mi Most Eaten
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-3">
          <TabPane tabId="1">
            <SearchFoodTab />
          </TabPane>
          <TabPane tabId="2">
            <RecentFoodTab />
            <div className="add-new-food">
              <p>
                {`Can't find what you're looking for? `}
                <Link to="/minutrition/food-action" state={{ state_title: 'Mi Nutrition' }}>
                  Add a new food!
                </Link>{' '}
              </p>
            </div>
          </TabPane>
          <TabPane tabId="3">
            <MostEatenFoodTab />
            <div className="add-new-food">
              <p>
                {`Can't find what you're looking for? `}
                <Link to="/minutrition/food-action" state={{ state_title: 'Mi Nutrition' }}>
                  Add a new food!
                </Link>{' '}
              </p>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
}

export default MiNutritionSearchFood;
