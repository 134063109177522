import axios from 'axios';

axios.defaults.baseURL = process.env.SERVER_API;

const authToken = localStorage?.getItem('token');
if (authToken) {
  axios.interceptors.request.use(
    (config) => {
      const authToken = localStorage?.getItem('token');
      const stringWithoutQuotes = authToken.replace(/"/g, '');
      if (authToken) {
        config.headers['Authorization'] = `Bearer ${stringWithoutQuotes}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

// // Add a response interceptor
// axios.interceptors.response.use(
//   (response) => {
//     // Transform response data or handle success
//     return response;
//   },
//   (error) => {
//     // Handle error responses globally
//     if (error.response.status === 401) {
//       // Handle unauthorized access
//     } else if (error.response.status === 404) {
//       // Handle not found error
//     } else {
//       // Handle other error scenarios
//     }
//     return Promise.reject(error);
//   }
// );
