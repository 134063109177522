import { createSlice } from '@reduxjs/toolkit';
import {
  friendRequest,
  getMiFriendList,
  getsStatusOfFriend,
  searchFriend,
  searchMyFriend,
  updateFriendStatus
} from './mifriendsAction';
import { IFriends } from '../../../../types/miFriend';

const initialState: IFriends = {
  searchFriends: null,
  searchMyFriends: null,
  friendRequests: null,
  getMiFriendLists: null,
  friendStatus: null,
  getStatusOfFriends: null
};

const miFriendsSlice = createSlice({
  name: 'Friends',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchFriend.fulfilled, (state, action) => {
      state.searchFriends = action.payload;
    });
    builder.addCase(searchMyFriend.fulfilled, (state, action) => {
      state.searchMyFriends = action.payload;
    });
    builder.addCase(friendRequest.fulfilled, (state, action) => {
      state.friendRequests = action.payload;
    });
    builder.addCase(updateFriendStatus.fulfilled, (state, action) => {
      state.friendStatus = action.payload;
    });
    builder.addCase(getMiFriendList.fulfilled, (state, action) => {
      state.getMiFriendLists = action.payload;
    });
    builder.addCase(getsStatusOfFriend.fulfilled, (state, action) => {
      state.getStatusOfFriends = action.payload;
    });
  }
});

export default miFriendsSlice.reducer;
