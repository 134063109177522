import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  MI_RECORD_DELETE,
  MI_RECORD_FETCH,
  MI_RECORD_POST,
  MI_RECORD_UPDATE
} from '../../../apiRoutes';
import { setLoading } from '../../CommonSlice/commonSlice.';
import { toast } from 'react-toastify';
import errorHandlers from '../../../../shared/config/errorHandler';

export interface Record {
  userRecordId?: number;
  userId: string;
  label: string;
  url: string;
  notes: string;
}

export interface dataState {
  miRecord: Record[];
  loading: boolean;
  error: string | null;
}

export const fetchMiRecords = createAsyncThunk(
  'miRecord/fetchMiRecords',
  async (_, { dispatch: AppDispatch }) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    AppDispatch(setLoading(true));
    try {
      const response = await axios.get<Record[]>(`${MI_RECORD_FETCH}/${userData.userId}`);
      AppDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      errorHandlers(error);
    }
  }
);

export const postMIRecords = createAsyncThunk(
  'miRecord/postMIRecords',
  async (data: Record, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.post(`${MI_RECORD_POST}`, data);
      AppDispatch(fetchMiRecords());
      AppDispatch(setLoading(false));
      toast.success('Record added successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const updateMIRecords = createAsyncThunk(
  'miRecord/UpdateMIRecords',
  async (data: Record, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.put(MI_RECORD_UPDATE, data);
      AppDispatch(fetchMiRecords());
      AppDispatch(setLoading(false));
      toast.success('Record updated successfully');
      return response.data;
    } catch (error) {
      errorHandlers(error);
      AppDispatch(setLoading(false));
    }
  }
);

export const DeleteMIRecords = createAsyncThunk(
  'miRecord/DeleteMIRecords',
  async (id: number, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const response = await axios.delete(`${MI_RECORD_DELETE}${id}`);
      AppDispatch(fetchMiRecords());
      AppDispatch(setLoading(false));
      toast.success('Record deleted successfully');
      return response.data;
    } catch (error) {
      AppDispatch(setLoading(false));
      errorHandlers(error);
    }
  }
);

const initialState: dataState = {
  miRecord: [],
  loading: false,
  error: null
};

const miRecordSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //getting data
      .addCase(fetchMiRecords.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMiRecords.fulfilled, (state, action) => {
        state.miRecord = action.payload;
        state.loading = false;
      })
      .addCase(fetchMiRecords.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'Error fetching posts';
      });
  }
});

// export const { getDataStart, getDataSuccess, getDataFailure, addData } = miRecordSlice.actions; /// for reducers only
export default miRecordSlice.reducer;
