import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import food_redirect from '/public/images/food-redirect.svg';
import no_nutrition from '/public/images/no-nutrition.png';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { FetchMiNutritionMostRecentFoods } from '../../../store/Slices/userModule/NutritionSlice/miNutritionAction';

const RecentFoodTab = () => {
  const dispatch = useAppDispatch();
  const miNutritionMostRecentFoods: any = useAppSelector(
    (state) => state.miNutrition.miNutritionMostRecentFoods
  );

  useEffect(() => {
    dispatch(FetchMiNutritionMostRecentFoods());
  }, []);

  return (
    <>
      {miNutritionMostRecentFoods?.length ? (
        miNutritionMostRecentFoods?.map((data: any, index: number) => (
          <div className="search-food-list d-flex flex-wrap align-items-center mb-3" key={index}>
            <div className="search-food-left">
              <h5>{data.food_name}</h5>
              <p>{data.description}</p>
            </div>
            <div className="search-food-right">
              <Link
                to="/minutrition/minutritionaddanentry"
                state={{ foodData: data, isEditProfile: true }}>
                <button>
                  <img src={food_redirect} />
                </button>
              </Link>
            </div>
          </div>
        ))
      ) : (
        <>
          <div className="d-flex flex-wrap justify-content-center">
            <img className="pe-none" src={no_nutrition} />
          </div>
        </>
      )}
    </>
  );
};

export default RecentFoodTab;
