import React, { useEffect, useState } from 'react';

import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { ErrorMessage } from '../..';
import ReactSelect from 'react-select';
import {
  createScheduleCardioSet,
  getEquipmentCombo,
  getExerciseTypeCombo,
  getScheduleCardioLatLong,
  getScheduleCardioListById,
  updateScheduleCardioSet
} from '../../../store/Slices/userModule/DashboardSlice/dashboardAction';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import CommonConfirmModal from '../../common/ConfirmModal';

interface CardioRecordValues {
  scheduleCardioId: number;
  equipmentId: number;
  exerciseTypeId: number;
  warmUpDuration: number;
  // sustainedHeartRate: number;

  heartRateBefore: number;
  heartRateAter: number;
  latitude: number;
  longitude: number;

  sustainedDuration: number;
  coolDownDuration: number;
  date: string | Date;
  userId: number;

  // Repeat
  startDate: string;
  endDate: string;
  daysOfWeek?: any;
  repeats: string | number;
  recurrenceType: number;
  isRecurrence: boolean;
  isOveride: boolean;
  slot: number;
}

function CardioRecord(props: any) {
  const Cardio = props.activeTab;
  const [confirmModal, setConfirmModal] = useState(false);
  const [editPayloadData, setEditPayloadData] = useState<any>();
  const [isStartOpen, setIsStartOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { GetEquipmentCombo, GetExerciseTypeCombo } = useAppSelector((state) => state.miDashboard);
  const { localUserData } = useAppSelector((state) => state?.common);
  const [isDetailRequire, setIsDetailRequire] = useState(props?.model?.isDetailRequired);
  const currentCardio = useAppSelector((state) => state.miDashboard.GetSingleScheduleCardioList);

  useEffect(() => {
    props?.model?.currentCardioData?.scheduleCardioId &&
      props?.model?.isEditMode &&
      dispatch(getScheduleCardioListById(props?.model?.currentCardioData?.scheduleCardioId));
  }, []);

  const currentLatLong = useAppSelector((state) => state.miDashboard.GetScheduleCardioLatLongList);

  useEffect(() => {
    const selectedDate =
      isDetailRequire === 'true'
        ? dayjs(currentCardio?.date).format('MM/DD/YYYY')
        : props?.model?.currentSelectedDate ?? dayjs().format('YYYY-MM-DD');
    dispatch(getScheduleCardioLatLong(selectedDate));
  }, []);

  useEffect(() => {
    dispatch(getEquipmentCombo());
  }, [dispatch]);

  const initialValues: CardioRecordValues = {
    scheduleCardioId: props?.model?.isEditMode ? currentCardio.scheduleCardioId : 0,
    equipmentId: props?.model?.isEditMode ? currentCardio.equipmentId : null,
    exerciseTypeId: props?.model?.isEditMode ? currentCardio.exerciseTypeId : null,
    warmUpDuration: props?.model?.isEditMode ? currentCardio.warmUpDuration : null,
    // sustainedHeartRate: props?.model?.isEditMode ? currentCardio.sustainedHeartRate : null,
    heartRateBefore: props?.model?.isEditMode
      ? currentCardio.heartRateBefore
      : currentLatLong?.heartRateBefore === '0.00'
      ? null
      : currentLatLong?.heartRateBefore,
    heartRateAter: props?.model?.isEditMode
      ? currentCardio.heartRateAter
      : currentLatLong?.heartRateAter === '0.00'
      ? null
      : currentLatLong?.heartRateAter,
    latitude: props?.model?.isEditMode ? currentCardio.latitude : null,
    longitude: props?.model?.isEditMode ? currentCardio.longitude : null,

    sustainedDuration: props?.model?.isEditMode ? currentCardio.sustainedDuration : null,
    coolDownDuration: props?.model?.isEditMode ? currentCardio.coolDownDuration : null,
    date: props?.model?.isEditMode ? currentCardio.date : new Date(),
    userId: props?.model?.isEditMode ? currentCardio.userId : 0,

    // Repeat
    startDate: props?.model?.isEditMode ? currentCardio.startDate : null,
    endDate: props?.model?.isEditMode ? currentCardio.endDate : null,
    daysOfWeek: props?.model?.isEditMode
      ? currentCardio?.daysOfWeek || [0, 0, 0, 0, 0, 0, 0]
      : [0, 0, 0, 0, 0, 0, 0],
    repeats: props?.model?.isEditMode ? currentCardio.repeats : null,
    recurrenceType: props?.model?.isEditMode ? currentCardio.recurrenceType : null,
    isRecurrence: props?.model?.isEditMode ? currentCardio.isRecurrence : false,
    isOveride: props?.model?.isEditMode ? currentCardio.isOveride : false,
    slot: props?.model?.isEditMode ? currentCardio.slot : 0
  };

  const CardioRecordSchema = Yup.object().shape({
    equipmentId: Yup.number().required('Please select exercise equipment').strict(true),
    exerciseTypeId: Yup.number().required('Please select exercise type').strict(true),
    warmUpDuration: Yup.number()
      .typeError('Please enter number')
      .required('Please enter warmup duration'),
    // sustainedHeartRate: Yup.number()
    //   .typeError('Please enter number')
    //   .required('Please enter sustained heart rate'),
    heartRateBefore: Yup.number()
      .typeError('Please enter number')
      .required('Please enter heart rate before'),

    heartRateAter: Yup.number()
      .typeError('Please enter number')
      .required('Please enter heart rate after'),
    latitude: Yup.number().typeError('Please enter number').required('Please enter latitude'),
    longitude: Yup.number().typeError('Please enter number').required('Please enter longitude'),
    sustainedDuration: Yup.number()
      .typeError('Please enter number')
      .required('Please enter sustained duration'),
    coolDownDuration: Yup.number()
      .typeError('Please enter number')
      .required('Please enter cool down duration'),

    // Repeat
    startDate: Yup.string()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.string().nullable().required('Please select start date')
      }),
    endDate: Yup.string()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.string().nullable().required('Please select end date')
      }),
    repeats: Yup.number()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.number().nullable().required('Please enter interval duration')
      }),
    recurrenceType: Yup.number()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.number().nullable().required('Please select recurrence')
      })
  });

  const recurrence = [
    { label: 'Daily', value: 1 },
    { label: 'Weekly', value: 2 },
    { label: 'Monthly', value: 3 }
  ];

  return (
    <>
      <div className="auth-left-wrapper">
        {!props.model.isEditMode && <h4 className="mb-3 popup-subtitle">Add Cardio Record</h4>}
        {Cardio == 'Cardio' && (
          <Formik
            initialValues={initialValues}
            enableReinitialize={props.model.isEditMode || !!currentLatLong}
            validationSchema={CardioRecordSchema}
            onSubmit={(values, actions) => {
              values.userId = localUserData.userId || 0;
              if (!props?.model?.isEditMode) {
                values.date = props?.model?.currentSelectedDate || dayjs().format('YYYY-MM-DD');
              }
              if (currentCardio.length > 0) {
                values.date = dayjs((currentCardio as any)?.date).format('YYYY-MM-DD');
              }
              if (!values?.isRecurrence) {
                values.repeats = 0;
                values.startDate = null;
                values.endDate = null;
                values.recurrenceType = 0;
                values.slot = 0;
              }
              if (values?.startDate && values.endDate) {
                values.startDate = dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss');
                values.endDate = dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss');
              }

              if (values.scheduleCardioId) {
                if (values.isRecurrence) {
                  setConfirmModal(true);
                  setEditPayloadData(values);
                } else {
                  dispatch(updateScheduleCardioSet({ ...values, onlyThisDate: true }));
                  props?.toggle();
                }
              } else {
                dispatch(createScheduleCardioSet(values));
                props?.toggle();
              }
              actions.setSubmitting(false);
              // props?.toggle();
            }}>
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                {isDetailRequire && props?.model?.isEditMode && (
                  <Row>
                    <Col md="12">
                      <FormGroup row className="d-flex flex-wrap align-items-top">
                        <Col sm={12} className="m-auto">
                          <span>{dayjs((currentCardio as any)?.date).format('MM/DD/YYYY')}</span>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Exercise equipment:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.equipment}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <ReactSelect
                            className="react-select-dropdown"
                            options={GetEquipmentCombo}
                            placeholder="Select exercise equipment"
                            name="equipmentId"
                            value={GetEquipmentCombo.find(
                              (data) => data.value === values.equipmentId
                            )}
                            onChange={async (option) => {
                              await dispatch(getExerciseTypeCombo(+option.value));
                              setFieldValue('equipmentId', +option.value);
                              setFieldValue('exerciseTypeId', null);
                            }}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (provided) => ({
                                ...provided,
                                borderRadius: 50,
                                paddingLeft: 8,
                                boxShadow: null
                              })
                            }}
                          />
                          {errors.equipmentId && touched.equipmentId && (
                            <ErrorMessage text={errors.equipmentId} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Exercise type:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.exerciseType}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <ReactSelect
                            isDisabled={values.equipmentId ? false : true}
                            className="react-select-dropdown"
                            options={GetExerciseTypeCombo}
                            placeholder="Select exercise type"
                            name="exerciseTypeId"
                            value={
                              values.exerciseTypeId &&
                              GetExerciseTypeCombo.find(
                                (data) => data.value === values.exerciseTypeId
                              )
                            }
                            onChange={(option) => {
                              setFieldValue('exerciseTypeId', +option.value);
                            }}
                            noOptionsMessage={() => <>Select exercise equipment first</>}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              control: (provided) => ({
                                ...provided,
                                borderRadius: 50,
                                paddingLeft: 8,
                                boxShadow: null
                              })
                            }}
                          />
                          {errors.exerciseTypeId && touched.exerciseTypeId && (
                            <ErrorMessage text={errors.exerciseTypeId} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Warmup duration:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.warmUpDuration} Minutes</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="side-content position-relative">
                          <Input
                            type="number"
                            name="warmUpDuration"
                            value={values.warmUpDuration}
                            onChange={handleChange}
                            placeholder="Warmup duration"></Input>
                          {errors.warmUpDuration && touched.warmUpDuration && (
                            <ErrorMessage text={errors.warmUpDuration} />
                          )}
                          <span>Minutes</span>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6"></Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Sustained duration:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.sustainedDuration} Minutes</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="side-content position-relative">
                          <Input
                            type="number"
                            name="sustainedDuration"
                            value={values.sustainedDuration}
                            onChange={handleChange}
                            placeholder="Sustained duration"></Input>
                          {errors.sustainedDuration && touched.sustainedDuration && (
                            <ErrorMessage text={errors.sustainedDuration} />
                          )}
                          <span>Minutes</span>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Cooldown duration:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.coolDownDuration} Minutes</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="side-content position-relative">
                          <Input
                            type="number"
                            name="coolDownDuration"
                            value={values.coolDownDuration}
                            onChange={handleChange}
                            placeholder="Cooldown duration"></Input>
                          {errors.coolDownDuration && touched.coolDownDuration && (
                            <ErrorMessage text={errors.coolDownDuration} />
                          )}
                          <span>Minutes</span>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Heart rate before:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.heartRateBefore} BPM</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="side-content position-relative">
                          <Input
                            name="heartRateBefore"
                            placeholder="Heart rate before"
                            value={values.heartRateBefore}
                            onChange={handleChange}
                            type="number"></Input>

                          {errors.heartRateBefore && touched.heartRateBefore && (
                            <ErrorMessage text={errors.heartRateBefore} />
                          )}
                          <span>BPM</span>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Heart rate after:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.heartRateAter} BPM</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="side-content position-relative">
                          <Input
                            name="heartRateAter"
                            placeholder="Heart rate after"
                            value={values.heartRateAter}
                            onChange={handleChange}
                            type="number"></Input>
                          {errors.heartRateAter && touched.heartRateAter && (
                            <ErrorMessage text={errors.heartRateAter} />
                          )}
                          <span>BPM</span>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Latitude:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.latitude}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="latitude"
                            placeholder="Latitude"
                            value={values.latitude}
                            onChange={handleChange}
                            type="text"></Input>
                          {errors.latitude && touched.latitude && (
                            <ErrorMessage text={errors.latitude} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Longitude:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentCardio as any)?.longitude}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="longitude"
                            placeholder="Longitude"
                            value={values.longitude}
                            onChange={handleChange}
                            type="text"></Input>
                          {errors.longitude && touched.longitude && (
                            <ErrorMessage text={errors.longitude} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="6"></Col>
                  {isDetailRequire && props?.model?.isEditMode ? (
                    <Col md="12">{(currentCardio as any)?.isRecurrence && <b>Repeat</b>}</Col>
                  ) : (
                    <Col md="12">
                      <div className="custom-checkbox d-inline-block">
                        <label className="checkbox-container">
                          Repeat
                          <Input
                            name="isRecurrence"
                            type="checkbox"
                            className="form-check-input"
                            checked={values.isRecurrence}
                            onChange={(e) => {
                              setFieldValue('isRecurrence', e.target.checked);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </Col>
                  )}

                  {values?.isRecurrence && (
                    <>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" lg={4}>
                            When does it start?
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{dayjs(currentCardio?.startDate).format('MM/DD/YYYY')}</span>
                            </Col>
                          ) : (
                            <Col lg={8}>
                              <Row className="flex-wrap align-items-top">
                                <Col>
                                  <DatePicker
                                    autoComplete="off"
                                    name="startDate"
                                    selected={
                                      values?.startDate ? new Date(values?.startDate) : null
                                    }
                                    onChange={(date: Date | null) => {
                                      setFieldValue('startDate', date);
                                      setFieldValue('endDate', null);
                                    }}
                                    placeholderText="Please select start date"
                                    dateFormat="MM/dd/yyyy"
                                    popperProps={{ strategy: 'fixed' }}
                                  />
                                  {errors.startDate && touched.startDate && (
                                    <ErrorMessage text={errors.startDate} />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" lg={4}>
                            When does it end?
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{dayjs(currentCardio?.endDate).format('MM/DD/YYYY')}</span>
                            </Col>
                          ) : (
                            <Col lg={8}>
                              <Row className="flex-wrap align-items-top">
                                <Col>
                                  <DatePicker
                                    autoComplete="off"
                                    name="endDate"
                                    disabled={values.startDate ? false : true}
                                    minDate={values.startDate && new Date(values.startDate)}
                                    selected={values?.endDate ? new Date(values?.endDate) : null}
                                    onChange={(date: Date | null) => setFieldValue('endDate', date)}
                                    placeholderText="Please select end date"
                                    dateFormat="MM/dd/yyyy"
                                    popperProps={{ strategy: 'fixed' }}
                                  />
                                  {errors.endDate && touched.endDate && (
                                    <ErrorMessage text={errors.endDate} />
                                  )}
                                </Col>{' '}
                              </Row>
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" sm={4}>
                            Recurrence
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>
                                {
                                  recurrence.find(
                                    (data) => data.value === currentCardio.recurrenceType
                                  )?.label
                                }
                              </span>
                            </Col>
                          ) : (
                            <Col sm={8}>
                              <ReactSelect
                                className="react-select-dropdown"
                                options={recurrence}
                                placeholder="Select recurrence"
                                name="recurrenceType"
                                onChange={(options) => {
                                  setFieldValue('recurrenceType', +options.value);
                                }}
                                value={recurrence.find(
                                  (data) => data.value === values.recurrenceType
                                )}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  control: (provided) => ({
                                    ...provided,
                                    borderRadius: 50,
                                    paddingLeft: 8,
                                    boxShadow: null
                                  })
                                }}
                              />
                              {errors.recurrenceType && touched.recurrenceType && (
                                <ErrorMessage text={errors.recurrenceType} />
                              )}
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" sm={4}>
                            Interval duration
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{(currentCardio as any)?.repeats}</span>
                            </Col>
                          ) : (
                            <Col sm={8}>
                              <Input
                                name="repeats"
                                placeholder="Interval duration"
                                value={values.repeats}
                                onChange={handleChange}
                                type="number"
                                min={1}
                                max={99}></Input>
                              {errors.repeats && touched.repeats && (
                                <ErrorMessage text={errors.repeats} />
                              )}
                            </Col>
                          )}
                        </FormGroup>
                      </Col>

                      {isDetailRequire && props?.model?.isEditMode ? (
                        <></>
                      ) : (
                        values.recurrenceType === 2 && (
                          <Col md="12" className="d-flex flex-wrap mb-3">
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Sun
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[0]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[0]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Mon
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[1]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[1]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Tue
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[2]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[2]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Wed
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[3]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[3]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Thu
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[4]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[4]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Fri
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[5]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[5]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Sat
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[6]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[6]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </Col>
                        )
                      )}
                    </>
                  )}
                  <Col md="12">
                    <div className="justify-content-end mt-3 col-md-12 modal-footer">
                      <Button className="cancel-btn me-3" onClick={props?.toggle}>
                        Cancel
                      </Button>{' '}
                      {isDetailRequire && props?.model?.isEditMode && (
                        <Button
                          className="save-record"
                          type="button"
                          onClick={() => {
                            setIsDetailRequire(!isDetailRequire);
                          }}>
                          Edit
                        </Button>
                      )}
                      {!isDetailRequire && (
                        <Button className="save-record" type="submit">
                          {/* {Object.keys(currentCardio).length ? 'Update' : 'Add'} */}
                          {props?.model.isEditMode ? 'Update' : 'Add'}
                        </Button>
                      )}
                      {props?.model?.isDateSelected && !props?.model?.isEditMode && (
                        <Button className="save-record" type="submit">
                          {props?.model.isEditMode ? 'Update' : 'Add'}
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <CommonConfirmModal
        header="Please confirm"
        message="Are you sure you want to over ride all record ?"
        modal={confirmModal}
        toggle={() => {
          setConfirmModal(false);
        }}
        confirmNoClickClick={() => {
          dispatch(updateScheduleCardioSet({ ...editPayloadData, onlyThisDate: true }));
          setConfirmModal(false);
          setEditPayloadData({});
          props?.toggle();
        }}
        confirmDeleteClick={() => {
          dispatch(updateScheduleCardioSet({ ...editPayloadData, onlyThisDate: false }));
          setConfirmModal(false);
          setEditPayloadData({});
          props?.toggle();
        }}
      />
    </>
  );
}

export default CardioRecord;
