/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import delete_white from '/public/images/delete-white.svg';
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ErrorComponent from '../../../components/ErrorMessage/error';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  FetchMiNutritionById,
  deleteFoodDetailEntry,
  deleteFoodDetails,
  updateNutritionFood
} from '../../../store/Slices/userModule/NutritionSlice/miNutritionAction';
import CommonDelete from '../../../components/DeleteComponent/commonDelete';

function EditFood() {
  const { state } = useLocation();

  const dispatch = useAppDispatch();
  const formRef = useRef<any>();

  const [getFoodDescriptionId, setFoodDescriptionId] = useState<number>(0);

  const miNutritionMiSportsProfile: any = useAppSelector(
    (state) => state.miNutrition.miNutritionDetailByIds
  );

  const [isdelete, setIsdelete] = useState(false);
  const [isdeleteFood, setIsdeleteFood] = useState(false);
  const toggleDelete = () => setIsdelete(!isdelete);
  const toggleDeleteFood = () => setIsdeleteFood(!isdeleteFood);
  const navigate = useNavigate();

  interface editFormValues {
    food_name: string;
    brand_name: string;
  }

  const editFoodSchema = Yup.object().shape({
    food_name: Yup.string().required('Please enter Food Name').trim().strict(true)
    // brand_name: Yup.string()
    //   .required('Please enter brand')
    //   // .min(5, 'Username must be greater than 5 character')
    //   .max(15, 'Username must be less than 15 character')
    //   .strict(true)
  });

  const initialValues: editFormValues = {
    food_name: miNutritionMiSportsProfile?.food_name ? miNutritionMiSportsProfile?.food_name : '',
    brand_name: miNutritionMiSportsProfile?.brand_name ? miNutritionMiSportsProfile?.brand_name : ''
  };

  useEffect(() => {
    if (state?.foodData?.food_id) {
      dispatch(FetchMiNutritionById(state?.foodData?.food_id));
    }
  }, [dispatch]);

  const navigateFunction = () => {
    navigate('/minutrition/minutritionaddanentry', {
      state: state?.retrievalData
    });
  };

  const homeNavigate = function () {
    navigate('/minutrition');
  };

  return (
    <>
      <h3 className="page-heading">Edit Food</h3>
      <div className="edit-food-top mb-3">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={editFoodSchema}
          onSubmit={(values) => {
            const payload = { ...miNutritionMiSportsProfile, ...values };
            // dispatch(updateNutritionFood(payload));
            dispatch(
              updateNutritionFood({
                data: payload,
                navigate2: navigateFunction,
                navigate1: (): null => {
                  return null;
                }
              })
            );
            // navigate(-1);
          }}>
          {({ errors, touched, values, handleChange }) => (
            <Form>
              <Row>
                <Col lg="6">
                  <FormGroup row className="d-flex flex-wrap align-items-center">
                    <Label for="exampleEmail" sm={4}>
                      Food Name
                    </Label>
                    <Col sm={8}>
                      <Input
                        name="food_name"
                        value={values.food_name}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter a food name"
                      />
                      {errors.food_name && touched.food_name && (
                        <ErrorComponent text={errors.food_name} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup row className="d-flex flex-wrap align-items-center">
                    <Label for="exampleEmail" sm={4}>
                      Brand (If Applicable)
                    </Label>
                    <Col sm={8}>
                      <Input
                        name="brand_name"
                        value={values.brand_name}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter brand name"
                      />
                      {errors.brand_name && touched.brand_name && (
                        <ErrorComponent text={errors.brand_name} />
                      )}
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>

      <h4 className="edit-ser-heading mb-3">Servings</h4>
      <div className="edit-ser-table mb-3">
        <div className="table-wrapper">
          <Table responsive className="mb-0">
            <thead>
              <tr>
                <th>Description</th>
                <th>Measurement</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {miNutritionMiSportsProfile?.serving?.length > 0 ? (
                miNutritionMiSportsProfile?.serving?.map((data: any, index: number) => (
                  <tr key={index}>
                    <td>{data.measurement_description}</td>
                    <td>{data.metric_serving_amount}</td>
                    <td>
                      <>
                        <button
                          className="me-2"
                          onClick={() => {
                            navigate('/minutrition/food-action', {
                              state: {
                                isEdit: true,
                                foodData: miNutritionMiSportsProfile,
                                clickIndex: index,
                                retrievalData: state?.retrievalData
                              }
                            });
                          }}>
                          <img src={edit_ic} />
                        </button>
                        {miNutritionMiSportsProfile?.serving?.length !== 1 && (
                          <button
                            onClick={() => {
                              toggleDelete();
                              setFoodDescriptionId(data.serving_id);
                            }}>
                            <img src={delete_ic} />
                          </button>
                        )}
                      </>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>
                    <center>
                      <h3>No data found</h3>
                    </center>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div className="d-flex flex-wrap align-items-center nutrition-btn">
          <Link to="#">
            <button
              className="add-entry me-3"
              onClick={(e) => {
                e.preventDefault();
                formRef.current.handleSubmit();
              }}>
              Save
            </button>
          </Link>
          <Link
            to="/minutrition/addaserving"
            state={{
              state_title: 'Mi Nutrition',
              foodData: miNutritionMiSportsProfile,
              retrievalData: state?.retrievalData
            }}>
            <button className="edit-food me-3">Add a Serving</button>
          </Link>
          <button className="cancel" onClick={() => navigateFunction()}>
            Cancel
          </button>
        </div>
        <div className="d-flex flex-wrap align-items-center nutrition-btn">
          <button className="edit-food me-3" onClick={toggleDeleteFood}>
            {' '}
            <img className="me-2" src={delete_white} /> Delete Food
          </button>
        </div>
      </div>
      <CommonDelete
        header="Delete Servings"
        message="Are you sure you want to delete this servings?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
        confirmDeleteClick={() => {
          dispatch(
            deleteFoodDetailEntry({ id: getFoodDescriptionId, food_id: state?.foodData?.food_id })
          );
          toggleDelete();
        }}
      />
      <CommonDelete
        header="Delete Food"
        message="Are you sure you want to delete this food?"
        modal={isdeleteFood}
        toggle={() => {
          toggleDeleteFood();
        }}
        confirmDeleteClick={() => {
          dispatch(
            deleteFoodDetails({
              id: state?.foodData?.foodId || state?.foodData?.food_id,
              redirect: homeNavigate
            })
          );
          toggleDeleteFood();
        }}
      />
    </>
  );
}

export default EditFood;
