import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

function Logout(props: any) {
  const navigate = useNavigate();

  const onLogoutClick = () => {
    localStorage.clear();
    navigate('/login');
    location.reload();
  };
  return (
    <Modal
      isOpen={props?.modal}
      toggle={props?.toggle}
      className="popup-wrapper delete-popup"
      centered
      size="sm">
      <ModalHeader toggle={props?.toggle} className="p-0 d-flex flex-wrap justify-content-between">
        Logout
      </ModalHeader>
      <ModalBody className="text-center">Are you sure you want to logout?</ModalBody>
      <ModalFooter className="justify-content-center mt-3">
        <Button className="cancel-btn me-3" onClick={props?.toggle}>
          Cancel
        </Button>{' '}
        <Button className="save-record" onClick={() => onLogoutClick()}>
          Yes, Logout
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Logout;
