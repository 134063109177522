import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET_SPORTS_COMBO, GET_TIMEZONE_COMBO, GET_VISIBILITY } from '../../apiRoutes';
import axios from 'axios';

interface LoaderState {
  loading: boolean;
  partialLoading: boolean;
  timeZone: Record[];
  visibility: Record[];
  sports: Record[];
  localUserData: any;
  error: any;
}

const initialState: LoaderState = {
  loading: false,
  partialLoading: false,
  timeZone: [],
  visibility: [],
  sports: [],
  localUserData: [],
  error: ''
};

export interface Record {
  label: string;
  value: number;
}
export const fetchCommon = createAsyncThunk(
  'common/fetchCommon',
  async (_, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const timeZoneCombo = await axios.get<Record[]>(GET_TIMEZONE_COMBO);
      const visibility = await axios.get<Record[]>(GET_VISIBILITY);
      const sports = await axios.get<Record[]>(GET_SPORTS_COMBO);
      AppDispatch(setLoading(false));
      return { data: timeZoneCombo.data, visibility: visibility.data, sports: sports.data };
    } catch (error) {
      AppDispatch(setLoading(false));
    }
  }
);

export const fetchTimeZonCommon = createAsyncThunk(
  'common/fetchTimeZonCommon',
  async (_, { dispatch: AppDispatch }) => {
    AppDispatch(setLoading(true));
    try {
      const timeZoneCombo = await axios.get<Record[]>(GET_TIMEZONE_COMBO);

      AppDispatch(setLoading(false));
      return { data: timeZoneCombo.data };
    } catch (error) {
      AppDispatch(setLoading(false));
    }
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPartialLoading: (state, action) => {
      state.partialLoading = action.payload;
    },
    setLocalUserData: (state, action) => {
      state.localUserData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommon.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCommon.fulfilled, (state, action) => {
        state.loading = false;
        state.timeZone = action.payload.data;
        state.visibility = action.payload.visibility;
        state.sports = action.payload.sports;
      })
      .addCase(fetchTimeZonCommon.fulfilled, (state, action) => {
        state.loading = false;
        state.timeZone = action.payload.data;
      })
      .addCase(fetchCommon.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { setLoading, setLocalUserData, setPartialLoading } = commonSlice.actions;
export default commonSlice.reducer;
