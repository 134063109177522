import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  NavLink
} from 'reactstrap';

import weight from '/public/images/weight.svg';
import cardio from '/public/images/cardio.svg';
import general_fitness from '/public/images/general-fitness.svg';
import sports from '/public/images/sports.svg';
import w_weight from '/public/images/w-weight.svg';
import w_cardio from '/public/images/w-cardio.svg';
import w_general_fitness from '/public/images/w-general-fitness.svg';
import w_sports from '/public/images/w-sports.svg';
import WeightRecord from '../../TodaysProgramComponent/WeightRecord/weightRecord';
import CardioRecord from '../../TodaysProgramComponent/CardioRecord/CardioRecord';
import GeneralFitnessRecord from '../../TodaysProgramComponent/GeneralFitnessRecord/GeneralFitnessRecord';
import SportRecord from '../../TodaysProgramComponent/SportRecord/SportRecord';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import CommonConfirmModal from '../../common/ConfirmModal';
import { createScheduleCardioSet, createScheduleGeneral, createScheduleSportsSet, createScheduleWeightSet } from '../../../store/Slices/userModule/DashboardSlice/dashboardAction';
import { closeModal } from '../../../store/Slices/userModule/DashboardSlice/dashboardSlice';

function index(props: any) {
  const [activeTab, setActiveTab] = useState('Weight');
  useEffect(() => {
    setActiveTab(props?.modalTitle);
  }, [props?.modalTitle]);

  const dispatch = useAppDispatch();

  const miDashboardData = useAppSelector((state) => state.miDashboard);
  const miDashboardData1 = useAppSelector((state) => state?.miDashboard?.modalData);
  const { GetProgramLists } = useAppSelector((state) => state.miDashboard);


  return (
    <>
      <Modal
        isOpen={props?.modal}
        //  isOpen={true}
        // toggle={props?.toggle}
        activeTab={props?.activeTab}
        className="popup-wrapper large-popup"
        centered
        scrollable
        size="lg">
        <ModalHeader toggle={props?.toggle} className="p-0">
          {`${props?.isEditMode ? (props?.isDetailRequired && props?.isEditMode ? '' : 'Edit') : 'Add'
            } ${activeTab}`}
        </ModalHeader>
        <ModalBody>
          <div className="tab-wrapper">
            {!props?.isEditMode && (
              <Nav tabs className="tab-top">

                {GetProgramLists?.length === 0 ? <>
                  <NavItem className="mb-3">
                    <NavLink
                      className="weight"
                      activeClassName={activeTab == 'Weight' ? 'active' : ''}
                      onClick={() => setActiveTab('Weight')}>
                      <img src={weight} className="me-2 normal" />
                      <img src={w_weight} className="me-2 active" />
                      Weight
                    </NavLink>
                  </NavItem>
                </> : ''}

                {GetProgramLists[0] && <>
                  <NavItem className="mb-3">
                    <NavLink
                      className="weight"
                      activeClassName={activeTab == 'Weight' ? 'active' : ''}
                      onClick={() => setActiveTab('Weight')}>
                      <img src={weight} className="me-2 normal" />
                      <img src={w_weight} className="me-2 active" />
                      Weight
                    </NavLink>
                  </NavItem>
                </>}

                {GetProgramLists[1] && <>
                  <NavItem className="mb-3">
                    <NavLink
                      className="cardio"
                      activeClassName={activeTab == 'Cardio' ? 'active' : ''}
                      onClick={() => setActiveTab('Cardio')}>
                      <img src={cardio} className="me-2 normal" />
                      <img src={w_cardio} className="me-2 active" />
                      Cardio
                    </NavLink>
                  </NavItem>
                </>}

                {GetProgramLists[2] && <>

                  <NavItem className="mb-3">
                    <NavLink
                      className="general-fitness"
                      activeClassName={activeTab == 'General Fitness' ? 'active' : ''}
                      onClick={() => setActiveTab('General Fitness')}>
                      <img src={general_fitness} className="me-2 normal" />{' '}
                      <img src={w_general_fitness} className="me-2 active" />
                      General Fitness
                    </NavLink>
                  </NavItem>
                </>}

                {GetProgramLists[3] && <>

                  <NavItem className="mb-3">
                    <NavLink
                      className="sports"
                      activeClassName={activeTab == 'Sports' ? 'active' : ''}
                      onClick={() => setActiveTab('Sports')}>
                      <img src={sports} className="me-2 normal" />
                      <img src={w_sports} className="me-2 active" />
                      Sports
                    </NavLink>
                  </NavItem>
                </>}
              </Nav>
            )}
            <TabContent activeTab={activeTab}>
              <TabPane tabId="Weight">
                <WeightRecord model={props} activeTab={activeTab} />
              </TabPane>
              <TabPane tabId="Cardio">
                <CardioRecord model={props} toggle={props?.toggle} activeTab={activeTab} />
              </TabPane>
              <TabPane tabId="General Fitness">
                <GeneralFitnessRecord model={props} toggle={props?.toggle} activeTab={activeTab} />
              </TabPane>
              <TabPane tabId="Sports">
                <SportRecord model={props} toggle={props?.toggle} activeTab={activeTab} />
              </TabPane>
            </TabContent>
          </div>
        </ModalBody>
        {/* <ModalFooter className="justify-content-end mt-3">
        <Button className="cancel-btn me-3" onClick={props?.toggle}>
          Cancel
        </Button>{' '}
        <Button className="save-record">Add</Button>
      </ModalFooter> */}
      </Modal>
      <CommonConfirmModal
        header="Warning"
        message="Are you sure you want to over ride this record ?"
        modal={miDashboardData?.showModal}
        isEdit={true}
        toggle={() => {
          dispatch(closeModal());
        }}
        confirmNoClickClick={() => {
          if (miDashboardData1.modalData.scheduleGeneralId === 0) {
            dispatch(createScheduleGeneral({ ...miDashboardData1.modalData, NotOverRide: true }));
          }
          if (miDashboardData1.modalData.scheduleWeightId === 0) {
            dispatch(createScheduleWeightSet({ ...miDashboardData1.modalData, NotOverRide: true }));
          }
          if (miDashboardData1.modalData.scheduleCardioId === 0) {
            dispatch(createScheduleCardioSet({ ...miDashboardData1.modalData, NotOverRide: true }));
          }
          if (miDashboardData1.modalData.scheduleSportId === 0) {
            dispatch(createScheduleSportsSet({ ...miDashboardData1.modalData, NotOverRide: true }));
          }
          dispatch(closeModal());
        }}
        confirmDeleteClick={() => {
          if (miDashboardData1.modalData.scheduleGeneralId === 0) {
            dispatch(createScheduleGeneral({ ...miDashboardData1.modalData, isOveride: true }));
          }
          if (miDashboardData1.modalData.scheduleWeightId === 0) {
            dispatch(createScheduleWeightSet({ ...miDashboardData1.modalData, isOveride: true }));
          }
          if (miDashboardData1.modalData.scheduleCardioId === 0) {
            dispatch(createScheduleCardioSet({ ...miDashboardData1.modalData, isOveride: true }));
          }
          if (miDashboardData1.modalData.scheduleSportId === 0) {
            dispatch(createScheduleSportsSet({ ...miDashboardData1.modalData, isOveride: true }));
          }
          dispatch(closeModal());
        }}
      />
    </>
  );
}

index.propTypes = {};

export default index;
