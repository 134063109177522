/* eslint-disable prettier/prettier */
import * as React from 'react';
// import * as ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import App from './App/app';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App/Assets/styles/style.scss';
import { Provider } from 'react-redux';
import { store } from './App/store/store';
import ErrorBoundary from './App/components/common/ErrorBoundries';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
  // </React.StrictMode>
);
