import React, { useEffect, useState } from 'react';

import friend from '/public/images/friend.png';
import friend1 from '/public/images/friend1.png';
import friend2 from '/public/images/friend2.png';
import friend3 from '/public/images/friend3.png';
import friend4 from '/public/images/friend4.png';
import comment_user from '/public/images/comment-user.png';
import view_photo from '/public/images/view-photo.svg';
import dont_view_photo from '/public/images/dont-view-photo.svg';
import { Link, useLocation } from 'react-router-dom';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import close from '../../../Assets/images/close-btn.svg';

import delete_bin from '/public/images/delete-bin.svg';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  deleteGalleryComment,
  deleteGalleryImage,
  getAllImageComment,
  getGalleryDetail,
  postImageComment,
  updateGalleryComment
} from '../../../store/Slices/userModule/AlbumSlice/albumAction';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import NoDataFound from '../../../components/common/NoDataFound';
import { IGetGalleryDetails } from '../../../types';
import Delete from '../../../components/DeleteComponent/delete';
import CommonDelete from '../../../components/DeleteComponent/commonDelete';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import profile_placeholder from '/public/images/profile_placeholder.png';
import { getTimeDifference, redirectUser_friend } from '../../../shared/utils';
import CommonReplayConfirmModal from '../../../components/common/ConfirmModal';

interface IMessageInitialValues {
  galleryCommentId: number;
  galleryImageId: string;
  senderUserId: number;
  receiverUserId: number;
  message: string;
}

function AlbumFriend() {
  document.title = 'Mi Sports Profile - Mi Album';
  const [isdelete, setIsdelete] = useState(false);
  const toggleDelete = () => setIsdelete(!isdelete);
  const { state } = useLocation();
  const galleryId = state?.gallaryData?.galleryId;
  const dispatch = useAppDispatch();
  const [isFriend, setIsFriend] = React.useState('false');
  const [currentImage, setCurrentImage] = React.useState();

  const albumGalleryData: any[] = useAppSelector((state) => state.miAlbum.GetGalleryDetail);
  const albumImageCommentData: any[] = useAppSelector((state) => state.miAlbum.GetAllImageComment);
  const { localUserData } = useAppSelector((state) => state?.common);

  const [getReplayActiveIndex, setReplayActiveIndex] = useState<number>(null);
  const [getReplayDeleteId, setReplayDeleteId] = useState<number>(null);
  const [getReplayGalleryDeleteId, setReplayGalleryDeleteId] = useState<number>(null);
  const [getReplayActiveMessageText, setReplayActiveMessageText] = useState<string>('');
  const [isReplayConformationDelete, setReplayIsConformationDelete] = useState<boolean>(false);

  const toggleReplayConformationDelete = () =>
    setReplayIsConformationDelete(!isReplayConformationDelete);

  const userData = JSON.parse(localStorage.getItem('userData'));

  const userAccount = (data: any) => {
    redirectUser_friend(data?.userId);
  };
  React.useLayoutEffect(() => {
    setIsFriend(localStorage.getItem('friend'));
  }, [state?.gallaryData]);

  React.useLayoutEffect(() => {
    dispatch(getGalleryDetail(galleryId));
  }, [dispatch]);

  interface ImageItem {
    id: number;
    img: string;
  }

  const [images, setImages] = useState<ImageItem[]>([
    { id: 1, img: friend },
    { id: 2, img: friend1 },
    { id: 3, img: friend3 },
    { id: 4, img: friend4 },
    { id: 5, img: friend2 }
  ]);

  const [modal, setModal] = useState(false);

  const toggle = (image: any) => {
    setModal(!modal);
    setCurrentImage(image);
  };

  const validationSchema = Yup.object().shape({
    message: Yup.string()
      .required('Please enter message')
      .trim('Leading and trailing spaces not allowed')
  });

  const initialValues: IMessageInitialValues = {
    galleryCommentId: 0,
    galleryImageId: '',
    senderUserId: null,
    receiverUserId: 0,
    message: ''
  };

  const isImage = (url: any) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp|jfif)$/i.test(url);
  };

  const isVideo = (url: any) => {
    return /\.(mp4|webm|ogg|mov)$/i.test(url);
  };

  interface ImageInfo {
    imageGuid: string;
    imageUrl: string;
  }

  function containsVideo(images: ImageInfo[]): boolean {
    const videoExtensionsRegex = /\.(mp4|webm|ogg|mov)$/i;

    for (let i = 0; i < images?.length; i++) {
      const imageUrl = images[i].imageUrl;

      if (videoExtensionsRegex.test(imageUrl)) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between lay-down-wrapper">
        <h4>{state?.gallaryData?.title} - Album</h4>
        <Link to="/friends/friendsphotos">
          <button>Back To Album</button>
        </Link>
      </div>

      <div className="friend-list d-flex flex-wrap">
        {/* {albumGalleryData[0]?.imageUrlANDGuid?.length ? (
          albumGalleryData[0]?.imageUrlANDGuid?.map((image: any, index: number) => (
            <div className="friend-list-box" key={index}>
              <div className="friend-list-img position-relative mb-2">
                <div key={index}>
                  <img
                    className="friend-photo"
                    src={process.env.CLIENT_PATH + image?.imageUrl}
                    alt={`Image ${index}`}
                    onClick={() => {
                      toggle(image);
                      dispatch(getAllImageComment(image?.imageGuid));
                      setCurrentImage(image);
                    }}
                  />
                  {isFriend == 'true' ? (
                    ''
                  ) : (
                    <div className="delete-photo">
                      <button
                        className="delete-btn"
                        onClick={() => {
                          toggleDelete();
                          setCurrentImage(image);
                          // handleRemoveImage(image.galleryDetailId);
                        }}>
                        <img src={delete_bin} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoDataFound message="No Data Found" />
        )} */}
      </div>

      <div
        className={`friend-list d-flex flex-wrap ${
          containsVideo(albumGalleryData[0]?.imageUrlANDGuid) && 'video'
        }`}>
        {albumGalleryData[0]?.imageUrlANDGuid?.length ? (
          albumGalleryData[0]?.imageUrlANDGuid?.map((image: any, index: number) => (
            <div
              className={`friend-list-box ${isImage(image?.imageUrl) ? 'image' : ''} ${
                isVideo(image?.imageUrl) ? 'video' : ''
              }`}
              key={index}>
              <div className="friend-list-img position-relative mb-2">
                <div key={index}>
                  {}
                  <div className="album-image">
                    {isImage(image?.imageUrl) && (
                      <img
                        className="friend-photo"
                        src={`${process.env.CLIENT_PATH}${image?.imageUrl}`}
                        alt={`Image ${index}`}
                        onClick={() => {
                          toggle(image);
                          dispatch(getAllImageComment(image?.imageGuid)); // Assuming `image.guid` is the unique identifier
                          setCurrentImage(image);
                        }}
                        width={150}
                        height={150}
                      />
                    )}
                  </div>
                  <div className="album-video">
                    {isVideo(image?.imageUrl) && (
                      <div
                        className="text-center"
                        onClick={() => {
                          toggle(image);
                          dispatch(getAllImageComment(image?.imageGuid)); // Assuming `image.guid` is the unique identifier
                          setCurrentImage(image);
                        }}>
                        <video
                          className="width-100"
                          controls={true}
                          autoPlay={false}
                          muted={false}
                          onClick={(e: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
                            e.preventDefault();
                          }}>
                          <source
                            src={`${process.env.CLIENT_PATH}${image?.imageUrl}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                  </div>
                  {/* {isImage(image?.imageUrl) && (
                    <img
                      className='friend-photo'
                      src={process.env.CLIENT_PATH + image?.imageUrl}
                      alt={`Image ${index}`}
                      onClick={() => {
                        toggle(image);
                        dispatch(getAllImageComment(image?.imageGuid));  // Assuming `image.guid` is the unique identifier
                        setCurrentImage(image);
                      }}
                      width={150}
                      height={150}
                    />
                  )}
                  {isVideo(image?.imageUrl == '.mp4') && (
                    <video controls width="400">
                      <source src={image?.imageUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )} */}
                  {/* <img
                    className='friend-photo'
                    src={process.env.CLIENT_PATH + image?.imageUrl}
                    alt={`Image ${index}`}
                    onClick={() => {
                      toggle(image);
                      dispatch(getAllImageComment(image?.imageGuid));
                      setCurrentImage(image);
                    }}
                  /> */}
                  {isFriend == 'true' ? (
                    ''
                  ) : (
                    <div className="delete-photo">
                      <button
                        className="delete-btn"
                        onClick={() => {
                          toggleDelete();
                          setCurrentImage(image);
                          // handleRemoveImage(image.galleryDetailId);
                        }}>
                        <img src={delete_bin} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoDataFound message="No Data Found" />
        )}
      </div>

      {images.length <= 0 && (
        <div>
          <center>
            <h1>No data Found</h1>
          </center>
        </div>
      )}
      <Modal
        className="popup-wrapper nutrition-popup"
        isOpen={modal}
        toggle={toggle}
        {...arguments}
        centered
        scrollable>
        <ModalHeader toggle={toggle} className="p-0"></ModalHeader>
        <ModalBody>
          <div className="popup-photo-comment d-flex flex-wrap">
            <div className="popup-photo-comment-left">
              {/* <img
                src={currentImage && process.env.CLIENT_PATH + (currentImage as any)?.imageUrl}
              /> */}
              {isImage(
                currentImage && process.env.CLIENT_PATH + (currentImage as any)?.imageUrl
              ) && (
                <img
                  className="friend-photo"
                  src={`${process.env.CLIENT_PATH}${(currentImage as any)?.imageUrl}`}
                  alt={`Image ${(currentImage as any)?.imageUrl}`}
                />
              )}
              {isVideo(
                currentImage && process.env.CLIENT_PATH + (currentImage as any)?.imageUrl
              ) && (
                <div className="text-center">
                  <video controls className="width-100">
                    <source
                      src={`${process.env.CLIENT_PATH}${(currentImage as any)?.imageUrl}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}
            </div>
            <div className="popup-photo-comment-right">
              <div className="content-right">
                <div className="repaly-comment">
                  {albumImageCommentData?.length ? (
                    albumImageCommentData?.map((data: any, index: number) => (
                      <div
                        className="replay-top d-flex flex-wrap align-items-center position-relative"
                        key={index}>
                        <div className="replay-top-img">
                          {data.imageUrl ? (
                            <>
                              {data.imageUrl === null ? (
                                <img src={profile_placeholder} />
                              ) : (
                                <img src={process.env.CLIENT_PATH + data.imageUrl} />
                              )}
                            </>
                          ) : (
                            <img src={profile_placeholder} />
                          )}
                          {/* <img src={data.imageUrl && process.env.CLIENT_PATH + data.imageUrl} /> */}
                        </div>
                        <div className="replay-top-content">
                          <div className="minute-ago">
                            <p>{getTimeDifference(new Date(data.createdDate))}</p>
                          </div>
                          {/* <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <h4>
                              {userData?.userId == data?.userId ? '' : <p>{data.userName}</p>}
                            </h4>
                          </div> */}

                          <div className="1">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                              {userData?.userId == data?.userId ? (
                                <div className="w-100">
                                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                                    <h4>{data.userName}</h4>
                                  </div>
                                  {getReplayActiveIndex === index ? (
                                    <div className="d-flex flex-wrap align-items-center">
                                      <div className="replay-update-bottom d-flex flex-wrap align-items-center justify-content-between w-100">
                                        <textarea
                                          className="w-100-767"
                                          placeholder="Write your comments..."
                                          value={getReplayActiveMessageText}
                                          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            setReplayActiveMessageText(e.target.value);
                                          }}
                                        />
                                        <div className="d-flex flex-wrap align-items-center">
                                          <button
                                            type="button"
                                            className="me-2 update-btn"
                                            onClick={() => {
                                              const payload = {
                                                galleryCommentId: data?.galleryCommentId,
                                                galleryImageId: data?.galleryImageId,
                                                userId: data?.userId,
                                                message: getReplayActiveMessageText
                                              };
                                              dispatch(updateGalleryComment(payload));
                                              setReplayActiveIndex(null);
                                            }}>
                                            Update
                                          </button>
                                          <button
                                            className="rounded-circle-btn"
                                            type="button"
                                            onClick={() => setReplayActiveIndex(null)}>
                                            <img src={close} />
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="replay-comment-comment d-flex flex-wrap align-items-center justify-content-between">
                                      <p>{data.message}</p>
                                      <div className="d-flex flex-wrap align-items-center">
                                        <button
                                          className="me-2 bg-transparent mx-2 border-0 p-0"
                                          onClick={() => {
                                            setReplayActiveIndex(index);
                                            setReplayActiveMessageText(data.message);
                                          }}>
                                          <img src={edit_ic} />
                                        </button>
                                        <button
                                          className="view-all border-0 bg-transparent p-0"
                                          onClick={() => {
                                            toggleReplayConformationDelete();
                                            setReplayDeleteId(data?.galleryCommentId);
                                            setReplayGalleryDeleteId(data?.galleryImageId);
                                          }}>
                                          <img src={delete_ic} />
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <h4 onClick={() => userAccount(data)} className="cursor-pointer">
                                  {data.userName}
                                </h4>
                              )}
                            </div>
                            <div className="replay-comment-comment">
                              {userData?.userId == data?.userId ? '' : <p>{data.message}</p>}
                            </div>
                          </div>

                          {/* <div className="replay-comment-comment">
                            <p>{data.message}</p>
                          </div> */}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mb-3">
                      <NoDataFound message="No Comment Found" />
                    </div>
                  )}
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={(values: FormikValues, { resetForm }) => {
                      values.senderUserId = localUserData.userId;
                      values.galleryImageId = (currentImage as any).imageGuid;
                      dispatch(postImageComment(values));
                      resetForm();
                    }}>
                    {({ errors, touched, values, handleChange, handleSubmit }) => (
                      <div className="replay-bottom d-flex flex-wrap align-items-center">
                        <textarea
                          className="w-100-767"
                          name="message"
                          value={values.message}
                          placeholder="Write your comments..."
                          onChange={handleChange}
                        />
                        <button
                          className={
                            values.message && !errors.message
                              ? 'write-comment-btn'
                              : 'write-comment-btn-disable'
                          }
                          type="submit"
                          disabled={errors.message && touched.message ? true : false}
                          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            e.preventDefault();
                            handleSubmit();
                          }}>
                          Comment
                        </button>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <CommonDelete
        header="Delete Image"
        message="Are you sure you want to delete this image?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
        confirmDeleteClick={async () => {
          await dispatch(deleteGalleryImage((currentImage as any).imageGuid));
          await dispatch(getGalleryDetail(galleryId));
          toggleDelete();
        }}
      />
      <CommonReplayConfirmModal
        header="Delete Comment"
        message="Are you sure you want to delete this comment?"
        modal={isReplayConformationDelete}
        toggle={() => {
          toggleReplayConformationDelete();
        }}
        confirmDeleteClick={(data: any) => {
          data.getReplayDeleteId = getReplayDeleteId;
          data.getReplayGalleryDeleteId = getReplayGalleryDeleteId;
          dispatch(deleteGalleryComment(data));
          toggleReplayConformationDelete();
        }}
        confirmNoClickClick={() => toggleReplayConformationDelete()}
      />
    </>
  );
}

export default AlbumFriend;
