import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CardioRecordDetail from '../../TodaysProgramComponent/CardioRecord/CardioRecordDetails';
import WeightRecordDetail from '../../TodaysProgramComponent/WeightRecord/WeightRecordDetails';
import SportRecordDetail from '../../TodaysProgramComponent/SportRecord/SportRecordDetails';
import GeneralFitnessRecordDetail from '../../TodaysProgramComponent/GeneralFitnessRecord/GeneralFitnessRecordDetails';

const DetailModal = (props: any) => {
  const closeModal = () => {
    document.body.classList.add('overflow-visible');
    props?.toggle();
  };
  return (
    <Modal
      isOpen={props?.modal && props?.modal}
      toggle={closeModal}
      className="popup-wrapper large-popup"
      centered
      scrollable>
      <ModalHeader toggle={closeModal} className="p-0">
        {props?.modalTitle && props?.modalTitle}
      </ModalHeader>
      <ModalBody>
        {props?.modalTitle && props?.modalTitle === 'Weight' && <WeightRecordDetail />}
        {props?.modalTitle && props?.modalTitle === 'Cardio' && <CardioRecordDetail />}
        {props?.modalTitle && props?.modalTitle === 'General Fitness' && (
          <GeneralFitnessRecordDetail togModal={closeModal} />
        )}
        {props?.modalTitle && props?.modalTitle === 'Sports' && <SportRecordDetail />}
      </ModalBody>
    </Modal>
  );
};

export default DetailModal;
