import React, { useCallback, useState } from 'react';
import { Table } from 'reactstrap';
import { items } from './profileTableUtils';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import up_ic from '/public/images/up.svg';
import down_ic from '/public/images/down.svg';
import AddUpdateModal from './AddUpdateModal/index';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import CommonDelete from '../DeleteComponent/commonDelete';
import {
  deleteScheduleGeneralFITNESSSet,
  getScheduleGeneralListById
} from '../../store/Slices/userModule/DashboardSlice/dashboardAction';
import dayjs from 'dayjs';
import { IgeneralFitness } from '../../types/dashboard';

function GeneralFitnessTable(props: any) {
  const [isAllDeleted, setAllDeleted] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [isdelete, setIsdelete] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('General Fitness');
  const [currentGeneralFitnessData, setCurrentGeneralFitnessData] = useState<IgeneralFitness>();
  const [active, setActive] = useState<number>();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(null);

  const toggleImage = (index: number) => {
    setActiveTabIndex((prevIndex: number) => (prevIndex === index ? null : index));
    setActive((prevIndex: number) => (prevIndex === index ? null : index));
  };

  const dispatch = useAppDispatch();

  const currentGeneralFitness = useAppSelector(
    (state) => state.miDashboard.GetScheduleGeneralListById
  );

  const searchBarProps = {
    modal: modal,
    currentGeneralFitnessData: currentGeneralFitnessData,
    isEditMode: true,
    modalTitle: modalTitle,
    toggle: (name: string) => toggle(name)
  };

  const toggle = async (name: string) => {
    await setModal(!modal);
    await setModalTitle(name);
  };
  const toggleDelete = () => setIsdelete(!isdelete);

  return (
    <>
      {Object.keys(props?.tableData).length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Activity</th>
              <th>Duration</th>
              {/* <th>Heart Rate Before</th>
              <th>Heart Rate After</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {props?.tableData?.map((item: IgeneralFitness, rowIndex: number) => (
              <React.Fragment key={rowIndex}>
                <tr key={rowIndex}>
                  <td>{dayjs((item as IgeneralFitness)?.date)?.format('MM/DD/YYYY')}</td>
                  <td>{item.activity}</td>
                  <td>{item.duration} Minutes</td>
                  {/* <td>{item.heartRateBefore} BPM</td>
                  <td>{item.heartRateAter} BPM</td> */}
                  <td>
                    <button
                      className="me-2"
                      onClick={() => {
                        toggle('General Fitness');
                        setCurrentGeneralFitnessData(item);
                      }}>
                      <img src={edit_ic} />
                    </button>
                    <button
                      className="me-2"
                      onClick={() => {
                        toggleDelete();
                        setCurrentGeneralFitnessData(item);
                        item.scheduleGeneralListModels.length === 0 ? setAllDeleted(false) : setAllDeleted(true)
                      }}>
                      <img src={delete_ic} />
                    </button>
                    {item.scheduleGeneralListModels.length === 0 ? <></> : <><button onClick={() => toggleImage(rowIndex + 1)}>
                      <img src={active === rowIndex + 1 ? up_ic : down_ic} alt="Image" />
                    </button></>}

                  </td>
                </tr>
                {activeTabIndex === rowIndex + 1 && (
                  <>
                    {item.scheduleGeneralListModels.length === 0 ? <></> :
                      <>
                        {item.scheduleGeneralListModels.map((item: IgeneralFitness, index: number) => (
                          <tr key={index} className='expanded'>
                            <td>{dayjs((item as IgeneralFitness)?.date)?.format('MM/DD/YYYY')}</td>
                            <td>{item.activity}</td>
                            <td>{item.duration} Minutes</td>
                            {/* <td>{item.heartRateBefore} BPM</td>
                            <td>{item.heartRateAter} BPM</td> */}
                            <td>
                              <button
                                className="me-2"
                                onClick={() => {
                                  toggle('General Fitness');
                                  setCurrentGeneralFitnessData(item);
                                }}>
                                <img src={edit_ic} />
                              </button>
                              <button
                                className="me-2"
                                onClick={() => {
                                  toggleDelete();
                                  setCurrentGeneralFitnessData(item);
                                }}>
                                <img src={delete_ic} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>}
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="border p-5 rounded">
          <center>
            <h3 className="text-muted">No data Found</h3>
          </center>
        </div>
      )}
      <CommonDelete
        header={isAllDeleted ? "Delete All General Fitness" : "Delete General Fitness"}
        message={isAllDeleted ? "Are you sure you want to delete all General Fitness ?" : "Are you sure you want to delete this General Fitness ?"}
        modal={isdelete}
        toggle={() => {
          toggleDelete();
          setAllDeleted(false)
        }}
        confirmDeleteClick={() => {
          const payload = { id: (currentGeneralFitnessData as any)?.scheduleGeneralId, isDeleteAll: isAllDeleted }
          dispatch(deleteScheduleGeneralFITNESSSet(payload));
          toggleDelete();
        }}
      />
      <AddUpdateModal {...searchBarProps} />
    </>
  );
}

export default GeneralFitnessTable;
