/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Col, Row, FormGroup, Label, Input } from 'reactstrap';
import ReactSelect from 'react-select';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  createNutritionEntryFatSecret,
  FetchMiDetailsById,
  FetchMiNutritionById,
  FetchMiNutritionListById,
  FetchServingFoodCombo,
  FetchServingFoodDetailsCombo,
  createNutritionEntry,
  updateNutritionEntry
} from '../../../store/Slices/userModule/NutritionSlice/miNutritionAction';
import ReactApexChart from 'react-apexcharts';
import { useFormik } from 'formik';
import { ErrorMessage } from '../../../components';
import * as Yup from 'yup';
import { typeFoodDetails } from '../../../types/miNutrition';
import dayjs from 'dayjs';

interface IChart {
  // eslint-disable-next-line no-undef
  options?: ApexCharts.ApexOptions;
  // eslint-disable-next-line no-undef
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
}

interface INutritionServings {
  nutritionId: number;
  userId: number;
  foodId: number;
  foodDetailsId: number;
  numberOfServing: number;
  time: string;
  minute: number;
  hour: number;
  mealCategory: number;
  date: Date | string;
  servingId: number;
  servingName: string;
  food: any;
}

const validationSchema = Yup.object().shape({
  numberOfServing: Yup.number().min(1, 'Add serving').required('Add serving').strict(true),
  mealCategory: Yup.number().required('Please select meal').strict(true),
  hour: Yup.string().required('Please select hour'),
  servingId: Yup.number().required('Please select servings').min(1, 'Please select relationship'),

  minute: Yup.string().required('Please select minute')
});

function MiNutritionAddanEntry() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [foodDetailsData, setFoodDetailsData] = useState<typeFoodDetails>();

  // const miServingFoodDetailsCombos: any = useAppSelector(
  const miNutritionFoodDetailsCombo: any = useAppSelector(
    (state) => state.miNutrition.miNutritionFoodDetailsCombo
  );
  const miServingFoodDetailsCombos: any = useAppSelector(
    (state) => state.miNutrition.miServingFoodDetailsCombos
  );

  const miNutritionMiSportsProfile: any = useAppSelector(
    (state) => state.miNutrition.miNutritionDetailByIds
  );
  const FetchMiDetailsByIds: any = useAppSelector((state) => state.miNutrition.FetchMiDetailsByIds);

  const timeOption = (length: number) => {
    let options = Array.from({ length: length || 1 }, (_, i) => ({ label: i, value: i }));
    return options;
  };

  const mealOptions = [
    { label: 'Breakfast', value: 1 },
    { label: 'Lunch', value: 2 },
    { label: 'Dinner', value: 3 },
    { label: 'Snack', value: 4 }
  ];

  const dataSet: IChart = {
    series: [
      +foodDetailsData?.fat || 0,
      +foodDetailsData?.carbohydrate || 0,
      +foodDetailsData?.protein || 0
    ],
    options: {
      chart: {
        width: 380,
        type: 'pie'
      },
      labels: ['Fat', 'Carbs', 'Protein'],
      colors: ['#54A613', '#B8CC08', '#2B2B2B'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }
      ]
    }
  };

  const initialValues: INutritionServings = {
    nutritionId: state?.isEdit ? state?.foodData?.nutritionId : 0,
    userId: state?.isEdit ? state?.foodData?.userId : null,
    foodId: state?.isEdit ? state?.foodData?.foodId : null,
    foodDetailsId: state?.isEdit ? state?.foodData?.foodDetailsId : null,
    numberOfServing: state?.isEdit ? state?.foodData?.numberOfServing : null,
    minute: state?.isEdit ? dayjs(new Date(state?.foodData?.time)).minute() : null,
    hour: state?.isEdit ? dayjs(new Date(state?.foodData?.time)).hour() : null,
    time: state?.isEdit ? state?.foodData?.time : '',
    mealCategory: state?.isEdit ? state?.foodData?.mealCategoryId : null,
    date: dayjs(new Date()).format('YYYY-MM-DD'),
    servingId: state?.isEdit
      ? state?.foodData?.foodDetailsId
      : (foodDetailsData as any)?.serving_id,

    servingName: state?.isEdit
      ? state?.foodData?.measurementDescription
      : (foodDetailsData as any)?.measurement_description,
    food: 0
  };

  const navigator = useNavigate();
  const navigateFunction = () => {
    navigator('/minutrition');
  };

  const { errors, touched, values, setFieldValue, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (foodDetailsData?.food_id) {
        values.foodId = foodDetailsData?.food_id;
      }
      values.userId = userData?.userId;
      const selectedDate = sessionStorage.getItem('selectedDate');

      if (selectedDate) {
        values.date = selectedDate;
      }

      values.foodDetailsId = values?.servingId;

      if (state?.isEditFatSecret) {
        values.time = `${values.hour}:${values.minute}`;

        const payload = {
          foodDetailsId: 0,
          nutritionId: 0,
          userId: 2,
          foodId: 0,
          numberOfServing: values.numberOfServing,
          mealCategory: values.mealCategory.toString(),
          time: values.time,
          date: values.date
        };

        // values.food = { ...state?.foodData, serving: { ...foodDetailsData } };

        let mergedData = {
          ...payload,
          food: { ...state?.foodData, serving: [{ ...foodDetailsData }] }
        };

        mergedData.food.food_id = 0;
        mergedData.food.serving[0].serving_id = 0;

        dispatch(createNutritionEntryFatSecret({ data: mergedData, navigate: navigateFunction }));
      }
      if (state?.isEditProfile) {
        values.time = `${values.hour}:${values.minute}`;
        dispatch(createNutritionEntry({ data: values, navigate: navigateFunction }));
      }

      values.time = `${values.hour}:${values.minute}`;
      if (state?.isEdit) {
        dispatch(updateNutritionEntry({ data: values, navigate: navigateFunction }));
      } else {
        // values.food = { ...state?.foodData, serving: { ...foodDetailsData } };
        // dispatch(createNutritionEntry({ data: values, navigate: navigateFunction }));
      }
    }
  });

  useEffect(() => {
    dispatch(FetchServingFoodCombo(state?.foodData?.food_id || state?.foodData?.foodId));

    if (state?.nutritionData?.nutritionId) {
      dispatch(FetchMiNutritionListById(state?.nutritionData?.nutritionId));
    }
    // Mi Sports Profile
    if (state?.isEditProfile && state?.foodData?.food_id) {
      dispatch(FetchMiNutritionById(state?.foodData?.food_id));
    } else if (state?.isEditFatSecret && state?.foodData?.food_id) {
      dispatch(FetchMiDetailsById(state?.foodData?.food_id));
      dispatch(FetchServingFoodDetailsCombo(state?.foodData?.food_id));
    } else {
      dispatch(FetchMiNutritionById(state?.foodData?.foodId));
    }
  }, [
    dispatch,
    state?.nutritionData?.nutritionId,
    state?.foodData?.food_id,
    state?.foodData?.food_id,
    state?.food_id
  ]);

  const { localUserData } = useAppSelector((state) => state?.common);

  useEffect(() => {
    if (state?.isEditFatSecret) {
      setFoodDetailsData(FetchMiDetailsByIds?.serving[0]);
      setFieldValue('numberOfServing', FetchMiDetailsByIds?.numberOfServing);
      setFieldValue('foodDetailsId', FetchMiDetailsByIds?.serving_id);
      setFieldValue('mealCategory', FetchMiDetailsByIds?.mealCategory);
    } else if (state?.isEditProfile) {
      setFoodDetailsData(miNutritionMiSportsProfile?.serving[0]);
      setFieldValue('numberOfServing', miNutritionMiSportsProfile?.numberOfServing || null);
      setFieldValue('foodDetailsId', miNutritionMiSportsProfile?.foodDetailsId || null);
      setFieldValue('mealCategory', miNutritionMiSportsProfile?.mealCategory || null);
    } else {
      setFoodDetailsData(miNutritionMiSportsProfile?.serving[0]);
    }
  }, [state, FetchMiDetailsByIds, miNutritionMiSportsProfile]);


  return (
    <>
      <h3 className="page-heading">
        {state?.isEdit ? 'Mi Nutrition - Update Entry' : 'Mi Nutrition - Add Entry'}
      </h3>
      <div className="nutrition-entry-top competition-left-form mb-3">
        <Row className="align-items-end">
          <Col lg="6">
            <h4 className="nutrition-entry-heading mb-3">
              {`${
                state?.isThirdFood
                  ? FetchMiDetailsByIds?.food_name
                  : miNutritionMiSportsProfile?.food_name
              } - ${
                state?.isThirdFood
                  ? FetchMiDetailsByIds?.brand_name || ''
                  : miNutritionMiSportsProfile?.brand_name || ''
              }`}
            </h4>
            <div className="">
              <Form onSubmit={handleSubmit}>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleEmail" sm={4}>
                    Serving(s)
                  </Label>
                  <Col sm={8}>
                    <Row>
                      <Col xs="4">
                        <Input
                          name="numberOfServing"
                          type="number"
                          className="custom-input form-control"
                          placeholder="eg. 2"
                          value={values.numberOfServing}
                          onChange={handleChange}
                        />
                        {errors.numberOfServing && touched.numberOfServing && (
                          <ErrorMessage text={errors.numberOfServing} />
                        )}
                      </Col>
                      <Col xs="8">
                        <ReactSelect
                          className="react-select-dropdown"
                          options={
                            state?.isEditFatSecret
                              ? miServingFoodDetailsCombos
                              : miNutritionFoodDetailsCombo
                          }
                          value={
                            state?.isEditFatSecret
                              ? miServingFoodDetailsCombos?.find(
                                  (data: any) => data.label === values?.servingName
                                )
                              : miNutritionFoodDetailsCombo?.find(
                                  (data: any) => data.label === values?.servingName
                                )
                          }
                          placeholder="Select "
                          name="servingId"
                          onChange={(e: any) => {
                            setFieldValue('mealCategory', null);
                            setFieldValue('minute', null);
                            setFieldValue('hour', null);
                            setFieldValue('numberOfServing', '');
                            setFieldValue('servingId', e?.value);
                            setFieldValue('foodDetailsId', e?.value);
                            setFieldValue('servingName', e?.label);
                            const index = miServingFoodDetailsCombos?.findIndex(
                              (opt: any) => opt.value === e?.value
                            );
                            const index1 = miNutritionFoodDetailsCombo?.findIndex(
                              (opt: any) => opt.value === e?.value
                            );
                            state?.isEditProfile &&
                              setFoodDetailsData(miNutritionMiSportsProfile?.serving[index1]);
                            state?.isEditFatSecret &&
                              setFoodDetailsData(FetchMiDetailsByIds?.serving[index]);
                          }}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 50,
                              paddingLeft: 8,
                              boxShadow: null
                            })
                          }}
                        />
                        {errors.servingId && touched.servingId && (
                          <ErrorMessage text={errors.servingId} />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-center">
                  <Label for="exampleEmail" sm={4}>
                    Time Eaten
                  </Label>
                  <Col sm={8}>
                    <Row className="d-flex flex-wrap align-items-center">
                      <Col>
                        <ReactSelect
                          className="react-select-dropdown"
                          options={timeOption(24)}
                          placeholder="Select hour"
                          value={
                            timeOption(24)?.find((data: any) => data.label === values.hour) || null
                          }
                          name="hour"
                          onChange={(e: any) => {
                            setFieldValue('hour', e.label);
                          }}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 50,
                              paddingLeft: 8,
                              boxShadow: null
                            })
                          }}
                        />
                        {errors.hour && touched.hour && <ErrorMessage text={errors.hour} />}
                      </Col>{' '}
                      :
                      <Col>
                        <ReactSelect
                          className="react-select-dropdown"
                          options={timeOption(60)}
                          placeholder="Select minute"
                          name="minute"
                          value={
                            timeOption(60)?.find((data: any) => data.label === values.minute) ||
                            null
                          }
                          menuPortalTarget={document.body}
                          onChange={(e) => {
                            setFieldValue('minute', e?.value);
                          }}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (provided) => ({
                              ...provided,
                              borderRadius: 50,
                              paddingLeft: 8,
                              boxShadow: null
                            })
                          }}
                        />
                        {errors.minute && touched.minute && <ErrorMessage text={errors.minute} />}
                      </Col>
                    </Row>
                  </Col>
                </FormGroup>
                <FormGroup row className="d-flex flex-wrap align-items-top margin-bott-zero">
                  <Label for="exampleEmail" sm={4}>
                    Which Meal?
                  </Label>
                  <Col sm={8}>
                    <ReactSelect
                      className="react-select-dropdown"
                      options={mealOptions}
                      value={
                        mealOptions?.find((data: any) => data.value === values?.mealCategory) ||
                        null
                      }
                      placeholder="Select Meal"
                      name="mealCategory"
                      menuPortalTarget={document.body}
                      onChange={(e: any) => {
                        setFieldValue('mealCategory', e?.value);
                      }}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: 50,
                          paddingLeft: 8,
                          boxShadow: null
                        })
                      }}
                    />
                    {errors.mealCategory && touched.mealCategory && (
                      <ErrorMessage text={errors.mealCategory} />
                    )}
                  </Col>
                </FormGroup>
              </Form>
            </div>
          </Col>
          <Col
            lg="6"
            className="d-flex flex-wrap align-items-center justify-content-end nutrition-btn mt-3">
            <button className="add-entry me-3" type="submit" onClick={() => handleSubmit()}>
              {state?.isEdit ? 'Update Entry' : 'Add Entry'}
            </button>
            <Link
              to="/minutrition/editfood"
              state={{
                state_title: 'Mi Nutrition',
                foodData: state?.foodData,
                retrievalData: state
              }}>


              {FetchMiDetailsByIds?.userId ||
              (miNutritionMiSportsProfile?.userId === localUserData?.userId &&
                !state?.isThirdFood) ? (
                <button className="edit-food me-3">Edit Food</button>
              ) : null}

            </Link>
            <Link to="/minutrition">
              <button className="cancel">Cancel</button>
            </Link>
          </Col>
        </Row>
      </div>
      <div className="nutrition-entry-bottom">
        <Row>
          <Col lg="6" className="mb-3">
            <div className="nutrition-info">
              <div className="nutrition-info-header">
                <h5>Nutritional Info (Per Serving)</h5>
                <p>{foodDetailsData?.measurementDescription}</p>
              </div>
              <div className="nutrition-info-content">
                <ul>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Calories</p>
                    <span className="text-break">{foodDetailsData?.calories} kcal</span>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Protein</p>
                    <span className="text-break">{foodDetailsData?.protein} g</span>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Fat</p>
                    <span className="text-break">{foodDetailsData?.fat} g</span>
                    <ul>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Saturated Fat</p>
                        <span className="text-break">{foodDetailsData?.saturated_fat} g</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Polyunsaturated Fat</p>
                        <span className="text-break">{foodDetailsData?.polyunsaturated_fat} g</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Monounsaturated Fat</p>
                        <span className="text-break">{foodDetailsData?.monounsaturated_fat} g</span>
                      </li>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Trans Fat</p>
                        <span className="text-break">{foodDetailsData?.trans_fat} g</span>
                      </li>
                    </ul>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Carbohydrate</p>
                    <span className="text-break">{foodDetailsData?.carbohydrate} g</span>
                    <ul>
                      <li className="d-flex flex-wrap align-items-center">
                        <p className="text-break">Sugar</p>
                        <span className="text-break">{foodDetailsData?.sugar} g</span>
                      </li>
                    </ul>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Fiber</p>
                    <span className="text-break">{foodDetailsData?.fiber} g</span>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Cholesterol</p>
                    <span className="text-break">{foodDetailsData?.cholesterol} mg</span>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Potassium</p>
                    <span className="text-break">{foodDetailsData?.potassium} mg</span>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Sodium</p>
                    <span className="text-break">{foodDetailsData?.sodium} mg</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div className="nutrition-info mb-3">
              <div className="nutrition-info-header">
                <h5>Recommended Daily Intake</h5>
                <p>(based on 2000 cal diet)</p>
              </div>
              <div className="nutrition-info-content">
                <ul>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Vitamin A</p>
                    <span className="text-break">{foodDetailsData?.vitamin_a} kcal</span>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Vitamin C</p>
                    <span className="text-break">{foodDetailsData?.vitamin_c} kcal</span>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Calcium</p>
                    <span className="text-break">{foodDetailsData?.calcium} kcal</span>
                  </li>
                  <li className="d-flex flex-wrap align-items-center">
                    <p className="text-break">Iron</p>
                    <span className="text-break">{foodDetailsData?.iron} kcal</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="nutrition-info mb-3">
              <div className="nutrition-info-header">
                <h5 className="mb-0">Distribution</h5>
              </div>
              <div className="nutrition-info-content">
                <div className="d-flex flex-wrap justify-content-center mt-4 mb-4">
                  {/* <img src={distribution} /> */}
                  {foodDetailsData && (
                    <ReactApexChart
                      options={dataSet?.options}
                      series={dataSet?.series}
                      type="pie"
                      width={380}
                    />
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default MiNutritionAddanEntry;
