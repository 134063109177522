/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import WithoutLoginHeader from '../../../layouts/WithoutLoginHeader/withoutloginheader';
import Header from '../../../layouts/Header/header';

function AboutUs() {
  { document.title = 'Mi Sports Profile - About Us' }

  let auth = localStorage.getItem('token');
  return (
    <>
      {auth != 'true' ? <WithoutLoginHeader /> : <Header />}
      <div className="content-wrapper mb-5 mt-3 cms-page-wrapper">
        <div className="container">
          <Row className="mb-2">
            <Col md="12" className="content-right">
              <h3 className="page-heading">About Us</h3>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    Mi Sports Profile is a website designed to help yourself and each other in your
                    sporting goals.
                  </p>
                  <p>Mi Sports Profile allows you to:</p>
                  <ul>
                    <li>
                      Keep logs of your personal daily and weekly sporting activities, programs, and
                      nutrition.
                    </li>
                    <li>Helps you work toward fitness and nutrition goals.</li>
                    <li>Keeps you in touch with sporting friends as well as make new ones.</li>
                    <li>Gives you the opportunity to network with sporting groups and events.</li>
                    <li>
                      Encourages you to be positive and supportive with other sports enthusiasts.
                    </li>
                  </ul>
                  <p className="mb-3">
                    Mi Sports Profile is a great start for those beginning new sports. Experienced
                    sports people can help you every step of the way and will ensure you get the
                    support and advice if you need it. Please note this is not a social website - it
                    is only for people interested in connecting and networking with like minded
                    sporting people. Photos and comments must be relevant apart from those that
                    relate directly to a sporting event.
                  </p>
                  {!auth ? (
                    <p>
                      Still Not Sure? <Link to="/register">Register now</Link>
                    </p>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
