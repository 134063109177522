import React, { useEffect, useState } from 'react';
import search_ic from '/public/images/search-ic.svg';
import add_friends from '/public/images/addfriends.svg';
import defultprofile from '/public/images/defultprofile.svg';
import friend from '/public/images/friend.png';
import accept_friend from '/public/images/accept-friend.svg';
import { Col, Input, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import Delete from '../../../components/DeleteComponent/delete';
import no_friend from '/public/images/no-friend.svg';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  searchFriend,
  friendRequest,
  updateFriendStatus
} from '../../../store/Slices/userModule/MiFriends/mifriendsAction';
import RequestConfirmation from '../../../components/common/ConfirmModal';
import removeFriend from '/public/images/removeFriend.svg';

import RemoveConfirmation from '../../../components/common/ConfirmModal';
import { redirectUser_friend } from '../../../shared/utils';
import { Link } from 'react-router-dom';

function FindMoreFriends() {
  document.title = 'Mi Sports Profile - Find More Friends';

  const [isConformationdelete, setIsConformationdelete] = useState(false);
  const [isConformationremove, setIsConformationremove] = useState(false);
  const [AddFriendData, setAddFriendData] = useState();
  const [query, setQuery] = useState('');
  const [isAllDeleted, setAllDeleted] = useState<boolean>(false);
  const [isRemove, setRemoved] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const searchFriends: any = useAppSelector((state) => state.miFriends.searchFriends);
  const toggleConformationDelete = () => setIsConformationdelete(!isConformationdelete);
  const toggleConformationRemove = () => setIsConformationremove(!isConformationremove);

  const SearchFriend = () => {
    dispatch(searchFriend({ search: query }));
    // setQuery('');
  };

  const userAccount = (data: any) => {
    redirectUser_friend(data?.userId);
  };

  React.useEffect(() => {
    dispatch(searchFriend({ search: '' }));
  }, []);

  const action_button = (data: any) => {
    let renderButton;
    if (data.statusName === 'Pending' || data.statusName === 'Friend') {
      renderButton = (
        <button
          className="add-friend"
          onClick={() => {
            toggleConformationRemove();
            setRemoved(data);
          }}>
          <img src={removeFriend} />
        </button>
      );
    } else if (data.statusName === 'Blocked') {
      ('');
    } else if (data.friendRequest) {
      renderButton = (
        <button
          className="add-friend"
          onClick={() => {
            toggleConformationDelete();
            setAddFriendData(data);
          }}>
          <img src={add_friends} />
        </button>
      );
    } else {
      ('');
    }
    return <>{renderButton}</>;
  };

  return (
    <>
      <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between">
        <h4>Find More Friends...</h4>
        <div className="friend-search-right">
          <Link to="/nofriend">
            <button>Back to friends</button>
          </Link>
        </div>
      </div>
      <div className="friend-search d-flex flex-wrap align-items-center mb-3">
        <div className="friend-search-left position-relative">
          <Input
            type="text"
            placeholder="Search..."
            value={query}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                SearchFriend();
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
            }}
          />
          <span>
            <img src={search_ic} />
          </span>
        </div>
        <div className="friend-search-right">
          <button onClick={SearchFriend}>Search</button>
        </div>
      </div>
      <div className="friend-list d-flex flex-wrap">
        {searchFriends?.length ? (
          searchFriends?.map((data: any, index: number) => (
            <div className="friend-list-box" key={index}>
              <div className="friend-list-img position-relative mb-2">
                {data.statusName === 'Blocked' ? (
                  <div className="cursor-pointer">
                    {data.imageUrl ? (
                      <img
                        className="friend-profile-img"
                        src={process.env.CLIENT_PATH + data.imageUrl}
                        alt="Image Not Found"
                      />
                    ) : (
                      <img className="friend-profile-img" src={defultprofile} />
                    )}
                  </div>
                ) : (
                  <div onClick={() => userAccount(data)} className="cursor-pointer">
                    {data.imageUrl ? (
                      <img
                        className="friend-profile-img"
                        src={process.env.CLIENT_PATH + data.imageUrl}
                        alt="Image Not Found"
                      />
                    ) : (
                      <img className="friend-profile-img" src={defultprofile} />
                    )}
                  </div>
                )}

                {/* <div onClick={() => userAccount(data)}>
                  {data.imageUrl ? (
                    <img
                      className="friend-profile-img cursor-pointer"
                      src={process.env.CLIENT_PATH + data.imageUrl}
                      alt="Image Not Found"
                    />
                  ) : (
                    <img className="friend-profile-img cursor-pointer" src={defultprofile} />
                  )} */}

                {/* {data.imageUrl == null ? (
                    <img
                      src={process.env.CLIENT_PATH + data.imageUrl}
                      className="friend-profile-img cursor-pointer"
                      alt='Image Not Found'
                    />

                  ) : (
                    <img
                      src={defultprofile}
                      className="friend-profile-img cursor-pointer"
                    />
                  )} */}
                {/* </div> */}
                {action_button(data)}
              </div>
              <div className="row">
                <div className="mb-1 col-md-7">
                  <p className="text-break">{data.name}</p>
                </div>
                <div className="text-end mb-1 col-md-5">
                  <span className="text-break">
                    {data.genderName === '0' ? '' : data.genderName}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="mb-2 col-md-6">
                  <label className="text-break">{data.location}</label>
                </div>
                <div className="text-end mb-2 col-md-6">
                  <span className={'text-break-' + data.statusName.toLowerCase()}>
                    {data.statusName === '0' ? '' : data.statusName}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="m-auto">
              <div className="d-flex flex-wrap justify-content-center no-friend-found">
                <p className="mt-4 w-100 text-center mb-3">No profile found !!</p>
              </div>
              <div className="d-flex flex-wrap justify-content-center mt-5 mb-4">
                <img className="pe-none" src={no_friend} />
              </div>
            </div>
          </>
        )}
      </div>

      <RequestConfirmation
        header={isAllDeleted ? '' : 'Add Friend'}
        message={isAllDeleted ? '' : 'Are you sure you want to add friend?'}
        modal={isConformationdelete}
        toggle={() => {
          toggleConformationDelete();
          setAllDeleted(false);
        }}
        confirmDeleteClick={() => {
          const payload = {
            userId: (AddFriendData as any)?.userId,
            userRelationShipId: 0,
            status: 1,
            isRemove: true
          };
          dispatch(friendRequest(payload));
          // window.location.reload();
          toggleConformationDelete();
        }}
        confirmNoClickClick={() => toggleConformationDelete()}
      />

      <RemoveConfirmation
        header={isRemove && 'Cancel friend request'}
        message={isRemove && 'Are you sure you want to cancel sent friend request?'}
        modal={isConformationremove}
        toggle={() => {
          toggleConformationRemove();
          setRemoved(false);
        }}
        confirmDeleteClick={() => {
          const FindFriend = 1;
          const payload = {
            userId: (isRemove as any)?.userId,
            userRelationShipId: (isRemove as any)?.userRelationShipId,
            status: 3,
            isRemove: true,
            flag: FindFriend
          };
          dispatch(updateFriendStatus(payload));
          toggleConformationRemove();
        }}
        confirmNoClickClick={() => toggleConformationRemove()}
      />
    </>
  );
}

export default FindMoreFriends;
