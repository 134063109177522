import * as React from 'react';
import headerlogo from '/public/images/mi-header-logo.svg';
import { NavLink } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap';
import Logout from '../Header/Logout';

function WithoutLoginHeader() {
  const [isOpen, setIsOpen] = React.useState(false);
  let auth = localStorage.getItem('token');
  const links = [
    { href: '/login', text: 'Login' },
    { href: '/register', text: 'Register' }
  ];
  const link = [
    { href: '/home', text: 'Mi Profile' },
    { href: '/nofriend', text: 'Friends' },
    { href: '/editprofile', text: 'Account' }
  ];
  const toggle = () => setIsOpen(!isOpen);
  const [islogout, setLogout] = React.useState(false);
  const toggleLogout = () => setLogout(!islogout);
  const closeMenu = () => {
    // ... and this one only
    setIsOpen(false); // closes it ...
  };

  return (
    <>
      <div className="header-wrapper py-4">
        <div className="container">
          <div className="header-inner d-flex flex-wrap align-items-center justify-content-between">
            <div>
              <a href="/dashboard">
                <img src={headerlogo} />
              </a>
            </div>
            <div className="header-nav">
              <Navbar light expand="md">
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    {!auth ? (
                      links?.map((link, index) => {
                        return (
                          <NavLink key={index} to={link.href} onClick={closeMenu}>
                            {link.text}
                          </NavLink>
                        );
                      })
                    ) : (
                      <>
                        {link?.map((link, index) => {
                          return (
                            <NavLink key={index} to={link.href} onClick={closeMenu}>
                              {link.text}
                            </NavLink>
                          );
                        })}
                        <p onClick={() => toggleLogout()}>Logout</p>
                      </>
                    )}
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
          </div>
        </div>
        <Logout
          modal={islogout}
          toggle={() => {
            toggleLogout();
          }}
        />
      </div>
    </>
  );
}
export default WithoutLoginHeader;
