import dataReducer from './Slices/userModule/DashboardSlice/dashboardSlice';
import commonReducer from './Slices/CommonSlice/commonSlice.';
import AuthModuleSlice from './Slices/AuthModule/AuthModuleSlice';
import miRecordSlice from './Slices/userModule/MiRecordSlice/miRecordSlice';
import MITrainingSlice from './Slices/userModule/MiTraining/MITrainingSlice';
import competitionSlice from './Slices/userModule/CompetitionSlice/competitionSlice';
import dashboardSlice from './Slices/userModule/DashboardSlice/dashboardSlice';
import miProfileSlice from './Slices/userModule/MiProfileSlice/miProfileSlice';
import albumSlice from './Slices/userModule/AlbumSlice/albumSlice';
import miNutritionSlice from './Slices/userModule/NutritionSlice/miNutritionSlice';
import conversationSlice from './Slices/userModule/ConversationSlice/conversationSlice';
import miFriendsSlice from './Slices/userModule/MiFriends/mifriendSlice';

export const rootReducers = {
  data: dataReducer,
  common: commonReducer,
  auth: AuthModuleSlice,
  miRecords: miRecordSlice,
  miCompetition: competitionSlice,
  miTraining: MITrainingSlice,
  miConversation: conversationSlice,
  miDashboard: dashboardSlice,
  miProfile: miProfileSlice,
  miNutrition: miNutritionSlice,
  miAlbum: albumSlice,
  miFriends: miFriendsSlice
};
