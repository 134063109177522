import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function CommonConfirmModal(props: any) {
  const { message, header, modal, toggle, confirmDeleteClick, confirmNoClickClick, isEdit } = props;
  return (
    <Modal isOpen={modal} toggle={toggle} className="popup-wrapper delete-popup" centered size="sm">
      <ModalHeader toggle={toggle} className="p-0">
        {header}
      </ModalHeader>
      <ModalBody className="text-center">{message}</ModalBody>
      <ModalFooter className="justify-content-center mt-3">
        {/* <Button className="cancel-btn me-3" onClick={() => confirmNoClickClick('Current', 'Yes')}></Button> */}

        <Button className="cancel-btn me-3" onClick={confirmNoClickClick}>
          {isEdit ? 'Current' : 'No'}
        </Button>
        <Button className="save-record" onClick={confirmDeleteClick}>
          {isEdit ? ' All Upcoming' : 'Yes'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CommonConfirmModal;
