import React, { useEffect, useRef, useState } from 'react';
// import clientimg from '/public/images/client-img.png';
import friend_clientimg from '/public/images/mask_group.png';
import profile_placeholder from '/public/images/profile_placeholder.png';
import genderic from '/public/images/gender-icon.svg';
import marrage_ic from '/public/images/merrage-status-icon.svg';
import location_ic from '/public/images/location-icon.svg';
import birthday_ic from '/public/images/birthdate-icon.svg';
import time_ic from '/public/images/time-icon.svg';
import profession_ic from '/public/images/profession-icon.svg';
import back_arrow from '/public/images/back-arrow.svg';
import { fetchMiProfileDetail } from '../../store/Slices/userModule/MiProfileSlice/miProfileAction';

import { Col, Row } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import dayjs from 'dayjs';

function HeaderBottom() {
  const dispatch = useAppDispatch();

  const { localUserData } = useAppSelector((state) => state.common);

  const { FetchMiProfileDetail, miProfileRelationCombo } = useAppSelector(
    (state) => state.miProfile
  );
  const userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    userData && dispatch(fetchMiProfileDetail(localUserData?.userId || 0));
  }, [dispatch]);

  const genders = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Others', value: 3 }
  ];

  const genderdata = (FetchMiProfileDetail as any)?.gender;
  const gender = genders.find((data) => data.value === genderdata);

  const date = (FetchMiProfileDetail as any)?.dob;

  const timeZoneData = (FetchMiProfileDetail as any)?.timezone;
  const { timeZone } = useAppSelector((state) => state.common);
  const timeZonedispaly = timeZone.find((data) => data.value === timeZoneData);

  const relationShip = (FetchMiProfileDetail as any)?.relationShip;
  const relationShips = miProfileRelationCombo?.find((data) => data.value === relationShip);

  const miprofiles = (FetchMiProfileDetail as any)?.imageUrl;

  const navigate = useNavigate();

  const [showButton, setShowButton] = useState('false');
  const [isFriend, setIsFriend] = React.useState('false');

  const BacktoProfile = () => {
    localStorage.removeItem('friend');
    sessionStorage.removeItem('userData');
    sessionStorage.clear();
    navigate('/home');
  };

  const location = useLocation();
  React.useLayoutEffect(() => {
    setShowButton(localStorage.getItem('friend'));
    setIsFriend(localStorage.getItem('friend'));
  }, [location]);

  return (
    <>
      <div className="header-bottom py-3 mb-3">
        <div className="container">
          <div className="header-bottom-inner d-flex flex-wrap align-items-center">
            <div className="header-client-img d-flex flex-wrap align-items-center justify-content-center position-relative">
              {isFriend == 'true' ? (
                <img src={friend_clientimg} />
              ) : miprofiles ? (
                <img src={process.env.CLIENT_PATH + miprofiles} />
              ) : (
                <img src={profile_placeholder} />
              )}
            </div>
            <div className="header-side-content">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                {isFriend == 'true' ? (
                  <h1 className="mb-3">Katie</h1>
                ) : (
                  <h1 className="mb-3">{(FetchMiProfileDetail as any)?.displayName}</h1>
                )}
                {showButton == 'true' && (
                  <>
                    <button onClick={BacktoProfile} className="back-profile mb-3">
                      <img src={back_arrow} className="me-2" />
                      Back to Profile
                    </button>
                  </>
                )}
              </div>

              <Row className="mb-2">
                <Col>
                  <div className="d-flex flex-wrap align-items-center">
                    {gender?.label && <img src={genderic} />}
                    {isFriend == 'true' ? <span>FeMale</span> : <p>{gender?.label}</p>}
                  </div>
                </Col>
                <Col>
                  <div className="d-flex flex-wrap align-items-center">
                    {relationShips?.label && <img src={marrage_ic} />}
                    {isFriend == 'true' ? <p>Single</p> : <p> {relationShips?.label}</p>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="d-flex flex-wrap align-items-center">
                    {(FetchMiProfileDetail as any)?.location && <img src={location_ic} />}
                    {isFriend == 'true' ? (
                      <p>3409 E 21st Ave, Tampa, Florida - 33605</p>
                    ) : (
                      <p> {(FetchMiProfileDetail as any)?.location}</p>
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="header-bottom-content">
                <Col>
                  <div className="d-flex flex-wrap align-items-center">
                    {timeZonedispaly?.label && <img src={time_ic} />}

                    {isFriend == 'true' ? (
                      <p>Newfoundland (GMT-3:30)</p>
                    ) : (
                      <p> {timeZonedispaly?.label}</p>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="d-flex flex-wrap align-items-center">
                    {date && <img src={birthday_ic} />}

                    {isFriend == 'true' ? (
                      <p>15-07-2023</p>
                    ) : (
                      <p> {date && dayjs(date).format('MM/DD/YYYY')}</p>
                    )}
                  </div>
                </Col>

                <Col md="6">
                  <div className="d-flex flex-wrap align-items-center">
                    {(FetchMiProfileDetail as any)?.occupation && <img src={profession_ic} />}
                    {isFriend == 'true' ? (
                      <p>Professor at Law College Of Canada</p>
                    ) : (
                      <p> {(FetchMiProfileDetail as any)?.occupation}</p>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderBottom;
