import { createSlice } from '@reduxjs/toolkit';
import { getMiConversation, getWhatGoingOn } from './conversationActions';

// import { CommentInitialVal, SingleCompetitionInterface } from '../../../../types';

interface DataState {
  getMiConversation: any[];
  getWhatGoingOn: any[];
  myConversationReply: any[];
  isLoading: boolean;
  error: string | null;
}

const initialState: DataState = {
  getMiConversation: [],
  getWhatGoingOn: [],
  myConversationReply: [],
  isLoading: false,
  error: null
};

const conversationSlice = createSlice({
  name: 'competition',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMiConversation.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMiConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getMiConversation = action.payload;
      })
      .addCase(getMiConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      })
      .addCase(getWhatGoingOn.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getWhatGoingOn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getWhatGoingOn = action.payload;
      })
      .addCase(getWhatGoingOn.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'error';
      });
  }
});

export default conversationSlice.reducer;
