/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import WithoutLoginHeader from '../../../layouts/WithoutLoginHeader/withoutloginheader';
import Header from '../../../layouts/Header/header';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function MiPrivacyPolicy() {
  {
    document.title = 'Mi Sports Profile - Privacy Policy';
  }
  let auth = localStorage.getItem('token');
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <>
      {auth != 'true' ? <WithoutLoginHeader /> : <Header />}
      <div className="content-wrapper mb-5 mt-3 cms-page-wrapper">
        <div className="container">
          <Row className="mb-2">
            <Col md="12" className="content-right">
              <h3 className="page-heading">
                Mi Sports Profile Terms & Conditions and Privacy Policy
              </h3>

              <div className="mi-training-wrapper cms-page">
                <h5>Effective Date: December 10, 2018</h5>
                <div className="mi-training-box mb-3">
                  <p>
                    These Terms and Conditions of Use are the rules of the game - designed to create
                    a positive, constructive and law-abiding community of our users. By using any
                    “Mi Sports Profile” products and services, you are agreeing to all the terms
                    below.
                  </p>
                </div>
              </div>
              <div className="mi-training-box mb-3">
                <p>
                  These terms include a binding arbitration clause and a class action waiver in
                  section 15. This provision affects your rights to resolve disputes with Mi Sports
                  Profile and you should review it carefully. Your choice to maintain an account,
                  access or use the services constitutes your agreement to these terms and our
                  privacy policy, which is incorporated into the terms. If you disagree with any
                  part of the terms, then you are not permitted to use our services.
                </p>
              </div>
              <div className="mi-training-box mb-3">
                <h3>1. Use of Services and Your Account</h3>
              </div>

              <div className="mi-training-box mb-3">
                <h5>1.1 Who can use Mi Sports Profile</h5>
              </div>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    Mi Sports Profile welcomes users of at least 13 to use most of our Services.
                  </p>
                </div>
              </div>

              <div className="mi-training-box mb-3">
                <h5>1.2 Your Account</h5>
              </div>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    You will need to create a Mi Sports Profile account to access the Services, and
                    it's important that the information associated with your account is accurate and
                    up-to-date (particularly your email address - if you ever forget your password,
                    a working email address is often the only way for us to verify your identity and
                    help you log back in).
                  </p>
                </div>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  When you create an account for any of our Services, you must provide us with
                  accurate and complete information as prompted by the account creation and
                  registration process and keep that information up to date. Otherwise, some of our
                  Services may not operate correctly, and we may not be able to contact you with
                  important notices.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  You are responsible for maintaining the confidentiality of any and all actions
                  that take place while using your account, and must notify us right away of any
                  actual or suspected loss, theft, or unauthorized use of your account or account
                  password. We are not responsible for any loss that results from unauthorized use
                  of your username and password.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <h5>1.3 Service Updates, Changes and Limitations</h5>
              </div>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    Our Services are constantly evolving. With the launch of new products, services,
                    and features, we need the flexibility to make changes, impose limits, and
                    occasionally suspend or terminate certain Services. We may also update our
                    Services, which might not work properly if you don't install the updates.
                  </p>
                </div>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  The Services change frequently, and their form and functionality may change
                  without prior notice to you.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  We may provide updates (including automatic updates) for certain Services as and
                  when we see fit. This may include upgrades, modifications, bug fixes, patches and
                  other error corrections and/or new features (collectively, <b>"Updates"</b>).
                  Certain portions of our Services may not properly operate if you do not install
                  all Updates. You acknowledge and agree that the Service may not work properly if
                  you do not allow such Updates and you expressly consent to automatic Updates.
                  Further, you agree that the Terms (and any additional modifications of the same)
                  will apply to any and all Updates to the Services. We may change, suspend, or
                  discontinue any or all of the Services at any time, including the availability of
                  any product, feature, database, or Content. In addition, we have no obligation to
                  provide any Updates or to continue to provide or enable any particular features or
                  functionality of any Service. We may also impose limits on certain Services or
                  restrict your access to part or all of the Services without notice or liability.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <h5>1.4 Service Monitoring and Suspension</h5>
              </div>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    We reserve the right to refuse to provide the Services to anyone, and can
                    monitor, terminate or suspend your account or access to the Services at any
                    time.
                  </p>
                </div>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  We reserve the right, but have no obligation, to monitor any accounts and/or
                  activities conducted through or in any way related to the Services (including
                  inviting a fellow user into a community or group), as well as any user's use of or
                  access to Personal Data, and profiles of other users.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  We may also deactivate, terminate or suspend your account or access to certain
                  Services at any time: (1) if we, in our sole discretion, determine you are or have
                  been in violation of these Terms or the spirit thereof (as highlighted in our
                  Community Guidelines), (2) if we, in our sole discretion, determine you have
                  created risk or possible legal exposure for Mi Sports Profile, the general public,
                  any third party, or any user of our Services, (3) in response to requests by law
                  enforcement or other government agencies, (4) upon discontinuation or material
                  modification of any Services, or (5) due to unexpected technical issues or
                  problems. We will endeavour to notify you by email or the next time you attempt to
                  access your account after any such deactivation, termination or suspension.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <h5>1.5 Security</h5>
              </div>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    Please let us know right away if you believe your account has been hacked or
                    compromised.
                  </p>
                </div>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  We care about the security of our users. While we work hard to protect the
                  security of your Personal Data, User-Generated Content, and account, we cannot
                  guarantee that unauthorized third parties will not be able to defeat our security
                  measures. Please notify our Support Team immediately of any actual or suspected
                  breach or unauthorized access or use of your account.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <h3>2. Ownership and Use of Content</h3>
              </div>

              <div className="mi-training-box mb-3">
                <h5>2.1 Definitions</h5>
              </div>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    Content is what shows up on your display when you use our Services.
                    User-Generated Content is any Content that is created by you or other users, and
                    Mi Sports Profile Content is all other Content.
                  </p>
                </div>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  For purposes of these Terms, (i) <b>"Content"</b> means any form of information,
                  data or creative expression and includes, without limitation, video, audio,
                  photographs, images, illustrations, animations, tools, text, ideas,
                  communications, replies, "likes," comments, software, scripts, executable files,
                  graphics, maps, routes, geo-data, workouts and workout data, biometric data and
                  data elements derived therefrom, training plans, sleep activity, annotations,
                  nutrition information, recipes, interactive features, designs, copyrights,
                  trademarks, service marks, branding, logos, and other similar assets, patents,
                  sounds, applications and any intellectual property therein, any of which may be
                  generated, provided, or otherwise made accessible on or through the Services; (ii){' '}
                  <b>"User-Generated Content"</b> means any Content that a user submits, transfers,
                  or otherwise provides to or through the use of the Services; and (iii){' '}
                  <b>"Mi Sports Profile"</b> means all Content that is not UserGenerated Content.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <h5>2.2 Ownership</h5>
              </div>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>You own the Content that you create, and we own the Content that we create.</p>
                </div>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  All Mi Sports Profile Content and all copyright, trademarks, design rights,
                  patents and other intellectual property rights (registered and unregistered) in
                  and on the Services belong to Mi Sports Profile and/or its partners or applicable
                  third parties. Each user retains ownership, responsibility for, and/or other
                  applicable rights in the User-Generated Content that he/she creates but grants a
                  license of that User Generated Content to Mi Sports Profile. Mi Sports Profile
                  and/or its partners or third parties retain ownership, responsibility for and/or
                  other applicable rights in all Mi Sports Profile Content. Except as expressly
                  provided in the Terms, nothing grants you a right or license to use any Mi Sports
                  Profile Content, including any content owned or controlled by any of our partners
                  or other third parties. You agree not to duplicate, publish, display, distribute,
                  modify, or create derivative works from the material presented through the
                  Services unless specifically authorized in writing by Mi Sports Profile.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <h5>2.3 Our License to You</h5>
              </div>
              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    You are welcome to access and use the Mi Sports Profile Content and Services. We
                    work hard to provide a great experience for our users, so please respect our
                    intellectual property rights and only use the Mi Sports Profile Content and
                    Services as intended. This includes not using any Mi Sports Profile Content or
                    Services for commercial purposes without our permission. We do have APIs and
                    other tools you can use to help create your own apps and products. Contact us to
                    find out more.
                  </p>
                </div>
              </div>

              <div className="mi-training-box mb-3">
                <p>
                  Subject to your compliance with these Terms, we grant you a limited, revocable,
                  personal, non-transferable, and non-exclusive right and license to access and use
                  the Services and Mi Sports Profile Content for your own personal, non-commercial
                  purposes, provided that you do not (and do not allow any third party to) copy,
                  modify, create a derivative work from, reverse engineer, sell, assign, sublicense,
                  grant a security interest in, transfer or otherwise commercially exploit any right
                  in the Mi Sports Profile Content or Services.
                </p>
              </div>

              <div className="mi-training-box mb-3">
                <h3>3. Delete Account</h3>
              </div>

              <div className="mi-training-wrapper cms-page">
                <div className="mi-training-box mb-3">
                  <p>
                    You are entitled to access the personal data we hold about you and to have them
                    rectified where necessary. Whenever you decide to delete your account and all
                    related personal information or get an extract from what we keep in our database
                    about you, please send us an email to{' '}
                    <Link to="mailto:info@misportsprofile.com">info@misportsprofile.com</Link>. We
                    will reply within 1 month.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default MiPrivacyPolicy;
