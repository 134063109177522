import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false
  };

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    // Handle the error (e.g., log it or display a fallback UI)
    console.error('Error occurred:', error);
    console.error('Error info:', info.componentStack);

    // Set the state to indicate that an error occurred
    this.setState({ hasError: true });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // Render a custom fallback UI when an error occurs
      return <div>There is an error occured in the application...</div>;
    }

    // Otherwise, render the children as usual
    return this.props.children;
  }
}

export default ErrorBoundary;
