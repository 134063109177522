import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchMiTraining } from '../../../store/Slices/userModule/MiTraining/MITrainingSlice';
import dayjs from 'dayjs';
import { ITrainingData } from '../../../types';
import { ILocalUserData } from '../../../types/common';
function MiFriend() {
  document.title = 'Mi Sports Profile - Mi Training'
  const dispatch = useAppDispatch();
  const [isFriend, setIsFriend] = React.useState('false');
  const location = useLocation();

  const miTrainingDetail: ITrainingData = useAppSelector((state) => state?.miTraining?.miTraining);

  useEffect(() => {
    const userDataString = sessionStorage.getItem('userData');
    const userData = JSON.parse(userDataString);
    dispatch(fetchMiTraining(userData?.userId));
  }, [dispatch]);

  React.useLayoutEffect(() => {
    setIsFriend(localStorage.getItem('friend'));
  }, [location]);

  return (
    <>
      <div className="page-heading-button d-flex flex-wrap align-items-center justify-content-between">
        <h4>Mi Training</h4>
        {isFriend == 'true' ? (
          ''
        ) : (
          <Link to="/mitraining/editprofiletraining" state={{ state_title: 'Mi Training' }}>
            <button>Edit My Training</button>
          </Link>
        )}
      </div>
      <div className="mi-training-wrapper">
        <h5>About My Training</h5>
        <div className="mi-training-box mb-3">
          <Row className="mb-2">
            <Col md="3">
              <label>{`Why I'm Training:`}</label>
            </Col>
            <Col>
              <p>{miTrainingDetail?.whyStarted}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col md="3">
              <label>How I started:</label>
            </Col>
            <Col>
              <p>{miTrainingDetail?.howStarted}</p>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <label>When I started:</label>
            </Col>
            <Col>
              <p>
                {!miTrainingDetail?.whyStarted
                  ? null
                  : dayjs(miTrainingDetail?.whenStarted).format('D MMMM YYYY - h:mma')}
              </p>
            </Col>
          </Row>
        </div>
        <h5>Goals</h5>
        <div className="mi-training-box mb-3">
          <Row className="mb-2">
            <Col md="3">
              <label>Long Term:</label>
            </Col>
            <Col>
              <p>{miTrainingDetail?.longTermGoals}</p>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <label>Short Term:</label>
            </Col>
            <Col>
              <p>{miTrainingDetail?.shortTermGoals}</p>
            </Col>
          </Row>
        </div>
        <h5>Strengths</h5>
        <div className="mi-training-box mb-3">
          <p>{miTrainingDetail?.myStrengths ?? <br />}</p>
        </div>
        <h5>Weaknesses</h5>
        <div className="mi-training-box mb-3">
          <p>{miTrainingDetail?.myWeakness ?? <br />}</p>
        </div>
        <h5>Inspirations</h5>
        <div className="mi-training-box mb-3">
          <p>{miTrainingDetail?.myInspiration ?? <br />}</p>
        </div>
      </div>
    </>
  );
}

export default MiFriend;
