/* eslint-disable prettier/prettier */
import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import map_contact from '/public/images/map-contact.png';
import WithoutLoginHeader from '../../../layouts/WithoutLoginHeader/withoutloginheader';
import Header from '../../../layouts/Header/header';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import ErrorComponent from '../../../components/ErrorMessage/error';
import { useAppDispatch } from '../../../store/hooks';
import { AddFeedback } from '../../../store/Slices/userModule/MiProfileSlice/miProfileAction';

interface ContactFormValues {
  id: 0;
  email: string;
  name: string;
  comment: string;
}

const ContactSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email')
    .required('Please enter email')
    .trim()
    .strict(true),
  name: Yup.string()
    .required('Please enter username')
    // .min(5, 'Username must be greater than 5 character')
    .max(15, 'Username must be less than 15 character')
  // comment: Yup.string()
  //   .required('Please enter message')
  //   .min(5, 'Username must be greater than 5 character')
  //   .max(15, 'Username must be less than 15 character')
});

const initialValues: ContactFormValues = {
  id: 0,
  email: '',
  name: '',
  comment: ''
};

function ContactUs() {
  {
    document.title = 'Mi Sports Profile - Contact Us';
  }
  const dispatch = useAppDispatch();
  let auth = localStorage.getItem('token');
  return (
    <>
      {auth != 'true' ? <WithoutLoginHeader /> : <Header />}
      <div className="content-wrapper mb-5 mt-3 cms-page-wrapper">
        <div className="container">
          <Row className="mb-2">
            <Col md="12" className="content-right">
              <h3 className="page-heading">Contact Us</h3>
              <div className="mi-training-wrapper cms-page">
                <h5>Welcome to our Contact Us page!</h5>

                <div className="mi-training-box mb-3">
                  <ul>
                    <li className="mb-3">We're glad you're here and eager to hear from you.</li>
                    <li className="mb-3">
                      Whether you have a question, comment, or just want to say hello, we're always
                      happy to hear from our visitors. So please feel free to get in touch with us
                      using any of the methods listed below:
                    </li>
                    <li className="mb-3">
                      Email: If you prefer to communicate via email, you can reach us at our branch.
                    </li>
                    <li className="mb-3">
                      Phone: If you need to speak with someone over the phone, you can call us.
                    </li>
                    <li className="mb-3">
                      We strive to respond to all inquiries as quickly as possible. If you don't
                      hear back from us within 24 hours, please try again, as we may not have
                      received your message.
                    </li>
                    <li className="mb-3">
                      Thank you for considering contacting us. We look forward to hearing from you
                      and helping in any way we can.
                    </li>
                  </ul>
                </div>
                <Row>
                  <Col md="6">
                    <div className="mi-training-box mb-3 get-touch">
                      <h5 className="mb-3">Get In Touch</h5>
                      <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={ContactSchema}
                        onSubmit={(values, actions) => {
                          dispatch(AddFeedback(values));
                          setTimeout(() => {
                            actions.resetForm();
                          }, 3000);
                        }}>
                        {({ errors, touched, values, handleChange }) => (
                          <Form>
                            <FormGroup row className="d-flex flex-wrap align-items-center">
                              <Label className="mb-0" sm={3}>
                                Name
                              </Label>
                              <Col sm={9}>
                                <Input
                                  name="name"
                                  type="text"
                                  value={values.name}
                                  onChange={handleChange}
                                  placeholder="Enter Name"
                                />
                                {errors.name && touched.name && (
                                  <ErrorComponent text={errors.name} />
                                )}
                              </Col>
                            </FormGroup>
                            <FormGroup row className="d-flex flex-wrap align-items-center">
                              <Label className="mb-0" sm={3}>
                                Email
                              </Label>
                              <Col sm={9}>
                                <Input
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="Enter Email"
                                />
                                {errors.email && touched.email && (
                                  <ErrorComponent text={errors.email} />
                                )}
                              </Col>
                            </FormGroup>
                            <FormGroup row className="d-flex flex-wrap align-items-top">
                              <Label className="mb-0" sm={3}>
                                Message
                              </Label>
                              <Col sm={9}>
                                <Input
                                  name="comment"
                                  value={values.comment}
                                  onChange={handleChange}
                                  type="textarea"
                                  placeholder="Write your comment here..."
                                />
                                {errors.comment && touched.comment && (
                                  <ErrorComponent text={errors.comment} />
                                )}
                              </Col>
                            </FormGroup>
                            <div className="contact-btn d-flex flex-wrap justify-content-end">
                              <button>Send Message</button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mi-training-box mb-3">
                      <Row>
                        <Col lg="5">
                          {/* <div className="map-side-box phone">
                            <h5>Phone</h5>
                            <a href="tel:+11234567890" className="d-block">
                              +1 1234567890
                            </a>
                            <a href="tel:+11234567890">+1 1234567890</a>
                          </div> */}
                          <div className="map-side-box mail">
                            <h5>Email</h5>
                            <a href="mailto:info@misportsprofile.com" className="d-block">
                              info@misportsprofile.com
                            </a>
                          </div>
                          {/* <div className="map-side-box location">
                            <h5>Location</h5>
                            <p>12599 Grafton Rd, Carleton, Michigan - 48117</p>
                          </div> */}
                        </Col>
                        <Col lg="7">{/* <img src={map_contact} /> */}</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
