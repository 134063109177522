import React, { useEffect, useLayoutEffect, useState } from 'react';
import prev_date from '/public/images/prev-date.svg';
import next_date from '/public/images/next-date.svg';
import plus_ic from '/public/images/add.svg';
import no_nutrition from '/public/images/no-nutrition.png';
import edit_ic from '/public/images/edit.svg';
import delete_ic from '/public/images/delete.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Table } from 'reactstrap';
import Delete from '../../../components/DeleteComponent/delete';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  FetchMiNutritionListByDate,
  FetchMiNutritionListById,
  FetchMiNutritionSummaryByDate,
  deleteNutritionFoodEntry
} from '../../../store/Slices/userModule/NutritionSlice/miNutritionAction';
import BreakdownModal from './BreakDownModal';
import { INutritionList, INutritionSummary } from '../../../types/miNutrition';
import CommonDelete from '../../../components/DeleteComponent/commonDelete';
dayjs.extend(duration);
type ToggleFunction = () => void;

function MiNutrition() {
  document.title = 'Mi Sports Profile - Mi Nutrition';
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [dateState, setDateState] = useState<Dayjs>(dayjs());
  const [getFoodNutritionId, setFoodNutritionId] = useState<number>(0);
  const dispatch = useAppDispatch();
  const miNutritionList: any = useAppSelector((state) => state.miNutrition.miNutritionList);
  const miNutritionSummary: INutritionSummary = useAppSelector(
    (state) => state.miNutrition.miNutritionSummary
  );

  const toggle: ToggleFunction = () => setModal(!modal);

  const [isdelete, setIsdelete] = useState(false);
  const toggleDelete = () => setIsdelete(!isdelete);

  useLayoutEffect(() => {
    const selectedDate = sessionStorage.getItem('selectedDate');
    if (selectedDate) {
      setDateState(dayjs(selectedDate));
    }
  }, []);

  useEffect(() => {
    const selectedDate = sessionStorage.getItem('selectedDate');
    if (selectedDate) {
      dispatch(FetchMiNutritionSummaryByDate(dayjs(selectedDate).format('YYYY-MM-DD')));
      dispatch(FetchMiNutritionListByDate(dayjs(selectedDate).format('YYYY-MM-DD')));
    } else {
      dispatch(FetchMiNutritionSummaryByDate(dayjs(dateState).format('YYYY-MM-DD')));
      dispatch(FetchMiNutritionListByDate(dayjs(dateState).format('YYYY-MM-DD')));
    }
  }, [dispatch]);

  return (
    <>
      <div className="d-flex flex-wrap align-items-center justify-content-between page-heading">
        <h3>Mi Nutrition</h3>

        <div className="add-an-entry-btn">
          <button
            onClick={() => {
              sessionStorage.setItem('selectedDate', dayjs(dateState).format('YYYY-MM-DD'));
              navigate('minutritionsearchfood');
            }}>
            <img src={plus_ic} />
            Add an Entry
          </button>
        </div>
      </div>

      <div className="nutrition-header d-flex flex-wrap align-items-center justify-content-between mb-3">
        <div
          className="pointer"
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            const newDate = dayjs(dateState).add(dayjs.duration({ days: -1 }));
            setDateState(newDate);
            sessionStorage.setItem('selectedDate', dayjs(newDate).format('YYYY-MM-DD'));
            dispatch(FetchMiNutritionSummaryByDate(dayjs(newDate).format('YYYY-MM-DD')));
            dispatch(FetchMiNutritionListByDate(dayjs(newDate).format('YYYY-MM-DD')));
          }}>
          <img src={prev_date} />
        </div>
        <div>
          <p>{dayjs(dateState).format('D, MMMM YYYY')}</p>
        </div>
        <div
          className="pointer"
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            const newDate = dayjs(dateState).add(dayjs.duration({ days: 1 }));
            setDateState(newDate);
            sessionStorage.setItem('selectedDate', dayjs(newDate).format('YYYY-MM-DD'));
            dispatch(FetchMiNutritionSummaryByDate(dayjs(newDate).format('YYYY-MM-DD')));
            dispatch(FetchMiNutritionListByDate(dayjs(newDate).format('YYYY-MM-DD')));
          }}>
          <img src={next_date} />
        </div>
      </div>
      {/* NO NUTRITION  */}
      {miNutritionList?.breakfast?.length > 0 ||
      miNutritionList?.lunch?.length > 0 ||
      miNutritionList?.dinner?.length > 0 ||
      miNutritionList?.snacks?.length > 0 ? (
        <>
          <div className="d-flex flex-wrap justify-content-end mb-3 add-an-entry-btn">
            {/* <Link to="/minutrition/minutritionsearchfood" state={{ state_title: 'Mi Nutrition' }}> */}
            {/* <button
              onClick={() => {
                sessionStorage.setItem('selectedDate', dayjs(dateState).format('YYYY-MM-DD'));
                navigate('minutritionsearchfood');
              }}>
              <img src={plus_ic} />
              Add an Entry
            </button> */}
            {/* </Link> */}
          </div>
          {miNutritionList?.breakfast?.length > 0 && (
            <div className="edit-ser-table mb-3">
              <>
                <div className="table-wrapper mb-3">
                  <Table responsive className="mb-0">
                    <thead>
                      <tr>
                        <th>Break Fast</th>
                        <th>Calories (kcal)</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {miNutritionList?.breakfast?.length &&
                        miNutritionList?.breakfast?.map((breakfast: any, index: number) => (
                          <tr key={index}>
                            <td>{breakfast?.foodName}</td>
                            <td>{breakfast?.calories}</td>
                            <td>
                              {/* <Link
                            to="/minutrition/minutritionaddanentry"
                            state={{
                              state_title: 'Mi Nutrition',
                              isEdit: true,
                              nutritionData: data
                            }}> */}

                              <button
                                className="me-2"
                                onClick={async () => {
                                  // await dispatch(FetchMiNutritionListById(data?.nutritionId));
                                  await navigate('/minutrition/minutritionaddanentry', {
                                    state: { isEdit: true, foodData: breakfast }
                                  });
                                }}>
                                <img src={edit_ic} />
                              </button>
                              {/* </Link> */}
                              <button
                                onClick={() => {
                                  toggleDelete();
                                  setFoodNutritionId(breakfast.nutritionId);
                                }}>
                                <img src={delete_ic} />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </>
            </div>
          )}
          {miNutritionList?.snacks?.length > 0 && (
            <div className="edit-ser-table mb-3">
              <>
                <div className="table-wrapper mb-3">
                  <Table responsive className="mb-0">
                    <thead>
                      <tr>
                        <th>Snacks</th>
                        <th>Calories (kcal)</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {miNutritionList?.snacks?.length &&
                        miNutritionList?.snacks?.map((snacks: any, index: number) => (
                          <tr key={index}>
                            <td>{snacks?.foodName}</td>
                            <td>{snacks?.calories}</td>
                            <td>
                              {/* <Link
                            to="/minutrition/minutritionaddanentry"
                            state={{
                              state_title: 'Mi Nutrition',
                              isEdit: true,
                              nutritionData: data
                            }}> */}

                              <button
                                className="me-2"
                                onClick={async () => {
                                  // await dispatch(FetchMiNutritionListById(data?.nutritionId));
                                  await navigate('/minutrition/minutritionaddanentry', {
                                    state: { isEdit: true, foodData: snacks }
                                  });
                                }}>
                                <img src={edit_ic} />
                              </button>
                              {/* </Link> */}
                              <button
                                onClick={() => {
                                  toggleDelete();
                                  setFoodNutritionId(snacks.nutritionId);
                                }}>
                                <img src={delete_ic} />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </>
            </div>
          )}
          {miNutritionList?.lunch?.length > 0 && (
            <div className="edit-ser-table mb-3">
              <>
                <div className="table-wrapper mb-3">
                  <Table responsive className="mb-0">
                    <thead>
                      <tr>
                        <th>Lunch</th>
                        <th>Calories (kcal)</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {miNutritionList?.lunch?.length &&
                        miNutritionList?.lunch?.map((lunch: any, index: number) => (
                          <tr key={index}>
                            <td>{lunch?.foodName}</td>
                            <td>{lunch?.calories}</td>
                            <td>
                              {/* <Link
                            to="/minutrition/minutritionaddanentry"
                            state={{
                              state_title: 'Mi Nutrition',
                              isEdit: true,
                              nutritionData: data
                            }}> */}

                              <button
                                className="me-2"
                                onClick={async () => {
                                  // await dispatch(FetchMiNutritionListById(data?.nutritionId));
                                  await navigate('/minutrition/minutritionaddanentry', {
                                    state: { isEdit: true, foodData: lunch }
                                  });
                                }}>
                                <img src={edit_ic} />
                              </button>
                              {/* </Link> */}
                              <button
                                onClick={() => {
                                  toggleDelete();
                                  setFoodNutritionId(lunch.nutritionId);
                                }}>
                                <img src={delete_ic} />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </>
            </div>
          )}
          {miNutritionList?.dinner?.length > 0 && (
            <div className="edit-ser-table mb-3">
              <>
                <div className="table-wrapper mb-3">
                  <Table responsive className="mb-0">
                    <thead>
                      <tr>
                        <th>Dinner</th>
                        <th>Calories (kcal)</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {miNutritionList?.dinner?.length &&
                        miNutritionList?.dinner?.map((dinner: any, index: number) => (
                          <tr key={index}>
                            <td>{dinner?.foodName}</td>
                            <td>{dinner?.calories}</td>
                            <td>
                              {/* <Link
                            to="/minutrition/minutritionaddanentry"
                            state={{
                              state_title: 'Mi Nutrition',
                              isEdit: true,
                              nutritionData: data
                            }}> */}

                              <button
                                className="me-2"
                                onClick={async () => {
                                  // await dispatch(FetchMiNutritionListById(data?.nutritionId));
                                  await navigate('/minutrition/minutritionaddanentry', {
                                    state: { isEdit: true, foodData: dinner }
                                  });
                                }}>
                                <img src={edit_ic} />
                              </button>
                              {/* </Link> */}
                              <button
                                onClick={() => {
                                  toggleDelete();
                                  setFoodNutritionId(dinner.nutritionId);
                                }}>
                                <img src={delete_ic} />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </>
            </div>
          )}
          {Object.keys(miNutritionList)?.length > 0 && (
            <>
              <div className="d-flex flex-wrap align-item-center justify-content-between mb-3">
                <h4 className="edit-ser-heading mb-3">Servings</h4>
                <Link to="#">
                  <button className="view-breakdown" onClick={toggle}>
                    View Full Breakdown
                  </button>
                </Link>
              </div>
              <div className="edit-ser-table mb-3">
                <div className="table-wrapper mb-3">
                  <Table responsive className="mb-0">
                    <thead>
                      <tr>
                        <th>Fat (g)</th>
                        <th>Carbs (g)</th>
                        <th>Protein (g)</th>
                        <th>Calories (kcal)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{miNutritionSummary?.totalFat}</td>
                        <td>{miNutritionSummary?.totalCarbohydrate}</td>
                        <td>{miNutritionSummary?.totalProtein}</td>
                        <td>{miNutritionSummary?.totalCalories}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="no-nutrition d-flex flex-wrap justify-content-center">
          <p className="w-100 text-center mb-3 mt-5">
            {`You don't have any nutrition entries for this day.`}
          </p>
          <img className="pe-none" src={no_nutrition} />
          {/* <Link to="minutritionsearchfood"> */}{' '}
          {/* <button
            onClick={() => {
              sessionStorage.setItem('selectedDate', dayjs(dateState).format('YYYY-MM-DD'));
              navigate('minutritionsearchfood');
            }}>
            <img src={plus_ic} className="me-2" />
            Add an Entry
          </button> */}
          {/* </Link> */}
        </div>
      )}
      <BreakdownModal modal={modal} toggle={toggle} miNutritionSummary={miNutritionSummary} />
      <CommonDelete
        header="Delete Nutrition"
        message="Are you sure you want to delete this nutrition?"
        modal={isdelete}
        toggle={() => {
          toggleDelete();
        }}
        confirmDeleteClick={() => {
          dispatch(
            deleteNutritionFoodEntry({
              id: getFoodNutritionId,
              date: dayjs(dateState).format('YYYY-MM-DD')
            })
          );
          toggleDelete();
        }}
      />
    </>
  );
}

export default MiNutrition;
