import React, { useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ErrorMessage } from '../..';
import {
  createScheduleGeneral,
  getScheduleGeneralListById,
  updateScheduleGeneral
} from '../../../store/Slices/userModule/DashboardSlice/dashboardAction';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import ReactSelect from 'react-select';
import CommonConfirmModal from '../../common/ConfirmModal';

interface GeneralFitnessRecordValues {
  date: string | Date;
  scheduleGeneralId: number;
  userId: number;
  activity: string;
  priorActivity: string;
  duration: number;
  priorNutrition: string;
  details: string;
  // heartRateBefore: number;
  // heartRateAter: number;
  // latitude: number;
  // longitude: number;

  // Repeat
  startDate: string;
  endDate: string;
  daysOfWeek?: any;
  repeats?: number;
  recurrenceType?: number;
  isRecurrence: boolean;
  isOveride: boolean;
  slot: number;
}

function GeneralFitnessRecord(props: any) {
  const GeneralFitnessRecord = props.activeTab;
  const [confirmModal, setConfirmModal] = useState(false);
  const [editPayloadData, setEditPayloadData] = useState<any>();
  const [isDetailRequire, setIsDetailRequire] = useState(props?.model?.isDetailRequired);
  const [isStartOpen, setIsStartOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { localUserData } = useAppSelector((state) => state?.common);
  const currentRecord = useAppSelector((state) => state.miDashboard.GetScheduleGeneralListById);

  useEffect(() => {
    props?.model?.currentGeneralFitnessData?.scheduleGeneralId &&
      props?.model?.isEditMode &&
      dispatch(
        getScheduleGeneralListById(props?.model?.currentGeneralFitnessData?.scheduleGeneralId)
      );
  }, []);

  const initialValues: GeneralFitnessRecordValues = {
    date: props?.model?.isEditMode ? currentRecord.date : new Date(),
    scheduleGeneralId: props?.model?.isEditMode ? currentRecord.scheduleGeneralId : 0,
    userId: props?.model?.isEditMode ? currentRecord.userId : 0,
    activity: props?.model?.isEditMode ? currentRecord.activity : '',
    priorActivity: props?.model?.isEditMode ? currentRecord.priorActivity : '',
    duration: props?.model?.isEditMode ? currentRecord.duration : null,
    priorNutrition: props?.model?.isEditMode ? currentRecord.priorNutrition : '',
    details: props?.model?.isEditMode ? currentRecord.details : '',
    // heartRateBefore: props?.model?.isEditMode ? currentRecord.heartRateBefore : null,
    // heartRateAter: props?.model?.isEditMode ? currentRecord.heartRateAter : null,
    // latitude: props?.model?.isEditMode ? currentRecord.latitude : null,
    // longitude: props?.model?.isEditMode ? currentRecord.longitude : null,
    // Repeat
    startDate: props?.model?.isEditMode ? currentRecord.startDate : null,
    endDate: props?.model?.isEditMode ? currentRecord.endDate : null,
    daysOfWeek: props?.model?.isEditMode
      ? currentRecord?.daysOfWeek || [0, 0, 0, 0, 0, 0, 0]
      : [0, 0, 0, 0, 0, 0, 0],
    repeats: props?.model?.isEditMode ? currentRecord.repeats : null,
    recurrenceType: props?.model?.isEditMode ? currentRecord.recurrenceType : null,
    isRecurrence: props?.model?.isEditMode ? currentRecord.isRecurrence : false,
    isOveride: props?.model?.isEditMode ? currentRecord.isOveride : false,
    slot: props?.model?.isEditMode ? currentRecord.slot : 0
  };

  const GeneralFitnessRecordSchema = Yup.object().shape({
    activity: Yup.string().required('Please enter activity').strict(true),
    priorActivity: Yup.string().required('Please enter prior activity').strict(true),
    duration: Yup.number().typeError('Please enter number').required('Please enter duration'),
    priorNutrition: Yup.string().required('Please enter prior nutrition').strict(true),
    details: Yup.string()
      .required('Please enter details')
      .trim('Leading and trailing spaces not allowed')
      .strict(true),
    // heartRateBefore: Yup.number()
    //   .typeError('Please enter number')
    //   .required('Please enter heart rate before'),

    // heartRateAter: Yup.number()
    //   .typeError('Please enter number')
    //   .required('Please enter heart rate after'),
    // latitude: Yup.number().typeError('Please enter number').required('Please enter latitude'),
    // longitude: Yup.number().typeError('Please enter number').required('Please enter longitude'),
    // Repeat
    startDate: Yup.string()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.string().nullable().required('Please select start date')
      }),
    endDate: Yup.string()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.string().nullable().required('Please select end date')
      }),
    repeats: Yup.number()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.number().nullable().required('Please enter interval duration')
      }),
    recurrenceType: Yup.number()
      .nullable()
      .when('isRecurrence', {
        is: true,
        then: () => Yup.number().nullable().required('Please select recurrence')
      })
  });

  const recurrence = [
    { label: 'Daily', value: 1 },
    { label: 'Weekly', value: 2 },
    { label: 'Monthly', value: 3 }
  ];

  return (
    <>
      <div className="auth-left-wrapper">
        {!props.model.isEditMode && (
          <h4 className="mb-3 popup-subtitle">Add General Fitness Record</h4>
        )}
        {GeneralFitnessRecord == 'General Fitness' && (
          <Formik
            initialValues={initialValues}
            enableReinitialize={props.model.isEditMode ? true : false}
            validationSchema={GeneralFitnessRecordSchema}
            onSubmit={(values, actions) => {
              values.userId = localUserData.userId || 0;
              if (!props?.model?.isEditMode) {
                values.date = props?.model?.currentSelectedDate || dayjs().format('YYYY-MM-DD');
              }
              if (currentRecord.length > 0) {
                values.date = dayjs((currentRecord as any)?.date).format('YYYY-MM-DD');
              }
              if (!values?.isRecurrence) {
                values.repeats = 0;
                values.startDate = null;
                values.endDate = null;
                values.recurrenceType = 0;
                values.slot = 0;
              }
              if (values?.startDate && values.endDate) {
                values.startDate = dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss');
                values.endDate = dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss');
              }
              if (values.scheduleGeneralId) {
                if (values.isRecurrence) {
                  setConfirmModal(true);
                  setEditPayloadData(values);
                } else {
                  dispatch(updateScheduleGeneral(values));
                  props?.toggle();
                }
              } else {
                dispatch(createScheduleGeneral(values));
                props?.toggle();
              }
              actions.setSubmitting(false);
              // props?.toggle();
            }}>
            {({ errors, touched, values, setFieldValue, handleChange }) => (
              <Form>
                {isDetailRequire && props?.model?.isEditMode && (
                  <Row>
                    <Col md="12">
                      <FormGroup row className="d-flex flex-wrap align-items-top">
                        <Col sm={12} className="m-auto">
                          <span>{dayjs((currentRecord as any)?.date)?.format('MM/DD/YYYY')}</span>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Activity:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentRecord as any)?.activity}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="activity"
                            placeholder="Activity"
                            value={values.activity}
                            onChange={handleChange}
                            type="text"></Input>
                          {errors.activity && touched.activity && (
                            <ErrorMessage text={errors.activity} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Prior activity:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentRecord as any)?.priorActivity}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="priorActivity"
                            placeholder="Prior activity"
                            value={values.priorActivity}
                            onChange={handleChange}
                            type="text"></Input>
                          {errors.priorActivity && touched.priorActivity && (
                            <ErrorMessage text={errors.priorActivity} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Row>
                      <Col md="6">
                        <Col md="12">
                          <FormGroup row className="d-flex flex-wrap align-items-top">
                            <Label className="mb-0" sm={4}>
                              Duration:
                            </Label>
                            {isDetailRequire && props?.model?.isEditMode ? (
                              <Col sm={8} className="m-auto">
                                <span>{(currentRecord as any)?.duration} Minutes</span>
                              </Col>
                            ) : (
                              <Col sm={8} className="side-content position-relative">
                                <Input
                                  name="duration"
                                  placeholder="Duration"
                                  value={values.duration}
                                  onChange={handleChange}
                                  type="number"></Input>
                                {errors.duration && touched.duration && (
                                  <ErrorMessage text={errors.duration} />
                                )}
                                <span>Minutes</span>
                              </Col>
                            )}
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup row className="d-flex flex-wrap align-items-top">
                            <Label className="mb-0" sm={4}>
                              Prior nutrition:
                            </Label>
                            {isDetailRequire && props?.model?.isEditMode ? (
                              <Col sm={8} className="m-auto">
                                <span>{(currentRecord as any)?.priorNutrition}</span>
                              </Col>
                            ) : (
                              <Col sm={8}>
                                <Input
                                  name="priorNutrition"
                                  placeholder="Prior nutrition"
                                  value={values.priorNutrition}
                                  onChange={handleChange}
                                  type="text"></Input>
                                {errors.priorNutrition && touched.priorNutrition && (
                                  <ErrorMessage text={errors.priorNutrition} />
                                )}
                              </Col>
                            )}
                          </FormGroup>
                        </Col>
                      </Col>
                      <Col md="6">
                        <Col md="12">
                          <FormGroup row className="d-flex flex-wrap align-items-top">
                            <Label className="mb-0" sm={4}>
                              Details:
                            </Label>
                            {isDetailRequire && props?.model?.isEditMode ? (
                              <Col sm={8} className="m-auto">
                                <span>{(currentRecord as any)?.details}</span>
                              </Col>
                            ) : (
                              <Col sm={8}>
                                <Input
                                  name="details"
                                  placeholder="Details"
                                  value={values.details}
                                  onChange={handleChange}
                                  type="textarea"></Input>
                                {errors.details && touched.details && (
                                  <ErrorMessage text={errors.details} />
                                )}
                              </Col>
                            )}
                          </FormGroup>
                        </Col>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Heart rate before:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentRecord as any)?.heartRateBefore} BPM</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="side-content position-relative">
                          <Input
                            name="heartRateBefore"
                            placeholder="Heart rate before"
                            value={values.heartRateBefore}
                            onChange={handleChange}
                            type="number"></Input>

                          {errors.heartRateBefore && touched.heartRateBefore && (
                            <ErrorMessage text={errors.heartRateBefore} />
                          )}
                          <span>BPM</span>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Heart rate after:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentRecord as any)?.heartRateAter} BPM</span>
                        </Col>
                      ) : (
                        <Col sm={8} className="side-content position-relative">
                          <Input
                            name="heartRateAter"
                            placeholder="Heart rate after"
                            value={values.heartRateAter}
                            onChange={handleChange}
                            type="number"></Input>
                          {errors.heartRateAter && touched.heartRateAter && (
                            <ErrorMessage text={errors.heartRateAter} />
                          )}
                          <span>BPM</span>
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Latitude:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentRecord as any)?.latitude}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="latitude"
                            placeholder="Latitude"
                            value={values.latitude}
                            onChange={handleChange}
                            type="text"></Input>
                          {errors.latitude && touched.latitude && (
                            <ErrorMessage text={errors.latitude} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row className="d-flex flex-wrap align-items-top">
                      <Label className="mb-0" sm={4}>
                        Longitude:
                      </Label>
                      {isDetailRequire && props?.model?.isEditMode ? (
                        <Col sm={8} className="m-auto">
                          <span>{(currentRecord as any)?.longitude}</span>
                        </Col>
                      ) : (
                        <Col sm={8}>
                          <Input
                            name="longitude"
                            placeholder="Longitude"
                            value={values.longitude}
                            onChange={handleChange}
                            type="text"></Input>
                          {errors.longitude && touched.longitude && (
                            <ErrorMessage text={errors.longitude} />
                          )}
                        </Col>
                      )}
                    </FormGroup>
                  </Col> */}
                  <Col md="6"></Col>

                  {isDetailRequire && props?.model?.isEditMode ? (
                    <Col md="12">{(currentRecord as any)?.isRecurrence && <b>Repeat</b>}</Col>
                  ) : (
                    <Col md="12">
                      <div className="custom-checkbox d-inline-block">
                        <label className="checkbox-container">
                          Repeat
                          <Input
                            name="isRecurrence"
                            type="checkbox"
                            className="form-check-input"
                            checked={values.isRecurrence}
                            onChange={(e) => {
                              setFieldValue('isRecurrence', e.target.checked);
                            }}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </Col>
                  )}

                  {values?.isRecurrence && (
                    <>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" lg={4}>
                            When does it start?
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{dayjs(currentRecord?.startDate).format('MM/DD/YYYY')}</span>
                            </Col>
                          ) : (
                            <Col lg={8}>
                              <Row className="flex-wrap align-items-top">
                                <Col>
                                  <DatePicker
                                    autoComplete="off"
                                    name="startDate"
                                    selected={
                                      values?.startDate ? new Date(values?.startDate) : null
                                    }
                                    onChange={(date: Date | null) => {
                                      setFieldValue('startDate', date);
                                      setFieldValue('endDate', null);
                                    }}
                                    placeholderText="Please select start date"
                                    dateFormat="MM/dd/yyyy"
                                    popperProps={{ strategy: 'fixed' }}
                                  />
                                  {errors.startDate && touched.startDate && (
                                    <ErrorMessage text={errors.startDate} />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" lg={4}>
                            When does it end?
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{dayjs(currentRecord?.endDate).format('MM/DD/YYYY')}</span>
                            </Col>
                          ) : (
                            <Col lg={8}>
                              <Row className="flex-wrap align-items-top">
                                <Col>
                                  <DatePicker
                                    autoComplete="off"
                                    name="endDate"
                                    disabled={values.startDate ? false : true}
                                    minDate={values.startDate && new Date(values.startDate)}
                                    selected={values?.endDate ? new Date(values?.endDate) : null}
                                    onChange={(date: Date | null) => setFieldValue('endDate', date)}
                                    placeholderText="Please select end date"
                                    dateFormat="MM/dd/yyyy"
                                    popperProps={{ strategy: 'fixed' }}
                                  />
                                  {errors.endDate && touched.endDate && (
                                    <ErrorMessage text={errors.endDate} />
                                  )}
                                </Col>{' '}
                              </Row>
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" sm={4}>
                            Recurrence
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>
                                {
                                  recurrence.find(
                                    (data) => data.value === currentRecord.recurrenceType
                                  )?.label
                                }
                              </span>
                            </Col>
                          ) : (
                            <Col sm={8}>
                              <ReactSelect
                                className="react-select-dropdown"
                                options={recurrence}
                                placeholder="Select recurrence"
                                name="recurrenceType"
                                onChange={(options) => {
                                  setFieldValue('recurrenceType', +options.value);
                                }}
                                value={recurrence.find(
                                  (data) => data.value === values.recurrenceType
                                )}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  control: (provided) => ({
                                    ...provided,
                                    borderRadius: 50,
                                    paddingLeft: 8,
                                    boxShadow: null
                                  })
                                }}
                              />
                              {errors.recurrenceType && touched.recurrenceType && (
                                <ErrorMessage text={errors.recurrenceType} />
                              )}
                            </Col>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row className="d-flex flex-wrap align-items-top">
                          <Label className="mb-0" sm={4}>
                            Interval duration
                          </Label>
                          {isDetailRequire && props?.model?.isEditMode ? (
                            <Col sm={8} className="m-auto">
                              <span>{(currentRecord as any)?.repeats}</span>
                            </Col>
                          ) : (
                            <Col sm={8}>
                              <Input
                                name="repeats"
                                placeholder="Interval duration"
                                value={values.repeats}
                                onChange={handleChange}
                                type="number"
                                min={1}
                                max={99}></Input>
                              {errors.repeats && touched.repeats && (
                                <ErrorMessage text={errors.repeats} />
                              )}
                            </Col>
                          )}
                        </FormGroup>
                      </Col>

                      {isDetailRequire && props?.model?.isEditMode ? (
                        <></>
                      ) : (
                        values.recurrenceType === 2 && (
                          <Col md="12" className="d-flex flex-wrap mb-3">
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Sun
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[0]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[0]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Mon
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[1]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[1]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Tue
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[2]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[2]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Wed
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[3]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[3]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Thu
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[4]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[4]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Fri
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[5]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[5]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                            <div className="custom-checkbox me-4">
                              <label className="checkbox-container">
                                Sat
                                <Input
                                  name="daysOfWeek"
                                  onChange={(e) => {
                                    setFieldValue('daysOfWeek[6]', e.target.checked ? 1 : 0);
                                  }}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={values?.daysOfWeek && values?.daysOfWeek[6]}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          </Col>
                        )
                      )}
                    </>
                  )}
                  <Col md="12">
                    <div className="justify-content-end mt-3 col-md-12 modal-footer">
                      <Button className="cancel-btn me-3" onClick={props?.toggle}>
                        Cancel
                      </Button>
                      {isDetailRequire && props?.model?.isEditMode && (
                        <Button
                          className="save-record"
                          type="button"
                          onClick={() => {
                            setIsDetailRequire(!isDetailRequire);
                          }}>
                          Edit
                        </Button>
                      )}
                      {!isDetailRequire && (
                        <Button className="save-record" type="submit">
                          {/* {Object.keys(currentRecord).length ? 'Update' : 'Add'} */}
                          {props?.model.isEditMode ? 'Update' : 'Add'}
                        </Button>
                      )}
                      {props?.model?.isDateSelected && !props?.model?.isEditMode && (
                        <Button className="save-record" type="submit">
                          Add
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <CommonConfirmModal
        header="Please confirm"
        message="Are you sure you want to over ride all record ?"
        modal={confirmModal}
        toggle={() => {
          setConfirmModal(false);
        }}
        confirmNoClickClick={() => {
          dispatch(updateScheduleGeneral({ ...editPayloadData, onlyThisDate: true }));
          setConfirmModal(false);
          setEditPayloadData({});
          props?.toggle();
        }}
        confirmDeleteClick={() => {
          dispatch(updateScheduleGeneral({ ...editPayloadData, onlyThisDate: false }));
          setConfirmModal(false);
          setEditPayloadData({});
          props?.toggle();
        }}
      />
    </>
  );
}

export default GeneralFitnessRecord;
